import React from 'react'
import html2canvas from 'html2canvas'
import fileSaver from 'file-saver'
import { getExcel } from '../../utils/request'
import './style.scss'

const DownLoadBar = ({ imageId, title, style, excelParams }) => {
  const downloadExcel = async () => {
    let { name, key, data, filename } = excelParams
    const res = await getExcel({
      headers_name: name,
      headers_key: key,
      data,
      filename,
    })
    const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    const link = document.createElement('a')
    link.download = `${filename}.xlsx`
    link.href = URL.createObjectURL(blob)
    link.click()
  }
  const downloadImage = () => {
    html2canvas(document.getElementById(imageId)).then((canvas) => {
      canvas.toBlob(function (blob) {
        fileSaver.saveAs(blob, `${title}.png`)
      })
    })
  }
  return (
    <div className="downloadBar" style={style}>
      <div className="btn image">
        <i onClick={downloadImage} />
        <span>下载图片</span>
      </div>
      <div className="btn excel">
        <i onClick={downloadExcel} />
        <span>下载excel</span>
      </div>
    </div>
  )
}

export default DownLoadBar
