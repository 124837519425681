import React, { useReducer, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, Route, Switch, Redirect, Link } from 'react-router-dom';
import { Button, Input, message, Menu } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import Project from './project';
import Manage from './manage';
import {
  getOwnPortfolioDetail,
  editOwnPortfolioInfo,
  getOwnPortfolioFollow,
  postOwnPortfolioFollow,
  deleteOwnPortfolioFollow,
  getOwnPortfolioNotifications,
  setOnwPortfolioCover
} from '../../../../utils/request';
import { getOwnPortfolioFollowers } from '../../../../utils/api/ownportfolio';
import { deBounce, formatKilobit, simpleToast } from '../../../../utils';
import { coverMap } from './../../../../utils/variable';
import './style.scss';

const { TextArea } = Input;

const formatVerb = verb => {
  switch (verb) {
    case 'studio_project_add':
      return '添加作品';
    case 'studio_project_update':
      return '更新作品';
    case 'studio_bulk_add_project':
      return '添加作品';
    case 'studio_project_delete':
      return '移除作品';
    case 'studioactivity':
      return '更新工作室信息';
    default:
      return verb;
  }
};

const types = {
  SWITCH_TYPE: 'SWITCH_TYPE',
  SET_INFO_DATA: 'SET_INFO_DATA',
  SET_PROJECT_DATA: 'SET_PROJECT_DATA',
  SET_ACTIVITY_DATA: 'SET_ACTIVITY_DATA',
  SET_ALL_DATA: 'SET_ALL_DATA',
  SET_EDIT_STATUS: 'SET_EDIT_STATUS',
  ON_TITLE_CHANGE: 'ON_TITLE_CHANGE',
  ON_DESCR_CHANGE: 'ON_DESCR_CHANGE',
  SET_FOLLOWER_COUNT: 'SET_FOLLOWER_COUNT',
  SET_FOLLOW_TEXT: 'SET_FOLLOW_TEXT',
  SET_FOLLOW_STATUS: 'SET_FOLLOW_STATUS',
  SET_IMAGE_FILE: 'SET_IMAGE_FILE'
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_ALL_DATA:
      return {
        ...state,
        infoData: action.infoData || [],
        infoTitle: action.infoData.title || '',
        infoDescr: action.infoData.description || '',
        exists: true
      };
    case types.SET_ACTIVITY_DATA:
      return { ...state, activityData: action.data };
    case types.SET_EDIT_STATUS:
      return {
        ...state,
        editTitle:
          action.editTitle !== undefined ? action.editTitle : state.editTitle,
        editDescr:
          action.editDescr !== undefined ? action.editDescr : state.editDescr
      };
    case types.ON_TITLE_CHANGE:
      return {
        ...state,
        infoTitle: action.text
      };
    case types.ON_DESCR_CHANGE:
      return {
        ...state,
        infoDescr: action.text
      };
    case types.SWITCH_TYPE:
      return {
        ...state,
        type: action.value
      };
    case types.SET_FOLLOWER_COUNT:
      return {
        ...state,
        followers: action.count
      };
    case types.SET_FOLLOW_TEXT:
      return {
        ...state,
        followText: action.text
      };
    case types.SET_FOLLOW_STATUS:
      return { ...state, isFollow: action.status };
    case types.SET_IMAGE_FILE:
      return { ...state, imageFile: action.file };
    default:
      return state;
  }
};

const OwnPortfolioItem = props => {
  const initialState = {
    type: window.location.href.substring(window.location.href.lastIndexOf('/')),
    infoData: {},
    activityData: [],
    editTitle: false,
    editDescr: false,
    infoTitle: '',
    infoDescr: '',
    isFollow: null,
    followers: 0,
    followText: '已关注',
    imageFile: null,
    exists: false
  };
  const userInfo = useSelector(state => state.userInfo);
  const match = useRouteMatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    infoData,
    activityData,
    editTitle,
    editDescr,
    infoTitle,
    infoDescr,
    isFollow,
    followers,
    followText,
    imageFile,
    exists
  } = state;

  const fetchDetail = id => {
    return new Promise((resolve, reject) => {
      getOwnPortfolioDetail(id)
        .then(res => {
          if (Object.is(res.status, 200)) resolve(res.data);
        })
        .catch(err => reject(err));
    });
  };

  const fetchActivity = id => {
    return new Promise((resolve, reject) => {
      getOwnPortfolioNotifications(id).then(res => {
        if (Object.is(res.status, 200)) resolve(res.data);
      });
    });
  };

  const fetchData = useCallback(() => {
    Promise.all([fetchDetail(match.params.id), fetchActivity(match.params.id)])
      .then(res => {
        const [infoData, activityData] = res;
        dispatch({
          type: types.SET_ALL_DATA,
          infoData
        });
        dispatch({
          type: types.SET_ACTIVITY_DATA,
          data: activityData
        });
      })
      .catch(err => {
        message.error('该作品集已被创建者删除', 0);
      });
  }, [match.params.id]);

  const handleEditPortfolioInfo = type => {
    let getToast = simpleToast();
    return () => {
      if (!window.navigator.onLine) {
        getToast({ type: 'error', text: '操作失败，请稍后再尝试' });
        return;
      }
      if (state[type]) {
        const data = {
          title: infoTitle,
          description: infoDescr
        };

        editOwnPortfolioInfo(match.params.id, data).then(res => {
          dispatch({
            type: types.SET_EDIT_STATUS,
            [type]: false
          });
          message.success('保存成功');
        });
      } else {
        dispatch({
          type: types.SET_EDIT_STATUS,
          [type]: true
        });
      }
    };
  };

  const getFollowStatus = useCallback(() => {
    getOwnPortfolioFollow(match.params.id).then(res => {
      if (Object.is(res.status, 200)) {
        dispatch({ type: types.SET_FOLLOW_STATUS, status: res.data.is_follow });
      }
    });
  }, [match.params.id]);

  const patchFollowStatus = () => {
    postOwnPortfolioFollow(match.params.id)
      .then(res => {
        if (Object.is(res.status, 200)) {
          dispatch({ type: types.SET_FOLLOW_STATUS, status: true });
          getFollowers(match.params.id);
        }
      })
      .catch(err => {
        message.error(err.response.data.detail);
      });
  };

  const deleteFollowStatus = () => {
    deleteOwnPortfolioFollow(match.params.id).then(res => {
      if (Object.is(res.status, 200)) {
        dispatch({ type: types.SET_FOLLOW_STATUS, status: false });
        getFollowers(match.params.id);
      }
    });
  };

  const getActivityData = () => {
    getOwnPortfolioNotifications(match.params.id).then(res => {
      if (Object.is(res.status, 200)) {
        dispatch({
          type: types.SET_ACTIVITY_DATA,
          data: res.data
        });
      }
    });
  };

  const getFollowers = id => {
    getOwnPortfolioFollowers(id).then(res => {
      if (Object.is(res.status, 200))
        dispatch({ type: types.SET_FOLLOWER_COUNT, count: res.data.count });
    });
  };

  const uploadImg = () => {
    const fileRegExp = /\.(jpg|jpeg|png|bmp|JPG|JPEG|PNG|BMP)$/;
    let formData = new FormData();
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/jpg, image/jpeg, image/png, image/bmp';
    input.click();
    input.onchange = function (e) {
      let reader;
      if (e.target.files.length) {
        if (!fileRegExp.test(e.target.files[0].name)) {
          message.warning('上传封面失败');
          return;
        }

        if (e.target.files[0].size / 1024 > 1024) {
          message.warn('图片文件过大');
          return;
        }

        let file = e.target.files[0];
        reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        formData.append('file', e.target.files[0]);
        setOnwPortfolioCover(match.params.id, formData).then(res => {
          if (Object.is(res.status, 200)) {
            dispatch({
              type: types.SET_IMAGE_FILE,
              file
            });
            fetchData();
            getActivityData();
          }
        });
      }
    };
  };

  const getCover = () => {
    if (imageFile) {
      return URL.createObjectURL(imageFile);
    } else {
      let res = 'https://' + window.location.host;
      if (infoData && infoData.image && infoData.image.indexOf('/') !== 0)
        res += '/';
      return res + infoData.image;
    }
  };

  useEffect(() => {
    if (userInfo) {
      getFollowers(match.params.id);
    }
  }, [match.params.id, userInfo]);
  useEffect(() => {
    getFollowStatus();
  }, [getFollowStatus]);

  useEffect(() => {
    fetchData();
  }, [fetchData, match.params.id, match.params.type]);

  const renderFollowBtn = () =>
    userInfo ? (
      <>
        {isFollow ? (
          <Button
            className="follow-btn follow"
            size="large"
            onClick={deleteFollowStatus}
            onMouseOut={() => {
              dispatch({ type: types.SET_FOLLOW_TEXT, text: `已关注` });
            }}
            onMouseOver={() => {
              dispatch({ type: types.SET_FOLLOW_TEXT, text: '取消关注' });
            }}
          >
            {followText}
            {Object.is(followText, '已关注')
              ? ` ${formatKilobit(followers)}`
              : ''}
          </Button>
        ) : (
          <Button
            icon={<PlusOutlined />}
            className="follow-btn unfollow"
            size="large"
            onClick={patchFollowStatus}
          >
            关注{followers !== 0 ? ` ${formatKilobit(followers)}` : ''}
          </Button>
        )}
      </>
    ) : null;
  const { role, image, origin } = infoData;

  return (
    <div id="ownportfolioItem">
      {infoData ? (
        <div className="container">
          <div className="header">
            <div className="infoWrapper">
              <div className="iconContainer">
                <img
                  src={
                    Object.keys(infoData).length
                      ? image
                        ? getCover()
                        : coverMap[origin]
                      : coverMap['longan']
                  }
                  alt="cover"
                />
                {userInfo &&
                (Object.is(role, 'manager') || Object.is(role, 'owner')) ? (
                  <div className="mask" onClick={uploadImg}>
                    更换封面
                  </div>
                ) : null}
              </div>
              <div className="detail">
                <div className="editarea">
                  {!!!editTitle ? (
                    <>
                      <div className="titleTextArea">
                        <h3 className="title">
                          {infoTitle}
                          <>
                            {userInfo &&
                            (Object.is(role, 'manager') ||
                              Object.is(role, 'owner')) ? (
                              <span
                                className="btn"
                                style={{ fontWeight: 400 }}
                                onClick={deBounce(
                                  handleEditPortfolioInfo('editTitle'),
                                  300
                                )}
                              >
                                (编辑)
                              </span>
                            ) : null}
                          </>
                        </h3>
                      </div>
                    </>
                  ) : (
                    <>
                      <Input
                        onChange={e => {
                          dispatch({
                            type: types.ON_TITLE_CHANGE,
                            text: e.target.value
                          });
                        }}
                        placeholder="请输入名字..."
                        className="textInput"
                        maxLength={24}
                        value={infoTitle}
                      />
                      <span className="textlen">
                        {infoData.title.length > 24 ? 24 : infoTitle.length}/24
                      </span>
                      <Button
                        className="saveBtn"
                        onClick={deBounce(
                          handleEditPortfolioInfo('editTitle'),
                          500
                        )}
                      >
                        保存
                      </Button>
                    </>
                  )}
                </div>
                <div className="editarea">
                  {!!!editDescr ? (
                    <>
                      <div className="descrTextArea">
                        <p>
                          {infoDescr.length > 100
                            ? `${infoDescr.slice(0, 100)}...`
                            : infoDescr || '请输入简介...'}
                          <>
                            {userInfo &&
                            (Object.is(role, 'manager') ||
                              Object.is(role, 'owner')) ? (
                              <span
                                style={{ fontWeight: 400 }}
                                className="btn"
                                onClick={deBounce(
                                  handleEditPortfolioInfo('editDescr'),
                                  300
                                )}
                              >
                                (编辑)
                              </span>
                            ) : null}
                          </>
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <TextArea
                        onChange={e => {
                          dispatch({
                            type: types.ON_DESCR_CHANGE,
                            text: e.target.value
                          });
                        }}
                        placeholder="请输入简介..."
                        className="textInput"
                        maxLength={100}
                        row={4}
                        value={
                          infoDescr.length > 100
                            ? `${infoDescr.slice(0, 100)}...`
                            : infoDescr
                        }
                      />
                      <span className="textlen">
                        {infoDescr.length > 100 ? 100 : infoDescr.length}/100
                      </span>
                      <Button
                        className="saveBtn"
                        onClick={deBounce(
                          handleEditPortfolioInfo('editDescr'),
                          500
                        )}
                      >
                        保存
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="followWrapper">{renderFollowBtn()}</div>
          </div>
          <div className="projectWrapper">
            <div className="wrapper">
              <div className="projects">
                <Menu
                  className="typeMenu"
                  onClick={values => {
                    dispatch({ type: types.SWITCH_TYPE, value: values.key });
                    props.history.push({
                      pathname: `/manage/ownportfolio/${match.params.id}/${values.key}`
                    });
                  }}
                  selectedKeys={[match.params.type]}
                  mode="horizontal"
                >
                  <Menu.Item key="project">作品</Menu.Item>
                  <Menu.Item key="manage">成员</Menu.Item>
                </Menu>
                <Switch>
                  <Route
                    exact
                    path={`/manage/ownportfolio/${match.params.id}/project`}
                  >
                    <Project
                      exists={exists}
                      info={infoData}
                      id={match.params.id}
                      getActivityData={getActivityData}
                    />
                  </Route>
                  <Route
                    path={`/manage/ownportfolio/${match.params.id}/manage`}
                  >
                    <Manage
                      exists={exists}
                      info={infoData}
                      id={match.params.id}
                    />
                  </Route>
                  <Route path="/manage/*">
                    <Redirect to="/404" />
                  </Route>
                </Switch>
              </div>
            </div>
            <div className="activity">
              {infoData.author_vip ? (
                <div className="activityHeader">
                  <img src="/image/icon-certificate.png" alt="认证" />
                  <p className="text">已认证机构</p>
                </div>
              ) : null}
              <div className="actionBar">
                <div className="title">最新动态</div>
                {userInfo && infoData.role !== 'visitor' ? (
                  <div>
                    <Link
                      to={`/manage/ownportfolio/${match.params.id}/activity`}
                    >
                      <Button type="link" size="small" onClick={() => {}}>
                        查看全部
                        <img
                          width="6"
                          style={{ marginBottom: '1px', marginLeft: '6px' }}
                          height="10"
                          src="/image/icon-arrow.svg"
                          alt="add"
                        />
                      </Button>
                    </Link>
                  </div>
                ) : null}
              </div>
              <div className="activityWrapper">
                {activityData.map((item, index) => (
                  <div className="item" key={index}>
                    <div
                      className={`activityDetail ${
                        !item.tgt_content ? 'singleproject' : ''
                      }`}
                    >
                      <Link
                        className="author"
                        to={`/account/home/${item.actor_username}`}
                        target="_blank"
                      >
                        <img
                          className="actorImg"
                          src={item.actor_avatar}
                          width="48px"
                          alt="cover"
                        />
                      </Link>
                      <span className="name">
                        <Link
                          className="author"
                          to={`/account/home/${item.actor_username}`}
                          target="_blank"
                        >
                          {item.actor_nick || item.actor_username}
                        </Link>
                      </span>
                      <span className="action">{formatVerb(item.verb)}</span>
                      <span className="target">
                        {item.tgt_name}
                        {item.tgt_content
                          ? `等 ${
                              item.tgt_content.split(',').length + 1
                            } 个作品`
                          : ''}
                      </span>
                    </div>
                    <div className="time">
                      {dayjs(item.created_at).format('YYYY/MM/DD HH:mm')}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OwnPortfolioItem;
