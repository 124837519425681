import React, {
  useState,
  useLayoutEffect,
  useRef,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import './style.scss';
import { Pagination, Popover } from 'antd';
import { useHistory, Link, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { getProject } from '../../utils/request';
import Filter from '../../components/filter';

// const otherProject = [
//     {
//         name: 'UCL MicroPython',
//         image: 'https://longan-iot.oss-cn-shenzhen.aliyuncs.com/longan-iot/lms/cover/UCL%20MicroPython.jpg',
//         url: 'https://microbit-challenges.readthedocs.io/en/latest/index.html'
//     },
//     {
//         name: '3D printer projects',
//         image: 'https://longan-iot.oss-cn-shenzhen.aliyuncs.com/longan-iot/lms/cover/3D%20printer%20projects.jpg',
//         url: 'https://www.myminifactory.com/category/bbc-micro-bit'
//     },
//     {
//         name: 'Raspberry Pi',
//         image: 'https://longan-iot.oss-cn-shenzhen.aliyuncs.com/longan-iot/lms/cover/Raspberry%20Pi.jpg',
//         url: 'https://projects.raspberrypi.org/en/codeclub/microbit'
//     },
//     {
//         name: 'Machine Learning for Kids',
//         url: 'https://watson.longan.link/',
//         image: 'https://longan-iot.oss-cn-shenzhen.aliyuncs.com/longan-iot/lms/cover/ml4k.jpg'
//     }
//  ]

export const parseSearchRes = searchObj => {
  if (!window.location.search && !searchObj) return;

  let filtedRes = searchObj || qs.parse(window.location.search);

  Object.keys(filtedRes).forEach(key => {
    if (
      !['type', 'search', 'page'].includes(key) &&
      !Array.isArray(filtedRes[key]) &&
      filtedRes[key] !== 'all'
    ) {
      filtedRes[key] = [filtedRes[key]];
    }
  });
  return filtedRes;
};

export const useGetProjects = () => {
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    getProject().then(res => {
      setProjects(res.data.results);
    });
  }, []);
  return [projects, setProjects];
};

function levelParese(str) {
  switch (str) {
    case '初级':
      return 'primary';
    case '中级':
      return 'mid';
    case '高级':
      return 'high';
    default:
      return '';
  }
}
const resultType = {
  space: ['游戏', '解决生活中的问题'],
  software: ['游戏', '动画', '解决生活中的问题']
};
const knowledge = {
  space: ['图形化编程', 'AI'],
  software: ['图形化编程', 'AI']
};

export const formatFilterRes = (filterRes, is_series = false) => {
  let formatedFitlerRes = {};
  let keyMap = {
    type: is_series ? 'is_series' : 'category',

    难度: 'difficult_tags',
    知识范畴: 'knowledge_tags',
    核心知识点: 'key_tags',
    成果分类: 'result_tags',
    涉及的硬件: 'hardware_tags',
    // '适合阶段': 'level_tags',
    search: 'q',
    课程类型: 'new_course_type__iregex',
    适合阶段: 'new_suit_tags__iregex',
    // 课程模块: "course_modules",
    课程教具: 'new_teaching_aid__iregex',
    编程平台: 'new_program_platform__iregex'
  };

  Object.keys(filterRes).forEach(key => {
    if (filterRes[key] !== 'all') {
      keyMap[key] &&
        (formatedFitlerRes[keyMap[key]] =
          keyMap[key] === 'q' ? filterRes[key] : filterRes[key].join('|'));
    }
  });
  return formatedFitlerRes;
};

const Project = () => {
  const location = useLocation();
  const history = useHistory();
  const [otherProjects] = useGetProjects();
  const [projects, setProjects] = useState([]);
  const projectRef = useRef();
  const [pageSize, setPageSize] = useState(15);
  const [current, setCurrent] = useState(
    Number(qs.parse(history.location.search).page) || 1
  );
  const [conut, setCount] = useState(0);
  const [filterRes, setFilterRes] = useState();
  const searchRef = useRef();

  useLayoutEffect(() => {
    document.querySelector('.ant-pagination .ant-select-selection-item') &&
      (document.querySelector(
        '.ant-pagination .ant-select-selection-item'
      ).innerText = `${pageSize} 条/页`);
  }, [projects, pageSize, filterRes]); // eslint-disable-line

  const projectSelecteHandler = useCallback(
    project => {
      // eslint-disable-line
      history.push(`/project/${project.id}`);
    },
    [history]
  );

  const checkedRes = useMemo(() => {
    // 恢复url重置后的筛选结果
    if (searchRef.current && !location.search) {
      return parseSearchRes(qs.parse(searchRef.current));
    }
    return parseSearchRes();
  }, [location.search]); //eslint-disable-line

  useEffect(() => {
    if (searchRef.current && !location.search) {
      console.log('replace\n', JSON.stringify(filterRes));

      history.replace({
        search: qs.stringify(filterRes)
      });
    }
    searchRef.current = location.search;
  }, [location.search, filterRes, history]);

  useEffect(() => {
    if (!filterRes || JSON.stringify(checkedRes) === JSON.stringify(filterRes))
      return;
    if (!location.search) {
      console.log('replace\n', JSON.stringify(filterRes));

      history.replace({
        search: qs.stringify(filterRes)
      });
    } else {
      console.log('push\n', JSON.stringify(filterRes));

      history.push({
        search: qs.stringify(filterRes)
      });
    }
  }, [filterRes]); //eslint-disable-line

  useEffect(() => {
    if (!filterRes) return;
    getProject({
      page: filterRes.page || 1,
      page_size: pageSize,
      ...formatFilterRes(filterRes)
    }).then(res => {
      setCurrent(Number(filterRes.page || 1));
      setCount(res.data.count);
      setProjects(res.data.results);
    });
  }, [filterRes, pageSize]);

  return (
    <div id="project">
      <div className="container">
        <div className="row">
          <div className="col col--9 left">
            <div className="title">探索项目</div>
            <Filter
              searchPlacehloder={'搜索项目标题'}
              checkedRes={checkedRes}
              defaultRes={parseSearchRes()}
              types={[
                {
                  value: 'all',
                  label: '全部'
                },
                {
                  value: 'space',
                  label: '空间编程'
                },
                {
                  value: 'software',
                  label: '软件编程'
                }
              ]}
              selectedKeys={[qs.parse(history.location.search).type]}
              filtersName={[
                '知识范畴',
                '难度',
                '核心知识点',
                '成果分类',
                ...(qs.parse(history.location.search).type !== 'software'
                  ? ['涉及的硬件']
                  : [])
              ]}
              filtersType={[
                qs.parse(history.location.search).type === 'all'
                  ? [
                      ...new Set([
                        ...knowledge['space'],
                        ...knowledge['software']
                      ])
                    ]
                  : knowledge[qs.parse(history.location.search).type] || [],
                ['初级', '中级', '高级'],
                [
                  '对象控制',
                  '重复循环',
                  ...(qs.parse(history.location.search).type !== 'space'
                    ? ['数据分析']
                    : []),
                  '逻辑判断',
                  '指令属性模块',
                  ...(qs.parse(history.location.search).type !== 'space'
                    ? ['字符串']
                    : [])
                ],
                qs.parse(history.location.search).type === 'all'
                  ? [
                      ...new Set([
                        ...resultType['space'],
                        ...resultType['software']
                      ])
                    ]
                  : resultType[qs.parse(history.location.search).type] || [],
                ['智能家居硬件']
              ]}
              onChange={val => {
                setFilterRes(val);
              }}
            />
            <div className="projects" ref={projectRef}>
              {projects.map((project, index) => (
                <div className="project" key={index}>
                  <Link to={`/project/${project.id}`}>
                    <div className="img">
                      <div
                        className={`level ${levelParese(
                          project['difficult_tags']
                        )}`}
                      >
                        {project['difficult_tags']}
                      </div>
                      <img src={project.cover} alt={project.title} />
                    </div>
                  </Link>
                  <div className="title">
                    <Link to={`/project/${project.id}`}>{project.title}</Link>
                  </div>
                  <div className="description">{project.description}</div>
                  <div className="tags">
                    {Array.isArray(project.tag) &&
                      project.tag.slice(0, 2).map((tag, index) => (
                        <div className="tag" key={index}>
                          {tag}
                        </div>
                      ))}
                    {Array.isArray(project.tag) && project.tag.length > 2 ? (
                      <Popover
                        getPopupContainer={() => projectRef.current}
                        placement="bottomRight"
                        content={
                          Array.isArray(project.tag) &&
                          project.tag.map((tag, index) => (
                            <div className="tag" key={index}>
                              {tag}
                            </div>
                          ))
                        }
                        trigger="hover"
                      >
                        <img
                          src="/image/icon-more.png"
                          className="more"
                          alt="more"
                        />
                      </Popover>
                    ) : null}
                  </div>
                </div>
              ))}
              <i />
              <i />
              <i />
              <i />
              <i />
              <i />
            </div>
            {conut ? (
              <Pagination
                current={current}
                pageSize={pageSize}
                showQuickJumper={true}
                total={conut}
                defaultPageSize="15"
                onChange={val => {
                  setCurrent(val);
                  history.push({
                    path: history.location.pathname,
                    search: qs.stringify({
                      ...qs.parse(history.location.search),
                      page: val
                    })
                  });
                }}
                onShowSizeChange={(cur, size) => {
                  history.push({
                    path: history.location.pathname,
                    search: qs.stringify({
                      ...qs.parse(history.location.search),
                      page: 1
                    })
                  });
                  setCurrent(1);
                  setPageSize(size);
                }}
                showTotal={(total, range) =>
                  `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`
                }
              />
            ) : null}
          </div>
          <div className="col col--3 right">
            {/* <div className="nav">
                            <div className="title">其他 PBL 资源</div>
                            <div className="courses">
                                {otherProject.map((course, index) => (
                                    <a href={course.url} target="_blank" rel="noopener noreferrer" key={index}>
                                        <div className="course">
                                            <img src={course.image} alt={course.name}/>
                                            <div className="name">{course.name}</div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div> */}

            <div className="nav-other">
              <div className="title">
                <div className="titleName">热门项目</div>
              </div>
              {
                <div className="projects">
                  {otherProjects.slice(0, 6).map((project, index) => (
                    <div
                      className="project"
                      key={index}
                      onClick={() => projectSelecteHandler(project)}
                    >
                      <img src={project.cover} alt={project.title} />
                      <div className="name">{project.title}</div>
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
