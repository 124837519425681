import React, { useCallback, useEffect, useState, useRef } from 'react'
import * as echarts from 'echarts'
import { useSelector } from 'react-redux'
import { Spin, Tooltip, Progress } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import classNames from 'classnames'
import 'moment/locale/zh-cn'
import {
  getOrgActive,
  getOrgCourseRank,
  getOrgExtendRank,
  getOrgBaseData,
} from '../../../utils/request'
import SubHeader from '../../../components/subHeader'
import DownLoadBar from '../../../components/downloadBar'
import TeamView from './component/teamView'
import PersonnelView from './component/personnelView'
import StudentView from './component/studentView'
import ProjectsViews from './component/projectsView'

import './style.scss'

const DataCenter = ({ history, ...props }) => {
  const userInfo = useSelector((state) => state.userInfo)
  const [orgBaseData, setOrgBaseData] = useState({})
  const [orgBaseLoading, setOrgBaseLoading] = useState(true)
  const [orgActiveData, setOrgActiveData] = useState({})
  const [courseRankData, setCourseRankData] = useState([])
  const [extendRankData, setExtendRankData] = useState([])
  const [currTab, setCurrTab] = useState('team')
  const [visible, setVisible] = useState(false)
  const orgActiveChart = useRef(null)

  useEffect(() => {
    getOrgBaseData(userInfo.organization_data.id).then((res) => {
      setOrgBaseData(res.data)
      setOrgBaseLoading(false)
    })
    getOrgActive(userInfo.organization_data.id).then((res) => {
      setOrgActiveData(res.data)
    })
    getOrgCourseRank(userInfo.organization_data.id).then((res) => {
      setCourseRankData(res.data)
    })
    getOrgExtendRank(userInfo.organization_data.id).then((res) => {
      const data = res.data.sort((a, b) => b.count - a.count)
      setExtendRankData(data)
    })
    setInterval(() => {
      // setExtendLoading(true)
      // getProjectExtend(params).then((res) => {
      //   setExtendData(res.data)
      //   setExtendLoading(false)
      // })
    }, 30 * 60 * 1000)
  }, [userInfo])

  useEffect(() => {
    document.addEventListener('scroll', (e) => {
      const barTop = document
        .querySelector('.mainTabs')
        ?.getBoundingClientRect().top
      if (barTop === 96) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    })
  }, [])

  const onSwitchTab = (e) => {
    if (e.target.dataset.tab) {
      setCurrTab(e.target.dataset.tab)
    }
  }

  const setActiveChart = useCallback(() => {
    const option = {
      tooltip: {
        trigger: 'axis',
        formatter:
          '{b}<br/><span style="display:inline-block; width: 10px; height: 10px; background-color: #0bd295; border-radius: 50%; margin: 0 6px 0 0"></span>{a0}: {c0}<br /><span style="display:inline-block; width: 10px; height: 10px; background-color:#0084ff; border-radius: 50%; margin: 0 6px 0 0"></span>{a1}: {c1}',
      },
      legend: {
        data: ['教学云', '创作平台'],
      },
      grid: {
        // left: '3%',
        // right: '4%',
        // bottom: '3%',
        // height: '400px',
        containLabel: true,
      },
      toolbox: {
        right: 44,
        feature: {
          // saveAsImage: {},
          magicType: {
            type: ['line', 'bar'],
          },
        },
      },
      color: ['#0bd295', '#0084ff'],
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: orgActiveData.date_list.map((date) =>
          moment(date).format('YYYY/MM/DD'),
        ),
        axisLabel: {
          textStyle: {
            color: function (value, index) {
              return moment(value).format('YYYYMMDD') >=
                moment(orgBaseData?.data?.open_date).format('YYYYMMDD')
                ? '#666666'
                : '#cccccc'
            },
          },
          rotate: 60,
        },
      },
      yAxis: {
        type: 'value',
        // show: false,
        // boundaryGap: [0, 0.01],
        // maxInterval: Math.ceil(3051 / 5),
        max: function (value) {
          return value.max <= 5 ? 5 : null
        },
      },
      series: [
        {
          name: '创作平台',
          type: 'line',
          showSymbol: false,
          smooth: true,
          data: orgActiveData.creative_line_data,
        },
        {
          name: '教学云',
          type: 'line',
          showSymbol: false,
          smooth: true,
          data: orgActiveData.online_line_data,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: 0,
          endValue: 30,
          xAxisIndex: [0],
          minSpan: 2,
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          xAxisIndex: [0, 1],
        },
      ],
    }
    orgActiveChart.current = echarts.init(
      document.getElementById('orgActiveChart'),
    )
    orgActiveChart.current.setOption(option)
  }, [orgActiveData, orgBaseData])

  useEffect(() => {
    if (Object.keys(orgActiveData).length) {
      setActiveChart()
    }
  }, [orgActiveData, setActiveChart])

  const computedOrgExpirationDate = (start, end) => {
    const now = moment().format('YYYYMMDD')
    return (
      moment(start).format('YYYYMMDD') > now ||
      moment(end).format('YYYYMMDD') < now
    )
  }

  const computedPercent = (num, base) => {
    return num && base ? ((num / base) * 100).toFixed(1) : 0
  }

  return (
    <div className="container" id="manageDataCenter">
      <div className="dataContainer">
        <section className="contentItem">
          <SubHeader
            title={orgBaseData?.data?.text_mark ||
              orgBaseData?.data?.name}
            smallTitle={
              orgBaseData?.data &&
              `（服务有效期：${orgBaseData?.data?.start_date} ～ ${orgBaseData?.data?.end_date}）`
            }
            smallTitleStyle={{
              color: computedOrgExpirationDate(
                orgBaseData?.data?.start_date,
                orgBaseData?.data?.end_date,
              )
                ? '#ff4d4f'
                : moment(orgBaseData?.data?.end_date)
                    .subtract(30, 'days')
                    .format('YYYYMMDD') > moment().format('YYYYMMDD')
                ? '#666666'
                : '#faad14',
            }}
            tips={`本校区首次开通数据中心时间：${orgBaseData?.data?.open_date}`}
            width={295}
            style={{ marginBottom: '36px' }}
          />
          <Spin spinning={orgBaseLoading}>
            <div className="dataBar large">
              <div className="dataItem">
                <Progress
                  width={64}
                  strokeWidth={27}
                  showInfo={false}
                  strokeLinecap="butt"
                  strokeColor="#0084ff"
                  trailColor="#E0E0E0"
                  type="circle"
                  percent={computedPercent(
                    orgBaseData?.data?.staff_nums,
                    orgBaseData?.data?.max_staff,
                  )}
                />
                <div className="content">
                  <span className="item_title">教职工
                    <Tooltip
                      placement="top"
                      title="教职工指的是校区内除学员外的其他成员，如：管理员、教师、助理等"
                      overlayStyle={{ maxWidth: '282px' }}
                    >
                      <QuestionCircleOutlined
                        style={{ color: '#cccccc', marginLeft: '6px', fontSize: '16px' }}
                      />
                    </Tooltip>
                  </span>
                  <span className="num">
                    {orgBaseData?.data?.staff_nums?.toLocaleString() || 0}/
                    {orgBaseData?.data?.max_staff?.toLocaleString() || 0}
                  </span>
                  <span className="percent">
                    占比{' '}
                    {computedPercent(
                      orgBaseData?.data?.staff_nums,
                      orgBaseData?.data?.max_staff,
                    )}
                    %
                  </span>
                </div>
              </div>
              <div className="dataItem">
                <Progress
                  width={64}
                  strokeWidth={27}
                  showInfo={false}
                  strokeLinecap="butt"
                  strokeColor="#f55d00"
                  trailColor="#E0E0E0"
                  type="circle"
                  percent={computedPercent(
                    orgBaseData?.data?.org_real_used_student_nums,
                    orgBaseData?.data?.max_student,
                  )}
                />
                <div className="content">
                  <span className="item_title">学员</span>
                  <span className="num">
                    {orgBaseData?.data?.org_real_used_student_nums?.toLocaleString() || 0}/
                    {orgBaseData?.data?.max_student?.toLocaleString() || 0}
                  </span>
                  <span className="percent">
                    占比{' '}
                    {computedPercent(
                      orgBaseData?.data?.org_real_used_student_nums,
                      orgBaseData?.data?.max_student,
                    )}
                    %
                  </span>
                </div>
              </div>
              <div className="dataItem">
                <Progress
                  width={64}
                  strokeWidth={27}
                  showInfo={false}
                  strokeLinecap="butt"
                  strokeColor="#fec015"
                  trailColor="#E0E0E0"
                  type="circle"
                  percent={computedPercent(
                    orgBaseData?.data?.org_real_team_nums,
                    orgBaseData?.data?.max_team,
                  )}
                />
                <div className="content">
                  <span className="item_title">团队</span>
                  <span className="num">
                    {orgBaseData?.data?.org_real_team_nums?.toLocaleString() || 0}/
                    {orgBaseData?.data?.max_team?.toLocaleString() || 0}
                  </span>
                  <span className="percent">
                    占比{' '}
                    {computedPercent(
                      orgBaseData?.data?.org_real_team_nums,
                      orgBaseData?.data?.max_team,
                    )}
                    %
                  </span>
                </div>
              </div>
              <div className="dataItem">
                <Progress
                  width={64}
                  strokeWidth={27}
                  showInfo={false}
                  strokeLinecap="butt"
                  strokeColor="#0bd295"
                  trailColor="#E0E0E0"
                  type="circle"
                  percent={computedPercent(
                    orgBaseData?.data?.org_real_used_team_studios_nums,
                    orgBaseData?.data?.max_team_studios_num,
                  )}
                />
                <div className="content">
                  <span className="item_title">作品集</span>
                  <span className="num">
                    {orgBaseData?.data?.org_real_used_team_studios_nums?.toLocaleString() || 0}/
                    {orgBaseData?.data?.max_team_studios_num?.toLocaleString() || 0}
                  </span>
                  <span className="percent">
                    占比{' '}
                    {computedPercent(
                      orgBaseData?.data?.org_real_used_team_studios_nums,
                      orgBaseData?.data?.max_team_studios_num,
                    )}
                    %
                  </span>
                </div>
              </div>
            </div>
            <div className="dataBar medium">
              <div className="dataItem">
                <img src="/image/datacenter/projects.png" alt="projects" />
                <div className="content">
                  <span className="item_title">作品</span>
                  <span className="num">{orgBaseData?.project_num?.toLocaleString() || 0}</span>
                </div>
              </div>
              <div className="dataItem">
                <img src="/image/datacenter/course.png" alt="course" />
                <div className="content">
                  <span className="item_title">课程</span>
                  <span className="num">
                    {orgBaseData?.all_course_num?.toLocaleString() || 0}
                  </span>
                </div>
              </div>
              <div className="dataItem">
                <img src="/image/datacenter/extend.png" alt="extend" />
                <div className="content">
                  <span className="item_title">扩展</span>
                  <span className="num">
                    {orgBaseData?.all_extend_num?.toLocaleString() || 0}
                  </span>
                </div>
              </div>
            </div>
          </Spin>
        </section>
        <section className="contentItem" style={{padding: 0}} id="orgActive">
          <DownLoadBar
            style={{ right: '30px', top: '104px' }}
            imageId="orgActiveChartBox"
            title="活跃人数"
            excelParams={{
              name: ['日期', '教学云活跃人数', '创作平台活跃人数'],
              key: ['date', 'online_data', 'creative_data'],
              data: orgActiveData?.date_list?.map((item, idx) => ({
                date: item,
                online_data: orgActiveData?.online_line_data[idx],
                creative_data: orgActiveData?.creative_line_data[idx],
              })),
              filename: `活跃人数-${moment().format('YYYY/MM/DD')}`,
            }}
          />
          <div id="orgActiveChartBox" className="imageBox">
            <SubHeader title="活跃人数" style={{ marginBottom: '39px' }} />
            <div id="orgActiveChart" style={{ height: '550px' }}></div>
          </div>
        </section>
        <div className="mainTabs">
          <ul className="list" onClick={onSwitchTab}>
            <li
              data-tab="team"
              className={classNames('tab', { active: currTab === 'team' })}
            >
              团队
            </li>
            <li
              data-tab="personnel"
              className={classNames('tab', {
                active: currTab === 'personnel',
              })}
            >
              教职工
            </li>
            <li
              data-tab="student"
              className={classNames('tab', { active: currTab === 'student' })}
            >
              学员
            </li>
            <li
              data-tab="projects"
              className={classNames('tab', {
                active: currTab === 'projects',
              })}
            >
              作品
            </li>
          </ul>
        </div>
        <main className="mainContent">
          {currTab === 'team' && (
            <TeamView orgOpenDate={orgBaseData?.data?.open_date} orgStartDate={orgBaseData?.data?.start_date} />
          )}
          {currTab === 'personnel' && (
            <PersonnelView orgOpenDate={orgBaseData?.data?.open_date} />
          )}
          {currTab === 'student' && (
            <StudentView orgOpenDate={orgBaseData?.data?.open_date} />
          )}
          {currTab === 'projects' && (
            <ProjectsViews orgOpenDate={orgBaseData?.data?.open_date} orgStartDate={orgBaseData?.data?.start_date} />
          )}
        </main>
        {currTab === 'personnel' ? (
          <div className="rankContent">
            <div className="contentItem" style={{padding: 0}} id="courseRank">
              <DownLoadBar
                style={{ top: '81px', right: '38px' }}
                imageId="courseRankChart"
                title="已看课程排行"
                excelParams={{
                  name: ['用户名', '已看课程数', '未看课程数'],
                  key: ['name', 'nums', 'unread_nums'],
                  data: courseRankData.map((item) => ({
                    name: item.user_name,
                    nums: item.courses_num,
                    unread_nums: item.unread_courses_num,
                  })),
                  filename: `员工已看课程排行-${moment().format('YYYY/MM/DD')}`,
                }}
              />
              <div id="courseRankChart" className="imageBox">
                <SubHeader
                  title="已看课程排行"
                  smallTitle={
                    !Object.is(
                      moment(orgBaseData?.data?.start_date).format(
                        'YYYY/MM/DD',
                      ),
                      moment().format('YYYY/MM/DD'),
                    )
                      ? `（${moment(orgBaseData?.data?.start_date).format(
                          'YYYY/MM/DD',
                        )} ~ ${moment()
                          .subtract(1, 'day')
                          .format('YYYY/MM/DD')}）`
                      : null
                  }
                  tips="每日凌晨更新"
                  width={100}
                  style={{ marginBottom: '20px' }}
                />
                <div className="legend">
                  <div className="item">
                    <span
                      className="piece"
                      style={{
                        backgroundColor: '#0084ff',
                      }}
                    ></span>
                    <span
                      className="text"
                      style={{
                        color: '#666666',
                      }}
                    >
                      已看课程
                    </span>
                  </div>
                  <div className="item">
                    <span
                      className="piece"
                      style={{
                        backgroundColor: '#cccccc',
                      }}
                    ></span>
                    <span
                      className="text"
                      style={{
                        color: '#666666',
                      }}
                    >
                      未看课程
                    </span>
                  </div>
                </div>
                <div className="rankList">
                  {courseRankData.length
                    ? courseRankData.map((item) => (
                        <div className="rankItem" key={item.user_id}>
                          <div className="name">{item.user_name}</div>
                          <div className="percent">
                            <Tooltip
                              placement="top"
                              title={`已看课程：${item.courses_num} 门`}
                            >
                              <div
                                className="use"
                                style={{
                                  width:
                                    (item.courses_num /
                                      (item.courses_num +
                                        item.unread_courses_num)) *
                                      419 +
                                    'px',
                                }}
                              ></div>
                            </Tooltip>
                            <Tooltip
                              placement="top"
                              title={`未看课程：${item.unread_courses_num} 门`}
                            >
                              <div
                                className="unuse"
                                style={{
                                  width:
                                    (item.unread_courses_num /
                                      (item.courses_num +
                                        item.unread_courses_num)) *
                                      419 +
                                    'px',
                                }}
                              ></div>
                            </Tooltip>
                          </div>
                        </div>
                      ))
                    : null}
                  {/* <div id="useExtendChart" style={{ height: extendRankData.length * 12 + 'px' }}></div> */}
                </div>
              </div>
            </div>
            <section className="contentItem" style={{padding: 0}} id="extendRank">
              <DownLoadBar
                style={{ top: '81px', right: '38px' }}
                imageId="extendRankChart"
                title="使用扩展排行"
                excelParams={{
                  name: ['用户名', '已使用扩展数', '未使用扩展数'],
                  key: ['name', 'nums', 'unuse_nums'],
                  data: extendRankData.map((item) => ({
                    name: item.user_name,
                    nums: item.count,
                    unuse_nums: item.unused_count,
                  })),
                  filename: `员工使用扩展排行-${moment().format('YYYY/MM/DD')}`,
                }}
              />
              <div id="extendRankChart" className="imageBox">
                <SubHeader
                  title="使用扩展排行"
                  smallTitle={
                    !Object.is(
                      moment(orgBaseData?.data?.start_date).format(
                        'YYYY/MM/DD',
                      ),
                      moment().format('YYYY/MM/DD'),
                    )
                      ? `（${moment(orgBaseData?.data?.start_date).format(
                          'YYYY/MM/DD',
                        )} ~ ${moment()
                          .subtract(1, 'day')
                          .format('YYYY/MM/DD')}）`
                      : null
                  }
                  tips="每 30 分钟更新一次"
                  width={140}
                  style={{ marginBottom: '20px' }}
                />
                <div className="legend">
                  <div className="item">
                    <span
                      className="piece"
                      style={{
                        backgroundColor: '#0084ff',
                      }}
                    ></span>
                    <div className="text" style={{ color: '#666666' }}>
                      已使用
                    </div>
                  </div>
                  <div className="item">
                    <span
                      className="piece"
                      style={{
                        backgroundColor: '#cccccc',
                      }}
                    ></span>
                    <span
                      className="text"
                      style={{
                        color: '#666666',
                      }}
                    >
                      未使用
                    </span>
                  </div>
                </div>
                <div className="rankList">
                  {extendRankData.length
                    ? extendRankData.map((item) => (
                        <div className="rankItem" key={item.user}>
                          <div className="name">{item.user_name}</div>
                          <div className="percent">
                            <Tooltip
                              placement="top"
                              title={`已使用扩展：${item.count} 个`}
                            >
                              <div
                                className="use"
                                style={{
                                  width:
                                    (item.count /
                                      (item.count + item.unused_count)) *
                                      419 +
                                    'px',
                                }}
                              ></div>
                            </Tooltip>
                            <Tooltip
                              placement="top"
                              title={`未使用扩展：${item.unused_count} 个`}
                            >
                              <div
                                className="unuse"
                                style={{
                                  width:
                                    (item.unused_count /
                                      (item.count + item.unused_count)) *
                                      419 +
                                    'px',
                                }}
                              ></div>
                            </Tooltip>
                          </div>
                        </div>
                      ))
                    : null}
                  {/* <div id="useExtendChart" style={{ height: extendRankData.length * 12 + 'px' }}></div> */}
                </div>
              </div>
            </section>
          </div>
        ) : null}
        {visible ? <div id="fixedbar"></div> : null}
      </div>
    </div>
  )
}

export default DataCenter
