import React, { useCallback, useEffect, useState, useRef } from 'react'
import * as echarts from 'echarts'
import { useSelector } from 'react-redux'
import { Select, Spin, DatePicker, Tooltip, message } from 'antd'
import moment from 'moment'
import { QuestionCircleOutlined } from '@ant-design/icons'
import 'moment/locale/zh-cn'
import {
  getUserInfo,
  getProjectReport,
  getProjectAnalytics,
  getProjectExcellent,
  getProjectExtend,
  getPlatformAnalytics,
  getMineProgress,
  getCourseViewed,
  getCourseViewedRank,
  getPerfectMoment,
  getExtendRank,
} from '../../../utils/request'
import { scratchURL, disabledMonth } from '../../../utils'

import './style.scss'

const { Option } = Select

const SubHeader = ({
  title,
  text,
  tips,
  smallTitle,
  smallTitleStyle = {},
  width = 330,
}) => {
  return (
    <>
      <h3 className="subTitle">
        {title}
        {smallTitle ? (
          <span
            style={Object.assign(
              { fontWeight: 'normal', fontSize: '14px' },
              smallTitleStyle,
            )}
          >
            {smallTitle}
          </span>
        ) : null}
        {tips && (
          <Tooltip
            placement="right"
            title={tips}
            overlayStyle={{ width: width + 'px', maxWidth: width + 'px' }}
          >
            <QuestionCircleOutlined
              style={{ color: '#cccccc', marginLeft: '8px', fontSize: '17px' }}
            />
          </Tooltip>
        )}
      </h3>
      <p className="descr">{text}</p>
    </>
  )
}

const DataCenter = ({ history, ...props }) => {
  const userInfo = useSelector((state) => state.userInfo)
  const [targetUsername] = useState(props.match.params.username)
  const [targetUserInfo, setTargetUserInfo] = useState(null)
  const [projectReport, setProjectReport] = useState(null)
  const [reportLoading, setReportLoading] = useState(true)
  const [rankData, setRankData] = useState([])
  const [rankLoading, setRankLoading] = useState(true)
  const [excellentData, setExcellentData] = useState({})
  const [excellentLoading, setExcellentLoading] = useState(true)
  const [extendData, setExtendData] = useState([])
  const [extendLoading, setExtendLoading] = useState(true)
  const [platform, setPlatForm] = useState('teach')
  const [timeInterval, setTimeInterval] = useState('recently')
  const [dateRange, setDateRange] = useState({ start: null, end: null })
  const [sort, setSort] = useState('views')
  const [useTimeData, setUseTimeData] = useState([]) // eslint-disable-line
  const [useTimeDate, setUseTimeDate] = useState([])
  const [progressData, setProgressData] = useState({})
  const [progressRecord, setProgressRecord] = useState(null)
  const [courseViewed, setCourseViewedData] = useState([])
  const [courseRankData, setCourseRankData] = useState([])
  const [extendRankData, setExtendRankData] = useState([])
  const isStudent =
    (userInfo && userInfo.user_type === 'student') ||
    (targetUserInfo && targetUserInfo.user_type === 'student')
  const rankCharts = useRef(null)
  const progressCharts = useRef(null)
  const courseCharts = useRef(null)

  const hoursRange = [
    '0-8',
    '8-10',
    '10-12',
    '12-14',
    '14-16',
    '16-18',
    '18-20',
    '20-22',
    '22-24',
  ]
  // useEffect(() => {
  //   if(!targetUsername) {

  //   }
  // }, [targetUsername])
  // useEffect(() => {
  //   setTargetUserName(props.match.params.username)
  // }, [props.match.params.username])

  const getDates = useCallback(() => {
    let res = []
    if (timeInterval === 'recently') {
      let start, end
      for (let i = 30; i >= 0; i--) {
        const now = new Date()
        now.setDate(now.getDate() - i)
        res.push({
          num: moment(now).format('YYYY/MM/DD'),
          date: moment(now).format('YYYYMMDD'),
        })
        if (i === 30) start = moment(new Date(now)).format('YYYYMMDD')
        if (i === 0) end = moment(new Date(now)).format('YYYYMMDD')
      }
      setDateRange({ start, end })
    } else if (timeInterval === 'lastmonth') {
      const year = new Date().getFullYear()
      const month = new Date().getMonth() - 1
      const lastDay = new Date(year, month + 1, 0)
      const start = moment(new Date(year, month, 1)).format('YYYYMMDD')
      const end = moment(new Date(year, month + 1, 0)).format('YYYYMMDD')
      for (let i = 1; i <= lastDay.getDate(); i++) {
        res.push({
          num: moment(new Date(year, month, i)).format('YYYY/MM/DD'),
          date: moment(new Date(year, month, i)).format('YYYYMMDD'),
        })
      }
      // console.log(start, end)
      setDateRange({ start, end })
    } else {
      const lastDate = moment(dateRange.end)
      const firstDate = moment(dateRange.start)
      const date = new Date(firstDate)
      res.push({
        num: firstDate.format('YYYY/MM/DD'),
        date: moment(firstDate).format('YYYYMMDD'),
      })
      while (
        date.getDate() !== lastDate.date() ||
        date.getMonth() !== lastDate.month()
      ) {
        date.setDate(date.getDate() + 1)
        res.push({
          num: moment(date).format('YYYY/MM/DD'),
          date: moment(date).format('YYYYMMDD'),
        })
      }
      // console.log(new Date(firstDate))
      // console.log(moment(dateRange.start).date())
    }
    setUseTimeDate(res)
  }, [timeInterval, dateRange.start, dateRange.end])

  useEffect(() => {
    if (targetUsername) {
      getUserInfo(targetUsername)
        .then((res) => {
          if (
            Object.is(res.data.user_type, 'student') &&
            !res.data.student_name
          ) {
            message.warning({
              content: '当前账户已被注销',
              duration: 0,
              top: 200,
            })
          }
          console.log('data', res.data)
          setTargetUserInfo(res.data)
        })
        .catch((e) => {
          // console.log('无用户数据的bug: ', e.response);
          message.warning(`无用户 ${targetUsername} 数据`)
          // history.push('/account/home')
        })
    }
  }, [targetUsername])

  useEffect(() => {
    const params = { user_name: targetUsername }
    if (JSON.stringify((targetUserInfo || userInfo)?.organization_data) !== '{}') {
      getProjectReport(params).then((res) => {
        setProjectReport(res.data)
        setReportLoading(false)
      })
    }
    getProjectAnalytics(params).then((res) => {
      setRankData(res.data)
      setRankLoading(false)
    })
    getProjectExcellent(params).then((res) => {
      setExcellentData(res.data)
      setExcellentLoading(false)
    })
    getProjectExtend(params).then((res) => {
      setExtendData(res.data)
      setExtendLoading(false)
    })
    getMineProgress(params).then((res) => {
      setProgressData(res.data)
    })
    getPerfectMoment(params).then((res) => {
      const { data } = res
      data.study.date = moment(data.study.date).format('YYYY-MM-DD')
      data.creative.date = moment(data.creative.date).format('YYYY-MM-DD')
      setProgressRecord(data)
    })
    getCourseViewed(params).then((res) => {
      const maxVal = Math.max(...res.data.map((item) => item.total))
      const data = res.data.map((item) => ({
        ...item,
        width: 400 * (item.total / maxVal),
      }))
      setCourseViewedData(data)
    })
    getCourseViewedRank(params).then((res) => {
      setCourseRankData(res.data)
    })
    getExtendRank(params).then((res) => {
      const data = res.data.sort((a, b) => b.count - a.count)
      setExtendRankData(data)
    })
    setInterval(() => {
      setExtendLoading(true)
      getProjectExtend(params).then((res) => {
        setExtendData(res.data)
        setExtendLoading(false)
      })
    }, 30 * 60 * 1000)
  }, [targetUserInfo, targetUsername, userInfo])

  const onDateRangeChange = useCallback((e) => {
    // console.log(e.month())
    const year = e.year()
    const month = e.month()
    const start = moment(new Date(year, month, 1)).format('YYYYMMDD')
    const end = moment(new Date(year, month + 1, 0)).format('YYYYMMDD')
    setTimeInterval('datepicker')
    setDateRange({ start, end })
  }, [])

  const hasDataDate = (date) => {
    const arr = Array.from(new Set(useTimeData.map((item) => item.date)))
    return arr.indexOf(date) > -1
  }

  const getUseTimeVal = (date, range) => {
    return useTimeData.find((item) => item.date === date && item.hour === range)
  }
  const getUseTimeColor = (date, range) => {
    const res = getUseTimeVal(date, range)
    const isTeach = platform === 'teach'
    if (res) {
      // console.log(res)
      if (res.val >= 120) {
        return isTeach ? 'rgb(0,132,255)' : 'rgb(11,210,149)'
      } else if (res.val >= 90 && res.val < 120) {
        return isTeach ? 'rgba(0,132,255,0.75)' : 'rgba(11,210,149,.75)'
      } else if (res.val >= 60 && res.val < 90) {
        return isTeach ? 'rgba(0,132,255,0.5)' : 'rgba(11,210,149,.5)'
      } else if (res.val >= 30 && res.val < 60) {
        return isTeach ? 'rgba(0,132,255,0.25)' : 'rgba(11,210,149,.25)'
      } else if (res.val > 0 && res.val < 30) {
        return isTeach ? 'rgba(0,132,255,0.15)' : 'rgba(11,210,149,.15)'
      } else {
        return '#eeeeee'
      }
    }
    return '#fafafa'
  }

  const setRankCharts = useCallback(() => {
    const res = rankData.sort((a, b) => b[sort] - a[sort])
    const views = res.map((item) => item.views)
    const loves = res.map((item) => item.loves)
    const shared_views = res.map((item) => item.shared_views)
    const remixes = res.map(item => item.remixes)
    const favorites = res.map((item) => item.favorites)
    const option = {
      title: {
        // text: 'World Population'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      toolbox: {
        right: 10,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          // saveAsImage: {}
        },
      },
      legend: {
        // show: false
        itemWidth: 12,
        itemHeight: 12,
      },
      color: ['#0084ff', '#e15500', '#f6bc1c', '#0bd295'],
      grid: {
        // left: '3%',
        // right: '4%',
        width: '800',
        bottom: '15%',
        containLabel: true,
      },
      yAxis: {
        type: 'value',
        // show: false,
        // boundaryGap: [0, 0.01],
      },
      xAxis: {
        type: 'category',
        data: res.map((item) => item.project_name),
        axisLabel: {
          interval: 0,
          rotate: 20,
        },
      },
      series: [
        {
          name: '查看次数',
          type: 'bar',
          data: views,
        },
        isStudent &&
        {
          name: '分享后被观看次数',
          type: 'bar',
          data: shared_views,
        },
        {
          name: '被点赞数',
          type: 'bar',
          data: loves,
        },
        {
          name: '被收藏数',
          type: 'bar',
          data: favorites,
        },
        !isStudent && {
          name: '被改编次数',
          type: 'bar',
          data: remixes,
        }
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: 1,
          endValue: 10,
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          xAxisIndex: [0, 1],
        },
      ],
    }
    if (!rankCharts.current) {
      rankCharts.current = echarts.init(document.getElementById('rankCharts'))
      rankCharts.current.on('click', (params) => {
        window.open(`${scratchURL}/projects/${rankData[params.dataIndex].id}/`)
      })
    }
    rankCharts.current.setOption(option, true)
    rankCharts.current.resize()
  }, [rankData, sort, rankCharts, isStudent])

  const setProgressCharts = useCallback(() => {
    const option = {
      tooltip: {
        trigger: 'axis',
        formatter:
          '{b}<br/><span style="display:inline-block; width: 10px; height: 10px; background-color: #0bd295; border-radius: 50%; margin: 0 6px 0 0"></span>{a0}: {c0} h<br /><span style="display:inline-block; width: 10px; height: 10px; background-color:#0084ff; border-radius: 50%; margin: 0 6px 0 0"></span>{a1}: {c1} h',
      },
      legend: {
        data: ['教学云', '创作平台'],
      },
      grid: {
        // left: '3%',
        // right: '4%',
        // bottom: '3%',
        height: '400px',
        containLabel: true,
      },
      toolbox: {
        right: 50,
        feature: {
          // saveAsImage: {},
          magicType: {
            type: ['line', 'bar'],
          },
        },
      },
      color: ['#0bd295', '#0084ff'],
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: progressData.date_list.map((date) =>
          moment(date).format('YYYY/MM/DD'),
        ),
        axisLabel: {
          textStyle: {
            color: function (value, index) {
              return moment(value).format('YYYYMMDD') >= progressData.data_start
                ? '#666666'
                : '#cccccc'
            },
          },
          rotate: 60,
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: function (value, index) {
            return value + ' h'
          },
        },
        max: Math.min(
          24,
          Math.max(
            Math.max(...progressData.creative_line_data),
            Math.max(...progressData.online_line_data),
          ),
        ),
      },
      series: [
        {
          name: '创作平台',
          type: 'line',
          showSymbol: false,
          smooth: true,
          data: progressData.creative_line_data,
        },
        {
          name: '教学云',
          type: 'line',
          showSymbol: false,
          smooth: true,
          data: progressData.online_line_data,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: progressData.date_list.length - 31,
          endValue: progressData.date_list.length - 1,
          xAxisIndex: [0],
          minSpan: 2,
        },
        {
          type: 'inside',
          show: true,
          xAxisIndex: [0, 1],
        },
      ],
    }
    progressCharts.current = echarts.init(
      document.getElementById('progressCharts'),
    )
    progressCharts.current.setOption(option, true)
  }, [progressData])

  const setCourseCharts = useCallback(() => {
    let option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter:
          '{b}<br/><span style="display:inline-block; width: 10px; height: 10px; background-color: #0084ff; border-radius: 50%; margin: 0 6px 0 0"></span>{a0}: {c0}        h',
      },
      legend: {
        show: false,
      },
      color: ['#0084ff'],
      grid: {
        left: '0%',
        // right: '4%',
        // bottom: '0%',
        height: '227',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        show: false,
      },
      yAxis: {
        type: 'category',
        data: courseViewed.map((course) => course.course_title).reverse(),
        axisLabel: {
          formatter: function (value, index) {
            return value.length > 30 ? value.slice(0, 30) + '...' : value
          },
        },
      },
      series: [
        {
          name: '已看课时',
          type: 'bar',
          data: courseViewed
            .map((course) => (course.total / 3600).toFixed(1))
            .reverse(),
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: courseViewed.length - 1,
          endValue: courseViewed.length - 10,
          yAxisIndex: [0],
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          yAxisIndex: [0, 1],
        },
      ],
    }
    if (!courseCharts.current) {
      courseCharts.current = echarts.init(
        document.getElementById('courseCharts'),
      )
      courseCharts.current.on('click', (params) => {
        // console.log(unit_id)
        const course = courseViewed[params.dataIndex]
        window.open(`/course/${course.course}/${course.unit_id}/`)
      })
    }
    courseCharts.current.setOption(option, true)
  }, [courseViewed])

  useEffect(() => {
    getDates()
  }, [timeInterval, dateRange.start, dateRange.end]) // eslint-disable-line

  useEffect(() => {
    // console.log(usetimeData)
    getPlatformAnalytics({
      user_name: targetUsername,
      platform,
      start_time: dateRange.start,
      end_time: dateRange.end,
    }).then((res) => {
      // setUseTimeCharts(res.data)
      setUseTimeData(res.data)
    })
  }, [targetUsername, platform, dateRange.start, dateRange.end]) // eslint-disable-line

  useEffect(() => {
    // setProgressCharts()
    if (courseViewed.length > 0) {
      // setCourseCharts()
    }
    // setCourseRankCharts()
  }, [courseViewed, setCourseCharts])

  useEffect(() => {
    if (rankData.length > 0) {
      setRankCharts()
    }
  }, [sort, rankData, setRankCharts])

  useEffect(() => {
    if (Object.keys(progressData).length) {
      setProgressCharts()
    }
  }, [progressData, setProgressCharts])

  const showTimeRange = (date, range) => {
    if (date === moment().format('YYYYMMDD')) {
      const lastTime = range.split('-')[1]
      const nowTime = new Date()
      return nowTime.getHours() >= lastTime
    }
    return true
  }
  return (
    <div className="container" id="accountDataCenter">
      <div className="dataContainer">
        <h2 className="title">数据中心</h2>
        <section className="dataBar">
          <Spin spinning={reportLoading}>
            {projectReport ? (
              <div className={`list ${isStudent ? 'isstudent' : 'notstudent'}`}>
                <div className="dataItem">
                  <img className="icon" alt="day" src="/image/data_icon1.png" />
                  <div className="content">
                    <span className="item_title">在线时长/h</span>
                    <span className="text">
                      {projectReport.active_time.toLocaleString() || 0}
                    </span>
                  </div>
                </div>
                {!isStudent ? (
                  <>
                    <div className="dataItem">
                      <img
                        className="icon"
                        alt="day"
                        src="/image/data_icon5.png"
                      />
                      <div className="content">
                        <span className="item_title">看课时长/h</span>
                        <span className="text">
                          {projectReport.course_time.toLocaleString() || 0}
                        </span>
                      </div>
                    </div>
                    <div className="dataItem">
                      <img
                        className="icon"
                        alt="day"
                        src="/image/data_icon6.png"
                      />
                      <div className="content">
                        <span className="item_title">已看课程</span>
                        <span className="text">
                          {projectReport.course_num || 0}
                          <span>/{projectReport.all_course_num || 0}</span>
                        </span>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="dataItem">
                  <img className="icon" alt="day" src="/image/data_icon2.png" />
                  <div className="content">
                    <span className="item_title">创作时长/h</span>
                    <span className="text">
                      {projectReport.creation_time.toLocaleString() || 0}
                    </span>
                  </div>
                </div>
                <div className="dataItem">
                  <img className="icon" alt="num" src="/image/data_icon3.png" />
                  <div className="content">
                    <span className="item_title">创作作品</span>
                    <span className="text">
                      {projectReport.project_num || 0}
                    </span>
                  </div>
                </div>
                <div className="dataItem">
                  <img className="icon" alt="num" src="/image/data_icon4.png" />
                  <div className="content">
                    <span className="item_title">使用扩展</span>
                    <span className="text">
                      {projectReport.extend_num || 0}
                      <span>/{projectReport.all_extend_num || 0}</span>
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
          </Spin>
        </section>
        <section className="contentItem" id="useTime">
          <SubHeader
            title="平台使用"
            text="在教学云无论学习课程、创作作品还是与同学互动，都会点亮小格子记录你的成长。"
            tips="早上 8 点到次日凌晨 2 点，每 2 小时更新一次。"
          />
          <div className="actionBar">
            <div className="tabs">
              <div
                className="text"
                style={{
                  color: timeInterval === 'recently' ? '#0084ff' : '#333333',
                }}
                onClick={() => setTimeInterval('recently')}
              >
                最近
              </div>
              {moment()
                .subtract(1, 'months')
                .endOf('month')
                .format('YYYYMMDD') <
              moment(userInfo?.organization_data?.open_date).format(
                'YYYYMMDD',
              ) ? (
                <div className="text disabled">上月</div>
              ) : (
                <div
                  className="text"
                  style={{
                    color: timeInterval === 'lastmonth' ? '#0084ff' : '#333333',
                  }}
                  onClick={() => setTimeInterval('lastmonth')}
                >
                  上月
                </div>
              )}
            </div>
            <DatePicker
              onChange={onDateRangeChange}
              disabledDate={(current) =>
                disabledMonth(current, projectReport?.data_start)
              }
              picker="month"
            />
            {/* <div className="right">
              <Radio.Group
                onChange={(e) => {
                  setTimeInterval(e.target.value)
                }}
                value={timeInterval}
              >
                <Radio.Button value="recently">最近</Radio.Button>
                <Radio.Button value="lastmonth">上月</Radio.Button>
              </Radio.Group>
              <div className="tabs">
                <div className="text">最近</div>
                <div className="text">上月</div>
              </div>
              <DatePicker
                style={{ marginLeft: '16px' }}
                onChange={onDateRangeChange}
                disabledDate={disabledDate}
                picker="month"
              />
            </div> */}
          </div>
          <div className="legendList">
            <div class="legend" onClick={() => setPlatForm('teach')}>
              <div
                className="name"
                style={{ color: platform === 'teach' ? '#666666' : '#cccccc' }}
              >
                教学云
              </div>
              <div
                className="round"
                style={{
                  backgroundColor:
                    platform === 'teach'
                      ? 'rgb(0,132,255)'
                      : 'rgb(136,136,136)',
                }}
              ></div>
              <div
                className="round"
                style={{
                  backgroundColor:
                    platform === 'teach'
                      ? 'rgba(0,132,255,.75)'
                      : 'rgba(136,136,136,.75)',
                }}
              ></div>
              <div
                className="round"
                style={{
                  backgroundColor:
                    platform === 'teach'
                      ? 'rgba(0,132,255,.5)'
                      : 'rgba(136,136,136,.5)',
                }}
              ></div>
              <div
                className="round"
                style={{
                  backgroundColor:
                    platform === 'teach'
                      ? 'rgba(0,132,255,.25)'
                      : 'rgba(136,136,136,.25)',
                }}
              ></div>
              <div
                className="round"
                style={{
                  backgroundColor:
                    platform === 'teach'
                      ? 'rgba(0,132,255,.15)'
                      : 'rgba(136,136,136,.15)',
                }}
              ></div>
            </div>
            <div class="legend" onClick={() => setPlatForm('creative')}>
              <div
                className="name"
                style={{
                  color: platform === 'creative' ? '#666666' : '#cccccc',
                }}
              >
                创作平台
              </div>
              <div
                className="round"
                style={{
                  backgroundColor:
                    platform === 'creative'
                      ? 'rgb(11,210,149)'
                      : 'rgb(136,136,136)',
                }}
              ></div>
              <div
                className="round"
                style={{
                  backgroundColor:
                    platform === 'creative'
                      ? 'rgba(11,210,149,.75)'
                      : 'rgba(136,136,136,.75)',
                }}
              ></div>
              <div
                className="round"
                style={{
                  backgroundColor:
                    platform === 'creative'
                      ? 'rgba(11,210,149,.5)'
                      : 'rgba(136,136,136,.55)',
                }}
              ></div>
              <div
                className="round"
                style={{
                  backgroundColor:
                    platform === 'creative'
                      ? 'rgba(11,210,149,.25)'
                      : 'rgba(136,136,136,.25)',
                }}
              ></div>
              <div
                className="round"
                style={{
                  backgroundColor:
                    platform === 'creative'
                      ? 'rgba(11,210,149,.15)'
                      : 'rgba(136,136,136,.15)',
                }}
              ></div>
            </div>
          </div>
          {/* <div id="useTimeCharts" style={{ height: '300px' }}></div> */}
          <div id="useTimeRecord">
            <div className="content">
              <div className="yaxis">
                {hoursRange.map((item) => (
                  <span className="hours" key={item}>
                    {item}点
                  </span>
                ))}
              </div>
              <div className="recordTable">
                {useTimeDate.length
                  ? useTimeDate.map((item) => (
                      <div className="date">
                        {hoursRange.map((hours) => (
                          <>
                            {showTimeRange(item.date, hours) ? (
                              hasDataDate(item.date) ? (
                                <Tooltip
                                  placement="top"
                                  title={`本段学习时长累计：${
                                    getUseTimeVal(item.date, hours)?.val || 0
                                  }分钟`}
                                >
                                  <span
                                    className="hours use"
                                    style={{
                                      backgroundColor: getUseTimeColor(
                                        item.date,
                                        hours,
                                      ),
                                    }}
                                    key={`${item.date}-${hours}`}
                                  ></span>
                                </Tooltip>
                              ) : (
                                <span
                                  className="hours unuse"
                                  style={{
                                    backgroundColor: getUseTimeColor(
                                      item.date,
                                      hours,
                                    ),
                                  }}
                                  key={`${item.date}-${hours}`}
                                ></span>
                              )
                            ) : null}
                          </>
                        ))}
                        <span
                          className={`xaxis ${
                            hasDataDate(item.date) ? 'use' : 'unuse'
                          }`}
                          key={item.date}
                        >
                          {item.num}
                        </span>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </section>
        <section className="contentItem" id="progress">
          <SubHeader
            title="我的进步"
            text="记录学习过程中每一次成绩。"
            tips="早上 8 点到次日凌晨 2 点，每 2 小时更新一次。"
          />
          <div id="progressCharts" style={{ height: '550px' }}></div>
          {progressRecord ? (
            <div className="remarkContent">
              {progressRecord.study.time ? (
                <div className="remarkItem">
                  <img
                    style={{ width: '54px', height: '55px' }}
                    src="/image/learn.png"
                    alt="learn"
                  />
                  <div className="content">
                    <p className="text">最爱学习的一天</p>
                    <p className="date">{progressRecord.study.date}</p>
                    <p className="descr">
                      这一天在线时长 {progressRecord.study.time}{' '}
                      小时，是你学习时间最长的一天。{' '}
                    </p>
                  </div>
                </div>
              ) : null}
              {progressRecord.creative.time ? (
                <div className="remarkItem">
                  <img
                    style={{ width: '71px', height: '79px' }}
                    src="/image/create.png"
                    alt="learn"
                  />
                  <div className="content">
                    <p className="text">最努力创作的一天</p>
                    <p className="date">{progressRecord.creative.date}</p>
                    <p className="descr">
                      这一天是你创作时间最长的一天，累积{' '}
                      {progressRecord.creative.time} 小时，你太棒了。
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </section>
        {!isStudent ? (
          <section className="courseContent">
            <div className="contentItem" id="course">
              <SubHeader
                title="已看课程"
                text="每门课程累积查看时长。"
                tips="所有浏览累积超过 0.5 小时的课程，每天更新一次。"
              />
              {courseViewed.length ? (
                <div className="courseList">
                  {courseViewed.map((item) => (
                    <>
                      <div className="courseItem">
                        <div className="name">{item.course_title}</div>
                        <Tooltip
                          placement="top"
                          overlayStyle={{ width: 'auto' }}
                          title={
                            <>
                              <div>{item.course_title}</div>
                              <span
                                style={{
                                  display: 'inline-block',
                                  width: '10px',
                                  height: '10px',
                                  backgroundColor: '#0084ff',
                                  borderRadius: '50%',
                                  margin: '0 6px 0 0',
                                }}
                              ></span>
                              {(item.total / 3600).toFixed(1)} h
                            </>
                          }
                        >
                          <div
                            className="percent"
                            style={{ width: `${item.width}px` }}
                          ></div>
                        </Tooltip>
                      </div>
                    </>
                  ))}
                </div>
              ) : (
                <div className="empty" style={{ marginTop: '45px' }}>
                  <img src="/image/empty-page.png" alt="empty" />
                  <p className="text">
                    还没已看课程哦，到「教学课程」⻚⾯选⼀⻔你感兴趣的课程，开始吧！
                  </p>
                </div>
              )}
            </div>
            <div className="contentItem" id="courseRank">
              <SubHeader
                title="已看课程排行"
                text="老师/管理员已看课程数排行。"
                tips="每 30 分钟更新一次。"
              />
              <div className="legend">
                <div className="item">
                  <span
                    className="piece"
                    style={{
                      backgroundColor: '#0084ff',
                    }}
                  ></span>
                  <span
                    className="text"
                    style={{
                      color: '#666666',
                    }}
                  >
                    已看课程
                  </span>
                </div>
                <div className="item">
                  <span
                    className="piece"
                    style={{
                      backgroundColor: '#cccccc',
                    }}
                  ></span>
                  <span
                    className="text"
                    style={{
                      color: '#666666',
                    }}
                  >
                    未看课程
                  </span>
                </div>
              </div>
              <div className="rankList">
                {courseRankData.length
                  ? courseRankData.map((item) => (
                      <div className="rankItem" key={item.user_id}>
                        <div className="name">{item.user_name}</div>
                        <div className="percent">
                          <Tooltip
                            placement="top"
                            title={`已看课程：${item.courses_num} 门`}
                          >
                            <div
                              className="use"
                              style={{
                                width:
                                  (item.courses_num /
                                    (item.courses_num +
                                      item.unread_courses_num)) *
                                    232 +
                                  'px',
                              }}
                            ></div>
                          </Tooltip>
                          <Tooltip
                            placement="top"
                            title={`未看课程：${item.unread_courses_num} 门`}
                          >
                            <div
                              className="unuse"
                              style={{
                                width:
                                  (item.unread_courses_num /
                                    (item.courses_num +
                                      item.unread_courses_num)) *
                                    232 +
                                  'px',
                              }}
                            ></div>
                          </Tooltip>
                        </div>
                      </div>
                    ))
                  : null}
                {/* <div id="useExtendCharts" style={{ height: extendRankData.length * 12 + 'px' }}></div> */}
              </div>
            </div>
          </section>
        ) : null}
        <section className="contentItem">
          <SubHeader
            title="作品排行"
            text="通过各种维度的统计，让您更了解自己的作品。"
          />
          <Spin spinning={rankLoading}>
            {rankData.length > 1 ? (
              <>
                <div className="sort">
                  <span className="text">排序：</span>
                  <Select
                    placeholder="请选择"
                    style={{ width: 160 }}
                    onChange={(e) => setSort(e)}
                  >
                    <Option value="views">查看次数</Option>
                    {isStudent && <Option value="shared_views">分享后被观看次数</Option>}
                    <Option value="loves">被点赞数</Option>
                    <Option value="favorites">被收藏数</Option>
                    {!isStudent &&  <Option value="remixes">被改编次数</Option>}
                  </Select>
                </div>
                <div id="rankCharts" style={{ height: '400px' }}></div>
              </>
            ) : (
              <div className="empty">
                <img src="/image/empty-page.png" alt="empty" />
                <p className="text">还没有作品哦，从兴趣出发，创造你的作品。</p>
              </div>
            )}
          </Spin>
        </section>
        {isStudent ? (
          <section className="contentItem">
            <SubHeader
              title="优秀作品"
              text="只有坚持不懈的创作，才能成就更好的你。"
            />
            <Spin spinning={excellentLoading}>
              {Object.keys(excellentData).length > 0 ? (
                <div className="excellentList">
                  {excellentData.most_popular_project ? (
                    <div className="item">
                      <img
                        className="icon"
                        alt="excellent"
                        src="/image/excellent.png"
                      />
                      <div className="content">
                        <p className="item_title">最受欢迎的作品</p>
                        <p className="descr">您获得点赞 + 收藏最多的作品。</p>
                        <a
                          href={
                            scratchURL +
                            `/projects/${excellentData.most_popular_project.id}/`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            alt="cover"
                            src={
                              excellentData.most_popular_project.thumbnail_url
                            }
                          />
                        </a>
                        <a
                          className="text"
                          href={
                            scratchURL +
                            `/projects/${excellentData.most_popular_project.id}/`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {excellentData.most_popular_project?.title}
                        </a>
                      </div>
                    </div>
                  ) : null}
                  {excellentData.most_influential_project ? (
                    <div className="item">
                      <img className="icon" alt="top" src="/image/top.png" />
                      <div className="content">
                        <p className="item_title">影响力最大的作品</p>
                        <p className="descr">被围观次数最多的作品。</p>
                        <a
                          href={
                            scratchURL +
                            `/projects/${excellentData.most_influential_project.id}/`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={
                              excellentData.most_influential_project
                                .thumbnail_url
                            }
                            alt="cover"
                          />
                        </a>
                        <a
                          className="text"
                          href={
                            scratchURL +
                            `/projects/${excellentData.most_influential_project.id}/`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {excellentData.most_influential_project?.title}
                        </a>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="empty">
                  <img src="/image/empty-page.png" alt="empty" />
                  <p className="text">
                    还没有优秀作品哦，创作并分享你的作品吧。
                  </p>
                </div>
              )}
            </Spin>
          </section>
        ) : null}
        <section className="extendContent">
          <section
            className="contentItem"
            id="extend"
            style={{ paddingRight: '8px' }}
          >
            <SubHeader
              title="已使用扩展"
              text={`目前您的作品中已经使用过 ${
                extendData.filter((item) => item.usable).length
              } 个扩展，解锁更多技能，自由创作吧！`}
              tips="每 30 分钟更新一次。"
            />
            <Spin spinning={extendLoading}>
              {extendData.length ? (
                <div
                  className={`extendList ${
                    isStudent ? 'isStudent' : 'notStudent'
                  }`}
                >
                  <>
                    {extendData.map((item, index) => (
                      <div
                        className="item"
                        style={{ opacity: item.usable ? 1 : 0.3 }}
                      >
                        <img alt="extend" key={item.id} src={item.logo} />
                      </div>
                    ))}
                  </>
                </div>
              ) : null}
            </Spin>
          </section>
          {!isStudent ? (
            <section className="contentItem" id="extendRank">
              <SubHeader
                title="使用扩展排行"
                text="老师/管理员使用扩展数排行。"
                tips="每 30 分钟更新一次。"
              />
              <div className="legend">
                <div className="item">
                  <span
                    className="piece"
                    style={{
                      backgroundColor: '#0084ff',
                    }}
                  ></span>
                  <div className="text" style={{ color: '#666666' }}>
                    已使用
                  </div>
                </div>
                <div className="item">
                  <span
                    className="piece"
                    style={{
                      backgroundColor: '#cccccc',
                    }}
                  ></span>
                  <span
                    className="text"
                    style={{
                      color: '#666666',
                    }}
                  >
                    未使用
                  </span>
                </div>
              </div>
              <div className="rankList">
                {extendRankData.length
                  ? extendRankData.map((item) => (
                      <div className="rankItem" key={item.user}>
                        <div className="name">{item.user_name}</div>
                        <div className="percent">
                          <Tooltip
                            placement="top"
                            title={`已使用扩展：${item.count} 个`}
                          >
                            <div
                              className="use"
                              style={{
                                width:
                                  (item.count /
                                    (item.count + item.unused_count)) *
                                    232 +
                                  'px',
                              }}
                            ></div>
                          </Tooltip>
                          <Tooltip
                            placement="top"
                            title={`未使用扩展：${item.unused_count} 个`}
                          >
                            <div
                              className="unuse"
                              style={{
                                width:
                                  (item.unused_count /
                                    (item.count + item.unused_count)) *
                                    232 +
                                  'px',
                              }}
                            ></div>
                          </Tooltip>
                        </div>
                      </div>
                    ))
                  : null}
                {/* <div id="useExtendCharts" style={{ height: extendRankData.length * 12 + 'px' }}></div> */}
              </div>
            </section>
          ) : null}
        </section>
      </div>
    </div>
  )
}

export default DataCenter
