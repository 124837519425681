import { getToken } from './../utils';
import {
  SET_USERINFO,
  SET_TOKEN,
  SET_USERCAMPUS,
  SET_ISTEACHER,
  SET_UNREADCOUND,
  SET_SUPTEAMCOUNT,
  FETCH_TEACH_PLAN,
  SET_TEAMCOUNT,
  SET_PORTFOLIOFLAG,
  SET_STUDENTSFLAG,
  SET_STUDENTNUM,
  SET_MANAGER_CANCREATESTUDIOS,
  SET_TEACHER_CANCREATESTUDIOS,
  SET_MANAGER_CANCREATETEAM,
  SET_TEACHER_CANCREATETEAM,
  SET_MANAGER_CANCREATESTUDENT,
  SET_TEACHER_CANCREATESTUDENT,
  SET_ORGNUM,
  SET_SERVICE_VALIDITY,
  SET_SERVICE_BEFORE10,
  SET_SERVICETIP,
  SET_WEBSOCKET_OPEN,
  SET_OFFLINENOTIFICATION_SHOW,
  SET_ALL_CAMPUS
} from './actions';

const initialState = {
  userInfo: null,
  token: getToken(),
  isTeacher: undefined,
  unread: 0,
  plan: {},
  planIds: [],
  previewId: null,
  studentNum: 0, // 已创建的学员数
  team_data: {},
  campus:[],
  teamCount: true,
  // studentCount: true,
  manager_CanCreateStudios: 0, // 管理员可创建作品集数量
  teacher_CanCreateStudios: 0, // 教师可创建作品集数量（组织或非组织）
  manager_CanCreateTeam: 0, // 管理员可创建团队数量
  teacher_CanCreateTeam: 0, // 教师可创建团队数量（组织或非组织）
  manager_CanCreateStudent: 0, // 管理员可创建学员数量
  teacher_CanCreateStudent: 0, // 教师可创建学员数量（组织或非组织）
  portfolioFlag: true,
  orgNum: {
    remaining_students: 0,
    remaining_team: 0,
    remaining_portfolio: 0
  },
  studentsFlag: true, // 学员数是否达上限
  service_validity: 4, // 服务有效期 （1:有效期内；2:离有效期结束前30天内；3:服务有效期前；4：服务有效期后）
  service_before10: false, // 是否在有效期前结束的10天内
  service_tip: false, // 登录时服务有限期弹窗
  websocket_open: false, // 是否打开websocket,
  offlineNotification_show: false, // 是否显示下线通知弹窗
  allCampus: [] // 当前用户所有校区
};

function reducers(state = initialState, action) {
  switch (action.type) {
    case SET_USERINFO:
      return { ...state, userInfo: action.userinfo };
    case SET_TOKEN:
      return { ...state, token: action.token };
    case SET_USERCAMPUS:
      return { ...state, campus: action.campus };
    case SET_ISTEACHER:
      return { ...state, isTeacher: action.boolean };
    case SET_UNREADCOUND:
      return { ...state, unread: action.count };
    case SET_STUDENTNUM:
      return { ...state, studentNum: action.count };
    case SET_SUPTEAMCOUNT:
      return {
        ...state,
        userInfo: Object.assign({}, state.userInfo, {
          super_team_count: action.count
        })
      };
    case FETCH_TEACH_PLAN:
      return {
        ...state,
        planIds: state.planIds.concat(action.ids),
        plan: Object.assign(state.plan, action.plan)
      };
    case SET_TEAMCOUNT:
      return { ...state, teamCount: action.boolean };
    // case SET_STUDENTCOUNT:
    //   return { ...state, studentCount: action.boolean };
    case SET_PORTFOLIOFLAG:
      return { ...state, portfolioFlag: action.boolean };
    case SET_STUDENTSFLAG:
      return { ...state, studentsFlag: action.boolean };
    case SET_MANAGER_CANCREATESTUDIOS:
      return { ...state, manager_CanCreateStudios: action.count };
    case SET_TEACHER_CANCREATESTUDIOS:
      return { ...state, teacher_CanCreateStudios: action.count };
    case SET_MANAGER_CANCREATETEAM:
      return { ...state, manager_CanCreateTeam: action.count };
    case SET_TEACHER_CANCREATETEAM:
      return { ...state, teacher_CanCreateTeam: action.count };
    case SET_MANAGER_CANCREATESTUDENT:
      return { ...state, manager_CanCreateStudent: action.count };
    case SET_TEACHER_CANCREATESTUDENT:
      return { ...state, teacher_CanCreateStudent: action.count };
    case SET_ORGNUM:
      return { ...state, orgNum: action.orgNum };
    case SET_SERVICE_VALIDITY:
      return { ...state, service_validity: action.category };
    case SET_SERVICE_BEFORE10:
      return { ...state, service_before10: action.boolean };
    case SET_SERVICETIP:
      return { ...state, service_tip: action.boolean };
    case SET_WEBSOCKET_OPEN:
      return { ...state, websocket_open: action.boolean };
    case SET_OFFLINENOTIFICATION_SHOW:
      return { ...state, offlineNotification_show: action.boolean };
    case SET_ALL_CAMPUS:
      return { ...state, allCampus: action.allCampus };
    default:
      return state;
  }
}

// const reducers = combineReducers({
//   app,
//   // teachplan
// })
// const rootReducers = combineReducers({
//   reducers,
//   teachplan
// })

export default reducers;
// export default rootReducers
