import React from 'react'
import { Button } from 'antd';
import './style.scss'
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux'

const NotAuthority = ({ history, location, ...props }) => {
    const campus = useSelector((state) => state.campus)
    const selectCampus = campus.find(f => f.is_select)
    const toHome = () => {
        if (selectCampus.status === '1') {
            if (selectCampus.org_type === "0") { // 非机构和通用
                history.replace('/course');
            } else {
                if (selectCampus.user_type === 'teacher') {
                    history.replace('/teachplan');
                } else {
                    history.replace('/course');
                }
            }
        } else {
            history.replace('/notification');
        }
    }
    return (
        <div id="not-authority">
            <div className="content">
                <img src="/image/403.png" alt="" />
                <div className='tip'>
                    <h1>很抱歉，你无权访问此页面</h1>
                    <p>这可能是由于当前校区暂未开通此功能、当前校区服务已到期、你在当前校区的角色不包含此权限等原因导致的，建议联系校区管理员或英荔教育客服人员了解情况。</p>
                    <Button type='primary' onClick={toHome}>返回首页</Button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(NotAuthority)