import React, { useState, useRef, memo } from 'react';
import { Input, Button } from 'antd';
import './style.scss';

const ReplyBox = memo(
  ({ comment, handleChange, onOk, onCancel, commentee }) => {
    const PLACEHOLDER = `回复 ${commentee}: `;
    const [placeholder, setPlaceholder] = useState(PLACEHOLDER);
    const textareaRef = useRef();
    return (
      <div className="reply-box">
        <Input.TextArea
          autoSize={true}
          rows="5"
          maxLength="500"
          ref={textareaRef}
          placeholder={placeholder}
          onFocus={() => setPlaceholder('')}
          onBlur={() => {
            if (!comment.length) {
              setPlaceholder(PLACEHOLDER);
            }
          }}
          onChange={handleChange}
        />
        <div className="actionBar">
          <Button
            onClick={e => {
              textareaRef.current.handleReset(e);
              onCancel();
            }}
          >
            取消
          </Button>
          <Button
            type="primary"
            disabled={!comment}
            onClick={e => {
              onOk();
              textareaRef.current.handleReset(e);
            }}
          >
            发布
          </Button>
        </div>
        <span className="textAreaSuffix">{`${
          comment.length > 500 ? 500 : comment.length
        }/500`}</span>
      </div>
    );
  }
);

export default ReplyBox;
