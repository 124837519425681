import React, { useState, useEffect, useMemo, useRef } from 'react';
import './style.scss';
import { Pagination, Card, List, Empty, Spin } from 'antd';
import { useHistory, Link, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { getCourses, getHotCourses } from '../../utils/request';
import Filter from '../../components/filter';
import { parseSearchRes, formatFilterRes } from '../project';
import { LoadingOutlined } from '@ant-design/icons';
const Course = () => {
  const history = useHistory();
  const location = useLocation();
  const [pageSize, setPageSize] = useState(15);
  const [hotCourses, setHotCourses] = useState([]);
  const [current, setCurrent] = useState(
    Number(qs.parse(history.location.search).page) || 1
  );
  const [courses, setCourses] = useState([]);
  const [filterRes, setFilterRes] = useState();
  const [count, setCount] = useState(0);
  const [courseMenu, setCourseMenu] = useState({
    new_course_type__iregex: [],
    new_suit_tags__iregex: [],
    new_teaching_aid__iregex: [],
    new_program_platform__iregex: []
  });
  const [loading, setLoading] = useState(false);
  const searchRef = useRef();

  const checkedRes = useMemo(() => {
    // 恢复当前页面 url search 置空后的筛选结果
    if (searchRef.current && !location.search) {
      return parseSearchRes(qs.parse(searchRef.current));
    }
    return parseSearchRes();
  }, [location.search]); //eslint-disable-line
  useEffect(() => {
    getHotCourses().then(res => {
      setHotCourses(res.data.results);
    });
  }, []);
  useEffect(() => {
    let needToCourse = localStorage.getItem('needToCourseDetail')
    if(needToCourse){
      history.replace(needToCourse)
      setTimeout(() => {
        localStorage.setItem('needToCourseDetail','')
      }, 1000);
      return
    }
    if (searchRef.current && !location.search) {
      history.replace({
        search: qs.stringify(filterRes)
      });
    }
    searchRef.current = location.search;
  }, [location.search, filterRes, history]);

  useEffect(() => {
    if (!filterRes || JSON.stringify(checkedRes) === JSON.stringify(filterRes))
      return;

    if (!location.search) {
      history.replace({
        search: qs.stringify(filterRes)
      });
    } else {
      history.push({
        search: qs.stringify(filterRes)
      });
    }
  }, [filterRes]); //eslint-disable-line

  useEffect(() => {
    if (!filterRes) return;
    setLoading(true);
    getCourses({
      page: filterRes.page || 1,
      page_size: pageSize,
      ...formatFilterRes(filterRes, true)
    })
      .then(res => {
        setCourseMenu(res.data.search_list);
        setCurrent(Number(filterRes.page || 1));
        setCount(res.data.count);
        setCourses(res.data.results);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [filterRes, pageSize]);

  // useEffect(() => {
  //     getHotCourses().then((res) => {
  //         if (Object.is(res.status, 200)) dispatch({ type: GET_HOT_COURSE, list: res.data.results })
  //     })
  // }, [])

  // const { hotCourses } = state

  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  return (
    <div id="course">
      <div className="container course_container">
        <div
          // className={hotCourses.length === 0 ? "row hot_none" : "row"}
          className="row"
        >
          <div className="col col--9 left">
            <Spin indicator={antIcon} spinning={loading}>
              {/* <div className="title">
                            教学课程
                        </div> */}
              <Filter
                searchPlacehloder={'搜索课程标题'}
                checkedRes={checkedRes}
                defaultRes={parseSearchRes()}
                types={[
                  {
                    value: 'all',
                    label: '全部'
                  }
                  // {
                  //     value: 'true',
                  //     label: '系列课程'
                  // },
                  // {
                  //     value: 'false',
                  //     label: '专题课程'
                  // }
                ]}
                selectedKeys={[qs.parse(history.location.search).type]}
                filtersName={['课程类型', '适合阶段', '课程教具', '编程平台']}
                filtersType={[
                  courseMenu.new_course_type__iregex,
                  courseMenu.new_suit_tags__iregex,
                  courseMenu.new_teaching_aid__iregex,
                  courseMenu.new_program_platform__iregex
                ]}
                onChange={val => {
                  setFilterRes(val);
                }}
              />
              <div className="courses">
                {courses.length > 0 ? (
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 1,
                      md: 3,
                      lg: 3,
                      xl: 3,
                      xxl: 3
                    }}
                    dataSource={courses}
                    renderItem={lesson => (
                      <List.Item key={lesson.id}>
                        <Link
                          target="_blank"
                          to={
                            '/course/' + lesson.id + '/' + (lesson.unit_id || 0)
                          }
                        >
                          <Card
                            hoverable
                            style={{
                              height:'302px'
                            }}
                            cover={
                              <img
                                src={lesson.cover || '/image/course.png'}
                                alt={lesson.title}
                              />
                            }
                          >
                            <div className="title">{lesson.title}</div>
                            <div className="content">{`${lesson.description}`}</div>
                          </Card>
                        </Link>
                      </List.Item>
                    )}
                  />
                ) : (
                  <Empty
                    image="/image/empty-page.png"
                    description="如需获取课程资源，请致电 400-931-8118"
                  />
                )}
              </div>

              {count ? (
                <Pagination
                  current={current}
                  pageSize={pageSize}
                  showQuickJumper={true}
                  total={count}
                  onChange={val => {
                    setCurrent(val);
                    history.push({
                      path: history.location.pathname,
                      search: qs.stringify({
                        ...qs.parse(history.location.search),
                        page: val
                      })
                    });
                  }}
                  onShowSizeChange={(cur, size) => {
                    history.push({
                      path: history.location.pathname,
                      search: qs.stringify({
                        ...qs.parse(history.location.search),
                        page: 1
                      })
                    });
                    setCurrent(1);
                    setPageSize(size);
                  }}
                  showTotal={(total, range) =>
                    `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`
                  }
                />
              ) : null}
            </Spin>
          </div>
          <div className="col col--3 right">
            <div className="nav">
              <div className="title">推荐课程</div>
              <div className="courses">
                {hotCourses.length > 0 ? (
                  <>
                    {hotCourses.map(course => (
                      <Link
                        to={
                          '/course/' + course.id + '/' + (course.unit_id || 0)
                        }
                        key={course.id}
                      >
                        <div className="course">
                          <img
                            src={course.cover || '/image/course.png'}
                            alt={course.description}
                          />
                          <div className="name">{course.title}</div>
                        </div>
                      </Link>
                    ))}
                  </>
                ) : (
                  <p style={{ paddingLeft: '30px' }}>暂无推荐课程</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
