import React, { useState, useEffect } from 'react';
import { Table, Button } from 'antd';
import dayjs from 'dayjs';
import { getProjects } from '../../../utils/request';
import { scratchURL } from '../../../utils';
import './style.scss';

const PAGE_SIZE = 8;
const OtherProject = (props) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const username = props.match.params.name;

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <a
          href={scratchURL + `/projects/${record.id}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="name">
            <img src={record.image} width="48px" alt="cover" />
            {record.title}
          </span>
        </a>
      ),
    },
    // {
    //   title: '状态',
    //   dataIndex: 'status',
    //   key: 'status',
    //   align: 'left',
    //   render: (text, record) => (
    //     <div className="status">{record.lms_public ? '已公开' : '未公开'}</div>
    //   ),
    // },
    {
      title: '最后保存时间',
      dataIndex: 'time',
      key: 'time',
      align: 'left',
      render: (value, record) =>
        dayjs(record.history.order_at).format('YYYY/MM/DD HH:mm'),
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await getProjects({ page_size: PAGE_SIZE, page }, username);
        if (Object.is(res.status, 200)) {
          setData(res.data.results);
          setTotal(res.data.count);
        }
      } catch (err) {}
    };
    fetchData();
  }, [page, username]);

  const renderTable = (data) => {
    return data.length ? (
      <div className="table">
        <Table
          dataSource={data}
          columns={columns}
          pagination={{
            total,
            page,
            showQuickJumper: true,
            defaultPageSize: PAGE_SIZE,
            onChange: (val) => {
              setPage(val);
            },
            showTotal: (total, range) =>
              `共 ${Math.ceil(total / PAGE_SIZE)} 页 / ${total} 条数据`,
          }}
        />
      </div>
    ) : (
      <div className="nodata">
        <img src="/image/empty-page.png" alt="empty-page" />
        <p className="tips">还没有关注的作品集，到社区去找找吧</p>
        <Button className="btn" type="primary" onClick={() => {}}>
          前往社区
        </Button>
      </div>
    );
  };
  return (
    <div id="other-project">
      <div className="container">{renderTable(data)}</div>
    </div>
  );
};

export default OtherProject;
