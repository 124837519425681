import React, { useState, useRef, useCallback } from 'react'
import './style.scss'
import { Modal, Form, Radio, Input, Button, message } from 'antd'
import { feedback } from '../../utils/request'
import { UploadOutlined } from '@ant-design/icons'
import { testEmail } from '../../utils'

const Feedback = ({ show, requestclose }) => {
    const [formData, setFormData] = useState({ feedback_type: 'suggestion', subject: '', content: '', contact: '', image: null })
    const formRef = useRef()
    const [submitErr, setSubmitErr] = useState({image: null})

    const feedbackHeadler = (data) => {
        let formData = new FormData()
        Object.keys(data).forEach(key => {
            if (!data[key]) return
            formData.append(key, data[key])
        })
        feedback(formData)
        .then(res => {
            console.log(res)
            requestclose()
            formRef.current.setFieldsValue({ feedback_type: 'suggestion', subject: '', content: '', contact: '', image: null })
            setFormData({ feedback_type: 'suggestion', subject: '', content: '', contact: '', image: null })
            message.success('提交成功，感谢你的反馈')
        })
        .catch(e => {
            console.log(e)
            message.error('提交失败，请稍后再试')
        })
    }

    const uploadImg = useCallback(() => {
        let input = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/jpg, image/jpeg, image/png, image/bmp'
        input.click()
        input.onchange = function (e) {
            setSubmitErr({image: null})
            let reader
            if (e.target.files.length) {
                console.log(e.target.files[0], e.target.files[0].size / 1024, 'kb')
                if (e.target.files[0].size / 1024 > 1024 * 5) {    // 小于5mb
                    setSubmitErr({image: {
                        help: '文件过大，单个文件不可以超过 5M',
                        validateStatus: 'error'
                    }})
                    return
                }

                reader = new FileReader()
                reader.readAsDataURL(e.target.files[0])
                formRef.current.setFieldsValue({ image: e.target.files[0] })
                setFormData(prevData => ({...prevData, image: e.target.files[0]}))
            }
            reader.onload = function (e) {
                //   console.log(e.target.result)
            }
        }
    }, [])

    return (
        <Modal
            closeIcon={<img src='/image/close.png' alt="close" width="14px" height="14px" />}
            className="feedback"
            visible={show}
            onCancel={() => {
                formRef.current.setFieldsValue({ feedback_type: 'suggestion', subject: '', content: '', contact: '', image: null })
                // formRef.current.resetFields()
                setSubmitErr({ image: null })
                setFormData({ feedback_type: 'suggestion', subject: '', content: '', contact: '', image: null })
                requestclose()
            }}
            onOk={() => {
                formRef.current.submit()
            }}
            width={500}
            centered={true}
            getContainer={false}
            okButtonProps={{disabled: !formData.subject || !formData.content || submitErr.image}}
        >
            <div className="title">
                感谢你们的每一条反馈，<br />让我们一起成长
        </div>
            <Form
                ref={formRef}
                initialValues={formData}
                layout="vertical"
                onValuesChange={(value, allValue) => {
                    console.log(value)
                    setFormData(allValue)
                }}
                onFinish={(value) => feedbackHeadler(value)}
            >
                <Form.Item
                    name="feedback_type"
                    label="反馈类型"
                >
                    <Radio.Group>
                        <Radio value={'suggestion'}>我想提个建议</Radio>
                        <Radio value={'question'}>我遇到了问题</Radio>
                        <Radio value={'complain'}>随便吐槽一下</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="subject"
                    label="主题"
                    rules={[
                        {
                            required: true,
                            message: '请输入主题',
                        }
                    ]}
                >
                    <Input maxLength="30" placeholder="用一句话表达你的观点" suffix={<span className="suffix">{`${formData.subject.length > 30 ? 30 : formData.subject.length}/30`}</span>} />
                </Form.Item>
                <Form.Item
                    name="content"
                    label="详细描述"
                    rules={[
                        {
                            required: true,
                            message: '请输入描述',
                        }
                    ]}
                >
                    <Input.TextArea
                        rows={6}
                        style={{ resize: 'none' }}
                        maxLength="500"
                        placeholder="详细的描述，可以帮助我们准确找到问题所在，给你提供更好的服务" />
                </Form.Item>
                <span className="textAreaSuffix">{`${formData.content.length > 500 ? 500 : formData.content.length}/500`}</span>
                <Form.Item
                    name="contact"
                    label="你的邮箱"
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(rule, value) {

                            if (!value || (value && testEmail(value))) {
                                return Promise.resolve()
                            }
                            return Promise.reject('邮箱格式不正确');
                            }
                        })
                    ]}
                >
                    <Input placeholder="方便我们与你取得联系，更有机会参与福利抽奖" />
                </Form.Item>
                <Form.Item
                    {...submitErr.image}
                    name="image"
                    label="相关附件"
                    extra={!submitErr.image ? formData.image ? formData.image.name : '单次最多可上传 1 个文件，大小不超过 5M' : null}
                >
                    <Button icon={formData.image ? null : <UploadOutlined />} className="upload" onClick={uploadImg}>{formData.image ? '替换文件' : '上传文件'}</Button>
                </Form.Item>
            </Form>
        </Modal>)
}

export default Feedback