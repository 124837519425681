import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button, message } from 'antd';
import { deleteCourse } from '../../../utils/api/course';
import ConfirmModal from '../../../components/confirmModal';

const RemoveCourseModal = (props, ref) => {
  const {
    selectedRowKeys,
    selectedRows,
    team_code,
    setSelectedRowKeys,
    setSelectedRows,
    setCurrent,
    fetchList,
    current
  } = props;
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    removeCourseHandler: () => setVisible(true)
  }));

  const submit = () => {
    if (selectedRowKeys.length === 0) return;
    deleteCourse(team_code, { course_id_list: selectedRowKeys })
      .then(() => {
        message.success('移除课程成功');
        setVisible(false);
        setSelectedRowKeys([]);
        setCurrent(1);
        if (current === 1) {
          fetchList(1);
        }
        setTimeout(() => {
          setSelectedRows([]);
        }, 500);
      })
      .catch(() => {
        message.success('移除课程失败，请稍后重试');
      });
  };

  return (
    <ConfirmModal
      title="确定要从本团队移除以下课程？"
      visible={visible}
      iconType="error"
      onCancel={() => setVisible(false)}
      contentNode={
        <div className="remove">
          {selectedRows.length > 0 &&
            selectedRows.map(item => (
              <p key={item.id} className="removeList">
                {item.title}
              </p>
            ))}
        </div>
      }
      bottomNode={
        <>
          <Button onClick={() => setVisible(false)}>取消</Button>
          <Button danger onClick={submit}>
            确定
          </Button>
        </>
      }
    />
  );
};

export default forwardRef(RemoveCourseModal);
