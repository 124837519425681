import React, { useEffect, useState, useRef, useCallback } from 'react'
import * as echarts from 'echarts'
import { useSelector } from 'react-redux'
import { Select, Spin, DatePicker } from 'antd'
import moment from 'moment'
import DownLoadBar from '../../../../components/downloadBar'
import SubHeader from '../../../../components/subHeader'
import {
  getOrgTeamList,
  getTeamBaseData,
  getTeamLearnTime,
  getTeamProjectsNum,
  getTeamProjectsRank,
} from '../../../../utils/request'
import { scratchURL, getRealDateRange, disabledDate } from '../../../../utils'

const { Option } = Select
const { RangePicker } = DatePicker

const dateFormat = 'YYYY-MM-DD'

const TeamView = ({ orgOpenDate, orgStartDate }) => {
  const userInfo = useSelector((state) => state.userInfo)
  const [teamList, setTeamList] = useState([])
  const [teamData, setTeamData] = useState({})
  const [learnTimeData, setLearnTimeData] = useState([])
  const [projectsNumData, setProjectsNumData] = useState({ date_list: [] })
  const [projectsRankData, setProjectsRankData] = useState([])
  const [team, setTeam] = useState(null)
  const [sort, setSort] = useState('views')
  const [timeInterval, setTimeInterval] = useState('last7days')
  const [dateRange, setDateRange] = useState(getRealDateRange(timeInterval))
  const [loading, setLoading] = useState({
    team: false,
    time: false,
    nums: false,
    rank: false,
  })
  const learnTimeChart = useRef(null)
  const projectsRankChart = useRef(null)
  const projectsNumChart = useRef(null)

  const formatDate = (date) => moment(date).format('YYYYMMDD')

  useEffect(() => {
    getOrgTeamList(userInfo.organization_data.id).then((res) => {
      setTeamList(res.data)
      const id = window.localStorage.getItem(`lastTeam-${userInfo?.id}`)
      if (
        id &&
        res.data.length &&
        res.data.find((item) => Number(item.id) === Number(id))
      ) {
        setTeam(Number(id))
      } else if (res.data.length) {
        setTeam(res.data[res.data.length - 1].id)
      }
    })
  }, [userInfo])

  useEffect(() => {
    setLoading((prev) => ({ ...prev, team: true, nums: true, time: true }))
    if (team) {
      getTeamBaseData(team).then((res) => {
        setTeamData(res.data)
        setLoading((prev) => ({ ...prev, team: false }))
      })
      getTeamLearnTime(team).then((res) => {
        const maxVal = Math.max(...res.data.map((item) => item.total))
        const data = res.data.map((item) => ({
          ...item,
          width: 800 * (item.total / maxVal),
        }))
        setLearnTimeData(data)
        setLoading((prev) => ({ ...prev, time: false }))
      })
      getTeamProjectsNum(team).then((res) => {
        setProjectsNumData(res.data)
        setLoading((prev) => ({ ...prev, nums: false }))
      })
    }
  }, [team])

  useEffect(() => {
    if (team) {
      setLoading((prev) => ({ ...prev, rank: true }))
      getTeamProjectsRank(team, {
        start_date: dateRange.start,
        end_date: dateRange.end,
      }).then((res) => {
        setProjectsRankData(res.data)
        setLoading((prev) => ({ ...prev, rank: false }))
        if (projectsRankChart?.current) {
          const options = projectsRankChart.current.getOption()
          projectsRankChart.current.setOption(options, true)
        }
      })
    }
  }, [team, dateRange, userInfo])

  const setLearnTimeChart = useCallback(() => {
    const option = {
      title: {
        // text: 'World Population'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter:
          '<span style="display:inline-block; width: 10px; height: 10px; background-color: #0084ff; border-radius: 50%; margin: 0 6px 0 0"></span>总看课时长: {c0} h',
      },
      toolbox: {
        right: 44,
        top: -5,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          // saveAsImage: {}
        },
      },
      color: ['#0084ff'],
      grid: {
        // left: '3%',
        // right: '4%',
        // width: '800',
        // bottom: '15%',
        containLabel: true,
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: function (value, index) {
            return value + ' h'
          },
        },
        // show: false,
        // boundaryGap: [0, 0.01],
      },
      xAxis: {
        type: 'category',
        data: learnTimeData.map((item) => item.course_title),
        axisLabel: {
          interval: 0,
          rotate: 20,
        },
      },
      series: [
        {
          type: 'bar',
          data: learnTimeData.map((item) => (item.total / 3600).toFixed(1)),
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: 0,
          endValue: 19,
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          xAxisIndex: [0, 1],
        },
      ],
    }
    if (!learnTimeChart.current) {
      learnTimeChart.current = echarts.init(
        document.getElementById('learnTimeChart'),
      )
    }
    if (learnTimeChart.current && learnTimeData.length) {
      learnTimeChart.current.on('click', (params) => {
        const course = learnTimeData[params.dataIndex]
        window.open(`/course/${course.course}/${course.unit_id}/`)
      })
    }
    learnTimeChart.current.setOption(option, true)
    learnTimeChart.current.resize()
  }, [learnTimeData])

  useEffect(() => {
    if (learnTimeData.length) {
      setLearnTimeChart()
    } else if (learnTimeChart.current) {
      learnTimeChart.current.resize()
    }
  }, [team, learnTimeData, setLearnTimeChart])

  const setProjectNumsChart = useCallback(() => {
    console.log(projectsNumData)
    const option = {
      title: {
        // text: 'World Population'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter:
          '{b}<br/><span style="display:inline-block; width: 10px; height: 10px; background-color: #0084ff; border-radius: 50%; margin: 0 6px 0 0"></span>每日提交作品数: {c0}',
      },
      toolbox: {
        right: 44,
        top: -5,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          // saveAsImage: {}
        },
      },
      grid: {
        // left: '3%',
        // right: '4%',
        // width: '800',
        // bottom: '15%',
        containLabel: true,
      },
      color: ['#0084ff'],
      yAxis: {
        type: 'value',
        // show: false,
        // boundaryGap: [0, 0.01],
        // maxInterval: Math.ceil(3051 / 5),
        max: function (value) {
          return value.max <= 5 ? 5 : null
        },
      },
      xAxis: {
        type: 'category',
        data: projectsNumData?.date_list?.map((date) =>
          moment(date).format('YYYY/MM/DD'),
        ),
        /**
         * 1.开通日期大于服务日期的前提下，数据首次开通时间≤团队创建时间，从数据首次开通时间开始算起。例如首次开通日期8月2日，团队创建时间8月1日。数据从8月2日开始算起。
         * 2.开通日期大于服务日期的前提下，团队创建时间＜数据开通时间，数据从团队创建时间开始算起。例如首次开通日期8月1日，团队创建时间8月2日。数据从8月2日开始算起。
         * 3.开通日期小于服务日期的前提下，旧团队从数据首次开通日期开始算起，新团队从团队创建时间开始算起。
         */
        axisLabel: {
          interval: 0,
          textStyle: {
            color: function (value, index) {
              return (formatDate(orgOpenDate) >= formatDate(orgStartDate) &&
                formatDate(orgOpenDate) >= formatDate(teamData?.created_at) &&
                formatDate(value) >= formatDate(orgOpenDate)) ||
                (formatDate(orgOpenDate) >= formatDate(orgStartDate) &&
                  formatDate(orgOpenDate) < formatDate(teamData?.created_at) &&
                  formatDate(value) >= formatDate(teamData?.created_at)) ||
                (formatDate(orgOpenDate) < formatDate(orgStartDate) &&
                  formatDate(teamData?.created_at) >= formatDate(orgOpenDate) &&
                  formatDate(value) >= formatDate(teamData?.created_at)) ||
                (formatDate(orgOpenDate) < formatDate(orgStartDate) &&
                  formatDate(teamData?.created_at) < formatDate(orgOpenDate) &&
                  formatDate(value) >= formatDate(orgOpenDate))
                ? '#666666'
                : '#cccccc'
            },
          },
          rotate: 60,
        },
      },
      series: [
        {
          type: 'bar',
          data: projectsNumData.result,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: projectsNumData?.date_list.length - 31,
          endValue: projectsNumData?.date_list.length - 1,
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          xAxisIndex: [0, 1],
        },
      ],
    }
    if (!projectsNumChart.current) {
      projectsNumChart.current = echarts.init(
        document.getElementById('projectsNumChart'),
      )
    }
    projectsNumChart.current.setOption(option, true)
    projectsNumChart.current.resize()
  }, [projectsNumData, orgOpenDate, orgStartDate, teamData.created_at])

  useEffect(() => {
    if (projectsNumData.date_list.length) {
      setTimeout(setProjectNumsChart, 500)
    }
  }, [team, projectsNumData, setProjectNumsChart])

  const setProjectsRankChart = useCallback(() => {
    console.log('render')
    const res = projectsRankData.sort((a, b) => b[sort] - a[sort])
    const views = res.map((item) => item.views)
    const loves = res.map((item) => item.loves)
    const shared_views = res.map((item) => item.shared_views)
    const favorites = res.map((item) => item.favorites)
    const option = {
      title: {
        // text: 'World Population'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      toolbox: {
        right: 44,
        top: -5,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          // saveAsImage: {}
        },
      },
      legend: {
        // show: false
        itemWidth: 12,
        itemHeight: 12,
      },
      color: ['#0084ff', '#0bd295', '#f55d00', '#fec015'],
      grid: {
        // left: '3%',
        // right: '4%',
        // width: '800',
        // bottom: '15%',
        containLabel: true,
      },
      yAxis: {
        type: 'value',
        // show: false,
        // boundaryGap: [0, 0.01],
        // maxInterval: Math.ceil(3051 / 5),
        max: function (value) {
          return value.max <= 5 ? 5 : null
        },
      },
      xAxis: {
        type: 'category',
        data: res.map((item) => item.title),
        axisLabel: {
          interval: 0,
          rotate: 20,
        },
      },
      series: [
        {
          name: '查看次数',
          type: 'bar',
          data: views,
        },
        {
          name: '分享后被观看次数',
          type: 'bar',
          data: shared_views,
        },
        {
          name: '被点赞数',
          type: 'bar',
          data: loves,
        },
        {
          name: '被收藏数',
          type: 'bar',
          data: favorites,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: 0,
          endValue: 9,
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          xAxisIndex: [0, 1],
        },
      ],
    }
    if (!projectsRankChart.current) {
      projectsRankChart.current = echarts.init(
        document.getElementById('projectsRankChart'),
      )
    }
    if (projectsRankChart.current && projectsRankData.length) {
      projectsRankChart.current.on('click', (params) => {
        window.open(
          `${scratchURL}/projects/${projectsRankData[params.dataIndex].id}/`,
        )
      })
    }
    projectsRankChart.current.setOption(option, true)
    projectsRankChart.current.resize()
  }, [projectsRankData, sort, projectsRankChart])

  useEffect(() => {
    if (projectsRankData.length) {
      setProjectsRankChart()
    } else if (projectsRankChart.current) {
      projectsRankChart.current.resize()
    }
  }, [sort, team, projectsRankData, setProjectsRankChart])

  const onDateRangeChange = (e) => {
    const start = moment(e[0]).format('YYYYMMDD')
    const end = moment(e[1]).format('YYYYMMDD')
    setDateRange({ start, end })
    setTimeInterval('datepicker')
  }

  useEffect(() => {
    if (timeInterval !== 'datepicker') {
      setDateRange(getRealDateRange(timeInterval))
    }
  }, [timeInterval])

  return (
    <>
      {teamList.length ? (
        <>
          <div className="mainItem" style={{ padding: '30px' }}>
            {/* {team} */}
            <div className="mainHeader">
              <h3>选择团队：</h3>
              {team && (
                <Select
                  defaultValue={team}
                  onChange={(e) => {
                    setTeam(e)
                    setTimeInterval('last7days')
                    window.localStorage.setItem(`lastTeam-${userInfo?.id}`, e)
                  }}
                  style={{ width: 160 }}
                  disabled={teamList.length === 1}
                >
                  {teamList.map((team) => (
                    <Option key={team.id} value={team.id}>
                      {team.name}
                    </Option>
                  ))}
                </Select>
              )}
              <p className="createTime">
                团队创建时间：
                {moment(teamData?.created_at).format('YYYY/MM/DD')}
              </p>
            </div>
            <Spin spinning={loading.team}>
              <div className="dataList">
                <div className="dataItem">
                  <img
                    className="icon"
                    alt="day"
                    src="/image/datacenter/data_icon1.png"
                  />
                  <div className="content">
                    <span className="item_title">当前学员</span>
                    <span className="num">{teamData?.num_student}</span>
                  </div>
                </div>
                <div className="dataItem">
                  <img
                    className="icon"
                    alt="day"
                    src="/image/datacenter/data_icon2.png"
                  />
                  <div className="content">
                    <span className="item_title">当前课程</span>
                    <span className="num">{teamData?.course_num}</span>
                  </div>
                </div>
                <div className="dataItem">
                  <img
                    className="icon"
                    alt="day"
                    src="/image/datacenter/data_icon3.png"
                  />
                  <div className="content">
                    <span className="item_title">作品集</span>
                    <span className="num">{teamData?.num_studios}</span>
                  </div>
                </div>
                <div className="dataItem">
                  <img
                    className="icon"
                    alt="day"
                    src="/image/datacenter/data_icon4.png"
                  />
                  <div className="content">
                    <span className="item_title">提交作品</span>
                    <span className="num">{teamData?.project_num}</span>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
          <div className="mainItem">
            <div className="bottomLine"></div>
            {learnTimeData.length ? (
              <DownLoadBar
                imageId="learnTimeChartBox"
                title="总看课时长"
                style={{ top: '93px' }}
                excelParams={{
                  name: ['课程名称', '看课时长/h'],
                  key: ['name', 'time'],
                  data: learnTimeData.map((item) => ({
                    name: item.course_title,
                    time: (item.total / 3600).toFixed(1),
                  })),
                  filename: `${teamData?.name}团队总看课时长-${moment().format(
                    'YYYY/MM/DD',
                  )}`,
                }}
              />
            ) : null}
            <div id="learnTimeChartBox" className="imageBox">
              <SubHeader
                title="总看课时长"
                smallTitle={
                  Object.is(
                    moment().format('YYYY/MM/DD'),
                    moment(teamData?.created_at).format('YYYY/MM/DD'),
                  )
                    ? null
                    : `（${moment(teamData?.created_at).format(
                      'YYYY/MM/DD',
                    )} ~ ${moment()
                      .subtract(1, 'day')
                      .format('YYYY/MM/DD')}）`
                }
                tips="每日凌晨更新"
                width={100}
                style={{ marginBottom: '30px' }}
              />
              <Spin spinning={loading.time}>
                <div
                  id="learnTimeChart"
                  style={{ height: learnTimeData.length ? '400px' : 0 }}
                ></div>
                {!learnTimeData.length ? (
                  <div className="empty">
                    <img src="/image/empty-page.png" alt="empty" />
                    <p className="text">本团队未关联课程</p>
                  </div>
                ) : null}
              </Spin>
            </div>
            <div className="bottomLine"></div>
          </div>

          <div className="mainItem">
            {!(
              Object.is(
                moment().format('YYYY/MM/DD'),
                moment(teamData?.created_at).format('YYYY/MM/DD'),
              ) ||
              Object.is(
                moment().format('YYYY/MM/DD'),
                moment(orgOpenDate).format('YYYY/MM/DD'),
              )
            ) ? (
              <DownLoadBar
                imageId="projectsNumChartBox"
                title="每日提交作品数"
                style={{ top: '90px' }}
                excelParams={{
                  name: ['日期', '提交作品数量'],
                  key: ['date', 'num'],
                  data: projectsNumData?.date_list?.map((item, idx) => ({
                    date: item,
                    num: projectsNumData?.result[idx],
                  })),
                  filename: `${teamData?.name
                    }团队每日提交作品数-${moment().format('YYYY/MM/DD')}`,
                }}
              />
            ) : null}
            <div id="projectsNumChartBox" className="imageBox">
              <SubHeader
                title="每日提交作品数"
                tips="每日凌晨更新"
                width={100}
                style={{ marginBottom: '30px' }}
              />
              <Spin spinning={loading.nums}>
                <div
                  id="projectsNumChart"
                  style={{
                    height: !(
                      Object.is(
                        moment().format('YYYY/MM/DD'),
                        moment(teamData?.created_at).format('YYYY/MM/DD'),
                      ) ||
                      Object.is(
                        moment().format('YYYY/MM/DD'),
                        moment(orgOpenDate).format('YYYY/MM/DD'),
                      )
                    )
                      ? '400px'
                      : 0,
                  }}
                ></div>
                {Object.is(
                  moment().format('YYYY/MM/DD'),
                  moment(teamData?.created_at).format('YYYY/MM/DD'),
                ) ||
                  Object.is(
                    moment().format('YYYY/MM/DD'),
                    moment(orgOpenDate).format('YYYY/MM/DD'),
                  ) ? (
                  <div className="empty">
                    <img src="/image/empty-page.png" alt="empty" />
                    <p className="text">暂无数据</p>
                  </div>
                ) : null}
              </Spin>
            </div>
            <div className="bottomLine"></div>
          </div>
          <div className="mainItem lastItem">
            {projectsRankData.length ? (
              <>
                <DownLoadBar
                  imageId="projectsRankChartBox"
                  title="作品排行榜"
                  style={{ top: '150px' }}
                  excelParams={{
                    name: [
                      '作品名',
                      '查看次数',
                      '分享后被观看次数',
                      '被点赞数',
                      '被收藏数',
                    ],
                    key: [
                      'name',
                      'views',
                      'shared_views',
                      'loves',
                      'favorites',
                    ],
                    data: projectsRankData
                      ?.sort((a, b) => b[sort] - a[sort])
                      .map((item) => ({
                        name: item.title,
                        views: item.views,
                        shared_views: item.shared_views,
                        loves: item.loves,
                        favorites: item.favorites,
                      })),
                    filename: `${teamData?.name
                      }团队作品排行榜-${moment().format('YYYY/MM/DD')}`,
                  }}
                />
                <div className="sortSelect">
                  <span className="text">排序：</span>
                  <Select
                    placeholder="请选择"
                    style={{ width: 160 }}
                    onChange={(e) => setSort(e)}
                  >
                    <Option value="views">查看次数</Option>
                    <Option value="shared_views">分享后被观看次数</Option>
                    <Option value="loves">被点赞数</Option>
                    <Option value="favorites">被收藏数</Option>
                  </Select>
                </div>
              </>
            ) : null}
            {moment(orgOpenDate).format('YYYYMMDD') <=
              moment().format('YYYYMMDD') ? (
              <div className="timeSelect">
                <div className="tabs">
                  <div
                    className="text"
                    style={{
                      color:
                        timeInterval === 'last7days' ? '#0084ff' : '#333333',
                    }}
                    onClick={() => setTimeInterval('last7days')}
                  >
                    近7天
                  </div>
                  <div
                    className="text"
                    style={{
                      color:
                        timeInterval === 'last30days' ? '#0084ff' : '#333333',
                    }}
                    onClick={() => setTimeInterval('last30days')}
                  >
                    近30天
                  </div>
                  {moment()
                    .subtract(1, 'weeks')
                    .endOf('week')
                    .format('YYYYMMDD') <
                    moment(orgOpenDate).format('YYYYMMDD') ? (
                    <div className="text disabled">上周</div>
                  ) : (
                    <div
                      className="text"
                      style={{
                        color:
                          timeInterval === 'lastweek' ? '#0084ff' : '#333333',
                      }}
                      onClick={() => setTimeInterval('lastweek')}
                    >
                      上周
                    </div>
                  )}
                  {moment()
                    .subtract(1, 'months')
                    .endOf('month')
                    .format('YYYYMMDD') <
                    moment(orgOpenDate).format('YYYYMMDD') ? (
                    <div className="text disabled">上月</div>
                  ) : (
                    <div
                      className="text"
                      style={{
                        color:
                          timeInterval === 'lastmonth' ? '#0084ff' : '#333333',
                      }}
                      onClick={() => setTimeInterval('lastmonth')}
                    >
                      上月
                    </div>
                  )}
                </div>
                <RangePicker
                  onChange={onDateRangeChange}
                  value={[moment(dateRange.start), moment(dateRange.end)]}
                  format={dateFormat}
                  disabledDate={(current) =>
                    disabledDate(current, orgOpenDate, true)
                  }
                  allowClear={false}
                />
              </div>
            ) : null}
            <div id="projectsRankChartBox" className="imageBox">
              <SubHeader
                title="作品排行榜"
                smallTitle={`（${moment(dateRange.start).format(
                  'YYYY/MM/DD',
                )} ~ ${moment(dateRange.end).format('YYYY/MM/DD')}）`}
                style={{ marginBottom: '87px' }}
              />
              <Spin spinning={loading.rank}>
                <div
                  id="projectsRankChart"
                  style={{ height: projectsRankData.length ? '400px' : 0 }}
                ></div>
                {!projectsRankData.length ? (
                  <div className="empty">
                    <img src="/image/empty-page.png" alt="empty" />
                    <p className="text">学员还没有提交作品</p>
                  </div>
                ) : null}
              </Spin>
            </div>
          </div>
        </>
      ) : (
        <div className="empty">
          <img src="/image/empty-page.png" alt="empty" />
          <p className="text">还没有创建团队</p>
        </div>
      )}
    </>
  )
}

export default TeamView
