import React, { useState, useEffect } from 'react';
import './style.scss';
import ReactMarkdown from 'react-markdown/with-html';

const Agreement = () => {
  const [markdown, setMarkdown] = useState();

  useEffect(() => {
    fetch('/docs/agreement.md')
      .then(response => response.text())
      .then(text => {
        setMarkdown(text);
        console.log('text', text);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  return (
    <div id="agreement" className="container markdown">
      <ReactMarkdown escapeHtml={false} linkTarget="_blank" source={markdown} />
    </div>
  );
};

export default Agreement;
