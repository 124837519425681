import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useReducer,
  useLayoutEffect
} from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import {
  LeftOutlined,
  SwitcherOutlined,
  PlusOutlined,
  EyeOutlined
} from '@ant-design/icons';
import {
  Button,
  Table,
  Input,
  message,
  Modal,
  Row,
  Col,
  Alert,
  Tooltip,
  Divider,
  Select
} from 'antd';
import copy from 'copy-to-clipboard';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getTeamDetail,
  getMember,
  createSingleMember,
  createMutilMember,
  patchTeamDetail,
  deleteTeam,
  deleteTeamMember,
  changeTeamTeacher,
  changeTeam,
  getTeachersList,
  editTeamDescr,
  getTeam
} from './../../../../utils/request';
import {
  secretImageUrl,
  testName,
  simpleToast,
  deBounce
} from './../../../../utils';
import Icon from '../../../../components/icon';
import ConfirmModal from '../../../../components/confirmModal';
import { getUser, setStudentsFlag } from '../../../../store/actions';
import ChangeSecretkey from '../../../../components/changeSecretkey';
import AddMember from '../../../../components/addMember';
import CourseManage from '../../../courseManage';

let timer = null;
const { TextArea } = Input;
const { Option } = Select;
const defaultUser = {
  key: Math.floor(Math.random() * 100000),
  default: true
};

const types = {
  EDIT_TEAM_DESCR: 'EDIT_TEAM_DESCR',
  ON_DESCR_CHANGE: 'ON_DESCR_CHANGE'
};

const initialState = {
  idEdit: false,
  teamDescr: ''
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.EDIT_TEAM_DESCR:
      return { ...state, isEdit: !state.isEdit };
    case types.ON_DESCR_CHANGE:
      return { ...state, teamDescr: action.text };
    default:
      return state;
  }
};

const TeamItem = ({ match, history, ...props }) => {
  const userInfo = useSelector(state => state.userInfo);
  const isCourse = window.location.search.includes('courseManage');
  const [state, dispatch] = useReducer(reducer, initialState);
  const [pageSize, setPageSize] = useState(8);
  const [addedName, setAddedName] = useState('');
  const [addedNames, setAddedNames] = useState([]);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showDeleteManagerModal, setDeleteManagerModal] = useState(false);
  const [curTeamCode, setCurTeamCode] = useState(null);

  const [showDeleteStudentModal, setDeleteStudentModal] = useState(false);
  const [showChangeModal, setChangeModal] = useState(false);
  // const [showAddModal, setAddModal] = useState(false);
  // const [showKeyModal, setKeyModal] = useState(false);

  // const [memberName, setMemberName] = useState(''); // 学员用户名
  const [changeTeachersModal, setChangeTeachersModal] = useState(false);
  const addedNameInputRef = useRef();
  const [inputValue, setInputValue] = useState('');
  const [showSecretkey, setShowSecretkey] = useState(false);
  const [showCreateUsers, setShowCreateUsers] = useState(false);
  const [showTeamLink, setShowTeamLink] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]); // 已保存的授课教师
  const [changeTeacherValue, setChangeTeacherValue] = useState(selectedValue); // 未保存的授课教师
  // const studentsFlag = useSelector((state) => state.studentsFlag); // 学员数是否达到上限
  const teamLinkRef = useRef('');
  const [teamData, setTeamData] = useState();
  const [data, setData] = useState([]);
  // const [total, setTotal] = useState(0); // 已有学员总数
  const _dispatch = useDispatch();
  const [saveData, setSaveData] = useState([]); // 已保存的学员列表数据
  const [current, setCurrent] = useState(1);
  const [success, setSuccess] = useState(false);
  const [selectedTeamValue, setSelectedTeamValue] = useState('');
  const [employee, setEmployee] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [allTeam, setAllTeam] = useState([]);
  const [curRecord, setCurRecord] = useState(null);
  // const [curKeyType, setCurKeyType] = useState(null);
  // const [curKeyType, setCurKeyType] = useState(null);

  const [curMemberRecord, setCurMemberRecord] = useState(null);
  const [curMemberRecordIndex, setCurMemberRecordIndex] = useState(null);
  const manager_CanCreateStudent = useSelector(
    state => state.manager_CanCreateStudent
  );
  const teacher_CanCreateStudent = useSelector(
    state => state.teacher_CanCreateStudent
  );

  // const [warning, setWarning] = useState(false)

  const onChangeData = useRef(null);
  // const warningRef = useRef(false)
  const successRef = useRef(false);
  const addMemberRef = useRef();
  const changeSecretkeyRef = useRef();

  const maxNum = userInfo?.team_data?.max_team_people || 0;
  // 组织管理员或非组织教师
  // const Manager_Or_NonOrgTeacher =
  //   userInfo?.user_type.indexOf("manager") !== -1 ||
  //   (userInfo?.user_type.indexOf("teacher") !== -1 &&
  //     JSON.stringify(userInfo?.organization_data) === "{}");

  const filtedData = useMemo(() => {
    let copiedData = data.slice();
    let insertIndex = 0;
    // 过滤首行默认用户
    copiedData = copiedData.filter(item => !item.default);
    // 未保存用户放前面
    copiedData = [
      ...copiedData.filter(item => item.saved === false),
      ...copiedData.filter(item => item.saved !== false)
    ];
    // 插入默认用户
    copiedData = copiedData.map((item, index) => ({ ...item, key: index }));
    while (insertIndex < copiedData.length) {
      copiedData.splice(insertIndex, 0, defaultUser);
      insertIndex += pageSize;
    }
    if (data.length === 0) {
      copiedData = [defaultUser];
    }
    // setTotal(copiedData.length - 1);
    _dispatch(getUser());
    return copiedData;
  }, [_dispatch, data, pageSize]);

  // const handleChange = (value) => {
  //   // setSelectedValue(value);
  //   // setChangeTeacherValue(value);
  // };
  const handleChangeTeam = value => {
    setSelectedTeamValue(value);
    setCurTeamCode(allTeam.find(f => f.id === value).code)
  };
  const patchTeamInfo = useCallback(
    config => {
      return patchTeamDetail(match.params.id, config);
    },
    [match.params.id]
  );
  const handleTeachersList = async () => {
    setChangeTeachersModal(true);
    let res = await getTeachersList();
    setEmployee(res?.data?.results);
  };
  const getAllMember = useCallback(() => {
    if (isCourse) return;
    return getMember(match.params.id, { page: 1, page_size: 99999 })
      .then(res => {
        dispatch(
          setStudentsFlag(
            res.data.count >= userInfo?.team_data?.max_team_people
          ) || userInfo?.team_data?.max_team_people === 0
        );
        setData(
          res.data.results.map((item, index) => ({
            ...item,
            cancelDelete: false,
            saved: true
          }))
        );
        // setTotal(res.data.count);
      })
      .catch(e => {
        console.error(e);
      });
  }, [match.params.id, userInfo.team_data.max_team_people, isCourse]);

  const deleteTeamHandler = useCallback(() => {
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再尝试');
      return;
    }
    deleteTeam(match.params.id)
      .then(res => {
        message.success('删除团队成功');
        setTimeout(() => {
          window.location.href = '/manage/team';
        }, 1000);
      })
      .catch(e => {
        if (typeof e.response.data === 'string') return;
        if (e.response.status === 400) {
          message.warning(e.response.data.message);
        } else {
          message.error('操作失败，请稍后再尝试');
        }
        setDeleteManagerModal(false);
      });
  }, [match.params.id]);

  useEffect(() => {
    onChangeData.current = data;
  });
  // useEffect(() => {
  //     warningRef.current = warning
  // }, [])
  useEffect(() => {
    let save = data.filter(item => item.saved);
    setSaveData(save);
  }, [data]);
  const deleteMemberQuickly = (info, index, id) => {
    let getToast = simpleToast();
    return () => {
      clearTimeout(null);
      if (!window.navigator.onLine) {
        getToast({ type: 'error', text: '操作失败，请稍后再尝试' });
        return;
      }
      const newData = onChangeData.current
        .slice(0, index)
        .concat(onChangeData.current.slice(index + 1));
      deleteTeamMember(id, info.id).then(res => {
        setData(newData);
        message.success('成功删除学员账户')
      });
    };
  };

  const deleteMemberHandler = (info, index, id) => {
    if (userInfo?.user_type.indexOf('manager') > -1 && teamData.is_now_create) {
      setCurMemberRecord(info);
      setCurMemberRecordIndex(index);
      setDeleteStudentModal(true);
    } else {
      //  非组织教师 或 成为组织管理员之前创建的 ，无弹框二次确认
      deleteMemberQuickly(info, index, id)();
    }
  };
  const changeTeamHandle = async (record, index, id) => {
    let { data } = await getTeam({
      page: 1,
      page_size: 9999
    });
    setCurRecord(record);
    setAllTeam(data.results)
    let options = data.results?.map(item => (
      <Option value={item.id} key={item.id}>
        {item.name}
      </Option>
    ));

    setTeamOptions(options);
    // setSelectedTeamValue(teamData?.name);
    setChangeModal(true);
  };
  // const addMemberHandle = () => {
  //   setAddModal(true);
  // };
  // const changeKeyHandle = (record, index, id) => {
  //   console.log(record);
  //   setCurRecord(record);
  //   setCurKeyType(record?.key_type);

  //   setKeyModal(true);
  // };
  // const changeKeyType = type => {
  //   type === 1 ? setCurKeyType('image') : setCurKeyType('word');
  // };
  const initPageDetail = useCallback(async () => {
    let { data } = await getTeamDetail(match.params.id);
    if (data) {
      setTeamData({
        ...data,
        cover: secretImageUrl + data.cover.replace('covers/', '')
      });
      let ids = data?.team_teacher?.map(element => {
        return element.id;
      });
      setSelectedValue(ids);
      setChangeTeacherValue(ids);
      dispatch({ type: types.ON_DESCR_CHANGE, text: data.description || '' });
      setShowSecretkey(data.show_key);
      await getAllMember();
    } else {
      message.info('未找到该团队');
    }
    // })()
  }, [getAllMember, match.params.id]);
  // 初始化数据
  useEffect(() => {
    (async () => {
      if (teamData) {
        await getAllMember();
      } else {
        await initPageDetail();
      }
    })();
  }, [match.params.id]); // eslint-disable-line

  useLayoutEffect(() => {
    document.querySelector(
      '.ant-table-pagination .ant-select-selection-item'
    ) &&
      (document.querySelector(
        '.ant-table-pagination .ant-select-selection-item'
      ).innerText = `${pageSize} 条/页`);
  }, [data]); // eslint-disable-line

  const editTeamIcon = useCallback(
    file => {
      let ids = teamData?.team_teacher?.map(item => {
        return item.id;
      });
      return patchTeamInfo({ cover: file, team_teacher: ids })
        .then(res => {
          setTeamData(res.data);
        })
        .catch(err => console.log(err));

      // === before the change
      // patchTeamInfo({ cover: file })
      //   .then(res => {
      //     setTeamData(res.data);
      //   })
      //   .catch(err => console.log(err));
    },
    [patchTeamInfo, teamData]
  );
  const handleTeachers = () => {
    if (!changeTeacherValue.length) {
      message.warn('请选择授课教师');
      return;
    }
    if (changeTeacherValue && changeTeacherValue.length > 3) {
      message.warn('最多可选择 3 位授课教师');
      return;
    }
    changeTeamTeacher(match.params.id, { team_teacher: changeTeacherValue }).then(res => {
      if (res.status === 200) {
        message.success('更换授课教师成功');
        initPageDetail();
      }
    });

    setChangeTeachersModal(false);
  };

  const saveSingleUser = useCallback(
    user => {
      if (!testName(user.name)) {
        message.info('请输入中文、英文、数字或下划线');
        return;
      }
      createSingleMember(match.params.id, user.name)
        .then(res => {
          message.success('创建学员成功');

          let newData = data.map(item =>
            item.randomKey === user.randomKey
              ? { ...res.data, saved: true }
              : item
          );
          setData(newData);
          // _dispatch(getUser());
          if (!teamData.is_touch) {
            let ids = teamData.team_teacher.map(item => {
              return item.id;
            });
            return patchTeamInfo({ is_touch: true, team_teacher: ids });
          }
        })
        .catch(e => {
          if (typeof e.response.data === 'string') return;
          if (e.response.status === 400) {
            message.error(
              e.response.data.message || '创建学员失败，请稍后再试'
            );
            return;
          }
          message.error('创建学员失败，请稍后再试');
        });
    },
    [data, match.params.id, patchTeamInfo, teamData]
  );

  const saveMutilUser = useCallback(() => {
    let users = data
      .filter(item => item.saved === false)
      .map(user => ({ name: user.name }));
    //  全部保存时数量超出可创建数量
    if (
      (userInfo?.user_type.indexOf('manager') > -1 &&
        users.length > manager_CanCreateStudent) ||
      (userInfo?.user_type === 'teacher' &&
        JSON.stringify(userInfo?.organization_data) !== '{}' &&
        users.length > teacher_CanCreateStudent) ||
      (userInfo?.user_type === 'teacher' &&
        JSON.stringify(userInfo?.organization_data) === '{}' &&
        users.length > userInfo?.team_data?.max_team_people - saveData.length)
    ) {
      return message.warn('创建学员数量超额');
    }

    createMutilMember(match.params.id, users)
      .then(res => {
        message.success(
          `批量创建学员成功 ${users.length} 个，失败 ${users.length - res.data.length
          } 个`
        );

        setAddedNames([]);
        setData([
          ...res.data.map(item => ({ ...item, saved: true })),
          ...data.filter(item => item.saved === true)
        ]);
        // _dispatch(getUser());

        if (!teamData.is_touch) {
          let ids = teamData.team_teacher.map(item => {
            return item.id;
          });
          return patchTeamInfo({ is_touch: true, team_teacher: ids });
        }
      })
      .catch(e => {
        console.error(e);
        if (typeof e.response.data === 'string') return;
        if (e.response.status === 400) {
          message.error(
            e.response.data.message || '批量创建学员全部失败，请稍后再试'
          );
          return;
        }
        message.error('批量创建学员全部失败，请稍后再试');
      });
  }, [
    data,
    manager_CanCreateStudent,
    match.params.id,
    patchTeamInfo,
    saveData.length,
    teacher_CanCreateStudent,
    teamData,
    // teamData.is_touch,
    // teamData.team_teacher,
    userInfo.organization_data,
    userInfo.team_data.max_team_people,
    userInfo.user_type
  ]);

  const cancelUsers = useCallback(users => {
    setData(data => {
      let copiedData = [...data];
      users.forEach(user => {
        copiedData.splice(
          copiedData.findIndex(item => item.randomKey === user.randomKey),
          1
        );
      });
      return copiedData;
    });
  }, []);

  const handleCreateUsersOk = useCallback(
    e => {
      setData(data => {
        let filered = [
          ...addedNames.slice(0, 100).map((name, index) => ({
            name: name,
            saved: false,
            randomKey: Math.floor(Math.random() * 100000)
          })),
          ...data
        ];
        return filered;
      });
      setAddedNames([]);
      setShowCreateUsers(false);
      setCurrent(1);
    },
    [addedNames]
  );

  const createUser = useCallback(
    e => {
      if (!testName(addedName)) {
        message.info('请输入中文、英文、数字或下划线');
        return;
      }
      setData(data => {
        let filered = [
          {
            name: addedName,
            saved: false,
            randomKey: Math.floor(Math.random() * 100000)
          },
          ...data
        ];
        return filered;
      });
      setAddedName('');
      setInputValue('');
      // addedNameInputRef.current.handleReset(e);
      setCurrent(1);
    },
    [addedName]
  );

  const handleEditTeamDescr = useCallback(() => {
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再尝试');
      return;
    }
    dispatch({ type: types.EDIT_TEAM_DESCR });
    if (state.isEdit) {
      let ids = teamData.team_teacher.map(item => {
        return item.id;
      });
      editTeamDescr(match.params.id, {
        description: state.teamDescr,
        team_teacher: ids
      }).then(res => {
        if (res.status === 200) {
          message.success('保存成功');
        }
      });
    }
  }, [match.params.id, state.isEdit, state.teamDescr, teamData]);
  const columns = [
    {
      title: () => (
        <span>
          <span style={{ color: 'red' }}> *</span> 姓名
        </span>
      ),
      dataIndex: 'name',
      key: 'name',
      width: 400,
      render: (value, record, index) => {
        if (record.default) {
          return (
            <Input
              ref={addedNameInputRef}
              maxLength={15}
              onChange={e => {
                e.persist();
                setAddedName(e.target.value);
                setInputValue(e.target.value);
              }}
              value={inputValue}
              style={{ width: '300px' }}
              disabled={
                userInfo?.user_type.indexOf('manager') > -1
                  ? !(manager_CanCreateStudent > 0)
                  : userInfo?.user_type === 'teacher' &&
                    JSON.stringify(userInfo?.organization_data) !== '{}'
                    ? !(teacher_CanCreateStudent > 0)
                    : userInfo?.team_data?.max_team_people <= saveData.length
              }
            />
          );
        }

        return (
          <span
            className='num_studios'
            onClick={() =>
              history.push({
                pathname: `/account/home/${record.user}`
              })
            }
          >{value}</span>
        );
      }
    },
    {
      title: '用户名',
      dataIndex: 'user',
      key: 'user',
      render: (value, record, index) => {
        if (!record.saved) {
          return '自动生成';
        }
        return value;
      }
    },
    {
      title: () => (
        <span>
          <span style={{ userSelect: 'none' }}>密钥</span>
          <span
            className="showSecretkey-btn"
            onClick={() => {
              let ids = teamData.team_teacher.map(item => {
                return item.id;
              });

              setShowSecretkey(!showSecretkey);
              patchTeamInfo({ show_key: !showSecretkey, team_teacher: ids });
            }}
          >
            {!showSecretkey ? (
              <span className="iconHide">
                <svg
                  t="1585283272898"
                  className="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2253"
                  width="16"
                  height="16"
                >
                  <path
                    d="M949.81249971 283.71875029c-11.00625029-2.45625029-18.86249971 1.42499971-25.2 8.71875-3.41250029 3.9375-10.4625 16.93125-11.26874971 18.35624971-17.55 31.46249971-83.11875029 102.30000029-177.6375 154.2375-3.825 0.9-7.51875029 2.43749971-10.53749971 5.36249971-0.225 0.225-0.29999971 0.54375029-0.52500058 0.76875029-61.12500029 31.79999971-133.55624971 55.10625029-212.69999971 55.10625029-204.37499971 0-359.60625-153.09375029-398.43749971-211.40625058-1.96875-2.96250029-11.3625-19.42499971-15.8625-24.1875-5.86874971-6.22500029-13.5-10.10625029-23.83125029-6.525-7.78124971 2.7-11.75625 9.675-11.8125 16.85625029-0.01874971 2.96250029 2.00625029 8.86875029 2.04374971 8.94375 0.86249971 2.54999971 2.04374971 5.36249971 3.56250058 8.41875029 17.775 36.13124971 79.83749971 106.10624971 168.03749971 163.35l-121.93125029 124.875a22.48125029 22.48125029 0 1 0 32.175 31.42499942l125.68125058-128.7c1.2375-1.27500029 1.89375029-2.83124971 2.77499971-4.29374971a559.125 559.125 0 0 0 109.51875 46.55625029l-50.7375 160.34999942a22.48125029 22.48125029 0 1 0 42.88124971 13.55625l51.525-162.86249971c27.35624971 5.47499971 55.575 8.60625 84.45000058 8.60625 15.73125029 0 31.20000029-1.08749971 46.49999942-2.79375029l50.75625058 157.01250058a22.5 22.5 0 0 0 42.78749942-13.8375l-48.67499971-150.58125c47.09999971-10.10625029 91.44375029-27.13124971 131.88750029-48.00000058l114.73124942 118.25625058a22.5 22.5 0 0 0 32.2875-31.31250029l-106.34999942-109.64999971c95.41874971-58.59375029 162.54375029-133.94999971 180.7875-172.18125 2.04374971-4.29374971 5.36249971-11.11875029 5.34375-18.86250058-0.03750029-6.59999971-4.34999971-13.79999971-12.22500058-15.56249942z"
                    p-id="2254"
                  ></path>
                </svg>
              </span>
            ) : (
              <EyeOutlined />
            )}
          </span>
        </span>
      ),
      dataIndex: 'secretkey',
      key: 'secretkey',
      width: 220,
      render: (value, record, index) => {
        if (!record.saved) {
          return '自动生成';
        } else if (!showSecretkey) {
          return <span>******</span>;
        }
        return record.key_type === 'word' ? (
          record.secret
        ) : (
          <img
            className="secretKeyImg"
            src={secretImageUrl + record.secret_image}
            alt={record.secret_image}
          />
        );
      }
    },
    {
      title: '操作',
      key: 'action',
      align: 'left',
      width: 322,
      render: (value, record, index) => {
        if (record.default) {
          return (
            <Button
              // disabled={maxNum <= data.length}
              disabled={
                userInfo?.user_type.indexOf('manager') > -1
                  ? !(manager_CanCreateStudent > 0)
                  : userInfo?.user_type === 'teacher' &&
                    JSON.stringify(userInfo?.organization_data) !== '{}'
                    ? !(teacher_CanCreateStudent > 0)
                    : userInfo?.team_data?.max_team_people <= saveData.length
              }
              type="primary"
              onClick={deBounce(createUser, 500)}
            >
              创建
            </Button>
          );
        } else if (!record.saved) {
          return (
            <React.Fragment>
              <Button
                type="primary"
                onClick={deBounce(() => saveSingleUser(record), 500)}
                disabled={
                  userInfo?.user_type.indexOf('manager') > -1
                    ? !(manager_CanCreateStudent > 0)
                    : userInfo?.user_type === 'teacher' &&
                      JSON.stringify(userInfo?.organization_data) !== '{}'
                      ? !(teacher_CanCreateStudent > 0)
                      : userInfo?.team_data?.max_team_people <= saveData.length
                }
              >
                保存
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                onClick={() => cancelUsers([record])}
              >
                取消
              </Button>
            </React.Fragment>
          );
        } else {
          return (
            <div className="link_btnsCss">
              {JSON.stringify(userInfo?.organization_data) !== '{}' &&
                teamData.is_now_create ? (
                <>
                  <Button
                    type="link"
                    className="keyBtn"
                    onClick={() => {
                      setCurRecord(record);
                      changeSecretkeyRef.current.changeSecretkeyHandler();
                    }}
                  >
                    更换密钥
                  </Button>
                </>
              ) : null}
              {userInfo?.user_type === 'teacher' ||
                (userInfo?.user_type.indexOf('manager') > -1 &&
                  !teamData.is_now_create) ? null : (
                <>
                  {JSON.stringify(userInfo?.organization_data) !== '{}' &&
                    teamData.is_now_create ? (
                    <Divider type="vertical" />
                  ) : null}

                  <Button
                    type="link"
                    className="changeBtn"
                    onClick={() =>
                      changeTeamHandle(record, record.key, match.params.id)
                    }
                  >
                    更换团队
                  </Button>
                  <Divider type="vertical" />
                </>
              )}
              {/* 成为组织教师之后创建的就隐藏删除按钮 */}
              {Object.is(userInfo?.user_type, 'teacher') &&
                JSON.stringify(userInfo?.organization_data) !== '{}' &&
                teamData.is_now_create ? null : (
                // 加入组织之前创建的，或者是非组织教师/管理员则显示
                <>
                  <Button
                    type="link"
                    // className="deleteBtn"
                    onClick={() => {
                      deleteMemberHandler(record, record.key, match.params.id);
                    }}
                  >
                    删除
                  </Button>
                </>
              )}
            </div>
          );
        }
      }
    }
  ];

  useEffect(() => {
    successRef.current = success;
  }, [success]);

  const copyLink = () => {
    // let getToast = simpleToast()
    // return () => {
    clearTimeout(timer);
    teamLinkRef.current.focus();
    copy(teamLinkRef.current.input.value);
    // getToast({ text: '复制成功' })
    if (!successRef.current) {
      message.success('复制成功', 0);
      setSuccess(true);
    }
    timer = setTimeout(() => {
      message.destroy();
      setSuccess(false);
      // clearTimeout(timer)
    }, 3000);
    // }
  };

  const { isEdit, teamDescr } = state;

  return (
    <div id="manageTeamItem">
      {teamData ? (
        <>
          <div className="container">
            <div className="back">
              <Link
                className="backLink"
                to={`/manage/team${history.location.state?.prevSearch || ''}`}
              >
                <LeftOutlined />
                <span className="back">查看所有团队</span>
              </Link>
            </div>

            <div className="teamTitle">
              <Icon
                type="team"
                className="image"
                defaultImg={teamData.cover}
                random={true}
                onChange={file => editTeamIcon(file)}
              />
              <div className="message">
                <div style={{ verticalAlign: 'top' }}>
                  <span className="title">
                    {teamData?.name} · {isCourse ? '课程管理' : '学员管理'}
                  </span>
                  {userInfo?.user_type.indexOf('manager') !== -1 ||
                    (userInfo?.user_type.indexOf('teacher') !== -1 &&
                      JSON.stringify(userInfo?.organization_data) !== '{}')
                    ? (() => {
                      if (
                        // 校区管理员 | 组织教师，判断 其 is_now_create , false 隐藏授课教师、批量创建按钮和问号
                        (userInfo?.user_type.indexOf('manager') > -1 ||
                          (userInfo?.user_type === 'teacher' &&
                            JSON.stringify(userInfo?.organization_data) !==
                            '{}')) &&
                        !teamData.is_now_create
                      ) {
                        return null;
                      } else {
                        return (
                          <span className="showTeachers">
                            授课教师：{' '}
                            {teamData.team_teacher
                              .map(item => {
                                return item.name || item.username;
                              })
                              .join(' | ')}
                            {/* 组织教师不显示更改按钮 */}
                            {userInfo?.user_type === 'teacher' &&
                              JSON.stringify(userInfo?.organization_data) !==
                              '{}' ? null : (
                              <span
                                className="btn"
                                onClick={() => {
                                  handleTeachersList();
                                }}
                              >
                                (更换)
                              </span>
                            )}
                          </span>
                        );
                      }
                    })()
                    : null}
                </div>
                <div className="editarea">
                  {!!!isEdit ? (
                    <React.Fragment>
                      <div className="textArea">
                        {teamDescr || '请输入团队简介...'}
                        <span className="btn" onClick={handleEditTeamDescr}>
                          (编辑)
                        </span>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <TextArea
                        onChange={e => {
                          dispatch({
                            type: types.ON_DESCR_CHANGE,
                            text: e.target.value
                          });
                        }}
                        placeholder="请输入团队简介..."
                        className="textInput"
                        maxLength={100}
                        row={4}
                        value={state.teamDescr}
                      />
                      <span className="textlen">
                        {state.teamDescr.length > 100
                          ? 100
                          : state.teamDescr.length}
                        /100
                      </span>
                      <Button className="saveBtn" onClick={handleEditTeamDescr}>
                        保存
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>

            {isCourse ? (
              <CourseManage team_code={match.params.id} courseBags={teamData.course_bag} course={teamData.course} initPageDetail={initPageDetail}/>
            ) : (
              <>
                {(() => {
                  if (
                    // 管理员或组织教师加入组织前的团队只能查看和删除
                    (userInfo?.user_type.indexOf('manager') > -1 ||
                      (userInfo?.user_type === 'teacher' &&
                        JSON.stringify(userInfo?.organization_data) !==
                        '{}')) &&
                    !teamData.is_now_create
                  ) {
                    return null;
                  } else {
                    // 非组织教师、加入组织后的团队
                    return (
                      <div className="actionBar">
                        {/* 有组织的才显示’添加本组织成员‘ */}
                        {JSON.stringify(userInfo?.organization_data) !==
                          '{}' ? (
                          <Button
                            type="primary"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                              // addMemberHandle();
                              addMemberRef.current.addMemberHandler();
                            }}
                            icon={<PlusOutlined />}
                          >
                            添加本校区学员
                          </Button>
                        ) : null}

                        <Button
                          type="primary"
                          style={{ marginRight: 10 }}
                          // disabled={
                          //   data.length >= maxNum ||
                          //   userInfo?.team_data?.max_team_people === 0
                          // }
                          disabled={
                            // Boolean(-1) 为 true, 注意xx_CanCreateStudios的值可能为负数

                            userInfo?.user_type.indexOf('manager') > -1
                              ? !(manager_CanCreateStudent > 0)
                              : userInfo?.user_type === 'teacher' &&
                                JSON.stringify(userInfo?.organization_data) !==
                                '{}'
                                ? !(teacher_CanCreateStudent > 0)
                                : userInfo?.team_data?.max_team_people <=
                                saveData.length
                          }
                          onClick={() => {
                            setShowCreateUsers(true);
                          }}
                          icon={<SwitcherOutlined />}
                        >
                          批量创建
                        </Button>

                        <Tooltip
                          placement="right"
                          // title={`当前账户可以创建 ${userInfo?.team_data?.max_team} 个 ${userInfo?.team_data?.max_team_people} 人以下的团队`}
                          title={() => {
                            if (userInfo?.user_type.indexOf('manager') > -1) {
                              if (manager_CanCreateStudent > 0) {
                                return `当前还可以创建 ${manager_CanCreateStudent} 个学员账号`;
                              } else {
                                return '当前创建学员的数量已达上限，如需增加学员数量，请致电 400-931-8118';
                              }
                            }
                            // 教师身份
                            // if (userInfo?.user_type === "teacher") {
                            //   if (teacher_CanCreateStudent > 0) {
                            //     return `当前还可以创建 ${teacher_CanCreateStudent} 个学员账号`;
                            //   } else {
                            //     return JSON.stringify(
                            //       userInfo?.organization_data
                            //     ) === "{}"
                            //       ? "当前创建学员的数量已达上限，如需增加学员数量，请致电 400-931-8118"
                            //       : "当前创建学员的数量已达上限，如需增加学员数量，请联系组织管理员";
                            //   }
                            // }
                            // 组织教师身份
                            if (
                              userInfo?.user_type === 'teacher' &&
                              JSON.stringify(userInfo?.organization_data) !==
                              '{}'
                            ) {
                              if (teacher_CanCreateStudent > 0) {
                                return `当前还可以创建 ${teacher_CanCreateStudent} 个学员账号`;
                              } else {
                                return '当前创建学员的数量已达上限，如需增加学员数量，请联系组织管理员';
                              }
                            }
                            // 非组织教师身份
                            if (
                              userInfo?.user_type === 'teacher' &&
                              JSON.stringify(userInfo?.organization_data) ===
                              '{}'
                            ) {
                              if (
                                userInfo?.team_data?.max_team_people -
                                saveData.length >
                                0
                              ) {
                                return `当前还可以创建 ${userInfo?.team_data?.max_team_people -
                                  saveData.length
                                  } 个学员账号`;
                              } else {
                                return '当前创建学员的数量已达上限，如需增加学员数量，请致电 400-931-8118';
                              }
                            }
                            // ====== before the change ====
                            // if (userInfo?.team_data?.max_team_people === 0) {
                            //   return "当前账户无法创建团队学员，如需使用此功能，请致电 400-931-8118";
                            // } else if (data.length >= maxNum) {
                            //   return "当前账户创建团队学员的数量已达上限，如需增加团队学员数量，请致电 400-931-8118";
                            // } else {
                            //   return `当前账户可以创建 ${userInfo?.team_data?.max_team} 个 ${userInfo?.team_data?.max_team_people} 人以下的团队`;
                            // }
                          }}
                          overlayClassName="teamFloatingTooltip"
                        >
                          <img
                            src="/image/icon_question.png"
                            alt="question"
                            className="tip"
                          />
                        </Tooltip>
                      </div>
                    );
                  }
                })()}

                <div className="table">
                  <Table
                    columns={columns}
                    dataSource={filtedData}
                    pagination={{
                      current: current,
                      showQuickJumper: true,
                      defaultPageSize: pageSize,
                      onChange: val => setCurrent(val),
                      onShowSizeChange: (cur, size) => {
                        setCurrent(cur);
                        setPageSize(size);
                      },
                      showTotal: (total, range) => {
                        return `共 ${Math.ceil(total / pageSize)} 页 / ${total - Math.ceil(total / pageSize)
                          } 条数据`;
                      }
                    }}
                    className={
                      // 校区教师|校区管理员 判断 其 is_now_create , false 隐藏授课教师、批量创建按钮和问号
                      (userInfo?.user_type.indexOf('manager') > -1 ||
                        (userInfo?.user_type === 'teacher' &&
                          JSON.stringify(userInfo?.organization_data) !==
                          '{}')) &&
                        !teamData.is_now_create
                        ? 'hide'
                        : ''
                    }
                  />
                  <div className="pagination">
                    <div className="teamAction">
                      {data.filter(item => item.saved === false).length > 0 ? (
                        <>
                          <Button
                            size="large"
                            type="primary"
                            onClick={deBounce(saveMutilUser, 500)}
                            disabled={
                              userInfo?.user_type.indexOf('manager') > -1
                                ? !(manager_CanCreateStudent > 0)
                                : userInfo?.user_type === 'teacher' &&
                                  JSON.stringify(
                                    userInfo?.organization_data
                                  ) !== '{}'
                                  ? !(teacher_CanCreateStudent > 0)
                                  : userInfo?.team_data?.max_team_people <=
                                  saveData.length
                            }
                          >
                            全部保存
                          </Button>
                          <Button
                            size="large"
                            onClick={() => {
                              message.error('已取消批量创建学员');
                              cancelUsers(
                                data.filter(item => item.saved === false)
                              );
                            }}
                          >
                            全部取消
                          </Button>
                          <Alert
                            className="alert"
                            message="建议您检查完所有内容无误后再进行全部操作"
                            type="warning"
                            showIcon
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            type="primary"
                            size="large"
                            disabled={!data.length}
                            onClick={() => setShowTeamLink(true)}
                          >
                            专属链接
                          </Button>

                          <Link
                            to={
                              match.url +
                              (match.url.slice(-1) === '/'
                                ? 'logincode'
                                : '/logincode')
                            }
                          >
                            <Button size="large" disabled={!data.length}>
                              打印登录卡
                            </Button>
                          </Link>
                          {/* 非组织教师 或 校区教师|管理员加入组织前*/}

                          {(userInfo?.user_type.indexOf('manager') > -1 &&
                            !teamData.is_now_create) ||
                            (userInfo?.user_type === 'teacher' &&
                              JSON.stringify(userInfo?.organization_data) ===
                              '{}') ||
                            (userInfo?.user_type === 'teacher' &&
                              JSON.stringify(userInfo?.organization_data) !==
                              '{}' &&
                              !teamData.is_now_create) ? (
                            <Button
                              // className="deleteBtn"
                              style={{ marginLeft: 10 }}
                              size="large"
                              onClick={() => setDeleteModal(true)}
                            >
                              删除团队
                            </Button>
                          ) : null}
                          {/* 校区管理员|组织教师加入组织后 */}
                          {(userInfo?.user_type.indexOf('manager') > -1 ||
                            (userInfo?.user_type.indexOf('teacher') !== -1 &&
                              JSON.stringify(userInfo?.organization_data) !==
                              '{}')) &&
                            teamData.is_now_create ? (
                            <Button
                              // className="deleteBtn"
                              style={{ marginLeft: 10 }}
                              size="large"
                              onClick={() => setDeleteManagerModal(true)}
                            >
                              删除团队
                            </Button>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <Modal
                  closeIcon={
                    <img
                      src="/image/close.png"
                      alt="close"
                      width="14px"
                      height="14px"
                    />
                  }
                  getContainer={false}
                  visible={showCreateUsers}
                  onCancel={e => {
                    setShowCreateUsers(false);
                    setAddedNames([]);
                  }}
                  centered={true}
                  destroyOnClose={true}
                  footer={
                    <>
                      {/* 非组织教师 保留原来的 */}
                      {(() => {
                        if (
                          userInfo?.user_type === 'teacher' &&
                          JSON.stringify(userInfo?.organization_data) === '{}'
                        ) {
                          return (
                            <Button
                              onClick={handleCreateUsersOk}
                              style={{ marginLeft: 10 }}
                              type="primary"
                              // === before the change
                              disabled={
                                !addedNames.length ||
                                addedNames.length > maxNum - saveData.length ||
                                addedNames.length > 100
                              }
                            >
                              完成
                            </Button>
                          );
                        } else if (
                          userInfo?.user_type.indexOf('manager') > -1
                        ) {
                          return (
                            <Button
                              onClick={handleCreateUsersOk}
                              style={{ marginLeft: 10 }}
                              type="primary"
                              disabled={
                                !addedNames.length ||
                                addedNames.length > manager_CanCreateStudent ||
                                addedNames.length > 100
                              }
                            >
                              完成
                            </Button>
                          );
                        } else if (
                          userInfo?.user_type === 'teacher' &&
                          JSON.stringify(userInfo?.organization_data) !== '{}'
                        ) {
                          return (
                            <Button
                              onClick={handleCreateUsersOk}
                              style={{ marginLeft: 10 }}
                              type="primary"
                              disabled={
                                !addedNames.length ||
                                addedNames.length > teacher_CanCreateStudent ||
                                addedNames.length > 100
                              }
                            >
                              完成
                            </Button>
                          );
                        }
                      })()}

                      {/* <Button
                  onClick={handleCreateUsersOk}
                  style={{ marginLeft: 10 }}
                  type="primary"
                  // === before the change
                  disabled={
                    !addedNames.length ||
                    addedNames.length > maxNum - data.length ||
                    addedNames.length > 100
                  }
                  
                >
                  完成
                </Button> */}
                    </>
                  }
                >
                  <div className="createUsers">
                    <div className="title">批量创建团队学员</div>
                    <div className="info">
                      请在下方输入你所需要批量创建的团队学员的姓名，单个姓名最长
                      15 个汉字，每行一个姓名（请不要留空行），每次最多批量创建
                      100 个学员账号。
                    </div>
                    <Input.TextArea
                      placeholder={'小花\n小明\n小白\n……'}
                      onChange={e => {
                        let users = e.target.value
                          .trim()
                          .split('\n')
                          .filter(item => item !== '')
                          .map(user => user.slice(0, 15));
                        setAddedNames(users);
                      }}
                      style={{ height: '261px' }}
                    />
                    <span style={{ color: '#999999', lineHeight: '30px' }}>
                      {/* 当前账户可以创建 {userInfo?.team_data?.max_team} 个{" "}
                {userInfo?.team_data?.max_team_people} 人以下的团队。 */}
                      当前还可以创建{' '}
                      {userInfo?.user_type.indexOf('manager') > -1
                        ? manager_CanCreateStudent
                        : userInfo?.user_type === 'teacher' &&
                          JSON.stringify(userInfo?.organization_data) !== '{}'
                          ? teacher_CanCreateStudent
                          : userInfo?.team_data?.max_team_people -
                          saveData.length}{' '}
                      个学员账号。
                    </span>
                  </div>
                </Modal>
                <Modal
                  closeIcon={
                    <img
                      src="/image/close.png"
                      alt="close"
                      width="14px"
                      height="14px"
                    />
                  }
                  getContainer={false}
                  visible={showTeamLink}
                  onOk={() => setShowTeamLink(false)}
                  onCancel={() => setShowTeamLink(false)}
                  centered={true}
                  footer={null}
                >
                  <div className="teamLink">
                    <div className="title">团队学员专属登录方式</div>
                    <div className="info">
                      团队登录代码:{' '}
                      <span style={{ color: 'black' }}>{teamData.code}</span>
                    </div>
                    <p className="info">团队专属登录链接：</p>
                    <div>
                      <Row gutter={8}>
                        <Col span={18}>
                          <Input
                            ref={teamLinkRef}
                            value={`${window.location.origin}/login/${teamData.code}`}
                          />
                        </Col>
                        <Col span={6}>
                          <Button type="primary" onClick={copyLink}>
                            复制链接
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <p className="tips">
                      小贴士：如何让学生方便地进入平台？
                      <br />
                      {`1. 复制链接后，在系统桌面上右键 > 新建 > 快捷方式(S)`}{' '}
                      <br />
                      2. 将专属链接粘贴到输入框中，点击下一步 <br />
                      3. 给快捷方式命名为团队名称 <br />
                      4. 学生即可从桌面快速打开团队登录页，进入平台
                    </p>
                  </div>
                </Modal>
                {/* 非组织教师保留原逻辑 */}
                <ConfirmModal
                  title="是否确定删除团队？"
                  visible={showDeleteModal}
                  iconType="error"
                  onCancel={() => setDeleteModal(false)}
                  contentNode={
                    <>
                      删除团队后，
                      <span style={{ color: 'red' }}>
                        该团队内的所有学生账户将被删除，且无法被恢复
                      </span>
                      ，学生并不会收到通知。删除团队后，
                      <span style={{ color: 'red' }}>
                        与团队关联的作品集会被删除
                      </span>
                      。删除团队后，团队内所有学生账户
                      <span style={{ color: 'red' }}>
                        之前创建的作品、产生的评论等数据仍会存在
                      </span>
                      。你确定要删除团队吗？
                    </>
                  }
                  bottomNode={
                    <>
                      <Button onClick={() => setDeleteModal(false)}>
                        取消
                      </Button>
                      <Button danger onClick={deleteTeamHandler}>
                        确定
                      </Button>
                    </>
                  }
                />
                {/* 组织教师和组织管理员 */}
                <ConfirmModal
                  title="是否确定删除团队？"
                  visible={showDeleteManagerModal}
                  iconType="error"
                  onCancel={() => setDeleteManagerModal(false)}
                  contentNode={
                    <>
                      只能删除没有学员的团队。删除团队后，该团队关联的所有作品集会被删除，作品集里的作品与评论仍然会存在学员的账号里。
                    </>
                  }
                  bottomNode={
                    <>
                      <Button onClick={() => setDeleteManagerModal(false)}>
                        取消
                      </Button>
                      <Button danger onClick={deBounce(deleteTeamHandler, 300)}>
                        确定
                      </Button>
                    </>
                  }
                />
                <ConfirmModal
                  title="是否确定删除学员？"
                  visible={showDeleteStudentModal}
                  iconType="error"
                  onCancel={() => setDeleteStudentModal(false)}
                  contentNode={
                    <>
                      删除学员后，如果该学员仍有其他团队，则可以继续登录教学云。否则该学员账号将被删除，无法登录教学云。
                    </>
                  }
                  bottomNode={
                    <>
                      <Button onClick={() => setDeleteStudentModal(false)}>
                        取消
                      </Button>
                      <Button
                        danger
                        onClick={() => {
                          deleteMemberQuickly(
                            curMemberRecord,
                            curMemberRecordIndex,
                            match.params.id
                          )();
                          setDeleteStudentModal(false);
                        }}
                      >
                        确定
                      </Button>
                    </>
                  }
                />
                <Modal
                  className="createModalCss"
                  title="更换团队"
                  visible={showChangeModal}
                  width="374px"
                  centered={true}
                  closeIcon={
                    <img
                      src="/image/close.png"
                      alt="close"
                      width="14px"
                      height="14px"
                    />
                  }
                  destroyOnClose={true}
                  onCancel={() => {
                    setChangeModal(false);
                    setSelectedTeamValue('');
                  }}
                  onOk={() => {
                    if (!window.navigator.onLine) {
                      message.error('操作失败，请稍后再尝试');
                      return;
                    }
                    if (!selectedTeamValue) {
                      message.success('更换团队成功');
                      setChangeModal(false);
                      return;
                    }
                    changeTeam(curRecord?.team || curTeamCode, curRecord?.id, {
                      name: curRecord?.name,
                      team: selectedTeamValue
                    })
                      .then(() => {
                        message.success('更换团队成功');
                        setChangeModal(false);
                        getAllMember();
                        _dispatch(getUser());
                      })
                      .catch(e => {
                        if (typeof e.response.data === 'string') return;
                        if (e.response.status === 400) {
                          message.warning(e.response.data.message);
                        } else {
                          message.error('更换团队失败');
                        }
                      });
                  }}
                >
                  <div className="formLabelCss">团队选择</div>
                  <Select
                    style={{ width: '100%' }}
                    placeholder="请选择"
                    defaultValue={teamData.id}
                    onChange={handleChangeTeam}
                  // value={selectedTeamValue}
                  >
                    {teamOptions}
                  </Select>
                </Modal>

                {/* 添加本校区成员 */}
                <AddMember
                  ref={addMemberRef}
                  team_code={match.params.id}
                  getAllMember={getAllMember}
                />

                {/* 更换密钥 */}
                <ChangeSecretkey
                  ref={changeSecretkeyRef}
                  record={curRecord}
                  getAllMember={getAllMember}
                />
              </>
            )}

            <Modal
              className="createModalCss"
              title="更换授课教师"
              closeIcon={
                <img
                  src="/image/close.png"
                  alt="close"
                  width="14px"
                  height="14px"
                />
              }
              centered={true}
              bodyStyle={{ borderRadius: '1em' }}
              destroyOnClose={true}
              maskClosable={false}
              width="374px"
              visible={changeTeachersModal}
              onOk={deBounce(handleTeachers, 500)}
              okText="保存"
              onCancel={() => {
                setChangeTeachersModal(false);
                setChangeTeacherValue(
                  teamData.team_teacher?.map(element => {
                    return element.id;
                  })
                );
              }}
              getContainer={true}
            >
              <div className="formLabelCss">授课教师</div>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="请选择"
                onChange={value => {
                  setChangeTeacherValue(value);
                }}
                // value={changeTeacherValue}
                defaultValue={teamData.team_teacher.map(element => {
                  return element.id;
                })}
              // defaultValue={selectedValue}
              >
                {employee.map(item => (
                  <Option value={item.user} key={item.user}>
                    {item.name || item.username}
                  </Option>
                ))}
              </Select>
            </Modal>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default withRouter(TeamItem);
