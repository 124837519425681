import React, { useEffect } from 'react'
import TCPlayer from 'tcplayer.js';
import 'tcplayer.js/dist/tcplayer.min.css';
import qs from 'query-string';
import './style.scss'
import { useSelector } from 'react-redux';
import { getVodSign } from '../../utils/request'
import { message } from 'antd';

const PlayerPage = ({ history, location, ...props }) => {
  const token = useSelector((state) => state.token);
  useEffect(() => {
    if (!token) {
      history.push('/login');
      return
    }
    message.config({
      top: window.innerHeight * 0.8
    })
    let { vid } = qs.parse(window.location.search);
    getVodSign({ file_id: vid })
      .then(res => {
        let hide;
        const { signature, license_url } = res.data
        const player = TCPlayer('player-container-id', {
          fileID: vid,
          // fileID: '1397757887676157141',
          appID: '1251349076',
          psign: signature,
          licenseUrl: license_url
        });
        player.on('resolutionswitching',function(){
          hide = message.loading({
            content: `清晰度切换中...`, 
            duration: 0,
            type:'loading',
          });
        })
        player.on('resolutionswitched',function(){
          message.success(`清晰度切换完毕`,1);
          hide()
        })
      })
      .catch((err) => {
        console.log('err', err);
        message.error(`获取签名失败：${err}`);
      })
      return ()=>{
        message.config({
          top:100
        })
      }
    // eslint-disable-next-line
  }, [])

  return (
    <div id="player">
      <video id="player-container-id" preload="auto" playsinline webkit-playsinline></video>
    </div>
  )
}

export default PlayerPage
