import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback
} from 'react';
import CommonModal from '../commonModal';
import { Button, message, Radio, Form } from 'antd';
import './index.scss';
import { secretImageUrl } from './../../utils';
import { fetchSecretkey, patchDetail } from './../../utils/api/manageCenter';

const ChangeSecretkey = (props, ref) => {
  const { record, getAllMember } = props;
  const [visible, setVisible] = useState(false);
  const formRef = useRef();
  const [secretType, setSecretType] = useState('image');
  const [secret, setSecret] = useState('');
  const [secretImage, setSecretImage] = useState('');

  useImperativeHandle(ref, () => ({
    changeSecretkeyHandler: () => setVisible(true)
  }));

  // 获取密钥
  const fetch = useCallback(type => {
    let secret_type = type;
    fetchSecretkey(secret_type).then(res => {
      if (secret_type === 'image') {
        setSecretImage(res?.data?.secret);
      } else {
        setSecret(res?.data?.secret);
      }
    });
  }, []);

  useEffect(() => {
    if (visible && formRef.current) {
      console.log(`formRef.current`, formRef.current, record);
      if (record) {
        const newItem = JSON.parse(JSON.stringify(record));
        formRef.current.setFieldsValue({
          secret_type: newItem.secret_type
        });
        setSecretType(newItem.secret_type);
        if (newItem.secret_type === 'image') {
          setSecretImage(newItem.secret_image);
          fetch('word');
        } else {
          setSecret(newItem.secret);
          fetch('image');
        }
      }
    }
  }, [formRef, visible, record, fetch]);

  const handlerSubmit = (id, data) => {
    patchDetail(id, data)
      .then(() => {
        message.success('更换密钥成功');
        handlerClose();
        getAllMember();
      })
      .catch(() => {
        message.error('更换密钥失败');
      });
  };

  // 关闭弹窗时做的处理
  const handlerClose = () => {
    setVisible(false);
    setSecretType('image');
    setSecret('');
    setSecretImage('');
  };

  return (
    <CommonModal
      title="更换密钥"
      width={284}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      contentNode={
        <div className="secretkey">
          <Form
            name="secretkey"
            ref={formRef}
            onFinish={values => {
              console.log('Success:', values);
              let val =
                values.secret_type === 'word'
                  ? { secret: secret, secret_image: '' }
                  : { secret_image: secretImage, secret: '' };
              handlerSubmit(record.id, { ...values, ...val });
            }}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item label="密钥类型" name="secret_type">
              <Radio.Group
                buttonStyle="solid"
                onChange={e => setSecretType(e.target.value)}
              >
                <Radio.Button value="image">图片密钥</Radio.Button>
                <Radio.Button value="word">词组密钥</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="密钥">
              <div className="secret">
                <p>
                  {secretType === 'image' ? (
                    <img src={secretImageUrl + secretImage} alt={secretImage} />
                  ) : (
                    secret
                  )}
                </p>
                <span onClick={() => fetch(secretType)}>（更换）</span>
              </div>
            </Form.Item>
          </Form>
        </div>
      }
      bottomNode={
        <>
          <Button onClick={() => setVisible(false)}>取消</Button>
          <Button type="primary" onClick={() => formRef.current.submit()}>
            确定
          </Button>
        </>
      }
    />
  );
};

export default forwardRef(ChangeSecretkey);
