import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import store from './store';
import * as serviceWorker from './serviceWorker';

import 'infima/dist/css/default/default.css';
import './index.scss';
import App from './App';
import './ant-css-fix.scss';
// import { mediaQuery } from './utils/index';
import websocket from './utils/websocket';

// if (!mediaQuery(480)) {
//   console.log('Mobile redirect');
//   window.location.href = window.location.href.replace('learn.', 'm.learn.');
// }
window.websocket = websocket;

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
