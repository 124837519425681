import React, { memo } from 'react';
import Comment from '../comment';
import './style.scss';

const areEqual = (prevProps, nextProps) => {
  // console.log(prevProps, nextProps);
  return false;
};
const TopLevelComment = memo(
  ({
    comment,
    replies,
    userName,
    onAddReply,
    onLoadMore,
    onLoadSingle,
    moreReplyLoad,
    canDelete,
    canReply,
    hightlightId,
    handleDelete,
  }) => {
    const loadMoreReply = () => {
      if (hightlightId) {
        // console.log(hightlightId);
        // let id = Object.is(comment.id, hightlightId) ?
        onLoadSingle(comment.id, hightlightId);
      } else {
        onLoadMore(comment.id, replies.length);
      }
    };
    return (
      <div className="comment-box">
        <Comment
          hightlight={hightlightId === comment.id}
          key={`${comment.id}-${comment.author.id}`}
          comment={comment}
          userName={userName}
          onAddReply={onAddReply}
          commenteeId={comment.author.id}
          parentId={comment.id}
          canDelete={canDelete}
          canReply={canReply}
          onDelete={handleDelete}
          type="comment"
        />
        <div className="replies-list">
          {replies.length
            ? replies.map((reply) => (
                <Comment
                  hightlight={hightlightId === reply.id}
                  key={`${reply.id}-${reply.author.id}`}
                  comment={reply}
                  userName={userName}
                  onAddReply={onAddReply}
                  commenteeId={comment.author.id}
                  parentId={comment.id}
                  canDelete={canDelete}
                  canReply={canReply}
                  onDelete={handleDelete}
                  type="reply"
                />
              ))
            : null}
          {moreReplyLoad ? (
            <div className="reply-load-more" onClick={loadMoreReply}>
              更多回复
            </div>
          ) : null}
        </div>
      </div>
    );
  },
  areEqual
);

export default TopLevelComment;
