import React, {
  useState,
  useEffect,
  // useCallback,
  useLayoutEffect,
  useRef,
} from 'react';
import './style.scss';
import ReactMarkdown from 'react-markdown/with-html';
import matter from 'gray-matter';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BackTop, message, /*Popover,*/ Button } from 'antd';
// import { CloseCircleOutlined } from '@ant-design/icons'
import { /*setVisitHistory,*/ getProjectPreview } from '../../utils/request';
// import { simpleToast} from '../../../utils'
import { useGetProjects } from '../project/index';
import { CodeBlock } from '../course/courseItem';
import 'markdown-navbar/dist/navbar.css';
import MarkdownNavbar from 'markdown-navbar';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import { PlayCircleOutlined } from '@ant-design/icons';

const DPlayer = window.DPlayer;

function levelParese(str) {
  switch (str) {
    case '初级':
      return 'primary';
    case '中级':
      return 'mid';
    case '高级':
      return 'high';
    default:
      return '';
  }
}

const ProjectPreview = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { team_data } = useSelector(() => []);
  const [otherProjects] = useGetProjects();
  const [markdown, setMarkdown] = useState();
  const [warning, setWarning] = useState(false);
  const playerRef = useRef();
  const videoRef = useRef();
  const markdownRef = useRef();
  const warningRef = useRef(false);
  // const projectRef = useRef();

  useEffect(() => {
    // if (!otherProjects.length) return;
    let currentProject;
    localStorage.setItem('previewUrl', location.search);
    getProjectPreview(match.params.id, location.search)
      .then((res) => {
        currentProject = res.data;
        if (res.data.title) {
          return fetch(res.data.content);
        } else {
          setMarkdown(res);
          return Promise.resolve('不请求文档');
        }
      })
      .then((response) => {
        if (response !== '不请求文档') {
          return response.text();
        }
      })
      .then((text) => {
        if (text) {
          let mattered = matter(text);
          // if (!team_data?.is_submit_teaching_plan) {
          // let index = mattered.content.indexOf('教师部分');
          // if (index !== -1) {
          //   mattered.content = mattered.content.substring(0, index + 5);
          // }
          // }
          mattered.data = { ...mattered.data, ...currentProject };

          mattered.content = '\n' + mattered.content;
          setMarkdown(mattered);
          // if (mattered?.data?.id) {
          //   return setVisitHistory({
          //     project: currentProject.id,
          //     resourcetype: 'CourseProjectHistory',
          //   });
          // }
        }
        return Promise.resolve('未发现project id');
      })
      .then((res) => {
        console.log('已记录历史', res.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [match.params.id, otherProjects, team_data, location]);

  // const projectSelecteHandler = useCallback(
  //   (project) => {
  //     // eslint-disable-line
  //     history.push(
  //       `/projectPreview/${project.id}/${localStorage.getItem('previewUrl')}`
  //     );
  //   },
  //   [history]
  // );

  useLayoutEffect(() => {
    if (!markdown?.data?.ppt_furl) {
      if (markdown?.data?.previewvideo) {
        if (!playerRef.current) {
          playerRef.current = new DPlayer({
            container: videoRef.current,
            video: {
              url: markdown.data.previewvideo,
            },
          });
        } else {
          playerRef.current.switchVideo({
            url: markdown.data.previewvideo,
          });
        }
      }
    }
  }, [markdown]);

  const findTechTitle = () => {
    const arr = document.querySelectorAll('h1');
    for (let h1 of arr) {
      if (Object.is(h1.innerText, '教师部分')) return h1;
    }
    return 0;
  };

  useEffect(() => {
    warningRef.current = warning;
  }, [warning]);

  useEffect(() => {
    if (markdownRef.current) {
      markdownRef.current.addEventListener('click', (e) => {
        if (e.target.nodeName === 'IMG') {
          new Viewer(e.target);
        }
      });
    }
  }, [markdownRef]);

  useEffect(() => {
    let timer = null;
    let onClick = (e) => {
      // let getToast = simpleToast()
      // console.log('simple')
      // return (e) => {
      //     const techTitle = findTechTitle()
      //     if (Object.is(e.target, techTitle)) getToast({ type: 'warning', text: '当前登录的账户没有查看「教师部分」的权限' })
      //     // getToast = null
      // }
      clearTimeout(timer);
      const techTitle = findTechTitle();
      if (Object.is(e.target, techTitle) && !warningRef.current) {
        message.warning('当前登录的账户没有查看「教师部分」的权限', 0);
        setWarning(true);
      }
      timer = setTimeout(() => {
        message.destroy();
        clearTimeout(timer);
        setWarning(false);
      }, 3000);
    };
    if (team_data) {
      window.addEventListener('click', onClick);
    }
    return () => {
      if (team_data) {
        window.removeEventListener('click', onClick);
      }
    };
  }, [team_data, history]);

  // useEffect(() => {
  //     return () => {
  //         setWarning(false)
  //     }
  // }, [match.params.id])
  useEffect(() => {
    warningRef.current = warning;
  }, [warning]);

  // 下载ppt
  const downLoadPPT = (ppt_furl) => {
    const a = document.createElement('a');
    a.href = ppt_furl;
    a.setAttribute('download', '');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  console.log('markdown222222-------', markdown);

  return (
    <div id="projectItem2">
      <BackTop />
      <div className="content">
        {markdown?.data?.title ? (
          <>
            <div className="header">
              <div className="container">
                {/* new=========== */}
                <div className="row">
                  <div className="project2">
                    <div className="img">
                      <div
                        className={`level ${levelParese(
                          markdown?.data['difficult_tags']
                        )}`}
                      >
                        {markdown?.data['difficult_tags']}
                      </div>
                      <img
                        src={markdown?.data?.cover || '/image/course.png'}
                        alt={markdown.data.title}
                      />
                    </div>
                    <div className="title">{markdown?.data?.title}</div>
                    <div className="description">
                      {markdown?.data?.subtitle || markdown?.data?.description}
                    </div>
                    {/* <div className="tags">
                  {Array.isArray(markdown?.data?.tag) &&
                    markdown?.data?.tag.slice(0, 2).map((tag, index) => (
                      <div className="tag" key={index}>
                        {tag}
                      </div>
                    ))}
                  {Array.isArray(markdown?.data?.tag) &&
                  markdown?.data?.tag.length > 2 ? (
                    <Popover
                      getPopupContainer={() => projectRef.current}
                      placement="bottomRight"
                      content={
                        Array.isArray(markdown?.data?.tag) &&
                        markdown?.data?.tag.map((tag, index) => (
                          <div className="tag" key={index}>
                            {tag}
                          </div>
                        ))
                      }
                      trigger="hover"
                    >
                      <img
                        src="/image/icon-more.png"
                        className="more"
                        alt="more"
                      />
                    </Popover>
                  ) : null}
                </div> */}
                  </div>
                </div>
                {/* ================ */}
                <div className="row">
                  <div className="col col--8 left">
                    <div className="title">{markdown?.data?.title}</div>
                    <div className="subtitle">
                      {markdown?.data?.subtitle || markdown?.data?.description}
                    </div>
                    <div className="metaData">
                      <div>
                        {markdown?.data?.['knowledge_tags'] ? (
                          <>
                            <span className="title">知识范畴：</span>
                            <span className="desc">
                              {markdown.data['knowledge_tags']
                                .split('\n')
                                .join('、 ')}
                            </span>
                          </>
                        ) : null}
                      </div>
                      <div>
                        {markdown?.data?.['difficult_tags'] ? (
                          <>
                            <span className="title">项目难度：</span>
                            <span className="desc">
                              {markdown.data['difficult_tags']}
                            </span>
                          </>
                        ) : null}
                      </div>
                      <div>
                        {markdown?.data?.['key_tags'] ? (
                          <>
                            <span className="title">核心知识点：</span>
                            <span className="desc">
                              {markdown.data['key_tags']
                                .split('\n')
                                .join('、 ')}
                            </span>
                          </>
                        ) : null}
                      </div>
                      <div>
                        {markdown?.data?.['result_tags'] ? (
                          <>
                            <span className="title">项目成果：</span>
                            <span className="desc">
                              {markdown.data['result_tags']
                                .split('\n')
                                .join('、 ')}
                            </span>
                          </>
                        ) : null}
                      </div>
                      <div>
                        {markdown?.data?.['hardware_tags'] ? (
                          <>
                            <span className="title">涉及的硬件：</span>
                            <span className="desc">
                              {markdown?.data?.['hardware_tags']
                                .split('\n')
                                .join('、 ')}
                            </span>
                          </>
                        ) : null}
                      </div>
                      <div className="tags">
                        {Array.isArray(markdown?.data?.tag) &&
                          markdown.data.tag.map((tag, index) => (
                            <div className="tag" key={index}>
                              {tag}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="markdownWrapper">
              <div className="container">
                <div className="row">
                  <div ref={markdownRef} className="markdown col col--8">
                    {/* new==========如果有ppt，则不显示视频====== */}
                    {markdown?.data?.ppt_furl ? (
                      <div className="pptBox">
                        <div style={{ position: 'relative' }}>
                          <div className="pptMark">
                            <a
                              href={markdown.data.ppt_furl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <PlayCircleOutlined />
                            </a>
                          </div>
                          <div className="row ppt_contain">
                            <i></i>
                            <iframe
                              className="pptIframe"
                              title="pptIframe4"
                              // allowfullscreen="true"
                              style={{
                                border: 0,
                                height: '100%',
                                width: '100%',
                              }}
                              src={markdown.data.ppt_furl}
                            ></iframe>
                            <i></i>
                          </div>
                        </div>

                        {markdown?.data?.is_down &&
                        markdown?.data?.download_url ? (
                          <div
                            style={{
                              width: '100%',
                              position: 'absolute',
                              bottom: '-45px',
                              textAlign: 'right',
                            }}
                          >
                            <Button
                              type="primary"
                              onClick={() =>
                                downLoadPPT(markdown.data.download_url)
                              }
                            >
                              下载 PPT
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    ) : markdown?.data?.previewvideo ? (
                      <div className="previewvideo" ref={videoRef}></div>
                    ) : null}
                    {/* ======================= */}

                    <ReactMarkdown
                      escapeHtml={false}
                      linkTarget="_blank"
                      renderers={{ code: CodeBlock }}
                      source={markdown?.content}
                    />
                  </div>
                  <div className="col col--4 sidebar">
                    {/* <div className="nav">
                  <div className="title">
                    <div className="titleName">热门项目</div>
                  </div>
                  {
                    <div className="projects">
                      {otherProjects.slice(0, 6).map((project, index) => (
                        <div
                          className="project"
                          key={index}
                          onClick={() => projectSelecteHandler(project)}
                        >
                          <img src={project.cover} alt={project.title} />
                          <div className="name">{project.title}</div>
                        </div>
                      ))}
                    </div>
                  }
                </div> */}
                    <MarkdownNavbar
                      updateHashAuto={false}
                      source={markdown?.content}
                      headingTopOffset={100}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : markdown?.data?.code && markdown?.data?.code === 1000052 ? (
          <div className="container timeoutMsg">{markdown.data.msg}</div>
        ) : null}
      </div>
    </div>
  );
};

export default ProjectPreview;
