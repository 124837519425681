import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Layout from './layout'
import moment from 'moment'
import './App.css'
import { BrowserRouter as Router } from 'react-router-dom'
import RouterRender from './router'
import ScrollToTop from './utils/scrollToTop'
import dayjs from 'dayjs'
import {reportOnLineData} from './utils'
// import OfflineNotification from './components/offlineNotification';
// const { Client } = require('@elastic/elasticsearch')
require('dayjs/locale/zh-cn')
dayjs.locale('zh-cn')
var now = moment().locale('zh-cn').format('YYYY/MM/DD');

function App() {
  const userInfo = useSelector((state) => state.userInfo)
  // 未登录时进入课程页面，缓存课程界面
  if(/\/course\/(\d+)/.test(window.location.href) && !userInfo){
    localStorage.setItem('preEntry',window.location.href)
  }
  useEffect(() => {
    if (userInfo && JSON.stringify(userInfo?.organization_data) !== '{}') {
      const { end_date, personal_data_center, overall_organization_data } = userInfo?.organization_data;
      if (moment(now).isBefore(end_date) && (personal_data_center || overall_organization_data)) {
        reportOnLineData('online')
      }
    }
    // eslint-disable-next-line
  }, [userInfo])

  useEffect(() => {

    window.addEventListener('storage',(e) => {
      const updateKay = ['token', 'curCampus']
      if (updateKay.includes(e.key) && e.newValue !== e.oldValue && e.oldValue) {
        window.location.reload()
      }
    })
  }, []) // eslint-disable-line
  return (
    <Router>
      <ScrollToTop />
      {/* <OfflineNotification /> */}

      <Layout>
        <RouterRender />
      </Layout>
    </Router>
  )
}

export default App
