import React from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { routes, authRoutes, loginedRouter } from './config';
import { removeToken } from './../utils';
import { useSelector, useDispatch } from 'react-redux';
import { setUserInfo, setToken } from '../store/actions';
import qs from 'query-string';

const RouterRender = () => {
  const location = useLocation();
  const userInfo = useSelector(state => state.userInfo);
  const dispatch = useDispatch();
  const userArr = ['teacher', 'manager', 'manager|teacher'];

  const campus = useSelector((state) => state.campus)
  const selectCampus = campus.find(f => f.is_select)
  const toHome = () => {
    if (selectCampus.status === '1') {
        if (selectCampus.org_type === "0") {
          return '/course';
        } else {
            if (selectCampus.user_type === 'teacher') {
                
              return '/teachplan';
            } else {
                
              return '/course';
            }
        }
    } else {
        
      return '/notification';
    }
}
  let needToCourse = localStorage.getItem('needToCourseDetail')
  return (
    <Switch>
      {[
        ...(userInfo ? loginedRouter : []),
        ...routes,
        ...authRoutes,
      ].map((item, index) => {
        if (location.search === '?from=www') {
          dispatch(setUserInfo(null));
          dispatch(setToken(''));
          removeToken();
          return <Redirect to="/login" />;
        }

        if (Object.is(location.pathname, '/')) {
          if (userInfo) {
            return (
              <Redirect
                to={
                  needToCourse?'/course':
                    (userArr.indexOf(userInfo.user_type) !== -1 
                    ? '/course'
                    : '/account/home')
                }
              />
            );
          } else {
            return <Redirect to="/login" />;
          }
        } else if (item.authority) {
          // 处理未登录下需要权限页面跳转
          if (!userInfo) {
            // localStorage.removeItem('offshow');
            return (
              <Route
                key={index}
                path={item.path}
                render={props => (
                  <Redirect
                    to={{
                      pathname:
                        item.redirect === '/register' ? '/register' : '/login',
                      state: { prevPath: props.location }
                    }}
                  ></Redirect>
                )}
              />
            );
          }
          // 权限控制
          if (item.authority.includes(userInfo.user_type)) {
            return <Route key={index} {...item}></Route>;
          } else {
            //  部分页面学生/教师/管理员无法访问
            return (
              <Route key={index} path={item.path}>
                <Redirect to='/403'></Redirect> 
              </Route>
            );
          }
        } else if (item.redirect) {
          //  登录后 login register 页面跳转
          return (
            <Route
              key={index}
              path={item.path}
              render={props => {
                // 用于配合社区登录跳转   不要redirect
                if (
                  qs.parse(props.location.search).redirect &&
                  item.path === '/login'
                ) {
                  // 不能直接返回变量  要写成组件形式
                  let Login = item.component;
                  return <Login />;
                }
                if (
                  qs.parse(props.location.search).redirect &&
                  item.path === '/register'
                ) {
                  // 不能直接返回变量  要写成组件形式
                  let Register = item.component;
                  return <Register />;
                }
                return (
                  <Redirect
                    to={
                      needToCourse?'/course':
                        (userArr.indexOf(userInfo.user_type) !== -1 
                        ? '/course'
                        : toHome())
                    }
                  />
                );
              }}
            />
          );
        } else {
          // 处理无需权限页面
          return <Route key={index} {...item}></Route>;
        }
      })}
      <Route path="*">
        <Redirect to='/404'></Redirect>
      </Route>
    </Switch>
  );
};

export default RouterRender;
