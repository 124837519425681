import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useReducer
} from 'react';
import './style.scss';
import Icon from './../../../../components/icon';
import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Pagination, message, Menu, Tooltip, Table, Empty, Input, Select, Modal, Form, Popconfirm, Divider, Alert } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PortfolioModal from '../../../../components/portfolioModal';
import ConfirmModal from '../../../../components/confirmModal';
import dayjs from 'dayjs';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { secretImageUrl, targetURL, toDetail } from '../../../../utils';
import {
  getPortfolioDetail,
  getAreaPortfolioDetail,
  getPortfolioProjects,
  getAreaPortfolioProjects,
  getAllProjectIds,
  getProjects,
  addProjectsToStudio,
  patchFolioDetail,
  getPortfolioNotifications,
  getAreaPfNotifications,
  deletePortfolio,
  deleteAreaPortfolio,
  deleteProjectFromPortfolio,
  getStudioCurator,
  updateAreaPortfolio,
  addStudiocurator,
  delStudiocurator,
  curatorPromotion,
  getCuratorRoles,
  checkInvite,
  acceptInvitation
} from '../../../../utils/request';
import qs from 'query-string';

export const formatVerb = verb => {
  switch (verb) {
    case 'studio_project_add':
      return '添加作品';
    case 'studio_project_update':
      return '更新作品';
    case 'studio_bulk_add_project':
      return '添加作品';
    case 'studio_project_delete':
      return '移除作品';
      case 'studioactivity':
        return '更新了作品集信息';
    default:
      return verb;
  }
};

const { TextArea } = Input;
const TOGGLE_DEL_MODAL = 'toggle_del_modal';

const initialState = {
  toggleDelModal: false
};

const { Option } = Select
const reducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_DEL_MODAL:
      return { ...state, toggleDelModal: !state.toggleDelModal };
    default:
      return state;
  }
};
const imgFix =
  process.env.NODE_ENV === 'development'
    ? targetURL
    : '';
const roleObj = {
  curator: '参与者',
  manager: '管理者',
  owner: '创建者'
}
const PortfolioItem = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  const isArea = qs.parse(location.search)?.type === 'area'
  const toMember = qs.parse(location.search)?.toMember === '1'
  const userInfo = useSelector(state => state.userInfo);
  const { username } = userInfo;

  const match = useRouteMatch();
  const [portfolioData, setPortfolioData] = useState(null);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [showAddModal, setShowAddModal] = useState(
    qs.parse(location.search).create === 'new'
  );
  const [myProjects, setMyprojects] = useState([]);
  const [myProjectTotal, setMyProjectTotal] = useState(0);
  const [dataCurrent, setDataCurrent] = useState(1);
  const [curatorListCurrent, setCuratorListCurrent] = useState(1);
  const [dataTotal, setDataTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [titleEdit, setTitleEdit] = useState(false);
  const [descEdit, setDescEdit] = useState(false);
  const [addMemberShow, setAddMemberShow] = useState(false);
  const [titleVal, setTitleVal] = useState('');
  const [description, setDescription] = useState('');
  const [activityData, setActivityData] = useState([]);
  const [studioCurator, setStudioCurator] = useState([]);
  const [orderStr, setOrderStr] = useState(!isArea ? '-project_num' : '');
  const [roleFields, setRoleFields] = useState(undefined);
  const [selectedKeys, setSelectedKeys] = useState(['project']);
  const onChangeData = useRef(null);
  const formRef = useRef();
  const [operationAuth, setOperationAuth] = useState(false)
  const [delProjectShow, setDelProjectShow] = useState(false)
  const [isInvited, setIsInvited] = useState(false)
  const [invitedText, setInvitedText] = useState('')
  const [curProject, setCurProject] = useState(null)
  const [submitErr, setSubmitErr] = useState({})
  const [userName, setUserName] = useState('')
  const [identityOpt, setIdentityOpt] = useState([])
  const formatData = data => {
    return data.map(item => ({
      ...item,
      cancelDelete: false
    }));
  };
  let columns = [
    {
      title: '用户',
      dataIndex: 'regional_username',
      key: 'regional_username',
      sorter: true,
      width: 250,
      render: (checked, record) =>
        <div className='user_name'>
          <img src={imgFix + record.avatar_url} alt="" />
          <a
            className="name"
            href={`/account/home/${record.user_name}`}
          >{isArea ? (record.regional_username || record.name || record.user_name) : (record.name || record.regional_username || record.user_name)}</a>
        </div>
    },
    {
      title: '作品数',
      dataIndex: 'project_num',
      key: 'project_num',
      sorter: true
    },
    {
      title: '加入时间',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (val, record) => dayjs(record.created_at).format('YYYY/MM/DD HH:mm')
    }
  ]
  if(isArea){
    columns.splice(1,0,{
      title: '身份',
      dataIndex: 'role',
      key: 'role',
      sorter: true,
      render: (checked, record) => roleObj[record.role]
    })
    columns.push({
      title: '操作',
      key: 'action',
      align: 'left',
      render: (text, record, index) => (
        <div className='action'>
          {/* 1.本人身份是创建者或者是管理员
          2.本行数据为非创建者 */}
          {
            (portfolioData.studio.role === 'manager' || portfolioData.studio.role === 'owner') && record.role !== 'owner' ?
              <div>
                {
                  record.role !== 'manager' ? <>
                    <Button type="link" size="small" onClick={() => handlePromotion(record)}>升职</Button>
                    <Divider type="vertical" />
                  </> : null
                }
                <Popconfirm
                  title="移出此用户？"
                  onConfirm={() => confirmDel(record)}
                  onCancel={cancelDel}
                  okText="确定"
                  cancelText="取消"
                >
                  <span style={{ color: '#ec2934', cursor: 'pointer' }}>移出</span>
                </Popconfirm>
              </div> : '-'
          }
        </div>
      )
    })
  }
  
  const cancelDel = (e) => {
    console.log(e);
  };
  // 获取作品集项目列表
  const getPortfolioProjectsHandler = useCallback(() => {
    const from_type = qs.parse(location.search)?.from_type;
    const requestFn = isArea ? getAreaPortfolioProjects : getPortfolioProjects;
    requestFn(match.params.id, { 
      page_size: 20, 
      page: dataCurrent,
      ...(from_type !== 'undefined' ? { from_type } : null),
      })
      .then(res => {
        setData(formatData(res.data.results));
        setDataTotal(res.data.count);
        console.log(res.data);
      })
      .catch(e => {
        console.log(e);
      });
  }, [dataCurrent, match.params.id, isArea, location.search]);
  // 获取作品集项目列表
  const getAllPortfolioProjectsHandler = useCallback(id => {
    const paramsId = isArea ? id : portfolioData?.studio?.id
    if(!paramsId){
      return
    }

    getAllProjectIds(paramsId)
      .then(res => {
        setAllData(res.data.id_list);
      })
      .catch(e => {
        console.log(e);
      });
  }, [isArea, portfolioData]);
  // 获取成员列表
  const getAllStudiocurator = useCallback(id => {
    console.log('roleFields---',roleFields);
    const paramsId = isArea ? id : portfolioData?.studio?.id
    return getStudioCurator(paramsId, {
      page_size: 10,
      page: curatorListCurrent,
      ...(roleFields ? { role: roleFields } : null),
      ...(orderStr ? { order_str: orderStr } : null)
    })
      .then(res => {
        setStudioCurator(res.data.results);
        return getCuratorRoles(id)
      })
      .then(res => {
        res.data.data.unshift('所有身份');
        setIdentityOpt(res.data.data.map(el => <Option value={el} key={el}>{roleObj[el]}</Option>))
      })
      .catch(e => {
        console.log(e);
      });
  }, [curatorListCurrent, orderStr, roleFields, portfolioData, isArea]);
  // 确定删除成员
  const confirmDel = useCallback((record) => {
    delStudiocurator(match.params.id, record.id).then(res => {
      getAllStudiocurator(match.params.id)
      message.success('成功将该成员移出当前作品集');
    }).catch(err => {
      message.error('操作失败，请稍后再试');
    })
  }, [getAllStudiocurator, match.params.id]);
  // 员工升职
  const handlePromotion = useCallback((record) => {
    curatorPromotion(match.params.id, record.id, { role: 'manager' }).then(res => {
      getAllStudiocurator(match.params.id)
      message.success('成功给该成员升职');
    }).catch(err => {
      message.error('操作失败，请稍后再试');
    })
  }, [getAllStudiocurator, match.params.id]);

  useEffect(() => {
    if(toMember){
      setSelectedKeys(['member'])
    }
    getAllPortfolioProjectsHandler(match.params.id);
  }, [getAllPortfolioProjectsHandler, match.params.id, toMember]);

  //检查是否有邀请
  useEffect(() => {
    if (selectedKeys.includes('member')) {
      checkInvite(match.params.id).then(res => {
        const { is_invited_user } = res.data
        if (is_invited_user) {
          setInvitedText('你被邀请成为当前作品集的「参与者」')
        }
        setIsInvited(is_invited_user)
      });
    }
  }, [match.params.id, selectedKeys]);

  // 获取动态列表
  const getNotifications = useCallback(() => {
    const from_type = qs.parse(location.search)?.from_type;
    const requestNotificationsFn = isArea ? getAreaPfNotifications : getPortfolioNotifications;
    requestNotificationsFn(match.params.id,
      { ...(from_type !== 'undefined' ? { from_type } : null) })
      .then(res => {
        console.log('动态消息列表1', res.data);
        setActivityData(res.data.results);
      })
  }, [match.params.id, isArea, location.search])

  // 添加作品到作品集
  const addProjectsToStudioHandler = useCallback(
    projects => {
      addProjectsToStudio(portfolioData.studio.id, projects)
        .then(res => {
          setShowAddModal(false);
          setSelectedProjects([]);
          setCurrent(1);
          message.success('添加作品成功');
          getNotifications()
          getPortfolioProjectsHandler();
          getAllPortfolioProjectsHandler(match.params.id);
          if (!portfolioData.is_touch && !isArea) {
            return patchFolioDetail(match.params.id, { is_touch: true });
          }
        })
        .catch(err => {
          console.log(err);
          message.error('操作失败，请稍后再试');
        });
    },
    [
      getPortfolioProjectsHandler,
      getAllPortfolioProjectsHandler,
      getNotifications,
      portfolioData,
      match.params.id,
      isArea
    ]
  );

  useEffect(() => {
    const from_type = qs.parse(location.search)?.from_type;
    const requestFn = isArea ? getAreaPortfolioDetail : getPortfolioDetail;
    requestFn(match.params.id,{
      ...(from_type !== 'undefined' ? { from_type } : null)})
      .then(res => {
        if (!res.data.studio) {
          res.data.studio = {
            id: res.data.id,
            role: res.data.role
          }
        }
        setPortfolioData({
          ...res.data,
          cover: secretImageUrl + res.data.cover
        });

        setDescription(res.data.description)
        setTitleVal(res.data.title)
        getNotifications()
      })
      .catch(e => {
        console.log(e);
      });
  }, [match, isArea, getNotifications, location.search]);
  useEffect(() => {
    onChangeData.current = data;
  });
  useEffect(() => {
    if (portfolioData) {      
      setOperationAuth(
        // eslint-disable-next-line
        (!isArea && JSON.stringify(userInfo?.organization_data) !== '{}' && portfolioData.is_now_create && portfolioData.studio?.campus_id == userInfo.organization_data.id)
         ||
         (isArea && (portfolioData?.studio?.role === "owner" || portfolioData?.studio?.role === "curator" || portfolioData?.studio?.role === "manager"))
        )
    }
  }, [portfolioData, isArea, userInfo]);

  useEffect(() => {
    getPortfolioProjectsHandler();
  }, [dataCurrent, getPortfolioProjectsHandler, isArea]);

  useEffect(() => {
    getProjects({ page_size: 6, page: current, lms_public: 'true' })
      .then(res => {
        setMyprojects(res.data.results);
        setMyProjectTotal(res.data.count);
      })
      .catch(e => {
        console.log(e);
      });
  }, [current, showAddModal]);

  // 删除作品集请求
  const delPortfolioFunc = id => {
    const requestFn = isArea ? deleteAreaPortfolio : deletePortfolio;
    requestFn(id)
      .then(res => {
        message.success('删除作品集成功');
        dispatch({ type: TOGGLE_DEL_MODAL });
        window.history.back()
        // setTimeout(() => {
        //   window.location.href = '/manage/portfolio';
        // }, 1000);
      })
      .catch(e => {
        dispatch({ type: TOGGLE_DEL_MODAL });
        if (typeof e.response.data === 'string') return;
        if (e.response.status === 400) {
          message.warning(e.response.data.message);
        } else {
          message.error('操作失败，请稍后再尝试');
        }
      });
  };
  // 删除作品集
  const delPortfolio = () => {
    if (!window.navigator.onLine) {
      dispatch({ type: TOGGLE_DEL_MODAL });
      message.error('操作失败，请稍后再尝试');
      return;
    }
    delPortfolioFunc(match.params.id);
  };
  //删除区域作品集作品
  const delProject = useCallback(() => {
    deleteProjectFromPortfolio(curProject.id, portfolioData.studio.id)
      .then(res => {
        if ((dataTotal - 1) / 10 === dataCurrent && dataTotal !== 1) {
          setDataCurrent((prev) => prev - 1) //监听到页数变化，请求页面数据
        }else{
          getPortfolioProjectsHandler()
        }
        message.success('移除作品成功');
        getAllPortfolioProjectsHandler(match.params.id)
        return getAreaPfNotifications(match.params.id);
      })
      .then(res => {
        setActivityData(res.data.results);
        setDelProjectShow(false)
      });
  }, [curProject,dataCurrent,dataTotal, portfolioData, getPortfolioProjectsHandler, getAllPortfolioProjectsHandler, match.params.id])
  // 添加作品
  const handleAdd = () => {
    if (!operationAuth) {
      return
    }
    setShowAddModal(true)
  }
  // 成员列表排序
  const onChangeCuratorList = (pagination, filters, sorter, extra) => {
    console.log('sorter',sorter);
    if (sorter.field) {
      if(sorter.field==='regional_username'){
        sorter.field = isArea ? 'user__regional_username' : 'user__name'
      }
      if (sorter.order && sorter.order === 'descend') {
        setOrderStr(`-${sorter.field}`)
      } else {
        setOrderStr(`${sorter.field}`)
      }
    }
  };
  // 区域作品集名称与简介编辑
  const handleEditPortfolio = (field) => {
    field === 'title' ? setTitleEdit(true) : setDescEdit(true)
  }
  // 更新区域作品集名称与简介
  const updatePortfolio = useCallback((field, val) => {
    updateAreaPortfolio(portfolioData.id, {
      [field]: val
    }).then(res => {

      getNotifications()
      message.success(res.data.message);
      if (field === 'title') {
        setTitleEdit(false)
      } else {
        setDescEdit(false)
      }
      setPortfolioData({ ...portfolioData, [field]: val })
    }).catch(err=>{
      message.error(err?.response?.data?.message || '操作失败，请稍后重试')
    })
  }, [getNotifications, portfolioData])
  // 添加成员
  const addMemberHandler = useCallback(() => {
    setAddMemberShow(true);
    if (formRef.current) {
      setSubmitErr({})
      formRef.current.setFieldsValue({
        user_name: ''
      })
    }
  }, [formRef])
  // 邀请成员
  const onFinishInvite = useCallback((values) => {
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再试');
      return;
    }
    addStudiocurator(match.params.id, values).then(res => {
      getAllStudiocurator(match.params.id);
      setAddMemberShow(false)
      message.success('成功邀请该用户加入当前作品集');
    }).catch(e => {
      if (e.response.data.message) {
        setSubmitErr({
          validateStatus: "error",
          help: e.response.data.message
        })
      } else {
        message.error('操作失败，请稍后再试');
      }
    })
  }, [match.params.id, getAllStudiocurator])

  useEffect(() => {
    if (( !isArea && portfolioData?.studio?.id ) || isArea) {
      getAllStudiocurator(match.params.id);
    }
  // eslint-disable-next-line
  }, [ orderStr, roleFields])
  // 删除作品
  const handleDelPortfolio = useCallback((item, index) => {
    if (!isArea) {
      deleteProjectFromPortfolio(item.id, portfolioData.studio.id)
        .then(res => {
          if ((dataTotal - 1) / 10 === dataCurrent && dataTotal !== 1) {
            console.log('最后一条数据');
            setDataCurrent((prev) => prev - 1) //监听到页数变化，请求页面数据
          }else{
            console.log('1---');
            getPortfolioProjectsHandler()
          }
          message.success('移除作品成功');
          getAllPortfolioProjectsHandler(match.params.id)
          return getNotifications();
        })
    } else {
      setCurProject(item)
      setDelProjectShow(true)
    }
    // eslint-disable-next-line
  }, [dataTotal, dataCurrent, isArea, portfolioData, getPortfolioProjectsHandler])
// 接受邀请
const handleAccept = useCallback(() => {
  acceptInvitation(match.params.id,{
    role: "curator"
  }).then(res=>{
    setIsInvited(true)
    setInvitedText('太棒了，你现在已经成为这个作品集的「参与者」了')
    getAllStudiocurator(match.params.id)
  })
},[match.params.id,getAllStudiocurator])

  return (
    <div id="portfolioItem">
      {portfolioData ? (
        <div className="container">
          <div className="header">
            <Icon
              defaultImg={portfolioData.cover}
              type={isArea ? 'areaPortfolio' : 'portfolio'} />
            {
              isArea ?
                <div className='detail'>
                  <>
                    {
                      !titleEdit ?
                        <div className="title">
                          {portfolioData.title}
                          {
                            portfolioData.role === 'owner' || portfolioData.role === 'manager' ? <span onClick={() => handleEditPortfolio('title')}>(编辑)</span> : null
                          }
                        </div> :
                        <div className='flex'>
                          <Input
                            placeholder="请输入区域作品集名称"
                            defaultValue={portfolioData.title}
                            onBlur={e => {
                              setTitleVal(e.target.value)
                            }}
                            onChange={(e) => {
                              e.persist();
                              setTitleVal(e.target.value)
                            }}
                            maxLength={15}
                            suffix={
                              <span className='title_length'>
                                {titleVal.length}/15
                              </span>
                            }
                            style={{
                              width: '768px',
                              height: '32px',
                              border: '1px dashed gray',
                              outline: 'none',
                              marginRight: '15px'
                            }} />

                          <Button type='primary' onClick={() => updatePortfolio('title', titleVal)}>保存</Button>
                        </div>
                    }
                  </>
                  <div className="team">
                    归属区域组织：{portfolioData.org_name}
                  </div>
                  <>
                    {
                      !descEdit ?
                        <div className="description">
                          {
                            portfolioData.description || '请输入区域作品集简介... '
                          }
                          {
                            portfolioData.role === 'owner' || portfolioData.role === 'manager' ? <span onClick={() => handleEditPortfolio('description')}>(编辑)</span> : null
                          }
                        </div> :
                        <div className='flex'>
                          <TextArea
                            placeholder="请输入区域作品集简介"
                            defaultValue={portfolioData.description}
                            maxLength={200}
                            onBlur={e => {
                              setDescription(e.target.value)
                            }}
                            onChange={e => {
                              setDescription(e.target.value)
                            }}
                            rows={4}
                            style={{
                              width: '768px',
                              height: '104px',
                              border: '1px dashed gray',
                              outline: 'none',
                              marginRight: '15px'
                            }} />
                          <span className='description_length'>
                            {description.length}/200
                          </span>
                          <Button type='primary' onClick={() => updatePortfolio('description', description)}>保存</Button>
                        </div>
                    }

                  </>
                </div>
                : <div className="detail">
                  <div className="title">{portfolioData.studio?.title}</div>
                  <div className="team">团队 {portfolioData.team_name}</div>
                  <div className="description">
                    {portfolioData.studio?.description ||
                      'Ta 很神秘，还没留下作品集介绍'}
                  </div>
                </div>
            }
          </div>
          <div className="projectWrapper">
            <div className="wrapper">
              <div className="projects">
                <Menu
                  className="typeMenu"
                  onClick={(values) => {
                    setSelectedKeys([values.key])
                    if (values.key === 'member') {
                      getAllStudiocurator(match.params.id)
                    }
                  }}
                  selectedKeys={selectedKeys}
                  mode="horizontal"
                >
                  <Menu.Item key="project">作品</Menu.Item>
                  <Menu.Item key="member">成员</Menu.Item>
                </Menu>

                {/* 作品列表 */}
                {
                  selectedKeys.includes('project') ?
                    <>
                     <div className="actionBar">
                        <div>
                          {/* 无角色，不显示 */}
                          {(isArea && portfolioData?.studio.role !== '') || (!isArea && portfolioData?.role !== '-') ? <Tooltip placement="top" title={!operationAuth ? '此作品集不属于当前学校，你在当前学校就职期间，不可将作品添加到不属于当前学校管理的作品集中' : ''}>
                            <Button
                              icon={
                                <img
                                  width="12"
                                  style={{
                                    marginBottom: '2px',
                                    marginRight: '8px'
                                  }}
                                  height="12"
                                  src={!operationAuth ? '/image/add_disabled.png' : '/image/icon-add.png'}
                                  alt="add"
                                />
                              }
                              type="link"
                              size="small"
                              onClick={handleAdd}
                              className={!operationAuth ? 'add_disabled' : ''}
                            >
                              添加作品
                            </Button>
                          </Tooltip> : null}
                        </div>
                      </div>
                      {
                        <>
                          {
                            data.length > 0 ?
                              <div className="list">
                                {data.map((item, index) => (
                                  <div className="project" key={index}>
                                    {
                                      portfolioData && (
                                        Object.is(username, item.username) || (isArea && (Object.is(portfolioData.role, 'owner') || Object.is(portfolioData.role, 'manager'))) ? <CloseCircleFilled
                                          className="closeBtn"
                                          onClick={() => handleDelPortfolio(item, index)}
                                        /> : null
                                      )
                                    }
                                    <a
                                      href={toDetail(item.platform, item.id)}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <div className="image">
                                        <img src={item.image} alt={item.title} />
                                      </div>
                                    </a>
                                    <div className="title">
                                      <a
                                        href={toDetail(item.platform, item.id)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {item.title}
                                      </a>
                                    </div>
                                    <div className="author">
                                      <Link
                                        className="author"
                                        to={`/account/home/${item.username}`}
                                        target="_blank"
                                      >
                                        <span
                                          className="authorImg"
                                          style={{
                                            backgroundImage: 'url(' + item.avatar + ')'
                                          }}
                                        ></span>
                                        <span className="name">
                                          {
                                            isArea ? (item.regional_username || item.name || item.username) : (item.name || item.regional_username || item.username)
                                          }
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                ))}
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                              </div>
                              :
                              <div className='empty'>
                                <Empty
                                  image="/image/empty-page.png"
                                  description={isArea ? "此区域作品集内暂无作品" : "此作品集内暂无作品"}
                                ></Empty>
                              </div>
                          }
                          <div className="flex">
                            <div className="deleteProjects">
                              {
                                (isArea && portfolioData?.studio.role !== '') || (!isArea && portfolioData?.role !== '-') ?
                                  (Object.is(portfolioData.studio.role, 'owner') ||
                                    (!isArea && (userInfo.user_type.indexOf('manager') > -1 || userInfo.user_type.indexOf('teacher') > -1))) && (
                                    <Button
                                      size="large"
                                      className="deleteBtn"
                                      onClick={() => dispatch({ type: TOGGLE_DEL_MODAL })}
                                    >
                                      删除作品集
                                    </Button>
                                  )
                                  : null
                              }
                            </div>
                            {
                              data.length > 0 ? <div className="pagination">
                                <Pagination
                                  showQuickJumper
                                  current={dataCurrent}
                                  defaultPageSize={20}
                                  total={dataTotal}
                                  onChange={val => setDataCurrent(val)}
                                  showTotal={(total, range) =>
                                    `共 ${Math.ceil(total / 20)} 页 / ${total} 条数据`
                                  }
                                />
                              </div> : null
                            }
                          </div>
                        </>
                      }
                    </> :
                    <div className='curator_table'>
                      {
                        isInvited ? <Alert message={
                          <div className='invited_alert'>
                            <span>{invitedText}</span>
                            {
                              !invitedText.includes('太棒了') ? <span className='invited_click' onClick={handleAccept}>点击接受邀请</span> : null
                            }
                          </div>
                        } type="info" /> : null
                      }
                      {
                        isArea && <div className='actionBar flex_jcsb'>
                          <div className="search">
                            <span>身份：</span>
                            <Select
                              defaultValue={roleFields}
                              style={{ width: 305 }}
                              onChange={(value) => {
                                setRoleFields(value === '所有身份' ? '' : value)
                              }}
                              placeholder="请选择"
                            >
                              {
                                identityOpt.map(m => m)
                              }
                            </Select>
                          </div>
                          {
                            (portfolioData.studio.role === 'manager' || portfolioData.studio.role === 'owner') && <Button
                              icon={<img
                                width="12"
                                style={{ marginBottom: "2px", marginRight: "8px" }}
                                height="12"
                                src="/image/icon-add.png"
                                alt="add"
                              />}
                              type="link"
                              size="small"
                              onClick={addMemberHandler}
                            >
                              添加成员
                            </Button>}
                        </div>
                      }
                      <Table
                        dataSource={studioCurator}
                        columns={columns}
                        pagination={false}
                        onChange={onChangeCuratorList}
                        rowKey={'id'}
                        className='table'
                      />
                      <div className="pagination">
                        <Pagination
                          showQuickJumper
                          current={curatorListCurrent}
                          defaultPageSize={10}
                          total={studioCurator.length}
                          onChange={val => setCuratorListCurrent(val)}
                          showTotal={(total, range) =>
                            `共 ${Math.ceil(studioCurator.length / 10)} 页 / ${studioCurator.length} 条数据`
                          }
                        />
                      </div>
                    </div>
                }
              </div>
            </div>
            <div className="activity">
              <div className="actionBar">
                <div className="title">最新动态</div>
                <div>
                  <Link to={`/manage/portfolio/${portfolioData.id}/activity/?isArea=${isArea}`}>
                    {
                      (isArea && portfolioData?.studio.role !== '') || (!isArea && portfolioData?.role !== '-') ? <Button type="link" size="small" onClick={() => { }}>
                        查看全部
                        <img
                          width="6"
                          style={{ marginBottom: '1px', marginLeft: '6px' }}
                          height="10"
                          src="/image/icon-arrow.svg"
                          alt="add"
                        />
                      </Button> : null
                    }
                    
                  </Link>
                </div>
              </div>
              <div className="activityWrapper">
                {activityData.map((item, index) => (
                  <div className="item" key={index}>
                    <div
                      className={`activityDetail`}
                    >
                      <Link
                        className="author"
                        to={`/account/home/${item.actor_username}`}
                        target="_blank"
                      >
                        <img
                          className="actorImg"
                          src={item.actor_avatar}
                          width="48px"
                          alt="cover"
                        />
                      </Link>
                      <span className="name">
                        <Link
                          className="author"
                          to={`/account/home/${item.actor_username}`}
                          target="_blank"
                        >
                          {
                            isArea ? (item.actor_regional_username || item.actor_nick || item.actor_username) : (item.actor_nick || item.actor_regional_username || item.actor_username)
                          }
                        </Link>
                      </span>
                      <span className="action">{formatVerb(item.verb)}</span>
                      <span className="target">
                        {item.tgt_name}
                        {item.tgt_content
                          ? `等 ${item.tgt_content.split(',').length + 1
                          } 个作品`
                          : ''}
                          {item.verb==='studioactivity'?`${item.obj_name}的基本信息`:null}
                      </span>
                    </div>
                    <div className="time">
                      {dayjs(item.created_at).format('YYYY/MM/DD HH:mm')}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Modal visible={addMemberShow} centered footer={false} width={'589px'} onCancel={() => setAddMemberShow(false)}>
        <div className='invite_form'>
          <div className="title">邀请他人加入区域作品集</div>
          <div className="desc">此教师接受邀请后将成为当前作品集的「参与者」</div>
          <Form
            ref={formRef}
            name="basic"
            layout="inline"
            onFinish={onFinishInvite}
          >
            <Form.Item
              style={{ marginRight: '8px' }}
              name="user_name"
              {...submitErr}
            >
              <Input placeholder='请输入对方的用户名（登录时使用的 ID）' style={{ width: '410px' }} onChange={(e) => {
                setUserName(e.target.value);
                setSubmitErr({})
              }} />
            </Form.Item>
            <Form.Item style={{ marginRight: '0' }}>
              <Button disabled={userName.length < 1} type="primary" htmlType="submit" style={{ width: '88px', padding: 0, textAlign: 'center' }}>
                搜索并邀请
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <PortfolioModal
        visible={showAddModal}
        selectProjects={selectedProjects}
        allData={myProjects}
        allDataTotal={myProjectTotal}
        data={allData}
        page={current}
        onCancel={() => {
          setShowAddModal(false);
          setSelectedProjects([]);
        }}
        onOk={() =>
          addProjectsToStudioHandler(selectedProjects.map(item => item.id))
        }
        onChangePage={val => setCurrent(val)}
        onChangeList={data => setSelectedProjects(data)}
      />
      <ConfirmModal
        title="是否删除作品集？"
        visible={state.toggleDelModal}
        iconType="error"
        contentNode={
          <>
            删除作品集后，
            <span style={{ color: 'red' }}>
              作品集的历史动态数据会被删除，且无法恢复。
            </span>
            删除作品集后，作品集的成员、作品集内所有的作品仍会存在。你确定要删除作品集吗？
          </>
        }
        bottomNode={
          <>
            <Button onClick={() => dispatch({ type: TOGGLE_DEL_MODAL })}>
              取消
            </Button>
            <Button danger onClick={delPortfolio}>
              确定
            </Button>
          </>
        }
      />
      <ConfirmModal
        title="是否确定移除此作品？"
        visible={delProjectShow}
        iconType="error"
        contentNode={
          <>
            移除此作品后，组织内的其他人将无法通过此作品集看到此作品。同时，当前作品集所有的管理员都会收到站内提醒。
            <br />
            移除此作品后，此作品仍存在，且处于公开状态，可以从其他渠道被人浏览。
          </>
        }
        bottomNode={
          <>
            <Button onClick={() => setDelProjectShow(false)}>
              取消
            </Button>
            <Button danger onClick={delProject}>
              确定
            </Button>
          </>
        }
      />
    </div>
  );
};

export default PortfolioItem;
