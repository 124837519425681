import React, { useState, useRef, useEffect, useCallback } from 'react';
import './style.scss';
import { Button, Input, Table, Switch, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import { useHistory } from 'react-router-dom';
import AddCourseModal from './addCourse';
import RemoveCourseModal from './removeCourse';
import {
  getCourseManageList,
  getOrgCourseList,
  closeCourse,
  openCourse,
  changeVisible,
  getOrgCourseBags,
} from '../../utils/api/course';
// import qs from 'query-string';
import { useSelector } from 'react-redux';

const CourseManage = ({ team_code, courseBags, initPageDetail, course }) => {
  const userInfo = useSelector(state => state.userInfo);
  const isShow = userInfo?.organization_data?.control_type === 2;
  // const history = useHistory();
  const childRef = useRef();
  const childRef2 = useRef();
  const searchRef = useRef();
  const [courseData, setCourseData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [orgCourseList, setOrgCourseList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [allData, setAllData] = useState([]);
  const [courseBagData, setCourseBagData] = useState([]);
  const [courseBagList, setCourseBagList] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [orderParams, setOrderParams] = useState({});
  const columns = [
    {
      title: () => (
        <span>
          课程名称{' '}
          {selectedRowKeys.length > 0 &&
            `（已选择 ${selectedRowKeys.length} 个）`}
        </span>
      ),
      dataIndex: 'title',
      key: 'title',
      width: 400,
      render: (value, record) => (
        <span
          className="name"
          onClick={() =>
            window.open('/course/' + record.id + '/' + (record.unit_id || 0))
          }
        >
          {value}
        </span>
      ),
      sorter: (a, b) => {
        return;
      }
      // sorter: (a, b) => a.title.localeCompare(b.title)
    },
    {}
  ];
  if (isShow) {
    columns.splice(1, 1, {
      title: '学员可见',
      dataIndex: 'is_visible',
      key: 'is_visible',
      render: (value, record) => (
        <Switch
          checkedChildren="开"
          unCheckedChildren="关"
          checked={value}
          onChange={checked => handlerChange(checked, record.id)}
        />
      ),
      // sorter: (a, b) => b.is_visible - a.is_visible
      sorter: (a, b) => {
        return;
      }
    });
  }

  const handlerChange = (is_visible, course_id) => {
    changeVisible(team_code, { is_visible, course_id }).then(() => {
      message.success('修改成功');
      fetchList();
    });
  };

  const fetchList = useCallback(
    async new_page => {
      setLoading(true);
      try {
        const res = await getCourseManageList(team_code, {
          page: new_page || current,
          page_size: pageSize,
          ...(JSON.stringify(orderParams) !== '{}' ? orderParams : null),
          ...(searchValue ? { search_str: searchValue } : null)
        })
        setCourseData(res.data.results);
        setTotal(res.data.count);

        const res1 = await getCourseManageList(team_code, {
          page: 1,
          page_size: 9999
        })
        setAllData(res1.data.results);
        setCourseBagData(courseBags)
        setLoading(false);
      } catch (error) {
        message.error('获取列表失败');
        setLoading(false);
      }
    },
    [current, pageSize, searchValue, team_code, orderParams, courseBags]
  );

  useEffect(() => {
    fetchList();
  }, [fetchList, current]);

  const onSelectChange = selectedRowKeys => {
    const newSelectedRows = selectedRowKeys.map(
      item2 => allData.filter(item => item.id === item2)[0]
    );
    setSelectedRows(newSelectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const addCourseVisible = async () => {

    setModalLoading(true);
    try {
      const params = {
        page: 1,
        page_size: 9999
      }
      let res = await getOrgCourseList(team_code, params)
      setOrgCourseList(res.data.results);

      let res1 = await getCourseManageList(team_code, params)
      setAllData(res1.data.results);
      
      let res2 = await getOrgCourseBags(team_code, params)
      setCourseBagList(res2.data.results)
      setModalLoading(false);
    } catch (error) {
      setModalLoading(false);
    }
    await childRef.current.addCourseHandler();

  };

  const handlerOpen = () => {
    openCourse(team_code, { course_id_list: selectedRowKeys })
      .then(() => {
        message.success('打开课程成功');
        setSelectedRowKeys([]);
        fetchList();
      })
      .catch(() => {
        message.success('打开课程失败，请稍后重试');
      });
  };

  const handlerClose = () => {
    closeCourse(team_code, { course_id_list: selectedRowKeys })
      .then(() => {
        message.success('关闭课程成功');
        setSelectedRowKeys([]);
        fetchList();
      })
      .catch(() => {
        message.success('关闭课程失败，请稍后重试');
      });
  };

  const btnArr = [
    {
      name: '打开',
      type: 'primary',
      danger: false,
      func: handlerOpen,
      show: isShow ? 'inline-block' : 'none'
    },
    {
      name: '关闭',
      type: 'primary',
      danger: false,
      func: handlerClose,
      show: isShow ? 'inline-block' : 'none'
    }
  ];

  const onSearchHandler = useCallback(value => {
    // history.push({
    //   path: history.location.pathname,
    //   search: qs.stringify({ type: 'courseManage', page: 1 })
    // });
    setCurrent(1);
    setSearchValue(value);
  }, []);

  const tableOnChange = (pagination, filters, sorter) => {
    if (sorter) {
      const key = sorter.columnKey;
      const value = sorter.order;
      const orderParams =
        key === 'title' ? { course_order: value } : { visible_order: value };
      setOrderParams(orderParams);
    }
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  return (
    <div id="courseManage">
      <div className="actionBar">
        <div className="btn">
          <Button type="primary" onClick={addCourseVisible} >
            管理本团队课程
          </Button>
        </div>
        <div className="search">
          <Input.Search
            ref={searchRef}
            placeholder="搜索课程名称"
            onSearch={onSearchHandler}
            style={{ width: 280, borderRadius: '4px' }}
          />
        </div>
      </div>

      <div className="table">
        <Table
          rowKey={record => record.id}
          columns={columns}
          dataSource={courseData}
          rowSelection={rowSelection}
          // loading={loading}
          loading={{
            spinning: loading,
            indicator: antIcon
          }}
          onChange={tableOnChange}
          pagination={{
            total,
            current: current,
            showQuickJumper: true,
            defaultPageSize: pageSize,
            onChange: val => setCurrent(val),
            onShowSizeChange: (cur, size) => {
              setCurrent(cur);
              setPageSize(size);
            },
            showTotal: (total, range) =>
              `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`
          }}
        />
        <div className="pagination">
          <div className="teamAction">
            {btnArr.map((item, index) => (
              <Button
                key={index}
                type={item.type}
                danger={item.danger}
                size="large"
                className="bottomBtn"
                disabled={selectedRowKeys.length === 0}
                onClick={item.func}
                style={{ display: item.show }}
              >
                {item.name}
              </Button>
            ))}
          </div>
        </div>
      </div>

      <AddCourseModal
        ref={childRef}
        list={orgCourseList}
        team_code={team_code}
        fetchList={fetchList}
        courseData={course}
        courseBagData={courseBagData}
        courseBagList={courseBagList}
        modalLoading={modalLoading}
        setSelectedRowKeys={setSelectedRowKeys}
        setSelectedRows={setSelectedRows}
        setCurrent={setCurrent}
        initPageDetail={initPageDetail}
      />
      <RemoveCourseModal
        ref={childRef2}
        team_code={team_code}
        selectedRowKeys={selectedRowKeys}
        selectedRows={selectedRows}
        setSelectedRowKeys={setSelectedRowKeys}
        setSelectedRows={setSelectedRows}
        setCurrent={setCurrent}
        fetchList={fetchList}
        current={current}
      />
    </div>
  );
};

export default CourseManage;
