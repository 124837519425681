import React, {
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
  useRef
} from 'react';
import './style.scss';
import { Button, Input, Table, Divider, Select, message } from 'antd';
// import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import ConfirmModal from '../../../components/confirmModal';
import {
  getProjects,
  setProjectVisiable,
  deleteProject
} from './../../../utils/request';
import { scratchURL, snapURL, toDetail } from './../../../utils';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import CreateBtn from '../../../components/createBtn';

const { Option } = Select;
let project = null;

const Project = () => {
  const location = useLocation();
  const history = useHistory();

  const userInfo = useSelector((state) => state.userInfo);
  const isNoRole = userInfo.user_type.includes('null');
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(8);

  const [showDeleteModal, setShowDeleteModal] = useState([false, '']);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(
    Number(qs.parse(history.location.search).page) || 1
  );

  const [loaded, setLoaded] = useState(false);
  const [orderStr, setOrderStr] = useState('');
  const [issearch, setIsSearch] = useState(false);
  const [filteType, setFilteType] = useState(
    qs.parse(location.search).team_visiable
  );

  // 平台切换
  const [platformValue, setPlatformValue] = useState('')
  // 选择平台
  const changePlatform = useCallback((value) => {
    setCurrent(1)
    history.push({
      path: history.location.pathname,
      search: qs.stringify({ ...qs.parse(location.search), ...{ page: 1 } })
    });
    setPlatformValue(value)
  }, [location, history])
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef();
  const platformMap = {
    snap: 'Snap 互联创作平台',
    scratch: '互联创作平台'
  }
  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
        let href
        // scratch平台
        if (record.platform === 'scratch') {
          href = `${scratchURL}/projects/${record.id}/`
        // snap平台
        } else if (record.platform === 'snap') {
          // href = `${snapURL}/snap.html?title=${record.title}&id=${record.id}`
          href = `${snapURL}/projects/${record.id}`
        }
        return <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
        <span className="name">
          <img src={record.image} width="48px" alt="cover" />
          {record.title}
        </span>
      </a>
      }
    },
    {
      title: '创作平台',
      dataIndex: 'platform',
      key: 'platform',
      render: (_, record) => (
        <div>
          {platformMap[record.platform]}
        </div>
      )
    },
    {
      title: '状态',
      dataIndex: 'lms_public',
      key: 'lms_public',
      align: 'left',
      sorter: true,
      render: (text, record) => (
        <div className="status">{record.lms_public ? '已公开' : '未公开'}</div>
      )
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'left',
      sorter: true,
      render: (value, record) =>
        dayjs(record.created_at).format('YYYY/MM/DD HH:mm')
    },
    {
      title: '最后保存时间',
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'left',
      sorter: true,
      render: (value, record) =>
        dayjs(record.updated_at).format('YYYY/MM/DD HH:mm')
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'left',
      width: 300,
      render: (value, record) => {
        let url = record.platform === 'scratch' ?
              scratchURL + `/projects/${record.id}/editor/`
              : record.platform === 'snap'
              ? snapURL + `/snap.html?title=${record.title}&id=${record.id}`
              : ''
        return <div className="action link_btnsCss">
          <>
            {record.lms_public ? (
              <Button
                size="small"
                style={{ paddingLeft: 0 }}
                type="link"
                onClick={() => {
                  project = record;
                  setShowDeleteModal([true, 'unpublic']);
                }}
              >
                撤回公开
              </Button>
            ) : (
              <Button
                size="small"
                style={{ paddingLeft: 0 }}
                type="link"
                onClick={() => {
                  project = record;
                  setProjectVisiableHandler(record.id, !record.lms_public);
                  // window.open(scratchURL + `/projects/${record.id}/`);
                  window.open(toDetail(record.platform, record.id))
                }}
              >
                设为公开
              </Button>
            )}
            <Divider type="vertical" />
          </>

          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button size="small" type="link">
              修改
            </Button>
          </a>
          <Divider type="vertical" />
          <Button
            size="small"
            className="delete"
            type="link"
            onClick={() => {
              project = record;
              setShowDeleteModal([true, 'delete']);
            }}
          >
            删除
          </Button>
        </div>
      }
    }
  ];
  const setProjectVisiableHandler = useCallback(
    (id, visible) => {
      setProjectVisiable(id, visible)
        .then(res => {
          let copiedData = [...data];
          copiedData.find(item => item.id === id).lms_public = visible;
          setData(copiedData);
          if (!visible) {
            message.success('撤回作品成功');
          }
          setShowDeleteModal([false, '']);
        })
        .catch(err => {
          if (!visible) {
            message.error('撤回作品失败，请稍后再试');
          }
          console.log(err);
        });
    },
    [data]
  );

  const getAllProject = useCallback(() => {
    return getProjects({
      page: current,
      page_size: pageSize,
      ...(searchValue ? { q: searchValue } : null),
      ...(filteType ? { lms_public: Boolean(filteType === 'visiable') } : null),
      ...(orderStr ? { order_str: orderStr } : null),
      ...(platformValue ? {platform: platformValue} : {})
    })
      .then(res => {
        setData(
          res.data.results.map((item, index) => ({ ...item, key: index }))
        );
        setTotal(res.data.count);
        if (!location.search && !searchValue && issearch) setIsSearch(false);
      })
      .catch(e => {
        console.error(e);
      });
  }, [current, pageSize, filteType, searchValue, issearch, location.search, orderStr, platformValue]);

  const onSearchHandler = useCallback(
    value => {
      history.push({
        path: history.location.pathname,
        search: qs.stringify({ ...qs.parse(location.search), ...{ page: 1 } })
      });
      setCurrent(1);
      setSearchValue(value);
      setIsSearch(true);
    },
    [history, location.search]
  );

  const ProjecModaltHandler = useCallback(() => {
    if (showDeleteModal[1] === 'delete') {
      deleteProject(project.id)
        .then(res => {
          let copiedData = [...data];
          copiedData.splice(
            copiedData.findIndex(item => item.id === project.id),
            1
          );
          setData(copiedData);
          if (!copiedData.length && current > 1) {
            setCurrent(prev => prev - 1);
          }
          getAllProject();
          message.success('删除作品成功');
          setShowDeleteModal([false, '']);
        })
        .catch(res => {
          message.error('删除作品失败，请稍后再试');
        });
    } else {
      setProjectVisiableHandler(project.id, !project.lms_public);
    }
  }, [
    showDeleteModal,
    data,
    current,
    getAllProject,
    setProjectVisiableHandler
  ]);

  const filteTypeHandle = useCallback(
    num => {
      // num  0: all  1: 公开  2：未公开
      setCurrent(1);
      setSearchValue('');
      // console.log("0000======", searchRef.current);
      if (searchRef.current.state) {
        searchRef.current.state.value = '';
      }

      setFilteType(num ? (num === 1 ? 'visiable' : 'invisiable') : '');
      if (num) {
        setIsSearch(true);
      } else {
        if (current === 1 && !history.location.search) {
          getAllProject();
        }
      }

      history.push({
        path: history.location.pathname,
        search: num
          ? qs.stringify({
              ...qs.parse(history.location.search),
              page: 1,
              team_visiable: num === 1 ? 'visiable' : 'invisiable'
            })
          : ''
      });
    },
    [history, current, getAllProject]
  );
  const onChange = (pagination, filters, sorter, extra) => {
    if(sorter.field){
      if(sorter.field === 'name'){
        sorter.field = 'user__name'
      }
      if(sorter.order && sorter.order === 'descend'){
        setOrderStr(`-${sorter.field}`)
      }else{
        setOrderStr(`${sorter.field}`)
      }
    }
};
  useEffect(() => {
    getAllProject().then(() => {
      setLoaded(true);
      if (qs.parse(location.search).team_visiable) setIsSearch(true);
    });
  }, [current, getAllProject, location.search]);

  useLayoutEffect(() => {
    document.querySelector(
      '.ant-table-pagination .ant-select-selection-item'
    ) &&
      (document.querySelector(
        '.ant-table-pagination .ant-select-selection-item'
      ).innerText = `${pageSize} 条/页`);
  }, [data.length, loaded]); // eslint-disable-line

  return (
    <div id="manageProject">
      <div className="container">
        {issearch || (loaded && data.length) ? (
          <section className="content">
            <div className="actionBar">
              <div className="btn">
                <CreateBtn className="create-btn">新建作品</CreateBtn>
                <Select
                  defaultValue={
                    !filteType ? 0 : filteType === 'visiable' ? 1 : 2
                  }
                  style={{ width: 120 }}
                  onChange={value => {
                    filteTypeHandle(value);
                  }}
                >
                  <Option value={0}>全部</Option>
                  <Option value={1}>已公开</Option>

                  <Option value={2}>未公开</Option>
                </Select>
                <Select
                  className='platform'
                  defaultValue={
                    platformValue
                  }
                  style={{ width: 170 }}
                  onChange={value => {
                    changePlatform(value);
                  }}
                >
                  <Option value="">全部平台</Option>
                  <Option value="scratch">互联创作平台</Option>
                  <Option value="snap">Snap 互联创作平台</Option>
                </Select>
              </div>
              <div className="search">
                <Input.Search
                  ref={searchRef}
                  placeholder="搜索作品名称"
                  onSearch={onSearchHandler}
                  style={{ width: 280, borderRadius: '4px' }}
                />
              </div>
            </div>
            <div className="table">
              <Table
                dataSource={data}
                columns={columns}
                pagination={{
                  total,
                  current,
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  onChange: val => {
                    setCurrent(val);
                    history.push({
                      path: history.location.pathname,
                      search: qs.stringify({
                        ...qs.parse(history.location.search),
                        page: val
                      })
                    });
                  },
                  onShowSizeChange: (cur, size) => {
                    history.push({
                      path: history.location.pathname,
                      search: qs.stringify({
                        ...qs.parse(history.location.search),
                        page: 1
                      })
                    });
                    setCurrent(1);
                    setPageSize(size);
                  },
                  showTotal: (total, range) =>
                    `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`
                }}
                onChange={onChange}
              />
            </div>
          </section>
        ) : loaded ? (
          <div className="nodata">
            <img src="/image/empty-page.png" alt="empty-page" />
            <div className="tips">暂无作品，快去创作属于你的作品吧</div>
            <div>
                {!isNoRole ? 
                // <a
                //   href={`${scratchURL}/projects/editor/`}
                //   target="_blank"
                //   rel="noopener noreferrer"
                // >
                //   <Button type="primary">前去创作</Button>
                // </a> 
                <CreateBtn className="create-btn">新建</CreateBtn>
                : null}
            </div>
          </div>
        ) : null}
      </div>
      <ConfirmModal
        title={
          showDeleteModal[1] === 'delete'
            ? '是否确定删除作品？'
            : '是否确定撤回公开？'
        }
        iconType={showDeleteModal[1] === 'delete' ? 'error' : 'warning'}
        visible={showDeleteModal[0]}
        contentNode={
          showDeleteModal[1] === 'delete'
            ? '删除该作品后，该作品会从个人作品列表和关联的作品集中消失，操作不可撤销，请谨慎。'
            : '撤回后作品会从所有作品集中消失。此作品已经生成的所有对外分享链接（包括你的同学、老师分享此作品的链接）均会被删除。'
        }
        bottomNode={
          <>
            <Button onClick={() => setShowDeleteModal([false, ''])}>
              取消
            </Button>
            <Button
              danger
              className={showDeleteModal[1] === 'delete' ? '' : 'actionOK'}
              onClick={ProjecModaltHandler}
            >
              确定
            </Button>
          </>
        }
      />
    </div>
  );
};

export default Project;
