import React, {
  useRef,
  useCallback,
  forwardRef,
  useState,
  useEffect
} from 'react';
import {
  Button,
  Input,
  Table,
  message,
  Tooltip,
  Divider,
  Menu,
  Dropdown,
  Select
} from 'antd';
import { PlusOutlined, EyeOutlined, DownOutlined } from '@ant-design/icons';
import ConfirmModal from './../../../components/confirmModal';
import ChangeSecretkey from './../../../components/changeSecretkey';
import './index.scss';
import Basic from './basic';
import {
  getStudentList,
  deleteStudent,
  patchDetail
} from './../../../utils/api/manageCenter';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { secretImageUrl } from './../../../utils';
import { getUser } from './../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import BatchImport from './batchImport';
import ModifyPassword from './modifyPassword';

const Student = () => {
  const history = useHistory();
  const orgNum = useSelector(state => state.orgNum);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const changeSecretkeyRef = useRef();
  const basicRef = useRef();
  const [modalType, setModalType] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [orderStr, setOrderStr] = useState('');
  const [role, setRole] = useState('');
  const [current, setCurrent] = useState(
    Number(qs.parse(history.location.search).page) || 1
  );
  const [pageSize, setPageSize] = useState(15);
  const [searchValue, setSearchValue] = useState('');
  const [showSecretkey, setShowSecretkey] = useState(false);
  const searchRef = useRef();
  const onChangeData = useRef(null);
  const [item, setItem] = useState(null);
  const _dispatch = useDispatch();
  const batchRef = useRef();
  const modifyRef = useRef();
  const service_validity = useSelector(state => state.service_validity);
  const isValidityAbled = service_validity === 3 || service_validity === 4;
  const columns = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (value, record) => {
        return (
          <span
            className='num_studios'
            onClick={() => {
              history.push({
                pathname: `/account/home/${record.username}`
              })
            }
            }
          >{value}</span>
        )
      },
      
    },
    {
      title: '用户名',
      key: 'username',
      align: 'left',
      // width: 195,
      dataIndex: 'username'
    },
    {
      title: () => (
        <span>
          <span style={{ userSelect: 'none' }}>密钥</span>
          <span
            className="showSecretkey-btn"
            onClick={() => {
              setShowSecretkey(!showSecretkey);
            }}
          >
            {!showSecretkey ? (
              <span className="iconHide">
                <svg
                  t="1585283272898"
                  className="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2253"
                  width="16"
                  height="16"
                >
                  <path
                    d="M949.81249971 283.71875029c-11.00625029-2.45625029-18.86249971 1.42499971-25.2 8.71875-3.41250029 3.9375-10.4625 16.93125-11.26874971 18.35624971-17.55 31.46249971-83.11875029 102.30000029-177.6375 154.2375-3.825 0.9-7.51875029 2.43749971-10.53749971 5.36249971-0.225 0.225-0.29999971 0.54375029-0.52500058 0.76875029-61.12500029 31.79999971-133.55624971 55.10625029-212.69999971 55.10625029-204.37499971 0-359.60625-153.09375029-398.43749971-211.40625058-1.96875-2.96250029-11.3625-19.42499971-15.8625-24.1875-5.86874971-6.22500029-13.5-10.10625029-23.83125029-6.525-7.78124971 2.7-11.75625 9.675-11.8125 16.85625029-0.01874971 2.96250029 2.00625029 8.86875029 2.04374971 8.94375 0.86249971 2.54999971 2.04374971 5.36249971 3.56250058 8.41875029 17.775 36.13124971 79.83749971 106.10624971 168.03749971 163.35l-121.93125029 124.875a22.48125029 22.48125029 0 1 0 32.175 31.42499942l125.68125058-128.7c1.2375-1.27500029 1.89375029-2.83124971 2.77499971-4.29374971a559.125 559.125 0 0 0 109.51875 46.55625029l-50.7375 160.34999942a22.48125029 22.48125029 0 1 0 42.88124971 13.55625l51.525-162.86249971c27.35624971 5.47499971 55.575 8.60625 84.45000058 8.60625 15.73125029 0 31.20000029-1.08749971 46.49999942-2.79375029l50.75625058 157.01250058a22.5 22.5 0 0 0 42.78749942-13.8375l-48.67499971-150.58125c47.09999971-10.10625029 91.44375029-27.13124971 131.88750029-48.00000058l114.73124942 118.25625058a22.5 22.5 0 0 0 32.2875-31.31250029l-106.34999942-109.64999971c95.41874971-58.59375029 162.54375029-133.94999971 180.7875-172.18125 2.04374971-4.29374971 5.36249971-11.11875029 5.34375-18.86250058-0.03750029-6.59999971-4.34999971-13.79999971-12.22500058-15.56249942z"
                    p-id="2254"
                  ></path>
                </svg>
              </span>
            ) : (
              <EyeOutlined />
            )}
          </span>
        </span>
      ),
      width: 140,
      dataIndex: 'secretkey',
      align: 'left',
      key: 'secretkey',
      render: (value, record) => {
        if (!showSecretkey) {
          return <span>******</span>;
        }
        return record.secret_type === 'word' ? (
          record.secret
        ) : (
          <img
            className="secretKeyImg"
            src={secretImageUrl + record.secret_image}
            alt={record.secret_image}
          />
        );
      }
    },
    {
      title: '所在团队',
      key: 'student_team',
      align: 'left',
      // width: 230,
      render: (value, record) => (
        <>
          {record.student_team.length === 0 ? (
            '-'
          ) : record.student_team.length > 1 ? (
            <p>
              共{' '}
              <Tooltip
                placement="top"
                title={record.student_team
                  .map(item => {
                    return item.name;
                  })
                  .join(' | ')}
                overlayClassName="teamTooltip"
              >
                <span className="hoverText">{record.student_team.length}</span>
              </Tooltip>{' '}
              个
            </p>
          ) : (
            record.student_team[0].name
          )}
        </>
      ),
      dataIndex: 'student_team'
    },
    {
      title: '禁用情况',
      width: 140,
      dataIndex: 'is_freeze',
      key: 'is_freeze',
      render:(value, record, index)=>(
        <div className='is_freeze'>
          <span className={['block',record.is_freeze?'freeze':record.allow_comments?'allow':'no_allow'].join(' ')}></span>
          {record.is_freeze?'账户冻结':record.allow_comments?'正常使用':'禁止评论'}
        </div>
      )
    },
    {
      title: '创建日期',
      key: 'created_at',
      width: 120,
      align: 'left',
      sorter:true,
      render: time => new Date(time).toLocaleDateString(),
      dataIndex: 'created_at'
    },
    {
      title: '操作',
      key: 'opreate',
      align: 'left',
      width: 275,
      render: (value, record, index) => (
        <div
          className={isValidityAbled ? 'operate validity_dropdown' : 'operate'}
        >
          {record.is_set_password ? (
            <Button
              size="small"
              type="link"
              disabled={isValidityAbled}
              onClick={() => {
                setItem(record);
                setModalType('modify');
                basicRef.current.handlerBasic();
              }}
            >
              修改
            </Button>
          ) : (
            <Dropdown
              disabled={isValidityAbled}
              overlay={() => (
                <Menu
                  onClick={({ key }) => {
                    setItem(record);
                    if (key === '0') {
                      setModalType('modify');
                      basicRef.current.handlerBasic();
                    } else {
                      modifyRef.current.modifyPassword();
                    }
                  }}
                >
                  <Menu.Item key="0">修改信息</Menu.Item>
                  <Menu.Item key="1">修改密码</Menu.Item>
                </Menu>
              )}
              overlayClassName="modify"
            >
              <span>
                修改 <DownOutlined />
              </span>
            </Dropdown>
          )}

          <Divider type="vertical" />
          <Button
            size="small"
            className="delete"
            type="link"
            disabled={isValidityAbled}
            onClick={() => {
              setItem(record);
              changeSecretkeyRef.current.changeSecretkeyHandler();
            }}
          >
            更换密钥
          </Button>
          <Divider type="vertical" />
          <Dropdown overlay={
            <Menu>
              <Menu.Item>
                <Button
                  size="small"
                  className="freeze_btn"
                  type='link'
                  disabled={isValidityAbled}
                  onClick={() => {
                    if (record.is_freeze) {
                      patchStatus(record.id, { is_freeze: false });
                    } else {
                      setDeleteModal([true, 'freeze', record.id]);
                    }
                  }}
                >
                  {record.is_freeze ? '解冻账号' : '冻结账号'}
                </Button>
              </Menu.Item>
              {
                !record.is_freeze && <Menu.Item>
                  <Button
                    size="small"
                    className="freeze_btn"
                    type='link'
                    disabled={record.is_freeze}
                    onClick={() => {
                      patchAllowComment(record.id, { allow_comments: !record.allow_comments });
                    }}
                  >
                    {!record.allow_comments ? '允许' : '禁止'}评论
                  </Button>
                </Menu.Item>
              }
            </Menu>
          }>
            <span onClick={(e) => e.preventDefault()} style={{cursor:'pointer',color:'#0084ff'}}>
              禁用功能 <DownOutlined />
            </span>
          </Dropdown>
          <Divider type="vertical" />
          <Button
            size="small"
            className="delete"
            disabled={isValidityAbled}
            type="link"
            onClick={() => {
              setDeleteModal([true, 'delete', record, index]);
            }}
          >
            删除
          </Button>
        </div>
      ),
      dataIndex: 'opreate'
    }
  ];
  const onSearchHandler = useCallback(
    value => {
      history.push({
        path: history.location.pathname,
        search: qs.stringify({ page: 1 })
      });
      setCurrent(1);
      setSearchValue(value);
    },
    [history]
  );

  const fetchList = useCallback(() => {
    setLoading(true)
    getStudentList({
      page: current,
      page_size: pageSize,
      ...(searchValue ? { search_str: searchValue } : null),
      ...(orderStr ? { order_str: orderStr } : null),
      ...(role ? { role } : null),
    }).then(res => {
      setData(
        res.data.results.map((item, index) => ({
          ...item,
          cancelDelete: false
        }))
      );
      setTotal(res.data.count);
      setLoading(false)
    });
  }, [current, pageSize, searchValue, orderStr, role]);

  // 解冻、冻结
  const patchStatus = useCallback(
    (id, config) => {
      patchStudentInfo(id, config)
        .then(() => {
          setDeleteModal(false);
          message.success(
            config.is_freeze ? '冻结学员账号成功' : '解冻学员账号成功'
          );
          fetchList();
        })
        .catch(() => {
          setDeleteModal(false);
          message.warning('操作异常，请重试');
        });
    },
    [fetchList]
  );

  const patchAllowComment = useCallback(
    (id, config) => {
      patchStudentInfo(id, config)
        .then(() => {
          message.success(
            config.allow_comments ? '赋予学员评论权限成功' : '禁用学员评论权限成功'
          );
          fetchList();
        })
        .catch(() => {
          message.warn( '操作异常，请重试');
        });
    },
    [fetchList]
  );
  const deleteStudentHandler = useCallback(
    (record, index) => {
      clearTimeout(null);
      const newData = onChangeData.current
        .slice(0, index)
        .concat(onChangeData.current.slice(index + 1));
      setData(newData);
      record.cancelDelete = false;
      deleteStudent(record.id).then(() => {
        fetchList();
        _dispatch(getUser());
        message.success('成功删除学员账户')
      });
      setDeleteModal(false);
    },
    [fetchList, _dispatch]
  );

  const modalRender = useCallback(() => {
    if (!showDeleteModal) {
      return;
    }
    const obj = {
      title:
        showDeleteModal[1] === 'delete'
          ? '是否确定删除学员？'
          : '是否确定冻结学员的账号？',
      content:
        showDeleteModal[1] === 'delete'
          ? '删除后，该学员将无法登录教学云，学员和他的作品将从团队中消失，请谨慎操作。'
          : '冻结后，该学员将无法登录教学云。需要解冻才可以恢复登录。',
      handleCancel: () => setDeleteModal(false),
      handleConfirm: () =>
        showDeleteModal[1] === 'delete'
          ? deleteStudentHandler(showDeleteModal[2], showDeleteModal[3])
          : patchStatus(showDeleteModal[2], { is_freeze: true })
    };

    return (
      <ConfirmModal
        title={obj.title}
        visible={showDeleteModal}
        iconType="error"
        onCancel={obj.handleCancel}
        contentNode={<>{obj.content}</>}
        bottomNode={
          <>
            <Button onClick={obj.handleCancel}>取消</Button>
            <Button danger onClick={obj.handleConfirm}>
              确定
            </Button>
          </>
        }
      />
    );
  }, [showDeleteModal, deleteStudentHandler, patchStatus]);


  useEffect(() => {
    onChangeData.current = data;
  });

  const patchStudentInfo = (id, data) => {
    return patchDetail(id, data);
  };

  const isDisabled = orgNum?.remaining_students <= 0;
  const onChange = (pagination, filters, sorter, extra) => {
    if(sorter.field){
      if(sorter.order && sorter.order === 'descend'){
        setOrderStr(`-${sorter.field}`)
      }else{
        setOrderStr(`${sorter.field}`)
      }
    }
};
  return (
    <div id="employee">
      <div className="container">
        <div className="con_top">
          <div className="btn2">
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                setModalType('new');
                basicRef.current.handlerBasic();
              }}
              disabled={isValidityAbled || isDisabled}
            >
              新建学员
            </Button>
            <Button
              onClick={() => {
                batchRef.current.batchImportStudent();
              }}
              disabled={isValidityAbled || isDisabled}
            >
              批量导入
            </Button>
            <Select
              placeholder="全部学员"
              style={{ width: 100,marginLeft:'10px' }}
              onChange={(val)=>{
                console.log('val',val);
                setRole(val)
              }}
              options={[
                {
                  label: '全部',
                  value: 'all',
                },
                {
                  label: '正常使用',
                  value: 'normal',
                },
                {
                  label: '冻结账户',
                  value: 'freeze',
                },
                {
                  label: '禁止评论',
                  value: 'no_comment',
                },
              ]}
            >
              
            </Select>
            {isValidityAbled && (
              <Tooltip
                placement="right"
                title={
                  service_validity === 3
                    ? '英荔教学云服务还没开始，请耐心等待'
                    : '英荔教学云服务已到期，如需继续使用请联系英荔销售或致电 400-931-8118'
                }
                overlayClassName="teamFloatingTooltip"
              >
                <img
                  src="/image/icon_question.png"
                  alt="question"
                  className="tip"
                />
              </Tooltip>
            )}
            {!isValidityAbled && isDisabled && (
              <Tooltip
                placement="right"
                title="当前新建学员的数量已达上限，如需增加学员数量，请致电 400-931-8118"
                overlayClassName="teamFloatingTooltip"
              >
                <img
                  src="/image/icon_question.png"
                  alt="question"
                  className="tip"
                />
              </Tooltip>
            )}
          </div>
          <div className="search">
            <Input.Search
              ref={searchRef}
              disabled={loading}
              placeholder="搜索姓名/用户名/团队名称"
              onSearch={onSearchHandler}
              style={{ width: 280, borderRadius: '4px' }}
            />
          </div>
        </div>

        <div className="table stu_table">
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            rowKey={record => record.id}
            pagination={{
              current: current,
              total,
              showQuickJumper: true,
              defaultPageSize: pageSize,
              onChange: val => {
                history.push({
                  path: history.location.pathname,
                  search: qs.stringify({
                    ...qs.parse(history.location.search),
                    page: val
                  })
                });
                setCurrent(val);
              },
              onShowSizeChange: (cur, size) => {
                history.push({
                  path: history.location.pathname,
                  search: qs.stringify({
                    ...qs.parse(history.location.search),
                    page: 1
                  })
                });
                setCurrent(1);
                setPageSize(size);
              },
              showTotal: (total, range) =>
                `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`
            }}
            onChange={onChange}
          />
        </div>

        {modalRender(showDeleteModal)}
        <ChangeSecretkey
          ref={changeSecretkeyRef}
          record={item}
          getAllMember={fetchList}
        />
        <Basic
          ref={basicRef}
          modalType={modalType}
          fetchList={fetchList}
          patchItem={item}
        />
        <BatchImport ref={batchRef} fetchList={fetchList} />
        <ModifyPassword ref={modifyRef} patchId={item?.id} />
      </div>
    </div>
  );
};

export default forwardRef(Student);
