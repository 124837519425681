import React from 'react'
import './style.scss'
import AccountHome from './home'
import AccountUser from './user'
import AccountFollow from './follow'
// import DataCenter from './dataCenter'
import { Route, Switch, Redirect } from 'react-router-dom'

const Account = ({ match, ...props }) => {
    return (
        <div id="account">
            <Switch>
                <Route path={`${match.path}`} exact>
                    <Redirect to={`${match.path}/home`} />
                </Route>
                <Route path={`${match.path}/home/:username`} component={AccountHome}/>
                <Route path={`${match.path}/home`} component={AccountHome} />
                <Route path={`${match.path}/user`} component={AccountUser} />
                <Route path={`${match.path}/follow`} component={AccountFollow} />
                <Route path={`${match.path}/*`}>
                    <Redirect to="/404" />
                </Route>
                {/* <Route path={`${match.path}/datacenter/:username`} component={DataCenter}/>
                <Route path={`${match.path}/datacenter`} component={DataCenter}/> */}
            </Switch>
        </div>
    )
}

export default Account