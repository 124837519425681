import React, { useEffect } from 'react';
import { Layout, message } from 'antd';
import Header from '../components/header';
import Footer from '../components/footer';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getUnRead, /*logout,*/ setToken } from '../store/actions';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Note from '../page/note';
const { Content } = Layout;
const AppLayout = props => {
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.userInfo);
  const token = useSelector(state => state.token);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    message.destroy();
  }, [location.pathname]);

  message.config({ maxCount: 1 });

  useEffect(() => {
    let timer;
    if (token) {
      dispatch(getUser(token));
    } else {
      timer = setInterval(() => {
        if (Cookies.get('token')) {
          dispatch(setToken(Cookies.get('token')));
        }
      }, 500);
    }
    return () => {
      timer && clearInterval(timer);
    };
  }, [token, dispatch]);

  useEffect(() => {
    let timer;
    if (token) {
      dispatch(getUnRead());
      timer = setInterval(() => {
        if (!token) return;

        if (!window.navigator.onLine) {
          console.log('断网了');
          return;
        }
        dispatch(getUnRead());
      }, 30 * 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [dispatch, token]);
  useEffect(() => {
    if (window.location.hostname.indexOf('longan.link') !== -1) {
      history.push({
        pathname: `/note`
      });
    }
  }, [history]);

  const _isShowFooter =
    location.pathname.includes('coursePreview') ||
    location.pathname.includes('projectPreview') ||
    location.pathname.includes('successPage')||
    location.pathname.includes('playerPage');

    const _isShowHeader = 
    location.pathname.includes('/successPage')||
    location.pathname.includes('playerPage');


  if (window.location.href.indexOf('note') !== -1) {
    return <Note></Note>;
  } else {
    return (
      <Layout>
        {!_isShowHeader && <Header></Header>}
        <Content>
          {token ? (userInfo ? props.children : null) : props.children}
        </Content>
        {!_isShowFooter && <Footer></Footer>}
      </Layout>
    );
  }
};

export default AppLayout;
