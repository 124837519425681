import React, { useState, useEffect, useRef, useCallback } from 'react'
import moment from 'moment'
import * as echarts from 'echarts'
import { Select, Spin } from 'antd'
import { useSelector } from 'react-redux'
import DownLoadBar from '../../../../components/downloadBar'
import SubHeader from '../../../../components/subHeader'
import { getProjectsNum, getProjectsTopRank } from '../../../../utils/request'
import { scratchURL } from '../../../../utils'

const { Option } = Select
const ProjectsViews = ({ orgStartDate, orgOpenDate }) => {
  const userInfo = useSelector((state) => state.userInfo)
  const start_date = moment(userInfo?.organization_data?.start_date).format(
    'YYYYMMDD',
  )
  const last_date = moment().subtract(1, 'days').format('YYYYMMDD')
  const last7days_date = moment().subtract(7, 'days').format('YYYYMMDD')
  const last30days_date = moment().subtract(30, 'days').format('YYYYMMDD')

  const [numsData, setNumsData] = useState({
    date_list: [],
    staff: [],
    student: [],
    org: [],
  })
  const [rankData, setRankData] = useState({
    all: [],
    last7days: [],
    last30days: [],
  })
  const [sort, setSort] = useState({
    all: 'influence',
    last7days: 'influence',
    last30days: 'influence',
  })

  const [loading, setLoading] = useState({
    nums: false,
    all: false,
    last7days: false,
    last30days: false,
  })
  const numsChart = useRef(null)
  const allRankChart = useRef(null)
  const last7daysRankChart = useRef(null)
  const last30daysRankChart = useRef(null)

  useEffect(() => {
    setLoading((prev) => ({ ...prev, nums: true }))
    Promise.all([
      getProjectsNum(userInfo?.organization_data.id, { user_type: 'staff' }),
      getProjectsNum(userInfo?.organization_data.id, { user_type: 'student' }),
    ]).then((res) => {
      const [staff, student] = res
      setNumsData({
        date_list: staff.data.date_list,
        staff: staff.data.result,
        student: student.data.result,
        org: staff.data.result.map(
          (item, idx) => item + student.data.result[idx],
        ),
      })
      setLoading((prev) => ({ ...prev, nums: false }))
    })
  }, [userInfo.organization_data.id])

  useEffect(() => {
    setLoading((prev) => ({ ...prev, all: true }))
    getProjectsTopRank(userInfo?.organization_data.id, {
      start_date: start_date,
      end_date: last_date,
    }).then((res) => {
      setRankData((prev) => ({ ...prev, all: res.data }))
      setLoading((prev) => ({ ...prev, all: false }))
    })
  }, [userInfo.organization_data.id, start_date, last_date])

  useEffect(() => {
    setLoading((prev) => ({ ...prev, last7days: true }))
    getProjectsTopRank(userInfo?.organization_data.id, {
      start_date: last7days_date,
      end_date: last_date,
    }).then((res) => {
      setRankData((prev) => ({ ...prev, last7days: res.data }))
      setLoading((prev) => ({ ...prev, last7days: false }))
    })
  }, [userInfo.organization_data.id, last7days_date, last_date])

  useEffect(() => {
    setLoading((prev) => ({ ...prev, last30days: true }))
    getProjectsTopRank(userInfo?.organization_data.id, {
      start_date: last30days_date,
      end_date: last_date,
    }).then((res) => {
      setRankData((prev) => ({ ...prev, last30days: res.data }))
      setLoading((prev) => ({ ...prev, last30days: false }))
    })
  }, [userInfo.organization_data.id, last30days_date, last_date])

  const setNumsChart = useCallback(() => {
    console.log(numsData)
    const option = {
      title: {
        // text: 'World Population'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter:
          '每日提交作品数<br/><span style="display:inline-block; width: 10px; height: 10px; background-color: #0084ff; border-radius: 50%; margin: 0 6px 0 0"></span>{a0}: {c0}<br /><span style="display:inline-block; width: 10px; height: 10px; background-color:#0bd295; border-radius: 50%; margin: 0 6px 0 0"></span>{a1}: {c1}<br /><span style="display:inline-block; width: 10px; height: 10px; background-color:#00b8ff; border-radius: 50%; margin: 0 6px 0 0"></span>{a2}: {c2}',
      },
      toolbox: {
        right: 44,
        top: -5,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          // saveAsImage: {}
        },
      },
      legend: {
        // show: false
        itemWidth: 12,
        itemHeight: 12,
      },
      color: ['#0084ff', '#0bd295', '#00b8ff'],
      grid: {
        // left: '3%',
        // right: '4%',
        // width: '800',
        // bottom: '15%',
        containLabel: true,
      },
      yAxis: {
        type: 'value',
        // show: false,
        // boundaryGap: [0, 0.01],
        // maxInterval: Math.ceil(3051 / 5),
        max: function (value) {
          return value.max <= 5 ? 5 : null
        },
      },
      xAxis: {
        type: 'category',
        data: numsData.date_list.map((date) =>
          moment(date).format('YYYY/MM/DD'),
        ),
        axisLabel: {
          interval: 0,
          textStyle: {
            color: function (value, index) {
              return moment(value).format('YYYYMMDD') >=
                moment(orgOpenDate).format('YYYYMMDD')
                ? '#666666'
                : '#cccccc'
            },
          },
          rotate: 60,
        },
      },
      series: [
        {
          name: '校区',
          type: 'bar',
          data: numsData.org,
        },
        {
          name: '学员',
          type: 'bar',
          stack: 'org',
          data: numsData.student,
        },
        {
          name: '员工',
          type: 'bar',
          stack: 'org',
          data: numsData.staff,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: 0,
          endValue: 30,
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          xAxisIndex: [0, 1],
        },
      ],
    }
    if (!numsChart.current) {
      numsChart.current = echarts.init(document.getElementById('numsChart'))
    }
    numsChart.current.setOption(option, true)
    numsChart.current.resize()
  }, [numsData, orgOpenDate])

  useEffect(() => {
    if (numsData.date_list.length) {
      setNumsChart()
    }
  }, [setNumsChart, numsData])

  const setAllRankChart = useCallback(() => {
    const res = rankData.all.sort((a, b) => b[sort.all] - a[sort.all])
    const views = res.map((item) => item.views)
    const influence = res.map((item) => item.influence)
    const remixs = res.map((item) => item.remixs)
    const plugins = res.map((item) => item.plugins)
    const blocks = res.map((item) => item.blocks)

    const option = {
      title: {
        // text: 'World Population'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      toolbox: {
        right: 44,
        top: -5,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          // saveAsImage: {}
        },
      },
      legend: {
        // show: false
        itemWidth: 12,
        itemHeight: 12,
      },
      color: ['#f55d00', '#fec015', '#0bd295', '#0084ff', '#00b8ff'],
      grid: {
        // left: '3%',
        // right: '4%',
        // width: '800',
        // bottom: '15%',
        containLabel: true,
      },
      yAxis: {
        type: 'value',
        // show: false,
        // boundaryGap: [0, 0.01],
        // maxInterval: Math.ceil(3051 / 5),
        max: function (value) {
          return value.max <= 5 ? 5 : null
        },
      },
      xAxis: {
        type: 'category',
        data: res.map((item) => item.title),
        axisLabel: {
          interval: 0,
          rotate: 60,
        },
      },
      series: [
        {
          name: '受欢迎度',
          type: 'bar',
          data: influence,
        },
        {
          name: '影响力',
          type: 'bar',
          data: views,
        },
        {
          name: '被改编数',
          type: 'bar',
          data: remixs,
        },
        {
          name: '扩展使用数',
          type: 'bar',
          data: plugins,
        },
        {
          name: '包含积木数',
          type: 'bar',
          data: blocks,
        },
      ],

      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: 0,
          endValue: 39,
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          xAxisIndex: [0, 1],
        },
      ],
    }
    if (!allRankChart.current) {
      allRankChart.current = echarts.init(
        document.getElementById('allRankChart'),
      )
    }
    if (allRankChart.current && rankData.all.length) {
      allRankChart.current.on('click', (params) => {
        window.open(
          `${scratchURL}/projects/${rankData.all[params.dataIndex].id}/`,
        )
      })
    }
    allRankChart.current.setOption(option, true)
    allRankChart.current.resize()
  }, [rankData.all, sort.all])

  useEffect(() => {
    if (rankData.all.length) {
      setAllRankChart()
    }
  }, [setAllRankChart, rankData.all, sort.all])

  const setLast7daysRankChart = useCallback(() => {
    const res = rankData.last7days.sort(
      (a, b) => b[sort.last7days] - a[sort.last7days],
    )
    const views = res.map((item) => item.views)
    const influence = res.map((item) => item.influence)
    const remixs = res.map((item) => item.remixs)
    const plugins = res.map((item) => item.plugins)
    const blocks = res.map((item) => item.blocks)

    const option = {
      title: {
        // text: 'World Population'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      toolbox: {
        right: 44,
        top: -5,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          // saveAsImage: {}
        },
      },
      legend: {
        // show: false
        itemWidth: 12,
        itemHeight: 12,
      },
      color: ['#f55d00', '#fec015', '#0bd295', '#0084ff', '#00b8ff'],
      grid: {
        // left: '3%',
        // right: '4%',
        // width: '800',
        // bottom: '15%',
        containLabel: true,
      },
      yAxis: {
        type: 'value',
        // show: false,
        // boundaryGap: [0, 0.01],
        // maxInterval: Math.ceil(3051 / 5),
        max: function (value) {
          return value.max <= 5 ? 5 : null
        },
      },
      xAxis: {
        type: 'category',
        data: res.map((item) => item.title),
        axisLabel: {
          interval: 0,
          rotate: 20,
        },
      },
      series: [
        {
          name: '受欢迎度',
          type: 'bar',
          data: influence,
        },
        {
          name: '影响力',
          type: 'bar',
          data: views,
        },
        {
          name: '被改编数',
          type: 'bar',
          data: remixs,
        },
        {
          name: '扩展使用数',
          type: 'bar',
          data: plugins,
        },
        {
          name: '包含积木数',
          type: 'bar',
          data: blocks,
        },
      ],

      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: 0,
          endValue: 9,
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          xAxisIndex: [0, 1],
        },
      ],
    }
    if (!last7daysRankChart.current) {
      last7daysRankChart.current = echarts.init(
        document.getElementById('last7daysRankChart'),
      )
    }
    if (last7daysRankChart.current && rankData.last7days.length) {
      last7daysRankChart.current.on('click', (params) => {
        window.open(
          `${scratchURL}/projects/${rankData.last7days[params.dataIndex].id}/`,
        )
      })
    }
    last7daysRankChart.current.setOption(option, true)
    last7daysRankChart.current.resize()
  }, [rankData.last7days, sort.last7days])

  useEffect(() => {
    if (
      moment().subtract(7, 'days').format('YYYYMMDD') >=
        moment(orgStartDate).format('YYYYMMDD') &&
      rankData.last7days.length
    ) {
      setLast7daysRankChart()
    }
  }, [setLast7daysRankChart, rankData.last7days, sort.last7days, orgStartDate])

  const setLast30daysRankChart = useCallback(() => {
    const res = rankData.last30days.sort(
      (a, b) => b[sort.last30days] - a[sort.last30days],
    )
    const views = res.map((item) => item.views)
    const influence = res.map((item) => item.influence)
    const remixs = res.map((item) => item.remixs)
    const plugins = res.map((item) => item.plugins)
    const blocks = res.map((item) => item.blocks)

    const option = {
      title: {
        // text: 'World Population'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      toolbox: {
        right: 44,
        top: -5,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          // saveAsImage: {}
        },
      },
      legend: {
        // show: false
        itemWidth: 12,
        itemHeight: 12,
      },
      color: ['#f55d00', '#fec015', '#0bd295', '#0084ff', '#00b8ff'],
      grid: {
        // left: '3%',
        // right: '4%',
        // width: '800',
        // bottom: '15%',
        containLabel: true,
      },
      yAxis: {
        type: 'value',
        // show: false,
        // boundaryGap: [0, 0.01],
        // maxInterval: Math.ceil(3051 / 5),
        max: function (value) {
          return value.max <= 5 ? 5 : null
        },
      },
      xAxis: {
        type: 'category',
        data: res.map((item) => item.title),
        axisLabel: {
          interval: 0,
          rotate: 20,
        },
      },
      series: [
        {
          name: '受欢迎度',
          type: 'bar',
          data: influence,
        },
        {
          name: '影响力',
          type: 'bar',
          data: views,
        },
        {
          name: '被改编数',
          type: 'bar',
          data: remixs,
        },
        {
          name: '扩展使用数',
          type: 'bar',
          data: plugins,
        },
        {
          name: '包含积木数',
          type: 'bar',
          data: blocks,
        },
      ],

      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: 0,
          endValue: 19,
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          xAxisIndex: [0, 1],
        },
      ],
    }
    if (!last30daysRankChart.current) {
      last30daysRankChart.current = echarts.init(
        document.getElementById('last30daysRankChart'),
      )
    }
    if (last30daysRankChart.current && rankData.last30days.length) {
      last30daysRankChart.current.on('click', (params) => {
        window.open(
          `${scratchURL}/projects/${rankData.last30days[params.dataIndex].id}/`,
        )
      })
    }
    last30daysRankChart.current.setOption(option, true)
    last30daysRankChart.current.resize()
  }, [rankData.last30days, sort.last30days])
  useEffect(() => {
    if (
      moment().subtract(30, 'days').format('YYYYMMDD') >=
        moment(orgStartDate).format('YYYYMMDD') &&
      rankData.last30days.length
    ) {
      setLast30daysRankChart()
    }
  }, [
    setLast30daysRankChart,
    rankData.last30days,
    sort.last30days,
    orgStartDate,
  ])

  useEffect(() => {
    const list = document.querySelectorAll('.bottomLine')
    console.log(list)
    if (list.length) {
      const lastItem = list[list.length - 1]
      if (!lastItem.classList.contains('lastItem')) {
        lastItem.classList.add('lastItem')
      }
    }
  }, [])
  return (
    <>
      <div className="mainItem">
        {!Object.is(
          moment().format('YYYY/MM/DD'),
          moment(orgStartDate).format('YYYY/MM/DD'),
        ) ? (
          <DownLoadBar
            style={{ top: '80px' }}
            imageId="numsChartBox"
            title="每日提交作品数"
            excelParams={{
              name: ['日期', '学员', '员工', '校区'],
              key: ['date', 'student', 'staff', 'org'],
              data: numsData.date_list.map((item, idx) => ({
                date: item,
                student: numsData.student[idx],
                staff: numsData.staff[idx],
                org: numsData.org[idx],
              })),
              filename: `组织每日提交作品数-${moment().format('YYYY/MM/DD')}`,
            }}
          />
        ) : null}
        <div id="numsChartBox" className="imageBox">
          <SubHeader
            title="每日提交作品数"
            style={{ marginBottom: '20px' }}
            tips="每日凌晨更新"
            width={100}
          />
          <Spin spinning={loading.nums}>
            <div
              id="numsChart"
              style={{
                height: !Object.is(
                  moment().format('YYYY/MM/DD'),
                  moment(orgStartDate).format('YYYY/MM/DD'),
                )
                  ? '400px'
                  : 0,
              }}
            ></div>
            {Object.is(
              moment().format('YYYY/MM/DD'),
              moment(orgStartDate).format('YYYY/MM/DD'),
            ) ? (
              <div className="empty">
                <img src="/image/empty-page.png" alt="empty" />
                <p className="text">暂无数据</p>
              </div>
            ) : null}
          </Spin>
        </div>
        <div className="bottomLine"></div>
      </div>
      <div className="mainItem">
        {rankData.all.length ? (
          <>
            <DownLoadBar
              style={{ top: '149px' }}
              imageId="allRankChartBox"
              title="作品排行榜"
              excelParams={{
                name: [
                  '作品名',
                  '受欢迎度',
                  '影响力',
                  '被改编数',
                  '扩展使用数',
                  '包含积木数',
                ],
                key: [
                  'name',
                  'views',
                  'influence',
                  'remixs',
                  'plugins',
                  'blocks',
                ],
                data: rankData.all
                  .sort((a, b) => b[sort.all] - a[sort.all])
                  .map((item) => ({
                    name: item.title,
                    views: item.views,
                    influence: item.influence,
                    remixs: item.remixs,
                    plugins: item.plugins,
                    blocks: item.blocks,
                  })),
                filename: `作品排行榜-${moment().format('YYYY/MM/DD')}`,
              }}
            />
            <div className="sortSelect">
              <span className="text">排序：</span>
              <Select
                placeholder="请选择"
                style={{ width: 160 }}
                onChange={(e) => setSort((prev) => ({ ...prev, all: e }))}
              >
                <Option value="influence">受欢迎度</Option>
                <Option value="views">影响力</Option>
                <Option value="remixs">被改编数</Option>
                <Option value="plugins">扩展使用数</Option>
                <Option value="blocks">包含积木数</Option>
              </Select>
            </div>
          </>
        ) : null}
        <div id="allRankChartBox" className="imageBox">
          <SubHeader
            title="作品排行榜"
            style={{ marginBottom: '87px' }}
            popover={
              <div>
                <div>受欢迎度：作品点赞 + 收藏数</div>
                <div>影响力：作品被围观数</div>
                <div>被改编数：作品被改编的次数</div>
                <div>扩展使用数：作品中使用的扩展数</div>
                <div>包含积木数：作品包含的积木数</div>
              </div>
            }
            smallTitle={
              !Object.is(
                moment().format('YYYY/MM/DD'),
                moment(orgOpenDate).format('YYYY/MM/DD'),
              )
                ? `（${moment(orgStartDate).format('YYYY/MM/DD')} ~ ${moment()
                    .subtract(1, 'days')
                    .format('YYYY/MM/DD')}）`
                : null
            }

          />
          <Spin spinning={loading.all}>
            <div
              id="allRankChart"
              style={{ height: rankData.all.length ? '400px' : 0 }}
            ></div>
            {!rankData.all.length ? (
              <div className="empty">
                <img src="/image/empty-page.png" alt="empty" />
                <p className="text">暂无数据</p>
              </div>
            ) : null}
          </Spin>
        </div>
        <div className="bottomLine"></div>
      </div>
      {moment().subtract(7, 'days').format('YYYYMMDD') >=
      moment(orgStartDate).format('YYYYMMDD') ? (
        <div className="mainItem">
          {rankData.last7days.length ? (
            <>
              <DownLoadBar
                style={{ top: '149px' }}
                imageId="last7daysRankChartBox"
                title="近 7 天作品排行"
                excelParams={{
                  name: [
                    '作品名',
                    '受欢迎度',
                    '影响力',
                    '被改编数',
                    '扩展使用数',
                    '包含积木数',
                  ],
                  key: [
                    'name',
                    'views',
                    'influence',
                    'remixs',
                    'plugins',
                    'blocks',
                  ],
                  data: rankData.last7days
                    .sort((a, b) => b[sort.last7days] - a[sort.last7days])
                    .map((item) => ({
                      name: item.title,
                      views: item.views,
                      influence: item.influence,
                      remixs: item.remixs,
                      plugins: item.plugins,
                      blocks: item.blocks,
                    })),
                  filename: `近 7 天作品排行榜-${moment().format(
                    'YYYY/MM/DD',
                  )}`,
                }}
              />
              <div className="sortSelect">
                <span className="text">排序：</span>
                <Select
                  placeholder="请选择"
                  style={{ width: 160 }}
                  onChange={(e) =>
                    setSort((prev) => ({ ...prev, last7days: e }))
                  }
                >
                  <Option value="influence">受欢迎度</Option>
                  <Option value="views">影响力</Option>
                  <Option value="remixs">被改编数</Option>
                  <Option value="plugins">扩展使用数</Option>
                  <Option value="blocks">包含积木数</Option>
                </Select>
              </div>
            </>
          ) : null}
          <div id="last7daysRankChartBox" className="imageBox">
            <SubHeader
              title="近 7 天作品排行"
              style={{ marginBottom: '87px' }}
              popover={
                <div>
                  <div>受欢迎度：作品点赞 + 收藏数</div>
                  <div>影响力：作品被围观数</div>
                  <div>被改编数：作品被改编的次数</div>
                  <div>扩展使用数：作品中使用的扩展数</div>
                  <div>包含积木数：作品包含的积木数</div>
                </div>
              }
              smallTitle={`（${moment()
                .subtract(7, 'days')
                .format('YYYY/MM/DD')} ~ ${moment()
                .subtract(1, 'days')
                .format('YYYY/MM/DD')}）`}
            />
            <Spin spinning={loading.last7days}>
              <div
                id="last7daysRankChart"
                style={{ height: rankData.last7days.length ? '400px' : 0 }}
              ></div>
              {!rankData.last7days.length ? (
                <div className="empty">
                  <img src="/image/empty-page.png" alt="empty" />
                  <p className="text">暂无数据</p>
                </div>
              ) : null}
            </Spin>
          </div>
          <div className="bottomLine"></div>
        </div>
      ) : null}
      {moment().subtract(30, 'days').format('YYYYMMDD') >=
      moment(orgStartDate).format('YYYYMMDD') ? (
        <div className="mainItem">
          {rankData.last30days.length ? (
            <>
              <DownLoadBar
                style={{ top: '149px' }}
                imageId="last30daysRankChartBox"
                title="近 30 天作品排行"
                excelParams={{
                  name: [
                    '作品名',
                    '受欢迎度',
                    '影响力',
                    '被改编数',
                    '扩展使用数',
                    '包含积木数',
                  ],
                  key: [
                    'name',
                    'views',
                    'influence',
                    'remixs',
                    'plugins',
                    'blocks',
                  ],
                  data: rankData.last30days
                    .sort((a, b) => b[sort.last30days] - a[sort.last30days])
                    .map((item) => ({
                      name: item.title,
                      views: item.views,
                      influence: item.influence,
                      remixs: item.remixs,
                      plugins: item.plugins,
                      blocks: item.blocks,
                    })),
                  filename: `近 30 天作品排行榜-${moment().format(
                    'YYYY/MM/DD',
                  )}`,
                }}
              />
              <div className="sortSelect">
                <span className="text">排序：</span>
                <Select
                  placeholder="请选择"
                  style={{ width: 160 }}
                  onChange={(e) =>
                    setSort((prev) => ({ ...prev, last30days: e }))
                  }
                >
                  <Option value="influence">受欢迎度</Option>
                  <Option value="views">影响力</Option>
                  <Option value="remixs">被改编数</Option>
                  <Option value="plugins">扩展使用数</Option>
                  <Option value="blocks">包含积木数</Option>
                </Select>
              </div>
            </>
          ) : null}
          <div id="last30daysRankChartBox" className="imageBox">
            <SubHeader
              title="近 30 天作品排行"
              style={{ marginBottom: '87px' }}
              popover={
                <div>
                  <div>受欢迎度：作品点赞 + 收藏数</div>
                  <div>影响力：作品被围观数</div>
                  <div>被改编数：作品被改编的次数</div>
                  <div>扩展使用数：作品中使用的扩展数</div>
                  <div>包含积木数：作品包含的积木数</div>
                </div>
              }
              smallTitle={`（${moment()
                .subtract(30, 'days')
                .format('YYYY/MM/DD')} ~ ${moment()
                .subtract(1, 'days')
                .format('YYYY/MM/DD')}）`}
            />
            <Spin spinning={loading.last30days}>
              <div
                id="last30daysRankChart"
                style={{ height: rankData.last30days.length ? '400px' : 0 }}
              ></div>
              {!rankData.last30days.length ? (
                <div className="empty">
                  <img src="/image/empty-page.png" alt="empty" />
                  <p className="text">暂无数据</p>
                </div>
              ) : null}
            </Spin>
            <div className="bottomLine"></div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ProjectsViews
