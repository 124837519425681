import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect
} from 'react';
import CommonModal from '../../../../components/commonModal';
import { Button, message, Upload } from 'antd';
import '../index.scss';
import { UploadOutlined } from '@ant-design/icons';
import { uploadStudentFile } from './../../../../utils/api/manageCenter';
import { useDispatch } from 'react-redux';
import { getUser } from './../../../../store/actions';

const BatchImport = (props, ref) => {
  const { fetchList } = props;
  const [visible, setVisible] = useState(false);
  const [commitFile, setCommitFile] = useState(false);
  const [selectFile, setSelectFile] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const _dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    batchImportStudent: () => setVisible(true)
  }));

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const onUpload = options => {
    setSelectFile(options.file);
    setCommitFile(options.file);
  };

  const getFileMsg = file =>
    file ? (
      <div className="file" key={file.uid}>
        {file.name}
      </div>
    ) : null;

  const uploadFile = useCallback(() => {
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再试');
      return;
    }
    setDisabled(true);
    const fileRegExp = /\.(xlsx)$/;
    if (!fileRegExp.test(selectFile.name)) {
      message.warning('请上传 xlsx 格式的文件');
      setDisabled(false);
      return;
    }

    const formData = new FormData();
    let reader = new FileReader();
    reader.readAsDataURL(selectFile);
    formData.append('excel_file', selectFile);
    uploadStudentFile(formData)
      .then(res => {
        if (res.data.success) {
          setVisible(false);
          message.success('导入学员成功');
          setSelectFile(null);
          setCommitFile(null);
          setDisabled(false);
          fetchList();
          _dispatch(getUser());
        }
      })
      .catch(e => {
        if (typeof e.response.data === 'string') return;
        if (e.response.status === 400) {
          message.warning(e.response.data.message);
        } else {
          message.error('导入学员失败');
        }
        setDisabled(false);
      });
  }, [fetchList, selectFile, _dispatch]);

  return (
    <CommonModal
      title="导入学员"
      width={480}
      visible={visible}
      onCancel={() => {
        setVisible(false);
        setSelectFile(null);
        setCommitFile(null);
      }}
      contentNode={
        <div className="context">
          <p>
            下载我们提供的模板，根据模板里的规则填写学员的信息。
            <a href="https://e-course.oss-cn-shenzhen.aliyuncs.com/%E6%95%99%E5%AD%A6%E4%BA%91/%E5%AF%BC%E5%85%A5%E5%AD%A6%E5%91%98%E6%A8%A1%E7%89%88.xlsx">
              下载模板
            </a>
            填写后，请检查信息和填写规则无误再提交。
          </p>
          <div className="s_btn">
            <Upload
              accept=".xlsx"
              customRequest={onUpload}
              showUploadList={false}
            >
              <Button>
                <UploadOutlined /> 选择文件
              </Button>
            </Upload>
            {commitFile && selectFile ? (
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                disabled={disabled}
                onClick={uploadFile}
              >
                提交
              </Button>
            ) : null}
            <div className="filelist">{getFileMsg(commitFile)}</div>
          </div>
        </div>
      }
    />
  );
};

export default forwardRef(BatchImport);
