import React, { useState, useEffect, forwardRef } from 'react';

import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './listPagination.scss';
import { deBounce } from '../../../../utils';
const ListPagination = (props, ref) => {
  const { total, getPage, speed } = props;
  const [page, setPage] = useState(1);

  const preHandle = () => {
    let curPage = page;
    if (page === 1) {
      return;
    }

    setPage(--curPage);
  };
  const nextHandle = () => {
    let curPage = page;
    if (page === total) {
      return;
    }

    setPage(++curPage);
  };
  useEffect(() => {
    getPage(page);
  }, [page, getPage]);
  return (
    <div id="pagination">
      <Button
        type="link"
        className={['preBtn', page === 1 ? 'btndis' : null]}
        // onClick={() => preHandle()}
        onClick={deBounce(() => preHandle(), speed)}
      >
        <LeftOutlined />
      </Button>
      {page} /{total}
      <Button
        className={['nextBtn', page === total ? 'btndis' : null]}
        type="link"
        onClick={deBounce(() => nextHandle(), speed)}

        // onClick={() => nextHandle()}
      >
        <RightOutlined />
      </Button>
    </div>
  );
};
export default forwardRef(ListPagination);
