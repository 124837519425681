import instance from '../request';

// 员工列表
export const getEmployeeList = params => {
  return instance.get('/api/v1/employee/', { params });
};

// 创建员工第二步
export const createEmployee = data => {
  return instance.post('/api/v1/employee/', { ...data });
};

// 创建员工第一步
export const checkEmployee = params => {
  return instance.get('/api/v1/employee/check_user_info/', { params });
};

// 修改员工
export const modifyEmployee = data => {
  return instance.put(`/api/v1/employee/${data.id}/`, { ...data });
};

// 删除员工
export const deleteEmployee = id => {
  return instance.delete(`/api/v1/employee/${id}/`);
};

// 验证团队名称
export const fetchName = params => {
  return instance.get(`/api/v1/team/get_name/`, { params });
};

// 重置密码
export const resetPassword = employee_id => {
  return instance.post(`/api/v1/reset/`, { employee_id });
};

// 学员列表
export const getStudentList = params => {
  return instance.get(`/api/v1/org_students/`, { params });
};

// 创建学员
export const createStudent = values => {
  return instance.post('/api/v1/org_students/', values);
};

// 更换密钥
export const fetchSecretkey = secret_type => {
  return instance.post('/api/v1/org_students/change_secret/', { secret_type });
};

// 删除学员
export const deleteStudent = id => {
  return instance.delete(`/api/v1/org_students/${id}/`);
};

// 修改学员信息
export const patchDetail = (id, data) => {
  return instance.patch(`/api/v1/org_students/${id}/`, data);
};

// 添加本校区学员
export const addStudent = (team_code, params) => {
  return instance.post(`/api/v1/team/${team_code}/students/add_org_student/`, params);
};

// 批量导入
export const uploadStudentFile = formData => {
  return instance.post('/api/v1/org_students/batch_add/', formData);
};

// 修改学员密码
export const patchPassword = data => {
  return instance.post('/api/v1/change/password/', data);
};

// 修改团队信息
export const patchTeamInfo = (team_code, data) => {
  return instance.put(`/api/v1/team/${team_code}/`, data);
};

// 获取校区的教职工
export const getCampusOtherStaff = () => {
  return instance.get(`/api/v1/employee/other_staff/`);
};

export const checkUserInfo = (params) => {
  return instance.get(`/api/v1/employee/check_user_info/`, {params})
}

export const checkTeamCourse = (data) => {
  return instance.post(`/api/v1/employee/check_team_course/`, data)
}
