import React, {
  useState,
  useCallback,
  useLayoutEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect
} from 'react';
import './style.scss';
import {
  Button,
  Input,
  Table,
  Modal,
  message,
  Form,
  Select,
  Tooltip
} from 'antd';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import {
  getTeam,
  createPortfolio,
  createAreaPortfolio,
  getPortfolios,
  getAreaPortfolios,
} from './../../../utils/request';
import Icon from './../../../components/icon';
import { Link, useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { PlusOutlined } from '@ant-design/icons';

import { secretImageUrl, deBounce } from './../../../utils';
import { setPortfolioFlag, getUser } from '../../../store/actions';
import PortfolioModal from './portfolioModal';

const Portfolio = (props, ref) => {
  const isArea = props?.type === 'area'
  const toMember = props?.toMember==='1'
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [submitErr, setSubmitErr] = useState({ name:null });
  const [pageSize, setPageSize] = useState(8);
  const isTeacher = useSelector(state => state.isTeacher);
  const userInfo = useSelector(state => state.userInfo);
  const isNoRole = userInfo.user_type.includes('null');
  const isStudent = userInfo && userInfo.user_type === 'student';
  // 可能为负数
  const manager_CanCreateStudios = useSelector(
    state => state.manager_CanCreateStudios
  );
  const teacher_CanCreateStudios = useSelector(
    state => state.teacher_CanCreateStudios
  );

  const [showCreateModal, setShowCreateModal] = useState(
    qs.parse(location.search).create === 'new'
  );
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(
    Number(qs.parse(location.search).page) || 1
  );

  const formRef = useRef();
  const canCreate = useRef();
  const modifyRef = useRef();
  const [formData, setFormData] = useState({
    cover: null,
    name: '',
    team: undefined,
    description: ''
  });

  const [loaded, setLoaded] = useState(false);
  const [issearch, setIsSearch] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef();
  const [curRecord, setCurRecord] = useState(null);

  const [orderStr, setOrderStr] = useState('');
  const service_validity = useSelector(state => state.service_validity);
  const isValidityAbled = service_validity === 3 || service_validity === 4;
  // 校区管理员或非校区教师
  const Manager_Or_NonOrgTeacher =
    userInfo?.user_type.indexOf('manager') !== -1 ||
    (userInfo?.user_type.indexOf('teacher') !== -1 &&
      JSON.stringify(userInfo?.organization_data) === '{}');
    let studiosFlag = userInfo?.user_type.indexOf('manager') > -1
    ? manager_CanCreateStudios
    : teacher_CanCreateStudios;
  const operate_columns = {
    title: '操作',
    key: 'operate',
    align: 'left',
    dataIndex: 'operate',
    render: (value, record) => (
      <div className="operate">
        {
          record.role !== '-' && ((isArea && record.role !== '参与者') || !isArea) ?
            <Button
              size="small"
              type="link"
              disabled={!isArea && isValidityAbled}
              onClick={() => handleInfo(record)}
            >
              修改
            </Button> : null
        }
        
      </div>
    )
  };
  const columns = [
    {
      title: '名称',
      dataIndex: isArea ? 'title' : 'studio',
      key: isArea ? 'title' : 'studio',
      sorter: true,
      render: (text, record) => {
        let url = '';
        if (isArea) {
          url = `/manage/portfolio/${record.id}/?type=area&toMember=${toMember ? 1 : 0}`
        } else {
          if (props.from_type) {
            url = `/manage/portfolio/${record.id}?from_type=${props.from_type}`
          } else {
            url = `/manage/portfolio/${record.id}`
          }
        }
        return ( <Link to={url}>
        <span className="name">
          <img src={record.cover} width="48px" alt="cover" style={{
            borderColor: isArea ? '#FFD86A' : '#8EC9FF',
            backgroundColor: isArea ? '#FFE395' : '#C5E3FF'
          }} />
          {isArea ? record?.title : record?.studio?.title}
        </span>
      </Link>)
      }
    },
    {
      title: '身份',
      dataIndex: 'role',
      key: 'role',
      align: 'left',
      sorter: true,
    },
    {
      title: '作品数',
      dataIndex: 'num_project',
      key: 'num_project',
      align: 'left',
      sorter: true,
      render: (value, record) => {
        // 校区教师|校区管理员 判断 其 is_now_create, false只可以查看和删除
        if (
          JSON.stringify(userInfo?.organization_data) !== '{}' && !record.is_now_create && !isArea
        ) {
          return (
            <span>{value}</span>
          );
        }
        return (!record.is_touch || isArea) && value === 0 ? (
          <Link to={`/manage/portfolio/${record.id}/?create=new&type=${isArea?'area':'normal'}`}>
            <Button className="tableAddBtn">添加作品</Button>
          </Link>
        ) : (
          <span>{value}</span>
        );
      }
    },
    (
      isArea ? {
        title: '主管人员',
        dataIndex: 'managers',
        key: 'managers',
        align: 'left',
        width: 140,
        ellipsis:true,
        render: (value, record) =>
          record?.managers?.length > 0 ?
            <Tooltip placement="topLeft" title={record?.managers?.join(',')}>
              {record?.managers?.join(',')}
            </Tooltip>
            : '-'
      } : {
        title: '归属团队',
        dataIndex: 'team_name',
        key: 'team_name',
        align: 'left'
      }
    ),
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'left',
      sorter: true,
      render: value => dayjs(value).format('YYYY/MM/DD')
    }
  ];
  if (userInfo?.user_type.indexOf('manager') > -1) {
    columns.push(operate_columns);
  }
  useImperativeHandle(ref, () => ({
    createPortfolioHandler
  }));

  const onSearchHandler = useCallback(
    value => {
      history.push({
        path: history.location.pathname,
        search: qs.stringify({ page: 1 })
      });
      setCurrent(1);
      setSearchValue(value);
      setIsSearch(true);
    },
    [history]
  );

  const getAllportfolio = useCallback(() => {
    const requestFn = isArea ? getAreaPortfolios : getPortfolios;
    setLoading(true)
    return requestFn({
      page: current,
      page_size: pageSize,
      ...(searchValue ? { q: searchValue } : null),
      ...(props.from_type ? { from_type: props.from_type } : null),
      ...(orderStr ? { order_str: orderStr } : null)
    })
      .then(res => {
        dispatch(
          // 是否还有用？
          setPortfolioFlag(
            res.data.count >= userInfo?.team_data?.max_team_studios_num ||
              userInfo?.team_data?.max_team_studios_num === 0
          )
        );
        setData(
          res.data.results.map((item, index) => ({
            ...item,
            key: index,
            cover: secretImageUrl + item.cover
          }))
        );
        setTotal(res.data.count);
        setLoading(false)
        if (!location.search && !searchValue && issearch) setIsSearch(false);
      })
      .catch(e => {
        console.error(e);
      });
  }, [
    current,
    pageSize,
    searchValue,
    props.from_type,
    dispatch,
    userInfo.team_data.max_team_studios_num,
    location.search,
    issearch,
    orderStr,
    isArea
  ]);
  const createPortfolioHandler = useCallback(() => {
    if (!teamData.length && !isArea) {
      message.warn('请先创建团队，再创建作品集');
    } else {
      setShowCreateModal(true);
    }
  }, [teamData.length, isArea]);

  const formFinishHandler = useCallback(
    value => {
      const requestFn = isArea ? createAreaPortfolio: createPortfolio
      if(isArea){
        value.title = value.name
      }
      requestFn(value)
        .then(res => {
          if ((!isArea && res.data.created_at) || isArea) {
            setSubmitErr({
              name: null
            })
            setFormData({
              cover: value.cover,
              name: '',
              team: undefined,
              description: ''
            });
            setShowCreateModal(false);
            message.success('新建作品集成功');
            getAllportfolio();
            dispatch(getUser());
          }
        })
        .catch(e => {
          if (!window.navigator.onLine) {
            message.error('操作失败，请稍后重试');
            return;
          }
          if (typeof e.response.data === 'string') return;
          // if (e.response.data.message.includes('名称')) {
          //   setSubmitErr({
          //     name: {
          //       help: e.response.data.message,
          //       validateStatus: 'error',
          //     },
          //   })
          // }
          if (e.response.status === 400) {
            message.warning(
              e.response.data.message ||
                e.response.data.invalid ||
                '新建作品集失败，请稍后再试'
            );
            return;
          }
          if (e.exist) {
            message.warn('您已创建过该作品集，请更换名称');
            return;
          }
        })
        .finally(() => {
          canCreate.current = false;
        });
    },
    [getAllportfolio, dispatch, isArea]
  );

  
  const onChange = (pagination, filters, sorter, extra) => {
    if (sorter.field) {
      if (sorter.order && sorter.order === 'descend') {
        setOrderStr(`-${sorter.field}`)
      } else {
        setOrderStr(`${sorter.field}`)
      }
    }
  };

  useEffect(() => {
    getTeam({
      page_size: 999,
      ...(props.from_type ? { from_type: props.from_type } : null)
    }).then(res => {
      setTeamData(res.data.results);
      return;
    });
  }, [props.from_type]);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.input.setValue('')
    }
  }, [isArea])
  
  useEffect(() => {
    setCurrent(Number(qs.parse(location.search).page) || 1);
    setPageSize(8)
    getAllportfolio().then(() => {
      setLoaded(true);
    });
  }, [current, getAllportfolio, location.search]);
  const handleInfo = record => {
    if(!record.studio){
      record.studio = {
        description: record.description,
        title: record.title
      }
    }
    setCurRecord(record);
    console.log('record==>>', record);
    modifyRef.current.modifyHandler();
  };
  useEffect(() => {
    if (!formRef || !teamData.length) return;
    formRef.current &&
      formRef.current.setFieldsValue({
        team: qs.parse(location.search).code
      });
  }, [teamData, location.search]);

  useLayoutEffect(() => {
    document.querySelector(
      '.ant-table-pagination .ant-select-selection-item'
    ) &&
      (document.querySelector(
        '.ant-table-pagination .ant-select-selection-item'
      ).innerText = `${pageSize} 条/页`);
  }, [data.length, loaded]); // eslint-disable-line

  return (
    <div id="portfolio">
      <div className="container">
        {issearch || (loaded && data.length) ? (
          <section className="content">
            <div className="actionBar">
              <div className="btn">
                {data.length && isTeacher ? (
                  <React.Fragment>
                    {
                      !isNoRole ? <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={createPortfolioHandler}
                        disabled={
                          !isArea && (
                            isValidityAbled ||
                            // Boolean(-1) 为 true, 注意xx_CanCreateStudios的值可能为负数
                            (userInfo?.user_type.indexOf('manager') > -1
                              ? !(manager_CanCreateStudios > 0)
                              : !(teacher_CanCreateStudios > 0))
                          )
                        }
                      >
                        新建作品集
                      </Button> : null
                    }
                    {!isArea && isValidityAbled && (
                      <Tooltip
                        placement="right"
                        title={
                          service_validity === 3
                            ? '英荔教学云服务还没开始，请耐心等待'
                            : userInfo?.user_type.indexOf('manager') > -1 ||
                              (userInfo?.user_type?.indexOf('teacher') > -1 &&
                                JSON.stringify(userInfo?.organization_data) ===
                                  '{}')
                            ? '英荔教学云服务已到期，如需继续使用请联系英荔销售或致电 400-931-8118'
                            : '英荔教学云服务已到期，如需继续使用，请告知校区管理员'
                        }
                        overlayClassName="teamFloatingTooltip"
                      >
                        <img
                          src="/image/icon_question.png"
                          alt="question"
                          className="tip"
                        />
                      </Tooltip>
                    )}
                    {!isArea && !isValidityAbled && (
                      <Tooltip
                        placement="right"
                        title={() => {
                          if (userInfo?.user_type.indexOf('manager') > -1) {
                            if (manager_CanCreateStudios > 0) {
                              return `当前还可以创建 ${manager_CanCreateStudios} 个作品集`;
                            } else {
                              return '当前添加作品集的数量已达上限，如需增加作品集数量，请致电 400-931-8118';
                            }
                          }
                          if (userInfo?.user_type?.indexOf('teacher') > -1) {
                            if (teacher_CanCreateStudios > 0) {
                              return `当前还可以创建 ${teacher_CanCreateStudios} 个作品集`;
                            } else {
                              return JSON.stringify(
                                userInfo?.organization_data
                              ) === '{}'
                                ? '当前添加作品集的数量已达上限，如需增加作品集数量，请致电 400-931-8118'
                                : '当前添加作品集的数量已达上限，如需增加作品集数量，请联系校区管理员';
                            }
                          }
                        }}
                      >
                        <img
                          src="/image/icon_question.png"
                          alt="question"
                          className="tip"
                        />
                      </Tooltip>
                    )}
                  </React.Fragment>
                ) : null}
              </div>
              <div className="search">
                <Input.Search
                  disabled={loading}
                  ref={searchRef}
                  placeholder={isArea ? "搜索区域作品集名称/主管人员" : "搜索作品集/团队名称"}
                  onSearch={onSearchHandler}
                  style={{ width: 280, borderRadius: '4px' }}
                />
              </div>
            </div>
            <div className="table">
              <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                pagination={{
                  total,
                  current,
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  onChange: val => {
                    history.push({
                      path: history.location.pathname,
                      search: qs.stringify({
                        ...qs.parse(location.search),
                        page: val
                      })
                    });
                    setCurrent(val);
                  },
                  onShowSizeChange: (cur, size) => {
                    history.push({
                      path: history.location.pathname,
                      search: qs.stringify({
                        ...qs.parse(history.location.search),
                        page: 1
                      })
                    });
                    setCurrent(1);
                    setPageSize(size);
                  },
                  showTotal: (total, range) =>
                    `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`,
                }}
                onChange={onChange}
              />
              <div className="pagination_linkCss">
                {isTeacher ? (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://${window.location.host}/course/38/0`}
                  >
                    不知道作品集要怎么用？
                  </a>
                ) : null}
              </div>
            </div>
          </section>
        ) : loaded ? (
          <div className="nodata">
            <img src="/image/empty-page.png" alt="empty-page" />
            <div className="tips">
              {
                  isArea ? `你还没有以当前校区的身份加入${userInfo.organization_data.name}的区域作品集` : (isTeacher ? '暂无作品集' : '暂无作品集，请联系老师创建')
              }
            </div>
            {isTeacher ? (
              /*
                  作品集达到上限，【创建】按钮置灰不可点击，提示
                  校区管理员/非校区教师：当前添加作品集的数量已达上限，如需增加作品集数量，请致电 400-931-8118”
                  校区教师：当前添加作品集的数量已达上限，如需增加作品集数量，请联系校区管理员
                  */
              <div>
                {isValidityAbled && (
                  <Tooltip
                    placement="top"
                    title={
                      service_validity === 3
                        ? '英荔教学云服务还没开始，请耐心等待'
                        : userInfo?.user_type.indexOf('manager') > -1 ||
                          (userInfo?.user_type?.indexOf('teacher') > -1 &&
                            JSON.stringify(userInfo?.organization_data) ===
                              '{}')
                        ? '英荔教学云服务已到期，如需继续使用请联系英荔销售或致电 400-931-8118'
                        : '英荔教学云服务已到期，如需继续使用，请告知校区管理员'
                    }
                    overlayClassName="teamFloatingTooltip"
                  >
                      {
                        !isNoRole ? <Button
                          type="primary"
                          onClick={createPortfolioHandler}
                          // disabled={!userInfo?.team_data?.max_team_studios_num}
                          disabled={true}
                        >
                          创建
                        </Button> : null
                      }
                  </Tooltip>
                )}
                  {
                    !isValidityAbled && (
                      isArea ? 
                        <Button
                          type="primary"
                          onClick={createPortfolioHandler}
                          disabled={isNoRole || isStudent}
                        >
                          创建
                        </Button>
                      : (
                        !(studiosFlag > 0) ? (
                          <Tooltip
                            placement="top"
                            title={
                              Manager_Or_NonOrgTeacher
                                ? '当前添加作品集的数量已达上限，如需增加作品集数量，请致电 400-931-8118'
                                : '当前添加作品集的数量已达上限，如需增加作品集数量，请联系校区管理员'
                            }
                          // title="当前账户无法新建作品集，如需使用此功能，请致电 400-931-8118"
                          >
                            <Button
                              type="primary"
                              onClick={createPortfolioHandler}
                              // disabled={!userInfo?.team_data?.max_team_studios_num}
                              disabled={
                                userInfo?.user_type.indexOf('manager') > -1
                                  ? !(manager_CanCreateStudios > 0)
                                  : !(teacher_CanCreateStudios > 0)
                              }
                            >
                              创建
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button
                            type="primary"
                            onClick={createPortfolioHandler}
                          // disabled={!userInfo?.team_data?.max_team_studios_num}
                          >
                            创建
                          </Button>
                        )
                      )
                    )
                  }
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <Modal
        closeIcon={
          <img src="/image/close.png" alt="close" width="14px" height="14px" />
        }
        className="createModal"
        visible={showCreateModal}
        onCancel={() => {
          // history.replace('/manage/portfolio');
          formRef.current &&
            formRef.current.setFieldsValue({
              cover: formData.cover,
              name: '',
              team: undefined,
              description: ''
            });
          setFormData({
            cover: formData.cover,
            name: '',
            team: undefined,
            description: ''
          });
          setShowCreateModal(false);
        }}
        okButtonProps={{ disabled: !formData.name || (!isArea && !formData.team) }}
        okText="保存"
        onOk={deBounce(() => {
          if (!canCreate.current) {
            console.log('OK');
            formRef.current.submit();
            canCreate.current = true;
          }
        }, 300)}
        width={500}
        centered={true}
        getContainer={false}
        destroyOnClose={true}
        maskClosable={false}
        title={isArea ? '新建区域作品集' : '新建作品集'}
      >
        <div className="content">
          <Form
            ref={formRef}
            initialValues={formData}
            layout="vertical"
            onValuesChange={(value, allValue) => setFormData(allValue)}
            onFinish={formFinishHandler}
          >
            {isArea ? <div className='tips'>
              区域作品集由个人创建，归属于区域组织，和团队、校区不做关联，可用于同组织不同校区的教职工、管理人员间进行各种活动。<br></br>
              只要获取到分享链接，你所在区域组织中的所有人都可以浏览此作品集。你也可以邀请你所在区域组织中的其他职工成为区域作品集的成员，与你一同管理此作品集。
            </div> : null}
            <Form.Item label="作品集封面" name="cover" required>
              <div>
                <Icon
                  onLoad={file => {
                    setFormData({
                      cover: file,
                      name: '',
                      team: undefined,
                      description: ''
                    });
                    formRef.current &&
                      formRef.current.setFieldsValue({
                        cover: file
                      });
                  }}
                  type={isArea ? 'areaPortfolio' : 'portfolio'}
                  random={true}
                  onChange={file => {
                    formRef.current &&
                      formRef.current.setFieldsValue({
                        cover: file
                      });
                  }}
                  showSwitch
                />
              </div>
            </Form.Item>
            {
              !isArea && <Form.Item label="关联团队" name="team" required>
              <Select
                placeholder="请选择已有团队"
                onChange={value => {
                  formRef.current &&
                    formRef.current.setFieldsValue({
                      team: value
                    });
                }}
                getPopupContainer={triggerNode =>
                  triggerNode.parentElement || document.body
                }
              >
                {/* 加入校区后只显示 is_now_create 为 ture 的团队,否则显示 false 的团队 */}
                {JSON.stringify(userInfo?.organization_data) !== '{}'
                  ? teamData.map((team, index) =>
                      team.is_now_create ? (
                        <Select.Option key={index} value={team.code}>
                          {team.name}
                        </Select.Option>
                      ) : null
                    )
                  : teamData.map((team, index) =>
                      !team.is_now_create ? (
                        <Select.Option key={index} value={team.code}>
                          {team.name}
                        </Select.Option>
                      ) : null
                    )}
              </Select>
            </Form.Item>
            }
            <Form.Item
              label="作品集名称"
              name="name"
              rules={[{ required: true, message: '请输入作品集名称' }]}
              {...submitErr.name}
            >
              <Input
                maxLength="15"
                placeholder="请输入作品集名称"
                suffix={
                  <span className="suffix">{`${Math.min(
                    formData.name.length,
                    15
                  )}/15`}</span>
                }
                onChange={() => setSubmitErr({ name: null })}
              ></Input>
            </Form.Item>

            <Form.Item
              label="作品集介绍"
              name="description"
              rules={[{ required: false }]}
            >
              <Input.TextArea
                placeholder="作品集介绍将在作品集主页里展示给每一位来访者。"
                maxLength="200"
                onChange={e => {
                  // if (e.target.value.length > 200) {
                  //     formRef.current.setFieldsValue({description: e.target.value.slice(0, 200)})
                  // }
                }}
                style={{ height: '153px', resize: 'none' }}
              ></Input.TextArea>
            </Form.Item>
            <span className="textAreaSuffix">{`${
              formData.description.length > 200
                ? 200
                : formData.description.length
            }/200`}</span>
          </Form>
        </div>
      </Modal>
      <PortfolioModal
        ref={modifyRef}
        patchItem={curRecord}
        fetchList={getAllportfolio}
        isArea={isArea}
      />
    </div>
  );
};

export default forwardRef(Portfolio);
