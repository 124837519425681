import React, { useState, useEffect, useRef, useCallback } from 'react'
import moment from 'moment'
import * as echarts from 'echarts'
import { DatePicker, Select, Spin } from 'antd'
import { useSelector } from 'react-redux'
import DownLoadBar from '../../../../components/downloadBar'
import SubHeader from '../../../../components/subHeader'
import TimeRecord from '../../../../components/timeRecord'
import {
  getOrgAvgTime,
  getOrgProjectInfo,
  getOrgProjectsRank,
} from '../../../../utils/request'
import {
  scratchURL,
  getDateRange,
  getRealDateRange,
  getDateList,
  disabledDate,
  disabledMonth,
  transformData2Excel,
} from '../../../../utils'

const { Option } = Select
const { RangePicker } = DatePicker

const dateFormat = 'YYYY-MM-DD'

const platFormMap = new Map([
  ['teach', '教学云'],
  ['creative', '创作平台'],
])

const StudentView = ({ orgOpenDate }) => {
  const userInfo = useSelector((state) => state.userInfo)
  const [platform, setPlatForm] = useState('teach')
  const [timeInterval, setTimeInterval] = useState({
    avg: 'last30days',
    info: 'last7days',
    rank: 'last7days',
  })
  const [dateRange, setDateRange] = useState({
    avg: getDateRange(timeInterval.avg),
    info: getRealDateRange(timeInterval.info),
    rank: getRealDateRange(timeInterval.rank),
  })
  const [useTimeDate, setUseTimeDate] = useState(
    getDateList(timeInterval.avg, dateRange.avg),
  )
  const [data2excel, setData2Excel] = useState([])
  const [sort, setSort] = useState({
    filter: 'project_num',
    rank: 'views',
  })
  const [useTimeData, setUseTimeData] = useState([]) // eslint-disable-line
  const [projectsFilterData, setProjectsFilterData] = useState([])
  const [projectsRankData, setProjectsRankData] = useState([])

  const [loading, setLoading] = useState({
    avg: false,
    info: false,
    rank: false,
  })

  const projectsFilterChart = useRef(null)
  const projectsRankChart = useRef(null)

  useEffect(() => {
    if (dateRange.avg.start && dateRange.avg.end) {
      setUseTimeDate(getDateList(timeInterval.avg, dateRange.avg))
    }
  }, [dateRange.avg, timeInterval.avg]) // eslint-disable-line

  useEffect(() => {
    if (dateRange.avg.start && dateRange.avg.end) {
      setLoading((prev) => ({ ...prev, avg: true }))
      getOrgAvgTime(userInfo?.organization_data.id, {
        user_type: 'student',
        platform,
        start_date: dateRange.avg.start,
        end_date: dateRange.avg.end,
      }).then((res) => {
        // setUseTimeChart(res.data)
        setUseTimeData(res.data)
        setData2Excel(transformData2Excel(res.data))
        setLoading((prev) => ({ ...prev, avg: false }))
      })
    }
  }, [platform, dateRange.avg.start, dateRange.avg.end]) // eslint-disable-line

  useEffect(() => {
    if (dateRange.info.start && dateRange.info.end) {
      setLoading((prev) => ({ ...prev, info: true }))
      getOrgProjectInfo(userInfo?.organization_data.id, {
        user_type: 'student',
        start_date: dateRange.info.start,
        end_date: dateRange.info.end,
      }).then((res) => {
        setProjectsFilterData(res.data)
        setLoading((prev) => ({ ...prev, info: false }))
      })
    }
  }, [dateRange.info]) // eslint-disable-line

  useEffect(() => {
    if (dateRange.rank.start && dateRange.rank.end) {
      setLoading((prev) => ({ ...prev, rank: true }))
      getOrgProjectsRank(userInfo?.organization_data.id, {
        user_type: 'student',
        start_date: dateRange.rank.start,
        end_date: dateRange.rank.end,
      }).then((res) => {
        setProjectsRankData(res.data)
        setLoading((prev) => ({ ...prev, rank: false }))
      })
    }
  }, [dateRange.rank]) // eslint-disable-line

  const setProjectsFilterChart = useCallback(() => {
    const res = projectsFilterData.sort(
      (a, b) => b[sort.filter] - a[sort.filter],
    )
    const project_num = res.map((item) => item.project_num)
    const share_count = res.map((item) => item.share_count)
    const shared_views = res.map((item) => item.shared_views)
    const loves = res.map((item) => item.loves)
    const favorites = res.map((item) => item.favorites)
    const be_comments = res.map((item) => item.be_comments)

    const option = {
      title: {
        // text: 'World Population'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      toolbox: {
        right: 44,
        top: -5,
        feature: {
          magicType: {
            type: ['stack'],
          },
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          // saveAsImage: {}
        },
      },
      legend: {
        // show: false
        itemWidth: 12,
        itemHeight: 12,
      },
      color: ['#0084ff', '#ff8400', '#0bd295', '#f55d00', '#fec015', '#00b8ff'],
      grid: {
        // left: '3%',
        // right: '4%',
        // width: '800',
        // bottom: '15%',
        containLabel: true,
      },
      yAxis: {
        type: 'value',
        // show: false,
        // boundaryGap: [0, 0.01],
        // maxInterval: Math.ceil(3051 / 5),
        max: function (value) {
          return value.max <= 5 ? 5 : null
        },
      },

      xAxis: {
        type: 'category',
        data: res.map((item) => item.name),
        axisLabel: {
          interval: 0,
          rotate: 20,
        },
      },
      series: [
        {
          name: '作品数',
          type: 'bar',
          data: project_num,
        },
        {
          name: '分享数',
          type: 'bar',
          data: share_count,
        },
        {
          name: '分享后被观看次数',
          type: 'bar',
          data: shared_views,
        },
        {
          name: '被点赞数',
          type: 'bar',
          data: loves,
        },
        {
          name: '被收藏数',
          type: 'bar',
          data: favorites,
        },
        {
          name: '被评论数',
          type: 'bar',
          data: be_comments,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: 0,
          endValue: 19,
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          xAxisIndex: [0, 1],
        },
      ],
    }
    if (!projectsFilterChart.current) {
      projectsFilterChart.current = echarts.init(
        document.getElementById('projectsFilterChart'),
      )
    }
    if (projectsFilterChart.current && projectsFilterData.length) {
      projectsFilterChart.current.on('click', (params) => {
        window.open(
          `/account/home/${projectsFilterData[params.dataIndex].user_name}`,
        )
      })
    }
    projectsFilterChart.current.setOption(option, true)
    projectsFilterChart.current.resize()
  }, [projectsFilterData, sort.filter, projectsFilterChart])

  useEffect(() => {
    if (projectsFilterData.length) {
      setProjectsFilterChart()
    } else if (projectsFilterChart.current) {
      projectsFilterChart.current.resize()
    }
  }, [sort.filter, projectsFilterData, setProjectsFilterChart])

  const setProjectsRankChart = useCallback(() => {
    const res = projectsRankData.sort((a, b) => b[sort.rank] - a[sort.rank])
    const views = res.map((item) => item.views)
    const loves = res.map((item) => item.loves)
    const shared_views = res.map((item) => item.shared_views)
    const favorites = res.map((item) => item.favorites)
    const option = {
      title: {
        // text: 'World Population'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      toolbox: {
        right: 44,
        top: -5,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          // saveAsImage: {}
        },
      },
      legend: {
        // show: false
        itemWidth: 12,
        itemHeight: 12,
      },
      color: ['#0084ff', '#0bd295', '#f55d00', '#fec015'],
      grid: {
        // left: '3%',
        // right: '4%',
        // width: '800',
        // bottom: '15%',
        containLabel: true,
      },
      yAxis: {
        type: 'value',
        // show: false,
        // boundaryGap: [0, 0.01],
        // maxInterval: Math.ceil(3051 / 5),
        max: function (value) {
          return value.max <= 5 ? 5 : null
        },
      },
      xAxis: {
        type: 'category',
        data: res.map((item) => item.title),
        axisLabel: {
          interval: 0,
          rotate: 20,
        },
      },
      series: [
        {
          name: '查看次数',
          type: 'bar',
          data: views,
        },
        {
          name: '分享后被观看次数',
          type: 'bar',
          data: shared_views,
        },
        {
          name: '被点赞数',
          type: 'bar',
          data: loves,
        },
        {
          name: '被收藏数',
          type: 'bar',
          data: favorites,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: 0,
          endValue: 9,
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          xAxisIndex: [0, 1],
        },
      ],
    }
    if (!projectsRankChart.current) {
      projectsRankChart.current = echarts.init(
        document.getElementById('projectsRankChart'),
      )
    }
    if (projectsRankChart.current && projectsRankData.length) {
      projectsRankChart.current.on('click', (params) => {
        window.open(
          `${scratchURL}/projects/${projectsRankData[params.dataIndex].id}/`,
        )
      })
    }
    projectsRankChart.current.setOption(option, true)
    projectsRankChart.current.resize()
  }, [projectsRankData, sort.rank, projectsRankChart])

  useEffect(() => {
    if (projectsRankData.length) {
      setProjectsRankChart()
    } else if (projectsRankChart.current) {
      projectsRankChart.current.resize()
    }
  }, [sort.rank, projectsRankData, setProjectsRankChart])

  useEffect(() => {
    setDateRange((prev) => ({ ...prev, avg: getDateRange(timeInterval.avg) }))
  }, [timeInterval.avg])

  useEffect(() => {
    if (timeInterval.info !== 'datepicker') {
      setDateRange((prev) => ({
        ...prev,
        info: getRealDateRange(timeInterval.info),
      }))
    }
  }, [timeInterval.info])

  useEffect(() => {
    if (timeInterval.rank !== 'datepicker') {
      setDateRange((prev) => ({
        ...prev,
        rank: getRealDateRange(timeInterval.rank),
      }))
    }
  }, [timeInterval.rank])

  const onDateRangeChange = (e, type) => {
    let start, end
    if (type === 'avg') {
      const year = e.year()
      const month = e.month()
      start = moment(new Date(year, month, 1)).format('YYYYMMDD')
      end = moment(new Date(year, month + 1, 0)).format('YYYYMMDD')
    } else {
      start = moment(e[0]).format('YYYYMMDD')
      end = moment(e[1]).format('YYYYMMDD')
    }
    setDateRange((prev) => ({ ...prev, [type]: { start, end } }))
    setTimeInterval((prev) => ({ ...prev, [type]: 'datepicker' }))
  }

  const onTimeChange = (type, interval) =>
    setTimeInterval((prev) => ({ ...prev, [type]: interval }))

  return (
    <>
      <div className="mainItem" id="useTime">
        <DownLoadBar
          style={{ top: '152px' }}
          imageId="useTimeChartBox"
          title="人均时长"
          excelParams={{
            name: [
              '日期',
              '0-8点',
              '8-10点',
              '10-12点',
              '12-14点',
              '14-16点',
              '16-18点',
              '18-20点',
              '20-22点',
              '22-24点',
            ],
            key: [
              'date',
              'range1',
              'range2',
              'range3',
              'range4',
              'range5',
              'range6',
              'range7',
              'range8',
              'range9',
            ],
            data: data2excel.map((item) => ({
              date: item.date,
              range1: `${item['0-8']} 分钟`,
              range2: `${item['8-10']} 分钟`,
              range3: `${item['10-12']} 分钟`,
              range4: `${item['12-14']} 分钟`,
              range5: `${item['14-16']} 分钟`,
              range6: `${item['16-18']} 分钟`,
              range7: `${item['18-20']} 分钟`,
              range8: `${item['20-22']} 分钟`,
              range9: `${item['22-24']} 分钟`,
            })),
            filename: `学员人均时长-${platFormMap.get(
              platform,
            )}--${moment().format('YYYY/MM/DD')}`,
          }}
        />
        <div className="actionBar">
          <div className="tabs">
            <div
              className="text"
              style={{
                color:
                  timeInterval.avg === 'last30days' ? '#0084ff' : '#333333',
              }}
              onClick={() => onTimeChange('avg', 'last30days')}
            >
              最近
            </div>
            {moment().subtract(1, 'months').endOf('month').format('YYYYMMDD') <
            moment(orgOpenDate).format('YYYYMMDD') ? (
              <div className="text disabled">上月</div>
            ) : (
              <div
                className="text"
                style={{
                  color:
                    timeInterval.avg === 'lastmonth' ? '#0084ff' : '#333333',
                }}
                onClick={() => onTimeChange('avg', 'lastmonth')}
              >
                上月
              </div>
            )}
          </div>
          <DatePicker
            onChange={(e) => onDateRangeChange(e, 'avg')}
            disabledDate={(current) => disabledMonth(current, orgOpenDate)}
            picker="month"
          />
        </div>
        <div id="useTimeChartBox" className="imageBox">
          <SubHeader title="人均时长" tips="每日凌晨更新" width={100} />
          <Spin spinning={loading.avg}>
            <TimeRecord
              data={useTimeData}
              dates={useTimeDate}
              platform={platform}
              setPlatForm={setPlatForm}
            />
          </Spin>
        </div>
        <div className="bottomLine"></div>
      </div>
      <div className="mainItem">
        {projectsFilterData.length ? (
          <>
            <DownLoadBar
              style={{ top: '149px' }}
              imageId="projectsFilterChartBox"
              title="作品统计"
              excelParams={{
                name: [
                  '用户名',
                  '作品数',
                  '分享数',
                  '分享后被观看次数',
                  '被点赞数',
                  '被收藏数',
                  '被评论数',
                ],
                key: [
                  'name',
                  'project_num',
                  'share_count',
                  'shared_views',
                  'loves',
                  'favorites',
                  'be_comments',
                ],
                data: projectsFilterData
                  ?.sort((a, b) => b[sort.filter] - a[sort.filter])
                  .map((item) => ({
                    name: item.user_name,
                    project_num: item.project_num,
                    share_count: item.share_count,
                    loves: item.loves,
                    favorites: item.favorites,
                    shared_views: item.shared_views,
                    be_comments: item.be_comments,
                  })),
                filename: `学员作品统计-${moment().format('YYYY/MM/DD')}`,
              }}
            />
            <div className="sortSelect">
              <span className="text">排序：</span>
              <Select
                placeholder="请选择"
                style={{ width: 160 }}
                onChange={(e) => setSort((prev) => ({ ...prev, filter: e }))}
              >
                <Option value="project_num">作品数</Option>
                <Option value="share_count">分享数</Option>
                <Option value="shared_views">分享后被观看次数</Option>
                <Option value="loves">被点赞数</Option>
                <Option value="favorites">被收藏数</Option>
                <Option value="be_comments">被评论数</Option>
              </Select>
            </div>
          </>
        ) : null}
        {moment(orgOpenDate).format('YYYYMMDD') <=
        moment().format('YYYYMMDD') ? (
          <div className="timeSelect">
            <div className="tabs">
              <div
                className="text"
                style={{
                  color:
                    timeInterval.info === 'last7days' ? '#0084ff' : '#333333',
                }}
                onClick={() => onTimeChange('info', 'last7days')}
              >
                近7天
              </div>
              <div
                className="text"
                style={{
                  color:
                    timeInterval.info === 'last30days' ? '#0084ff' : '#333333',
                }}
                onClick={() => onTimeChange('info', 'last30days')}
              >
                近30天
              </div>
              {moment().subtract(1, 'weeks').endOf('week').format('YYYYMMDD') <
              moment(orgOpenDate).format('YYYYMMDD') ? (
                <div className="text disabled">上周</div>
              ) : (
                <div
                  className="text"
                  style={{
                    color:
                      timeInterval.info === 'lastweek' ? '#0084ff' : '#333333',
                  }}
                  onClick={() => onTimeChange('info', 'lastweek')}
                >
                  上周
                </div>
              )}
              {moment()
                .subtract(1, 'months')
                .endOf('month')
                .format('YYYYMMDD') < moment(orgOpenDate).format('YYYYMMDD') ? (
                <div className="text disabled">上月</div>
              ) : (
                <div
                  className="text"
                  style={{
                    color:
                      timeInterval.info === 'lastmonth' ? '#0084ff' : '#333333',
                  }}
                  onClick={() => onTimeChange('info', 'lastmonth')}
                >
                  上月
                </div>
              )}
            </div>
            <RangePicker
              onChange={(e) => onDateRangeChange(e, 'info')}
              value={[moment(dateRange.info.start), moment(dateRange.info.end)]}
              format={dateFormat}
              disabledDate={(current) =>
                disabledDate(current, orgOpenDate, true)
              }
              allowClear={false}
            />
          </div>
        ) : null}
        <div id="projectsFilterChartBox" className="imageBox">
          <SubHeader
            title="作品统计"
            style={{ marginBottom: '87px' }}
            smallTitle={`（${moment(dateRange.info.start).format(
              'YYYY/MM/DD',
            )} ~ ${moment(dateRange.info.end).format('YYYY/MM/DD')}）`}
          />
          <Spin spinning={loading.info}>
            <div
              id="projectsFilterChart"
              style={{ height: projectsFilterData.length ? '400px' : 0 }}
            ></div>
            {!projectsFilterData.length ? (
              <div className="empty">
                <img src="/image/empty-page.png" alt="empty" />
                <p className="text">暂无数据</p>
              </div>
            ) : null}
          </Spin>
        </div>
        <div className="bottomLine"></div>
      </div>
      <div className="mainItem lastItem">
        {projectsRankData.length ? (
          <>
            <DownLoadBar
              imageId="projectsRankChartBox"
              title="作品排行"
              style={{ top: '149px' }}
              excelParams={{
                name: [
                  '作品名',
                  '查看次数',
                  '分享后被观看次数',
                  '被点赞数',
                  '被收藏数',
                ],
                key: ['name', 'views', 'shared_views', 'loves', 'favorites'],
                data: projectsRankData
                  ?.sort((a, b) => b[sort.rank] - a[sort.rank])
                  .map((item, idx) => ({
                    name: item.title,
                    views: item.views,
                    shared_views: item.shared_views,
                    loves: item.loves,
                    favorites: item.favorites,
                  })),
                filename: `学员作品排行-${moment().format('YYYY/MM/DD')}`,
              }}
            />
            <div className="sortSelect">
              <span className="text">排序：</span>
              <Select
                placeholder="请选择"
                style={{ width: 160 }}
                onChange={(e) => setSort((prev) => ({ ...prev, rank: e }))}
              >
                <Option value="views">查看次数</Option>
                <Option value="shared_views">分享后被观看次数</Option>
                <Option value="loves">被点赞数</Option>
                <Option value="favorites">被收藏数</Option>
              </Select>
            </div>
          </>
        ) : null}
        {moment(orgOpenDate).format('YYYYMMDD') <=
        moment().format('YYYYMMDD') ? (
          <div className="timeSelect">
            <div className="tabs">
              <div
                className="text"
                style={{
                  color:
                    timeInterval.rank === 'last7days' ? '#0084ff' : '#333333',
                }}
                onClick={() => onTimeChange('rank', 'last7days')}
              >
                近7天
              </div>
              <div
                className="text"
                style={{
                  color:
                    timeInterval.rank === 'last30days' ? '#0084ff' : '#333333',
                }}
                onClick={() => onTimeChange('rank', 'last30days')}
              >
                近30天
              </div>
              {moment().subtract(1, 'weeks').endOf('week').format('YYYYMMDD') <
              moment(orgOpenDate).format('YYYYMMDD') ? (
                <div className="text disabled">上周</div>
              ) : (
                <div
                  className="text"
                  style={{
                    color:
                      timeInterval.rank === 'lastweek' ? '#0084ff' : '#333333',
                  }}
                  onClick={() => onTimeChange('rank', 'lastweek')}
                >
                  上周
                </div>
              )}
              {moment()
                .subtract(1, 'months')
                .endOf('month')
                .format('YYYYMMDD') < moment(orgOpenDate).format('YYYYMMDD') ? (
                <div className="text disabled">上月</div>
              ) : (
                <div
                  className="text"
                  style={{
                    color:
                      timeInterval.rank === 'lastmonth' ? '#0084ff' : '#333333',
                  }}
                  onClick={() => onTimeChange('rank', 'lastmonth')}
                >
                  上月
                </div>
              )}
            </div>
            <RangePicker
              onChange={(e) => onDateRangeChange(e, 'rank')}
              value={[moment(dateRange.rank.start), moment(dateRange.rank.end)]}
              format={dateFormat}
              disabledDate={(current) =>
                disabledDate(current, orgOpenDate, true)
              }
              allowClear={false}
            />
          </div>
        ) : null}
        <div id="projectsRankChartBox" className="imageBox">
          <SubHeader
            title="作品排行"
            style={{ marginBottom: '87px' }}
            smallTitle={`（${moment(dateRange.rank.start).format(
              'YYYY/MM/DD',
            )} ~ ${moment(dateRange.rank.end).format('YYYY/MM/DD')}）`}
          />
          <Spin spinning={loading.rank}>
            <div
              id="projectsRankChart"
              style={{ height: projectsRankData.length ? '400px' : 0 }}
            ></div>
            {!projectsRankData.length ? (
              <div className="empty">
                <img src="/image/empty-page.png" alt="empty" />
                <p className="text">暂无数据</p>
              </div>
            ) : null}
          </Spin>
        </div>
      </div>
    </>
  )
}

export default StudentView
