import React from 'react'
import { Tooltip, Popover } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import './style.scss'

const SubHeader = ({
  title,
  text,
  tips,
  popover,
  smallTitle,
  smallTitleStyle = {},
  width = 330,
  style = { marginBottom: '92px' },
}) => {
  return (
    <>
      <h3 className="subTitle" style={style}>
        {title}
        {smallTitle ? (
          <span
            style={Object.assign(
              { fontWeight: 'normal', fontSize: '14px' },
              smallTitleStyle,
            )}
          >
            {smallTitle}
          </span>
        ) : null}
        {tips && (
          <Tooltip
            placement="top"
            title={tips}
            overlayStyle={{ width: width + 'px', maxWidth: width + 'px' }}
          >
            <QuestionCircleOutlined
              style={{ color: '#cccccc', marginLeft: '8px', fontSize: '17px' }}
            />
          </Tooltip>
        )}
        {popover && (
          <Popover placement="topLeft" content={popover} title="数据介绍">
            <QuestionCircleOutlined
              style={{ color: '#cccccc', marginLeft: '8px', fontSize: '17px' }}
            />
          </Popover>
        )}
      </h3>
      {text && <p className="descr">{text}</p>}
    </>
  )
}

export default SubHeader
