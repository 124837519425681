import React from 'react';
import './style.scss';
const note = () => {
    return (
        <div id='note'>
            <p>
                尊敬的用户您好，2022 年 5 月 30 日之后 longan.link 相关域名将不再支持跳转进入教学云，<br />请您保存教学云网址 <a href="https://course.aimaker.space/">https://course.aimaker.space/</a>  ，以便以后访问。
            </p>
        </div>
    )
}
export default note