import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import {
  Route,
  useRouteMatch,
  withRouter,
  Redirect,
  Switch,
  Link,
  useHistory,
} from 'react-router-dom'
import { Menu, Divider, Tooltip } from 'antd'
import moment from 'moment'
import Team from './team'
import TeamItem from './team/teamItem'
import DataCenter from './dataCenter'
import ManageLoginCode from './team/loginCode'
import Employee from './employee'
import Student from './student'
import Portfolio from '../manage/portfolio'
import { useSelector, useDispatch } from 'react-redux'
import { getUser } from './../../store/actions'

const DataBar = ({ userInfo }) => {
  const [data, setData] = useState({})
  useEffect(() => {
    let _data = {}
    for (const k in userInfo.organization_data) {
      if (typeof userInfo.organization_data[k] === 'number') {
        _data[k] = userInfo.organization_data[k].toLocaleString()
      }
    }
    setData(_data)
    // eslint-disable-next-line
  }, [])
  return (
    <div className="right">
      <div>
        <p>教职工</p>
        <p>{`${data?.staff_nums}/${data?.max_staff}`}</p>
      </div>
      <Divider type="vertical" />
      <div>
        <p>学员</p>
        <p>{`${data?.org_real_used_student_nums}/${data?.max_student}`}</p>
      </div>
      <Divider type="vertical" />
      <div>
        <p>团队</p>
        <p>{`${data?.org_real_team_nums}/${data?.max_team}`}</p>
      </div>
      <Divider type="vertical" />
      <div>
        <p>作品集</p>
        <p>{`${data?.org_real_used_team_studios_nums}/${data?.max_team_studios_num}`}</p>
      </div>
      {data?.overall_organization_data && moment().format('YYYYMMDD') <= moment(data?.end_date).format('YYYYMMDD')? (
        <img className="arrowright" src="/image/arrow-right.png" alt=""/>
      ) : null}
    </div>
  )
}

const Manage = (props) => {
  const match = useRouteMatch('/manageCenter/:type')
  const userInfo = useSelector((state) => state.userInfo)
  // const teamCount = useSelector((state) => state.teamCount);
  const childRef = useRef()
  const history = useHistory()
  const _dispatch = useDispatch()
  const service_validity = useSelector((state) => state.service_validity)

  if (!match) {
    return <Redirect to="/manageCenter/employee" />
  }

  const isShow =
    history.location.pathname.includes('/detail') ||
    history.location.pathname.includes('/logincode') ||
    history.location.pathname.includes('/courseManage') ||
    history.location.pathname.includes('/datacenter')

  return (
    <div id="manage">
      {isShow ? null : (
        <div className="header">
          <div className="container">
            <div className="details">
              <p>管理中心</p>
              <div className="introduction">
                <div className="left">
                  {
                    userInfo?.organization_data?.org_type === '0' ?
                      <img src="/image/school.png" alt="" />
                      : (
                        userInfo?.organization_data?.org_type === '1' ?
                      <img src="/image/companyImg.png" alt="" />:
                      <img src="/image/common.png" alt="" />)
                  }
                  
                  <div>
                    <h5>
                      {userInfo?.organization_data?.text_mark ||
                        userInfo?.organization_data?.name}
                    </h5>
                    <p
                      className={
                        service_validity === 1
                          ? null
                          : service_validity === 2
                          ? 'validity_yellow'
                          : 'validity_red'
                      }
                    >
                      服务有效期：
                      {`${userInfo?.organization_data?.start_date} ～ ${userInfo?.organization_data?.end_date}`}
                    </p>
                    {service_validity === 1 ? null : service_validity === 2 ? (
                      <p className="validity_yellow">
                        英荔教学云服务即将到期，到期后部分教学功能将无法使用，请提前联系销售或致电
                        400-931-8118。
                      </p>
                    ) : service_validity === 3 ? (
                      <p className="validity_red">
                        英荔教学云服务还没开始，请耐心等待。
                      </p>
                    ) : (
                      <p className="validity_red">
                        英荔教学云服务已到期，如需继续使用请联系英荔销售或致电
                        400-931-8118。
                      </p>
                    )}
                  </div>
                </div>
                <Tooltip placement="top" title="点击查看数据报告">
                  {userInfo?.organization_data?.overall_organization_data && moment().format('YYYYMMDD') <= moment(userInfo?.organization_data?.end_date).format('YYYYMMDD') ? (
                    <Link to="/manageCenter/datacenter">
                      <DataBar userInfo={userInfo} />
                    </Link>
                  ) : (
                    <DataBar userInfo={userInfo} />
                  )}
                </Tooltip>
              </div>
            </div>

            <Menu
              className="typeMenu"
              onClick={(values) => {
                props.history.push({ pathname: `/manageCenter/${values.key}` })
                _dispatch(getUser())
              }}
              selectedKeys={[match.params.type]}
              mode="horizontal"
            >
              <Menu.Item key="employee">教师</Menu.Item>
              <Menu.Item key="team">团队</Menu.Item>
              <Menu.Item key="portfolio">作品集</Menu.Item>
              <Menu.Item key="student">学员</Menu.Item>
            </Menu>
          </div>
        </div>
      )}
      <Switch>
        <Route path="/manageCenter/datacenter/" exact>
          <DataCenter />
        </Route>
        <Route path="/manageCenter/team/" exact>
          <Team ref={childRef} />
        </Route>
        <Route path="/manageCenter/team/:id/detail/" exact>
          <TeamItem />
        </Route>
        <Route path="/manageCenter/team/:id/detail/logincode/" exact>
          <ManageLoginCode />
        </Route>
        <Route path="/manageCenter/team/courseManage/:id/" exact>
          <TeamItem />
        </Route>
        <Route path="/manageCenter/employee/">
          <Employee ref={childRef} />
        </Route>
        <Route path="/manageCenter/student/">
          <Student ref={childRef} />
        </Route>
        <Route path="/manageCenter/portfolio/">
          <Portfolio ref={childRef} from_type="管理中心"/>
        </Route>
        <Route path="/manageCenter/*/">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </div>
  )
}

export default withRouter(Manage)
