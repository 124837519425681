import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useSelector, useDispatch } from 'react-redux';
import { 
  getNotifications, 
  markAllNotifications,
  getNotificationsCount,
  checkIsExpires,
  replyInvited
} from '../../utils/request';
import { toDetail } from '../../utils';
import { setUnreadCount } from '../../store/actions';
import { Link } from 'react-router-dom';
import { ExclamationCircleFilled, CloseOutlined } from '@ant-design/icons';
import { Empty, Modal, Spin, Pagination, Tooltip } from 'antd';
const { confirm } = Modal;
dayjs.extend(relativeTime);

function formatVerb(verb) {
  switch (verb) {
    case 'up':
      return '赞';
    case 'favorite':
      return '收藏';
    case 'comment':
      return '评论';
    case 'comment_reply':
      return '回复';
    case 'project_comment_reply':
      return '回复';
    case 'studio_invited':
      return '邀请';
    case 'user_comment':
      return '评论';
    case 'user_comment_reply':
      return '回复';
    case 'has_user_comment_reply':
      return '回复';
    default:
      return verb;
  }
}

const Notification = (props) => {
  const unread = useSelector(state => state.unread);
  const userInfo = useSelector(state => state.userInfo);
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const onChange = (pageNumber,pageSize) => {
    setCurrent(pageNumber)
  };
  
  const onShowSizeChange = (pageNumber,pageSize) => {
    setPageSize(pageSize)
  };
  useEffect(() => {
      getNotificationList()
      getNotificationsTotal()
      return () => {
        dispatch(setUnreadCount(0));
      };
  // eslint-disable-next-line    
  }, [dispatch, current, pageSize]);

  const getNotificationsTotal = () => {
    getNotificationsCount().then(res => {
      setTotal(res.data.count)
    })
  }
  const getNotificationList = () => {
    // 请求消息时绑定校区 id，消息随校区 id 的变化而变化
    getNotifications({ offset: (current - 1) * pageSize, limit: pageSize })
      .then(res => {
        setNotifications(res.data);
        setLoading(false)
          // const msg = res.data[0].tgt_content
        return markAllNotifications();
      })
      .then(res => {
        if (res.data.success) {
          // dispatch(setUnreadCount(0))
        }
      })
      .catch(e => {
      });
  }

  const processMsg = msg => {
    return msg.tgt_content ? { ...JSON.parse(msg.tgt_content.trim()) } : '';
  };

  const transformUserComReply = msg => {
    const user = processMsg(msg);
    return Object.is(user.username, userInfo.username)
      ? '你'
      : user.name || user.username;
  };
  
  const agreeOrNotBox = useRef()

  let timer = null;
  const agreeOrNot = (msg) => {
    if(timer){
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      const { status } = JSON.parse(msg.tgt_content);
      if(status === 'pending'){
        checkIsExpires(msg.tgt_id).then(res=>{
          confirm({
            title: '是否同意？', 
            icon: <ExclamationCircleFilled style={{color:'#0084FF'}}/>, 
            content:
              <>
                同意加入后管理员方可为您分配权限。
                <br/>
                不同意加入校区入口将不再显示。
                <CloseOutlined onClick={() => Modal.destroyAll()} />
              </>,
            cancelText: '不同意加入',
            okText: '同意加入',
            centered: true,
            className:'confirm_has_close',
            width: 480,
            getContainer: () => agreeOrNotBox.current,
            onOk: async () => {
              // request
              replyInvited(msg.tgt_id, {
                status: true,
                id:msg.id
              }).then(res=>{
                window.location.reload()
              })
              // getNotificationList()
            },
            onCancel: () => {
              replyInvited(msg.tgt_id, {
                status: false,
                id:msg.id
              }).then(res=>{
                window.location.reload()
              })
            },
            
          });
        }).catch(err=>{
          messageExpired()
        })
      }else if(status === 'False'){
        messageReject()
      }else{
        messageResolve()
      }
    }, 500);
  }

  let modal;
  const messageBox = useRef()
  const messageInfo = ({title, content, icon}) => {
    modal = Modal.info({
      title,
      content,
      icon,
      centered: true,
      width: 480,
      className: 'simpleMessage',
      getContainer: () => messageBox.current,
    })
  }
  
  // 各种类型的弹窗
  // eslint-disable-next-line
  const messageExpired = () => messageInfo({
    title: '信息已过期',
    content: 
        <>
          该信息已过期
          <CloseOutlined 
              onClick={() => modal.destroy()} 
              className='closeIcon'
          />
        </>,
    icon: <ExclamationCircleFilled style={{color: '#FAAD14'}} />
  })

  // eslint-disable-next-line
  const messageResolve = () => messageInfo({
    title: '信息已处理',
    content: 
        <>
          您已同意加入该校区
          <CloseOutlined
              onClick={() => modal.destroy()}
              className='closeIcon'
          />
        </>,
    icon: <ExclamationCircleFilled style={{color: '#0084FF'}} />
  })

  // eslint-disable-next-line
  const messageReject = () => messageInfo({
    title: '信息已处理',
    content: 
        <>
          您已拒绝加入该校区
          <CloseOutlined
              onClick={() => modal.destroy()}
              className='closeIcon'
          />
        </>,
    icon: <ExclamationCircleFilled style={{color: '#0084FF'}} />
  })
      
  return (
    <div id="notification" className="container">
      <div className="title">
        消息
        {unread > 0 && <span className="badge">{unread}</span>}
      </div>
        <div className="message">
          
        <Spin spinning={loading}>
          {notifications.length>0 && notifications.map((msg, index) =>
            {
              return [
                'up',
                'favorite',
                'comment',
                'comment_reply',
                'project_comment_reply',
                'user_comment',
                'user_comment_reply',
                'has_user_comment_reply',
                'welcome',
                'site_notification',
                'invited_user_in_studio',
                'studioactivity',
                'allow_comments',
                'invited_user_in_campus',
                'reply_to_invited_campus',
                'studio_project_add',
                'studio_project_delete',
                'promotion_user_in_studio',
                'delete_user_in_studio',
                'delete_studio',
                'set_up_teacher',
                'set_up_manager',
                'delete_teacher',
                'delete_manager',
                'studio_bulk_add_project'
              ].includes(msg.verb) ? (
                <>
                  {msg.verb !== 'user_comment_reply' ||
                  (msg.verb === 'user_comment_reply' && msg.tgt_content) ? (
                    <div className="msg" key={index}>
                      {['up', 'favorite'].includes(msg.verb) ? (
                        <>
                          <div className="avatar">
                            <Link
                              className="author"
                              to={`/account/home/${msg.actor_username}`}
                              target="_blank"
                            >
                              <img src={msg.actor_avatar} alt={msg.actor_nick} />
                            </Link>
                          </div>
                          <div className="detail">
                            <div className="actor">
                              <div>
                                <Link
                                  className="author"
                                  to={`/account/home/${msg.actor_username}`}
                                  target="_blank"
                                >
                                  {msg.actor_nick || msg.actor_username}
                                </Link>
                                {msg.unread && <span className="newIcon"></span>}
                              </div>
                              <div
                                className="time"
                                title={dayjs(msg.created_at).format(
                                  'YYYY/MM/DD HH:ss'
                                )}
                              >
                                {dayjs(msg.created_at).fromNow()}
                              </div>
                            </div>
                            <div className="content">
                              {formatVerb(msg.verb)}了你的作品{' '}
                               {/* 缺少platform标注 */}
                              <a
                                className="project"
                                style={{ color: '#0084ff' }}
                                href={toDetail(msg.platform, msg.obj_id)}
                                // href={scratchURL + `/projects/${msg.obj_id}/`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {msg.obj_name}
                              </a>
                            </div>
                          </div>
                        </>
                      ) : null}
                      {msg.verb === 'comment' && (
                        <>
                          <div className="avatar">
                            <Link
                              className="author"
                              to={`/account/home/${msg.actor_username}`}
                              target="_blank"
                            >
                              <img src={msg.actor_avatar} alt={msg.actor_nick} />
                            </Link>
                          </div>
                          <div className="detail">
                            <div className="actor">
                              <div>
                                <Link
                                  className="author"
                                  to={`/account/home/${msg.actor_username}`}
                                  target="_blank"
                                >
                                  {msg.actor_nick || msg.actor_username}
                                </Link>
                                <div className="content">
                                  {formatVerb(msg.verb)}了你的作品{' '}
                                  <a
                                    className="project"
                                    // href={
                                    //   scratchURL +
                                    //   `/projects/${msg.tgt_id}/#comments-${msg.obj_id}`
                                    // }
                                    href={toDetail(msg.platform, msg.tgt_id, `#comments-${msg.obj_id}`)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {msg.tgt_name}
                                  </a>
                                  {msg.unread && <span className="newIcon"></span>}
                                </div>
                              </div>
                              <div
                                className="time"
                                title={dayjs(msg.created_at).format(
                                  'YYYY/MM/DD HH:ss'
                                )}
                              >
                                {dayjs(msg.created_at).fromNow()}
                              </div>
                            </div>
                            <div className="comment">{msg.obj_name}</div>
                          </div>
                        </>
                      )}
                      {(msg.verb === 'comment_reply' ||
                        msg.verb === 'project_comment_reply') && (
                        <>
                          <div className="avatar">
                            <Link
                              className="author"
                              to={`/account/home/${msg.actor_username}`}
                              target="_blank"
                            >
                              <img src={msg.actor_avatar} alt={msg.actor_nick} />
                            </Link>
                          </div>
                          <div className="detail">
                            <div className="actor">
                              <div>
                                <Link
                                  className="author"
                                  to={`/account/home/${msg.actor_username}`}
                                  target="_blank"
                                >
                                  {msg.actor_nick || msg.actor_username}
                                </Link>
                                <div className="content">
                                  回复了你
                                  {msg.verb === 'comment_reply' ? '的' : '对'}
                                  作品{' '}
                                  <a
                                    className="project"
                                    // href={
                                    //   scratchURL +
                                    //   `/projects/${msg.tgt_id}/#comments-${msg.obj_id}`
                                    // }
                                    href={toDetail(msg.platform, msg.tgt_id, `#comments-${msg.obj_id}`)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {msg.tgt_name}
                                  </a>{' '}
                                  的评论
                                  {msg.unread && <span className="newIcon"></span>}
                                </div>
                              </div>
                              <div
                                className="time"
                                title={dayjs(msg.created_at).format(
                                  'YYYY/MM/DD HH:ss'
                                )}
                              >
                                {dayjs(msg.created_at).fromNow()}
                              </div>
                            </div>
                            <div className="comment">{msg.obj_name}</div>
                          </div>
                        </>
                      )}
                      {msg.verb === 'user_comment' ||
                      msg.verb === 'has_user_comment_reply' ? (
                        <>
                          <div className="avatar">
                            <Link
                              className="author"
                              to={`/account/home/${msg.actor_username}`}
                              target="_blank"
                            >
                              <img src={msg.actor_avatar} alt={msg.actor_nick} />
                            </Link>
                          </div>
                          <div className="detail">
                            <div className="actor">
                              <div>
                                <Link
                                  className="author"
                                  to={`/account/home/${msg.actor_username}`}
                                  target="_blank"
                                >
                                  {msg.actor_nick || msg.actor_username}
                                </Link>
                                <div className="content">
                                  {msg.verb === 'user_comment' ? (
                                    <>
                                      对
                                      <a
                                        className=""
                                        href={`/account/home/#comments-${msg.obj_id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        你的个人资料
                                      </a>
                                      发布了留言
                                    </>
                                  ) : (
                                    <>
                                      {formatVerb(msg.verb)}了
                                      <a
                                        className=""
                                        href={`/account/home/#comments-${msg.obj_id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        你的个人资料
                                      </a>
                                      的留言
                                    </>
                                  )}
                                  {msg.unread && <span className="newIcon"></span>}
                                </div>
                              </div>
                              <div
                                className="time"
                                title={dayjs(msg.created_at).format(
                                  'YYYY/MM/DD HH:ss'
                                )}
                              >
                                {dayjs(msg.created_at).fromNow()}
                              </div>
                            </div>
                            <div className="comment">{msg.obj_name}</div>
                          </div>
                        </>
                      ) : null}
                      {msg.verb === 'user_comment_reply' ? (
                        <>
                          <div className="avatar">
                            <Link
                              className="author"
                              to={`/account/home/${msg.actor_username}`}
                              target="_blank"
                            >
                              <img src={msg.actor_avatar} alt={msg.actor_nick} />
                            </Link>
                          </div>
                          <div className="detail">
                            <div className="actor">
                              <div>
                                <Link
                                  className="author"
                                  to={`/account/home/${msg.actor_username}`}
                                  target="_blank"
                                >
                                  {msg.actor_nick || msg.actor_username}
                                </Link>
                                <div className="content">
                                  {formatVerb(msg.verb)}了你在
                                  <a
                                    className=""
                                    href={`/account/home/${
                                      processMsg(msg).username
                                    }/#comments-${msg.obj_id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {transformUserComReply(msg)}
                                    的个人资料
                                  </a>
                                  里的留言
                                  {msg.unread && <span className="newIcon"></span>}
                                </div>
                              </div>
                              <div
                                className="time"
                                title={dayjs(msg.created_at).format(
                                  'YYYY/MM/DD HH:ss'
                                )}
                              >
                                {dayjs(msg.created_at).fromNow()}
                              </div>
                            </div>
                            <div className="comment">{msg.obj_name}</div>
                          </div>
                        </>
                      ) : null}
                      {msg.verb === 'welcome' ||
                      msg.verb === 'site_notification' ? (
                        <div className="admin">
                          <div className="avatar">
                            <img src="/image/头像.png" alt="admin" />
                          </div>
                          <div className="detail">
                            <div className="content">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: msg.description || msg.verb
                                }}
                              ></span>
                              {msg.unread && <span className="newIcon"></span>}
                            </div>
                            <div
                              className="time"
                              title={dayjs(msg.created_at).format(
                                'YYYY/MM/DD HH:ss'
                              )}
                            >
                              {dayjs(msg.created_at).fromNow()}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {msg.verb === 'invited_user_in_studio' ? (
                        <div className='admin' onClick={(e) => {
                          e.persist()
                          if (e.target.className === 'author' || e.target.className === 'obj_name') {
                            return
                          }
                          window.location.replace(`/manage/portfolio/${msg.obj_id}/?type=area&toMember=1`)
                        }}>
                          <div className="avatar">
                            <Link
                              className="author"
                              to={`/account/home/${msg.actor_username}`}
                              target="_blank"
                            >
                              <img src={msg.actor_avatar} alt={msg.actor_nick} />
                            </Link>
                          </div>
                          <div className="detail">
                            <div className='content'>
                              <Link
                                className="author"
                                to={`/account/home/${msg.actor_username}`}
                                target="_blank"
                              >
                                {msg.actor_regional_username || msg.actor_nick || msg.actor_username}
                              </Link>
                              <span className="invite">
                                &nbsp;邀请你来帮助管理作品集&nbsp;

                              </span>
                              <Link
                                className="obj_name"
                                style={{ color: '#0084ff' }}
                                to={msg.org_id !== 0 ? `/manage/portfolio/${msg.obj_id}/?type=area` : `/manage/portfolio/${msg.obj_id}`}
                              >
                                {msg.obj_name}
                              </Link>
                              &nbsp;
                              <span className="invite">
                                ，点击进入作品集，在「成员」分页内接受邀请
                              </span>
                            </div>
                          </div>
                          <div
                            className="time"
                            title={dayjs(msg.created_at).format('YYYY/MM/DD HH:ss')}
                          >
                            {dayjs(msg.created_at).fromNow()}
                          </div>
                        </div>
                      ) : null}
                      {msg.verb === 'studioactivity' ? (
                        <div className='admin'>
                          <div className="avatar">
                            <Link
                              className="author"
                              to={`/account/home/${msg.actor_username}`}
                              target="_blank"
                            >
                              <img src={msg.actor_avatar} alt={msg.actor_nick} />
                            </Link>
                          </div>
                          <div className="detail">
                            <div className='content'>
                              <Link
                                className="author"
                                to={`/account/home/${msg.actor_username}`}
                                target="_blank"
                              >
                                {msg.actor_nick || msg.actor_username}
                              </Link>
                              <span className="activity">&nbsp;修改了作品集&nbsp;</span>
                              <Link
                                style={{ color: '#0084ff' }}
                                to={msg.org_id !== 0 ? `/manage/portfolio/${msg.obj_id}/?type=area` : `/manage/portfolio/${msg.obj_id}`}
                              >
                                {msg.obj_name}
                              </Link>
                              &nbsp;<span className="activity">的基本信息</span>
                            </div>
                          </div>
                          <div
                            className="time"
                            title={dayjs(msg.created_at).format('YYYY/MM/DD HH:ss')}
                          >
                            {dayjs(msg.created_at).fromNow()}
                          </div>
                        </div>
                      ) : null}
                      {msg.verb === 'invited_user_in_campus' && <div className="admin">
                              <div className="avatar">
                                  <img src={JSON.parse(msg.tgt_content).logo} alt={msg.actor_nick} />
                              </div>
                              <div className="detail">
                                <p>{msg.tgt_name}</p>
                                <div>
                                  <span className="invite">
                                      管理员邀请您加入「{msg.tgt_name}」，
                                  </span>
                                  <span className="agreeOrNot"
                                        onClick={()=>{ agreeOrNot(msg) }}
                                  >
                                      是否同意？
                                  </span>
                                </div>
                              </div>
                              <div
                                  className="time"
                                  title={dayjs(msg.created_at).format('YYYY/MM/DD HH:ss')}
                              >
                                  {dayjs(msg.created_at).fromNow()}
                              </div>
                          </div>
                      }
                      {msg.verb === 'reply_to_invited_campus' && <div className="admin">
                          <div className="avatar">
                            <img src={JSON.parse(msg.tgt_content).logo} alt="admin" />
                          </div>
                          <div className="detail">
                            <div className="content">
                              <span>管理员你好，{msg.actor_name}已{JSON.parse(msg.tgt_content).status==='False'?'拒绝':'同意'}加入「{msg.tgt_name}」</span>
                            </div>
                            <div
                              className="time"
                              title={dayjs(msg.created_at).format(
                                'YYYY/MM/DD HH:ss'
                              )}
                            >
                              {dayjs(msg.created_at).fromNow()}
                            </div>
                          </div>
                        </div>}
                      {msg.verb === 'allow_comments' && <div className="admin">
                          <div className="avatar">
                            <img src={msg.tgt_content} alt="admin" />
                          </div>
                          <div className="detail">
                            <div className="content">
                                你已被<Link
                                  style={{ color: '#0084ff' }}
                                  to={`/account/home/${msg.actor_username}`}
                                >&nbsp;{msg.actor_nick || msg.actor_name || msg.actor_username}&nbsp;
                                </Link>
                                {
                                    msg.tgt_name === 'True' ? '解除评论限制，后续请保持文明发言' : '禁止评论，如需解除限制，请与对方沟通'
                                  }
                            </div>
                          </div>
                            <div
                              className="time"
                              title={dayjs(msg.created_at).format(
                                'YYYY/MM/DD HH:ss'
                              )}
                            >
                              {dayjs(msg.created_at).fromNow()}
                            </div>
                        </div>}
                      {msg.verb === 'studio_project_add' && <div className="admin">
                        <div className="avatar">
                          <img src={msg.actor_avatar} alt="admin" />
                        </div>
                        <div className="detail">
                          <div className="content">
                            <Link
                              style={{ color: '#0084ff' }}
                              to={`/account/home/${msg.actor_username}`}
                            >{msg.actor_nick || msg.actor_name || msg.actor_username}
                            </Link>&nbsp;向作品集
                            <Link
                              style={{ color: '#0084ff' }}
                              to={msg.org_id !== 0 ? `/manage/portfolio/${msg.obj_id}/?type=area`: `/manage/portfolio/${msg.obj_id}`}
                            >
                              &nbsp;{msg.obj_name}&nbsp;
                            </Link> 新增了作品
                            <a
                              className="project"
                              // href={scratchURL + `/projects/${msg.tgt_id}/`}
                              href={toDetail(msg.platform, msg.tgt_id)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              &nbsp;{msg.tgt_name}
                            </a>
                          </div>
                        </div>
                        <div
                          className="time"
                          title={dayjs(msg.created_at).format(
                            'YYYY/MM/DD HH:ss'
                          )}
                        >
                          {dayjs(msg.created_at).fromNow()}
                        </div>
                      </div>}
                      {msg.verb === 'studio_project_delete' && <div className="admin">
                        <div className="avatar">
                          <img src={msg.actor_avatar} alt="admin" />
                        </div>
                        <div className="detail">
                          <div className="content">
                            <Link
                              style={{ color: '#0084ff' }}
                              to={`/account/home/${msg.actor_username}`}
                            >{msg.actor_nick || msg.actor_name || msg.actor_username}
                            </Link>&nbsp;从作品集
                            &nbsp;<Link
                              style={{ color: '#0084ff' }}
                              to={msg.org_id !== 0 ? `/manage/portfolio/${msg.obj_id}/?type=area` : `/manage/portfolio/${msg.obj_id}`}
                            >
                              {msg.obj_name}
                            </Link>&nbsp; 移除了作品
                            &nbsp;<a
                              className="project"
                              // href={scratchURL + `/projects/${msg.tgt_id}/`}
                              href={toDetail(msg.platform, msg.tgt_id)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {msg.tgt_name}
                            </a>
                          </div>
                        </div>
                        <div
                          className="time"
                          title={dayjs(msg.created_at).format(
                            'YYYY/MM/DD HH:ss'
                          )}
                        >
                          {dayjs(msg.created_at).fromNow()}
                        </div>
                      </div>}
                      {msg.verb === 'promotion_user_in_studio' && <div className="admin">
                        <div className="avatar">
                          <img src={msg.actor_avatar} alt="admin" />
                        </div>
                        <div className="detail">
                          <div className="content">
                            <Link
                              style={{ color: '#0084ff' }}
                              to={`/account/home/${msg.actor_username}`}
                            >{msg.actor_nick || msg.actor_name || msg.actor_username}
                            </Link>&nbsp;将你在作品集
                            &nbsp;<Link
                              style={{ color: '#0084ff' }}
                              to={msg.org_id !== 0 ? `/manage/portfolio/${msg.obj_id}/?type=area` : `/manage/portfolio/${msg.obj_id}`}
                            >
                              {msg.obj_name}
                            </Link>&nbsp; 升职为管理者
                          </div>
                        </div>
                        <div
                          className="time"
                          title={dayjs(msg.created_at).format(
                            'YYYY/MM/DD HH:ss'
                          )}
                        >
                          {dayjs(msg.created_at).fromNow()}
                        </div>
                      </div>}
                      {msg.verb === 'delete_user_in_studio' && <div className="admin">
                        <div className="avatar">
                          <img src={msg.actor_avatar} alt="admin" />
                        </div>
                        <div className="detail">
                          <div className="content">
                            <Link
                              style={{ color: '#0084ff' }}
                              to={`/account/home/${msg.actor_username}`}
                            >{msg.actor_nick || msg.actor_name || msg.actor_username}
                            </Link>&nbsp;将你从作品集
                            &nbsp;<Link
                              style={{ color: '#0084ff' }}
                              to={msg.org_id !== 0 ? `/manage/portfolio/${msg.obj_id}/?type=area` : `/manage/portfolio/${msg.obj_id}`}
                            >
                              {msg.obj_name}
                            </Link>&nbsp; 移出
                          </div>
                        </div>
                        <div
                          className="time"
                          title={dayjs(msg.created_at).format(
                            'YYYY/MM/DD HH:ss'
                          )}
                        >
                          {dayjs(msg.created_at).fromNow()}
                        </div>
                      </div>}
                      {msg.verb === 'delete_studio' && <div className="admin">
                        <div className="avatar">
                          <img src={msg.actor_avatar} alt="admin" />
                        </div>
                        <div className="detail">
                          <div className="content">
                          <Link
                              style={{ color: '#0084ff' }}
                              to={`/account/home/${msg.actor_username}`}
                            >{msg.actor_nick || msg.actor_name || msg.actor_username}&nbsp;
                            </Link>删除了&nbsp;{msg.tgt_content}&nbsp;的区域作品集&nbsp;{msg.obj_name}
                          </div>
                        </div>
                        <div
                          className="time"
                          title={dayjs(msg.created_at).format(
                            'YYYY/MM/DD HH:ss'
                          )}
                        >
                          {dayjs(msg.created_at).fromNow()}
                        </div>
                      </div>}
                      {msg.verb === 'set_up_teacher' && <div className="admin">
                        
                        <div className="avatar">
                          <img src={msg.actor_avatar} alt="admin" />
                        </div>
                        <div className="detail">
                          <div className="content">
                            你已被设为 {msg.tgt_name} 的教师，你可以创建团队、管理校区学员
                          </div>
                        </div>
                        <div
                          className="time"
                          title={dayjs(msg.created_at).format(
                            'YYYY/MM/DD HH:ss'
                          )}
                        >
                          {dayjs(msg.created_at).fromNow()}
                        </div>
                      </div>}
                      {msg.verb === 'set_up_manager' && <div className="admin">
                        <div className="avatar">
                          <img src={msg.tgt_content} alt="admin" />
                        </div>
                        <div className="detail">
                          <div className="content">
                            你已被设为 {msg.tgt_name} 的校区管理员。你将和其他教师共享全校区的使用量，同时会获得全部课程权限
                          </div>
                        </div>
                        <div
                          className="time"
                          title={dayjs(msg.created_at).format(
                            'YYYY/MM/DD HH:ss'
                          )}
                        >
                          {dayjs(msg.created_at).fromNow()}
                        </div>
                      </div>}
                      {msg.verb === 'delete_teacher' && <div className="admin">
                        
                        <div className="avatar">
                          <img src={msg.actor_avatar} alt="admin" />
                        </div>
                        <div className="detail">
                          <div className="content">
                            你已被撤销 {msg.tgt_name} 的教师身份
                          </div>
                        </div>
                        <div
                          className="time"
                          title={dayjs(msg.created_at).format(
                            'YYYY/MM/DD HH:ss'
                          )}
                        >
                          {dayjs(msg.created_at).fromNow()}
                        </div>
                      </div>}
                      {msg.verb === 'delete_manager' && <div className="admin">
                        <div className="avatar">
                          <img src={msg.tgt_content} alt="admin" />
                        </div>
                        <div className="detail">
                          <div className="content">
                            你已被撤销 {msg.tgt_name} 的管理员身份，你的使用量权限会保留，但课程权限已被清空，需要管理员重新分配
                          </div>
                        </div>
                        <div
                          className="time"
                          title={dayjs(msg.created_at).format(
                            'YYYY/MM/DD HH:ss'
                          )}
                        >
                          {dayjs(msg.created_at).fromNow()}
                        </div>
                      </div>}
                      {msg.verb === 'studio_bulk_add_project' && <div className="admin">
                        <div className="avatar">
                          <img src={msg.actor_avatar} alt="admin" />
                        </div>
                        <div className="detail">
                          <div className="content">
                          <Link
                              style={{ color: '#0084ff' }}
                              to={`/account/home/${msg.actor_username}`}
                            >{msg.actor_nick || msg.actor_name || msg.actor_username}
                            </Link> 向作品集&nbsp; <Link
                              style={{ color: '#0084ff' }}
                              to={msg.org_id !== 0 ? `/manage/portfolio/${msg.obj_id}/?type=area` : `/manage/portfolio/${msg.obj_id}`}
                            >
                              {msg.obj_name}
                            </Link>
                            &nbsp; 新增了&nbsp; 
                            <Tooltip overlayStyle={{ maxWidth: '520px' }} placement="top" title={`作品名单：${msg.tgt_name}、${msg.tgt_content.replaceAll(',','、')}`}>
                            <a
                              className="project"
                              // href={scratchURL + `/projects/${msg.tgt_id}/`}
                              href={toDetail(msg.platform, msg.tgt_id)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {msg.tgt_name}
                            </a>&nbsp; 
                            </Tooltip>
                                等 {msg.tgt_content.split(',').length + 1} 个作品
                          </div>
                        </div>
                        <div
                          className="time"
                          title={dayjs(msg.created_at).format(
                            'YYYY/MM/DD HH:ss'
                          )}
                        >
                          {dayjs(msg.created_at).fromNow()}
                        </div>
                      </div>}
                      
                    </div>
                  ) : null}
                </>
              ) : null
            }
          )}
          {
            notifications.length === 0 && <div className='empty'>
              <Empty
                image="/image/empty-page.png"
                description="暂无消息"
              />
            </div>
          }
        </Spin>
        <div className='pagination'>
          <Pagination showQuickJumper current={current} showSizeChanger={true} pageSize={pageSize} pageSizeOptions={['50', '100', '150', '200']} total={total} onChange={onChange} onShowSizeChange={onShowSizeChange} />
        </div>
        </div>
      
      <div className="messageBox" ref={messageBox}></div>
      <div className="agreeOrNotBox" ref={agreeOrNotBox}></div>
    </div>
  );
};

export default Notification;
