import React, { useState, useCallback, useEffect } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types';
import './style.scss'

const Pagination = ({ defaultCurr = 1, pages, onChange, pattern, ...props }) => {
    const [now, setnow] = useState(defaultCurr)
    const prev = useCallback(() => {
        if (now === 0) return
        setnow(now => now - 1)

    }, [now])
    const next = useCallback(() => {
        if (now === pages || now > pages) return
        setnow(now => now + 1)
    }, [now, pages])
    useEffect(() => {
        setnow(1);
    }, [pattern])
    useEffect(() => {
        onChange && onChange(now)
    }, [onChange, now])

    return (
        <div id="simple-pagination" {...props}>
            <LeftOutlined className={`icon left ${now === 1 ? 'disabled' : ''}`} onClick={prev} />
            <span className="pages">{now || 1}/{pages}</span>
            <RightOutlined className={`icon right ${now === pages || now > pages ? 'disabled' : ''}`} onClick={next} />
        </div>
    )
}

Pagination.propTypes = {
    now: PropTypes.number,
    pages: PropTypes.number,
    onChange: PropTypes.func
}

export default Pagination
