import React, { useRef, useCallback, useState, useEffect } from 'react';
import './style.scss';
import { Input } from 'antd';
// import { DownOutlined, UpOutlined } from '@ant-design/icons';

const Filter = ({
  defaultRes,
  checkedRes,
  searchPlacehloder,
  onChange,
  onSearch,
  types = [],
  selectedKeys,
  filtersName = [],
  filtersType = []
}) => {
  checkedRes = checkedRes || {
    type: types[0].value,
    ...filtersName
      .map(item => ({ [item]: 'all' }))
      .reduce((total, curr) => ({ ...total, ...curr }), {})
  };

  const searchRef = useRef();
  const [showMore, setShowMore] = useState(new Array(filtersName.length));
  const [isShowMore, setIsShowMore] = useState([new Array(filtersName.length)]);
  const [filterRes, setFilterRes] = useState(
    defaultRes || {
      type: types[0].value,
      ...filtersName
        .map(item => ({ [item]: 'all' }))
        .reduce((total, curr) => ({ ...total, ...curr }), {})
    }
  );
  const filterResRef = useRef();

  useEffect(() => {
    if (filterResRef.current === JSON.stringify(filterRes)) return;
    // 第二页状态下搜索时 跳转到第一页
    let page;
    let searchVal;
    if (filterResRef.current) {
      try {
        page = JSON.parse(filterResRef.current).page;
        searchVal = JSON.parse(filterResRef.current).search;
        if (!searchVal && filterRes.search && page && page !== '1') {
          filterRes.page = '1';
        }
      } catch (e) {
        console.log(e);
      }
    }

    onChange && onChange(filterRes);
    filterResRef.current = JSON.stringify(filterRes);
  }, [filterRes, onChange]);

  useEffect(() => {
    //     back时 使 filterResRef 与 checkedRes  同步
    let flag = true;
    flag =
      Object.keys(JSON.parse(filterResRef.current)).length ===
      Object.keys(checkedRes).length;
    Object.keys(checkedRes).forEach(key => {
      if (!flag) return;
      flag =
        checkedRes[key].toString() ===
        JSON.parse(filterResRef.current)[key].toString();
    });
    if (flag) return;
    setFilterRes(checkedRes);
  }, [checkedRes]);

  const onSearchHandler = useCallback(val => {
    setFilterRes(prevVal => ({ ...prevVal, search: val }));
  }, []);

  return (
    <div id="filter">
      <div className="actionBar">
        <div className="title">教学课程</div>
        {/* <Menu
                onClick={(values) => {
                    setFilterRes(prev  => {
                        // 清空上次类别的选择
                        if (prev.type !== values.key) {
                            return {
                                type: values.key,
                                ...(filtersName
                                    .map(item => ({[item]: 'all'}))
                                    .reduce((total, curr) => ({...total, ...curr}), {})
                                )
                            }
                        }
                        return {...filterRes, type: values.key}
                    })
                }}
                selectedKeys={selectedKeys}
                mode="horizontal"
            >
                {
                    types.map((item, index) => (
                        <Menu.Item key={item.value}>
                            {item.label}
                        </Menu.Item>
                    ))
                }
            </Menu> */}
        <Input.Search
          ref={searchRef}
          defaultValue={checkedRes.search}
          placeholder={searchPlacehloder}
          onSearch={onSearchHandler}
          style={{ width: 280, borderRadius: '4px' }}
          allowClear={true}
        />
      </div>
      <div className="filters">
        {filtersType[1].length > 0 && (
          <>
            {filtersName.map((filtername, index) => (
                (index === 0 && filtersType[0].length === 0) ? <div></div> :
              <div className="filter" key={index}>
                <div className="name">{filtername}：</div>
                <div
                  className={`types ${
                    showMore[index] === true && !isShowMore[index]
                      ? 'unexpand'
                      : ''
                  }`}
                  ref={ref => {
                    if (!(showMore[index] === undefined) || !ref) return;
                    if (ref.clientHeight > 34) {
                      setShowMore(showMore => {
                        showMore[index] = true;
                        return [...showMore];
                      });
                      setIsShowMore(isShowMore => {
                        isShowMore[index] = false; // 默认收起
                        return [...isShowMore];
                      });
                    } else {
                      setShowMore(showMore => {
                        showMore[index] = false;
                        return [...showMore];
                      });
                    }
                  }}
                >
                  <div
                    onClick={() =>
                      setFilterRes({
                        ...checkedRes,
                        ...{ [filtername]: 'all' }
                      })
                    }
                    className={`typeItem ${
                      checkedRes[filtername] === 'all' ||
                      !checkedRes[filtername]
                        ? 'activity'
                        : ''
                    }`}
                  >
                    全选
                  </div>
                  {filtersType[index].map((type, typeIndex) => (
                    <div
                      onClick={() => {
                        if (type !== 'all') {
                          if (
                            checkedRes[filtername] &&
                            checkedRes[filtername] !== 'all'
                          ) {
                            let nowclicked = type;
                            type = [
                              ...new Set([...checkedRes[filtername], type])
                            ];
                            if (
                              checkedRes[filtername].includes(nowclicked) &&
                              type.length > 1
                            ) {
                              type = type.filter(item => item !== nowclicked);
                            }
                          } else {
                            type = [type];
                          }
                        }
                        setFilterRes({
                          ...checkedRes,
                          ...{ [filtername]: type, page: '1' }
                        });
                      }}
                      className={`typeItem ${
                        Array.isArray(checkedRes[filtername]) &&
                        checkedRes[filtername].includes(type)
                          ? 'activity'
                          : ''
                      }`}
                      key={typeIndex}
                    >
                      {type}
                    </div>
                  ))}
                </div>
                {/* {showMore[index] ? (
                  <div className="more">
                    <Button
                      type="link"
                      onClick={() =>
                        setIsShowMore(value => {
                          value[index] = !value[index];
                          return [...value];
                        })
                      }
                    >
                      {isShowMore[index] ? <UpOutlined /> : <DownOutlined />}
                    </Button>
                  </div>
                ) : null} */}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Filter;
