import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef
} from 'react';
import CommonModal from '../../../../components/commonModal';
import { Button, message, Form, Input } from 'antd';
import '../index.scss';
import { testPassword } from './../../../../utils';
import { patchPassword } from './../../../../utils/api/manageCenter';

const ModifyPassword = (props, ref) => {
  const [visible, setVisible] = useState(false);
  const formRef = useRef();

  useImperativeHandle(ref, () => ({
    modifyPassword: () => setVisible(true)
  }));

  const fetch = data => {
    patchPassword(data)
      .then(() => {
        message.success('修改密码成功');
        setVisible(false);
      })
      .catch(() => {
        message.success('修改密码失败');
      });
  };

  return (
    <CommonModal
      title="修改密码"
      width={422}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      contentNode={
        <div className="createModal mpasseord">
          <Form
            name="password-form"
            onFinish={values => {
              delete values.repectpassword;
              fetch({ ...values, student_id: props.patchId });
            }}
            ref={formRef}
            layout="vertical"
            autoComplete="off"
            preserve="false"
          >
            <Form.Item
              name="password"
              label="新密码"
              rules={[
                { required: true, message: '请输入密码' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || (value && testPassword(value))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      '请输入 6-16 位的密码，仅支持数字、英文、下划线'
                    );
                  }
                })
              ]}
            >
              <Input.Password placeholder="6-16 位密码，区分大小写" />
            </Form.Item>

            <Form.Item
              name="repectpassword"
              label="确认新密码"
              rules={[
                {
                  required: true,
                  message: '请再次输入密码'
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('前后密码不一致');
                  }
                })
              ]}
            >
              <Input.Password placeholder="请再次输入密码" />
            </Form.Item>
            <p>如果学员忘记了登录密码，您可帮助他修改密码。</p>
          </Form>
        </div>
      }
      bottomNode={
        <Button type="primary" onClick={() => formRef.current.submit()}>
          保存
        </Button>
      }
    />
  );
};

export default forwardRef(ModifyPassword);
