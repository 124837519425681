// import Home from './../page/home'
import Login from './../page/login/index';
import Register from './../page/register';
import ResetPassword from './../page/resetpassword';
import Agreement from './../page/agreement';
import Privacypolicy from './../page/privacypolicy';
import Account from './../page/account';
import DataCenter from './../page/account/dataCenter'
import Manage from './../page/manage';
import TeamItem from './../page/manage/team/teamItem';
import TeamDataCenter from './../page/manage/team/dataCenter'
import PortfolioItem from './../page/manage/portfolio/portfolioItem';
import OwnPortfolioItem from '../page/manage/ownportfolio/protfolioitem';
import PortfolioActivity from './../page/manage/portfolio/activity';
import OwnPortfolioActivity from './../page/manage/ownportfolio/activity';
import Project from './../page/project';
import Course from './../page/course';
import NotFound from './../page/404';
import NoAuthority from './../page/403';
import LoginCode from './../page/manage/team/loginCode';
import CourseItem from '../page/course/courseItem';
import ProjectItem from '../page/project/projectItem';
import Notification from '../page/notification';
import Favorites from '../page/favorites';
import Learnhistory from '../page/learnhistory';
import TeachPlan from '../page/teachplan/home';
import TeachPlanManage from '../page/teachplan/manage';
import OtherProject from '../page/account/otherProject';
import Oauth from '../page/oauth';
import Accountsetting from './../page/accountsetting';
import StudentAccountsetting from './../page/accountsetting/studentAccount.js';
import Note from '../page/note';
import CoursePreview from './../page/coursePreview';
import ProjectPreview from './../page/projectPreview';
import DownloadSoftware from './../page/software';
import ManageCenter from './../page/manageCenter';
import ManageTeam from './../page/manageCenter/team';
import ManageTeamItem from './../page/manageCenter/team/teamItem';
import ManageEmployee from './../page/manageCenter/employee';
import ManageLoginCode from './../page/manageCenter/team/loginCode';
import ManageStudent from './../page/manageCenter/student';
import ManageDataCenter from './../page/manageCenter/dataCenter';
import SuccessPage from './../page/login/successPage';
import PlayerPage from './../page/player';

export const routes = [
  {
    path: '/note',
    component: Note
  },
  {
    path: '/login/:code',
    component: Login
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/resetpassword',
    component: ResetPassword
  },
  {
    path: '/agreement',
    component: Agreement
  },
  {
    path: '/privacypolicy',
    component: Privacypolicy
  },
  {
    path: '/projectPreview/:id',
    component: ProjectPreview
  },
  {
    path: '/coursePreview/:id/:unit_id',
    component: CoursePreview
  },
  {
    path: '/oauth',
    redirect: '/oauth/authorize'
  },
  {
    path: '/authorize',
    component: Oauth
  },
  {
    path: '/binding',
    component: Oauth
  },
  {
    path: '/successPage',
    component: SuccessPage
  },
  {
    path: '/playerPage',
    component: PlayerPage
  },
  {
    path: '/404',
    component: NotFound
  },
  {
    path: '/403',
    component: NoAuthority
  }
];

export const authRoutes = [
  {
    path: '/manage/ownportfolio/:id/activity',
    component: OwnPortfolioActivity,
    redirect: '/',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  {
    path: '/manage/ownportfolio/:id/:type',
    component: OwnPortfolioItem
  },
  {
    path: '/manage/portfolio/:id/activity',
    component: PortfolioActivity,
    redirect: '/',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  {
    path: '/manage/portfolio/:id',
    component: PortfolioItem,
    redirect: '/',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  {
    path: '/manage/team/:id/logincode',
    component: LoginCode,
    redirect: '/manage',
    authority: ['teacher', 'manager', 'manager|teacher']
  },
  {
    path: '/manage/team/:code/datacenter',
    component: TeamDataCenter,
    redirect: '/manage',
    authority: ['teacher', 'manager', 'manager|teacher']
  },
  {
    path: '/manage/team/:id',
    component: TeamItem,
    redirect: '/manage',
    authority: ['teacher', 'manager', 'manager|teacher']
  },
  {
    path: '/manage',
    component: Manage,
    redirect: '/',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  {
    path: '/project/:id',
    component: ProjectItem,
    redirect: '/login',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  {
    path: '/project',
    component: Project,
    redirect: '/login',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },

  {
    path: '/otherproject/:name',
    component: OtherProject,
    redirect: '/login',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  {
    path: '/course/:id/:unit_id',
    component: CourseItem,
    redirect: '/login',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  {
    path: '/course',
    component: Course,
    redirect: '/login',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  {
    path: '/notification',
    component: Notification,
    redirect: '/',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  {
    path: '/favorites',
    component: Favorites,
    redirect: '/',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  {
    path: '/learnhistory',
    component: Learnhistory,
    redirect: '/',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  // {
  //   path: '/teachplan/:id',
  //   component: TeachPlan,
  //   redirect: '/login',
  //   authority: ['teacher']
  // },
  {
    path: '/account/datacenter/:username',
    component: DataCenter,
    redirect: '/',
    authority: ['teacher', 'manager', 'manager|teacher']
  },
  {
    path: '/account/datacenter',
    component: DataCenter,
    redirect: '/',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  {
    path: '/account',
    component: Account,
    redirect: '/login',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  {
    path: '/teachplan/manage',
    component: TeachPlanManage,
    redirect: '/login',
    authority: ['teacher', 'manager', 'manager|teacher', 'null']
  },
  {
    path: '/teachplan',
    component: TeachPlan,
    redirect: '/login',
    authority: ['teacher', 'manager', 'manager|teacher', "null"]
  },
  {
    path: '/accountsetting',
    component: Accountsetting,
    redirect: '/',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  {
    path: '/studentAccountsetting',
    component: StudentAccountsetting,
    redirect: '/',
    authority: ['student']
  },

  {
    path: '/downloadsoftware',
    component: DownloadSoftware,
    redirect: '/',
    authority: ['student', 'teacher', 'manager', 'manager|teacher','null']
  },
  {
    path: '/manageCenter',
    component: ManageCenter,
    redirect: '/',
    authority: ['manager', 'manager|teacher']
  },
  {
    path: '/manageCenter/datacenter',
    component: ManageDataCenter,
    redirect: '/manageCenter',
    authority: ['manager', 'manager|teacher']
  },
  {
    path: '/manageCenter/team',
    component: ManageTeam,
    redirect: '/manageCenter',
    authority: ['manager', 'manager|teacher']
  },
  {
    path: '/manageCenter/team/:id/detail',
    component: ManageTeamItem,
    redirect: '/manageCenter',
    authority: ['manager', 'manager|teacher']
  },
  {
    path: '/manageCenter/team/:id/detail/logincode',
    component: ManageLoginCode,
    redirect: '/manageCenter',
    authority: ['manager', 'manager|teacher']
  },
  {
    path: '/manageCenter/employee',
    component: ManageEmployee,
    redirect: '/manageCenter',
    authority: ['manager', 'manager|teacher']
  },
  {
    path: '/manageCenter/student',
    component: ManageStudent,
    redirect: '/manageCenter',
    authority: ['manager', 'manager|teacher']
  },
  {
    path: '/manageCenter/team/courseManage/:id',
    component: ManageTeamItem,
    redirect: '/manageCenter',
    authority: ['manager', 'manager|teacher']
  }
];

export const loginedRouter = [
  {
    path: '/login',
    redirect: '/',
    component: Login
  },
  {
    path: '/register',
    redirect: '/',
    component: Register
  }
];

