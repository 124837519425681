import React, { useRef } from "react";
import "./style.scss";
import {
  Route,
  useRouteMatch,
  withRouter,
  Redirect,
  Switch,
} from "react-router-dom";
import { Menu } from "antd";
import Team from "./team";
import OwnPortfolio from "./ownportfolio";
import Portfolio from "./portfolio";
import Project from "./project";
// import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
// import { scratchURL } from "./../../utils";

const Manage = (props) => {
  const match = useRouteMatch("/manage/:type");
  const isTeacher = useSelector((state) => state.isTeacher);
  const userInfo = useSelector((state) => state.userInfo);
  const isStudent = userInfo.user_type.includes('student');
  const isNoRole = userInfo.user_type.includes('null');
  const childRef = useRef();
  if (!match) {
    return <Redirect to="/manage/project" />;
  }

  return (
    <div id="manage">
      <div className="header">
        <div className="container">
          <h1>
            <div>我的管理</div>
            {/* <div className="btn">
              {match.params.type === "project" ? (
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  size="large"
                  onClick={create}
                >
                  新建作品
                </Button>
              ) : null} 
            </div> */}
          </h1>
          {
            /* //   match.params.type === "portfolio" &&
              //   portfolioData.length &&
              //   isTeacher ? (
              //   <React.Fragment>
              //     <Tooltip
              //       placement="left"
              //       title={() => {
              //         if (userInfo?.team_data?.max_team_studios_num === 0) {
              //           return "当前账户无法新建作品集，如需使用此功能，请致电 400-931-8118";
              //         } else if (portfolioFlag) {
              //           // 假设字段值 teacher 为校区教师，校区管理员 OAdministrator /非校区教师 outTeacher
              //           if (userInfo?.user_type === "teacher") {
              //             return "当前添加作品集的数量已达上限，如需增加作品集数量，请联系校区管理员";
              //           } else if (
              //             userInfo?.user_type === "OAdministrator" ||
              //             userInfo?.user_type === "outTeacher"
              //           ) {
              //             return "当前添加作品集的数量已达上限，如需增加作品集数量，请致电 400-931-8118";
              //           }
              //         } else if (
              //           portfolioTotal > 0 &&
              //           portfolioTotal <
              //             userInfo?.team_data?.max_team_studios_num
              //         ) {
              //           return `当前还可以创建 ${
              //             userInfo?.team_data?.max_team_studios_num -
              //             portfolioTotal
              //           } 个作品集`;
              //         }
              //         // else {
              //         //   return `当前账户可以创建 ${userInfo?.team_data?.max_team_studios_num} 个团队作品集`;
              //         // }

              //         /** Before the change
              //          * 
              //           if (userInfo?.team_data?.max_team_studios_num === 0) {
              //             return '当前账户无法新建作品集，如需使用此功能，请致电 400-931-8118';
              //           } else if (portfolioFlag) {
              //             return '当前账户新建作品集的数量已达上限，如需增加作品集数量，请致电 400-931-8118';
              //           } else {
              //             return `当前账户可以创建 ${userInfo?.team_data?.max_team_studios_num} 个团队作品集`;
              //           }
              //      */
            //       }}
            //     >
            //       <img
            //         src="/image/icon_question.png"
            //         alt="question"
            //         className="tip"
            //       />
            //     </Tooltip>
            //     <Button
            //       icon={<PlusOutlined />}
            //       type="primary"
            //       size="large"
            //       onClick={create}
            //       disabled={portfolioFlag}
            //     >
            //       新建作品集
            //     </Button>
            //   </React.Fragment>
            // ) : null}
            //   {match.params.type === "ownportfolio" ? (
            //     <Button
            //       icon={<PlusOutlined />}
            //       type="primary"
            //       size="large"
            //       onClick={create}
            //     >
            //       新建作品集
            //     </Button>
            //   ) : null}
            //  {match.params.type === "team" ? (
            //     <React.Fragment>
            //       <Tooltip
            //         placement="left"
            // title={`当前账户可以创建 ${userInfo?.team_data?.max_team} 个 ${userInfo?.team_data?.max_team_people} 人以下的团队`}
            /* Before the change 
                    // title={() => {
                    //   if (userInfo?.team_data?.max_team === 0) {
                    //     return "当前账户无法新建团队，如需使用此功能，请致电 400-931-8118";
                    //   } else if (teamCount) {
                    //     return "当前账户新建团队的数量已达上限，如需增加团队数量，请致电 400-931-8118";
                    //   } else {
                    //     return `当前账户可以创建 ${userInfo?.team_data?.max_team} 个 ${userInfo?.team_data?.max_team_people} 人以下的团队`;
                    //   }
                    // }}

                   
                    overlayClassName="teamFloatingTooltip"
                  >
                    <img
                      src="/image/icon_question.png"
                      alt="question"
                      className="tip"
                    />
                  </Tooltip>
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    size="large"
                    onClick={create}
                    disabled={teamCount}
                  >
                    新建团队
                  </Button>
                </React.Fragment>
              ) : null} */
          }

          <Menu
            className="typeMenu"
            onClick={(values) => {
              props.history.push({ pathname: `/manage/${values.key}` });
            }}
            selectedKeys={[match.params.type]}
            mode="horizontal"
          >
            <Menu.Item key="project">作品</Menu.Item>
            {/* {isTeacher ? ( <Menu.Item key="ownportfolio">个人作品集</Menu.Item> ) : null} */}

            {JSON.stringify(userInfo?.organization_data) !== '{}' && !isStudent ? <Menu.Item key="areaPortfolio">区域作品集</Menu.Item> : null}

            {!isNoRole || (isNoRole && userInfo.studio_count > 0) ? <Menu.Item key="portfolio">作品集</Menu.Item> : null}

            {isTeacher ? <Menu.Item key="team">团队</Menu.Item> : null}
          </Menu>
        </div>
      </div>
      {isTeacher !== undefined ? (
        <Switch>
          <Route path="/manage/team/">
            {isTeacher ? <Team ref={childRef} /> : <Redirect to="/manage/" />}
          </Route>
          <Route path="/manage/ownportfolio/">
            <OwnPortfolio ref={childRef} />
          </Route>
          <Route path="/manage/areaPortfolio/">
            <Portfolio
              ref={childRef}
              type="area"
            />
          </Route>
          <Route path="/manage/portfolio/">
            <Portfolio
              ref={childRef}
            // onUpdate={setPortfolioData}
            />
          </Route>
          <Route path="/manage/project/">
            <Project />
          </Route>
          <Route path="/manage/*/">
            <Redirect to="/404" />
          </Route>
        </Switch>
      ) : null}
    </div>
  );
};

export default withRouter(Manage);
