import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect
} from 'react';

import Icon from './../../../../components/icon';
import './style.scss';
import { Form, Modal, Input, message, Radio } from 'antd';
import { deBounce } from '../../../../utils';
import { patchTeamInfo } from '../../../../utils/api/manageCenter';

import { useSelector } from 'react-redux'
const ModifyTeamInfo = (props, ref) => {
  const userInfo = useSelector((state) => state.userInfo)
  const { patchItem, fetchList } = props;
  const [modifyVisible, setModifyVisible] = useState(false);
  const formRef = useRef();
  const [imageFile, setImageFile] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    description: ''
  });
  useImperativeHandle(ref, () => ({
    modifyTeamInfoHandler: () => setModifyVisible(true)
  }));

  useEffect(() => {
    if (patchItem && modifyVisible) {
      setFormData({
        name: patchItem.name,
        description: patchItem.description,
        oj_platform: patchItem.oj_platform,
        exam_platform: patchItem.exam_platform
      });
      formRef.current.setFieldsValue({
        name: patchItem.name,
        description: patchItem.description,
        oj_platform: patchItem.oj_platform,
        exam_platform: patchItem.exam_platform
      });
    }
  }, [modifyVisible, patchItem]);
  const fetchModify = values => {
    values.from_type = '管理中心'
    patchTeamInfo(patchItem.code, values)
      .then(res => {
        message.success(res.data.message);

        setModifyVisible(false);
        fetchList();
      })
      .catch(e => {
        if (typeof e.response.data === 'string') return;
        if (e.response.status === 400) {
          message.warning(e.response.data.message);
        } else {
          message.error('修改团队信息失败');
        }
      });
  };

  return (
    <Modal
      title="修改团队信息"
      width="374px"
      className="createModalCss teamModal"
      visible={modifyVisible}
      closeIcon={
        <img src="/image/close.png" alt="close" width="14px" height="14px" />
      }
      onCancel={() => {
        setModifyVisible(false);
        // setChangeTeamId(null);
      }}
      centered={true}
      bodyStyle={{ borderRadius: '1em' }}
      destroyOnClose={true}
      okText="保存"
      onOk={deBounce(() => {
        formRef.current.submit();
      }, 300)}
      maskClosable={false}
      forceRender={true} // 解决form.current为null,强制渲染
    >
      <Form
        ref={formRef}
        initialValues={formData}
        layout="vertical"
        onValuesChange={(value, allValue) => setFormData(allValue)}
        onFinish={value => {
          let curImageFile;
          if (imageFile.includes('http')) {
            let filearr = imageFile.split('/');
            curImageFile = filearr[filearr.length - 1];
          }
          Object.assign(value, { cover: curImageFile || imageFile });

          fetchModify(value);
        }}
        name="teamModify"
      >
        <div
          className="icon"
          style={{ textAlign: 'center', marginBottom: '16px' }}
        >
          <Icon
            defaultImg={patchItem?.cover}
            onLoad={setImageFile}
            random={true}
            onChange={setImageFile}
            type="team"
          />
        </div>
        <Form.Item
          label="团队名称"
          colon={false}
          name="name"
          rules={[
            { required: true, message: '请输入团队名称' },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || (value && !/[,|，]/g.test(value))) {
                  return Promise.resolve();
                }
                return Promise.reject('团队名称不能输入逗号「，」「,」');
              }
            })
          ]}
        >
          <Input
            maxLength={15}
            suffix={
              <span className="suffix">{`${Math.min(
                formData.name.length,
                15
              )}/15`}</span>
            }
          />
        </Form.Item>
        <Form.Item
          colon={false}
          label={<span> 一句话描述</span>}
          style={{marginBottom:'0px'}}
          name="description"
          rules={[{ required: false }]}
        >
          <Input.TextArea
            maxLength="100"
            onChange={e => {
              // if (e.target.value.length > 100) {
              //     formRef.current.setFieldsValue({description: e.target.value.slice(0, 100)})
              // }
            }}
            style={{ height: '120px', resize: 'none' }}
          />
        </Form.Item>
        <div className="textAreaSuffix">{`${
          formData.description.length > 100 ? 100 : formData.description.length
        }/100`}</div>
        {// eslint-disable-next-line
          userInfo.organization_data.oj_platform == 2 && <Form.Item
            colon={false}
            label="编程题库："
            name="oj_platform"
            className='questionType'
            rules={[{ required: false }]}
          >
            <Radio.Group >
              <Radio value={2}>开启</Radio>
              <Radio value={1}>不开启</Radio>
            </Radio.Group>
          </Form.Item>
        }
        {// eslint-disable-next-line
          userInfo.organization_data.exam_platform == 2 && <Form.Item
            colon={false}
            label="客观题库："
            name="exam_platform"
            className='questionType'
            rules={[{ required: false }]}
          >
            <Radio.Group
              className='questionType'>
              <Radio value={2}>开启</Radio>
              <Radio value={1}>不开启</Radio>
            </Radio.Group>
          </Form.Item>
        }
      </Form>
    </Modal>
  );
};

export default forwardRef(ModifyTeamInfo);
