import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
} from 'react';
import Icon from './../../../../components/icon';
import './style.scss';
import { Table, Modal, Button, Tooltip } from 'antd';
import { LeftOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import {
  getPortfolioNotifications,
  getAreaPortfolioNotifications,
  getPortfolioDetail,
  getAreaPortfolioDetail
} from './../../../../utils/request';
import { useRouteMatch, Link, useLocation } from 'react-router-dom';
import { secretImageUrl } from '../../../../utils';
import { formatVerb } from '../portfolioItem/index';
import qs from 'query-string';

let userdata = {};

const PortfolioActivity = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const isArea = JSON.parse(qs.parse(location.search).isArea);
  const [portfolioData, setPortfolioData] = useState();
  const [data, setData] = useState();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [pageSize, setPageSize] = useState(8);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  
  const getPortfolioNotificationsHandler = useCallback(() => {
    const requestFn = isArea ? getAreaPortfolioNotifications : getPortfolioNotifications;
    requestFn(match.params.id, {
      page_size: pageSize,
      page: current,
    })
      .then((res) => {
        console.log(res.data);
        setData(
          res.data.results.map((item, index) => ({ ...item, key: index }))
        );
        setTotal(res.data.count);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [pageSize, current, match.params.id, isArea]);

  useEffect(() => {
    const requestFn = isArea ? getAreaPortfolioDetail : getPortfolioDetail;
    requestFn(match.params.id)
      .then((res) => {
        setPortfolioData({
          ...res.data,
          cover: secretImageUrl + res.data.cover,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [match.params.id, isArea]);

  useEffect(() => {
    getPortfolioNotificationsHandler();
  }, [current, getPortfolioNotificationsHandler]);

  const columns = [
    {
      title: '用户',
      dataIndex: 'actor_nick',
      key: 'actor_nick',
      width: 200,
      align: 'left',
      render: (text, record) => (
        <span className="name">
          <Link
            className="author"
            to={`/account/home/${record.actor_username}`}
            target="_blank"
          >
            <img src={record.actor_avatar} width="48px" alt="cover" />
            <div className='actor_nick'>
              {
                isArea ? (record.actor_regional_username || record.actor_nick || record.actor_username) : (record.actor_nick || record.actor_regional_username || record.actor_username)
              }
            </div>
          </Link>
        </span>
      ),
    },
    {
      title: '操作',
      dataIndex: 'verb',
      key: 'verb',
      align: 'left',
      width: 150,
      render: (text) => formatVerb(text),
    },
    {
      title: '作品',
      dataIndex: 'tgt_name',
      key: 'tgt_name',
      align: 'left',
      render: (val, record) => (
        record.tgt_name ? 
        
          <Tooltip overlayStyle={{ maxWidth: 500 }} placement="topLeft" title={record.tgt_content ? `${record.tgt_name}、${record.tgt_content.replaceAll(',', '、')} 等 ${record.tgt_content.split(',').length + 1} 个作品` : record.tgt_name}>
          <div
            className={`detail ${record.tgt_content ? '' : 'disable'}`}
            onClick={() => {
              if (!record.tgt_content) return;
              userdata = record;
              setShowDetailModal(true);
              console.log(userdata);
            }}
          >
            <div className='tag_name single-ellipsis'>{record.tgt_name}</div>
            {record.tgt_content
              ? ` 等 ${record.tgt_content.split(',').length + 1} 个作品`
              : ''}
          </div>
      </Tooltip>
         : '-'
      ),
    },
    {
      title: '时间',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'left',
      render: (val) => dayjs(val).format('YYYY/MM/DD HH:mm'),
    },
  ];

  useLayoutEffect(() => {
    document.querySelector(
      '.ant-table-pagination .ant-select-selection-item'
    ) &&
      (document.querySelector(
        '.ant-table-pagination .ant-select-selection-item'
      ).innerText = `${pageSize} 条/页`);
  }, []); // eslint-disable-line

  return (
    <div id="portfolioActivity">
      <header>
        <div className="container">
          <Button className='go_back' type='link' icon={<LeftOutlined />} onClick={() => window.history.back()}> 返回作品集详情 </Button>
          <div className="header">
            <Icon defaultImg={portfolioData?.cover} type={isArea?'areaPortfolio':'portfolio'}/>
            <div className="detail">
              <div className="title">{portfolioData?.title || portfolioData?.studio.title}</div>
            </div>
          </div>
          <div className='tab_title'>
            全部动态
          </div>
        </div>
      </header>
      <div className="content">
        <div className="container">
          <div className="table">
            <Table
              dataSource={data}
              columns={columns}
              pagination={{
                total,
                showQuickJumper: true,
                defaultPageSize: pageSize,
                onChange: (val) => {
                  setCurrent(val);
                },
                onShowSizeChange: (cur, size) => {
                  setCurrent(cur);
                  setPageSize(size);
                },
                showTotal: (total, range) =>
                  `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`,
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        closeIcon={
          <img src="/image/close.png" alt="close" width="14px" height="14px" />
        }
        className="actionDetail"
        visible={showDetailModal}
        onCancel={() => setShowDetailModal(false)}
        footer={null}
        width={436}
        centered={true}
        getContainer={false}
      >
        <div className="content">
          <div className="userWrapper">
            <div className="icon">
              <img
                src={userdata.actor_avatar}
                alt="icon"
              />
            </div>
            <div className="userDetail">
              <div className="username">{userdata.actor_nick}</div>
              <div className="time">
                {dayjs(userdata.created_at).format('YYYY/MM/DD HH:mm')}
              </div>
            </div>
          </div>
          <div className="title">添加作品名单：</div>
          <div className="details">
            <div className="detail">{userdata.tgt_name}</div>
            {!!userdata.tgt_content &&
              userdata.tgt_content.split(',').map((item, index) => (
                <div className="detail" key={index}>
                  {item}
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PortfolioActivity;
