import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, message } from 'antd';
import { withRouter, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toDetail } from './../../../utils';
import { coverMap } from './../../../utils/variable';
import { Tooltip } from 'antd';
import CreateBtn from '../../../components/createBtn'

import {
  createOwnPortfolio,
  getOwnPortfolioCount
} from '../../../utils/request';

const emptyBtnText = new Map([
  ['我的作品', '创建'],
  ['我的收藏', '去作品集'],
  ['我的个人作品集', '创建'],
  ['我关注的作品集', '前往社区'],
  ['我的作品集', '创建'],
  ['我的团队', '创建']
]);

const emptyText = new Map([
  ['我的作品', '还没有创建作品哦'],
  ['我的作品集', '还没有创建作品集哦'],
  ['我的团队', '还没有创建团队哦'],
  ['我的收藏', '尚未有收藏的作品，快去作品集多看看吧'],
  ['Ta 的作品', 'Ta 还没有公开过作品哦'],
  ['最近学习', 'Ta 还没有看过在线课程或项目哦'],
  ['Ta 的收藏', 'Ta 还没有收藏过作品哦']
]);

const ContentItem = ({
  location,
  curpage = 1,
  count = 0,
  onChange,
  pageSize = 6,
  data = [],
  title,
  showAllUrl,
  createUrl,
  clickable = true,
  rectangle,

  ...props
}) => {
  const scrollBarRef = useRef();
  const history = useHistory();
  const [scrollPositon, setScrollPositon] = useState(0);
  const isTeacher = useSelector(state => state.isTeacher);
  const userInfo = useSelector(state => state.userInfo);
  // 可能为负数
  const manager_CanCreateStudios = useSelector(
    state => state.manager_CanCreateStudios
  );
  const teacher_CanCreateStudios = useSelector(
    state => state.teacher_CanCreateStudios
  );
  const manager_CanCreateTeam = useSelector(
    state => state.manager_CanCreateTeam
  );
  const teacher_CanCreateTeam = useSelector(
    state => state.teacher_CanCreateTeam
  );
  const service_validity = useSelector(state => state.service_validity);
  const isValidityAbled = service_validity === 3 || service_validity === 4;
  // 校区管理员或非校区教师
  const Manager_Or_NonOrgTeacher =
    userInfo?.user_type.indexOf('manager') !== -1 ||
    (userInfo?.user_type.indexOf('teacher') !== -1 &&
      JSON.stringify(userInfo?.organization_data) === '{}');

  const onChangehandler = useCallback(current => {
    let scrollLeft =
      current === 'right'
        ? scrollBarRef.current.scrollLeft + 780
        : scrollBarRef.current.scrollLeft - 780;
    scrollBarRef.current.scroll({
      top: 100,
      left: scrollLeft,
      behavior: 'smooth'
    });
  }, []);

  const createOwnPortfolioHandler = () => {
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再尝试');
      return;
    }
    getOwnPortfolioCount({})
      .then(res => {
        if (Object.is(res.status, 200)) return res.data;
      })
      .then(res => {
        createOwnPortfolio({
          title: `个人作品集-${res.studio_count + 1}`,
          description: ''
        }).then(res => {
          if (Object.is(res.status, 201)) {
            message.success('新建作品集成功');
            history.push(`/manage/ownportfolio/${res.data.id}/project`);
          }
        });
      });
  };

  const createBtnHandler = () => {
    console.log('进createBtnHandler:', title, createUrl);
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再尝试');
      return;
    }
    if (title === '我的个人作品集') {
      createOwnPortfolioHandler();
    } else if (typeof createUrl === 'string') {
      props.history.push(createUrl);
    } else if (typeof createUrl === 'function') {
      createUrl();
    } else if (typeof createUrl === 'object') {
      window.open(createUrl.href);
    }
  };

  useEffect(() => {
    let scrollBar = scrollBarRef.current;
    const scrillEventHandler = e => {
      setScrollPositon(scrollBar.scrollLeft);
    };
    scrollBar.addEventListener('scroll', scrillEventHandler);
    return () => {
      scrollBar.removeEventListener('scroll', scrillEventHandler);
    };
  }, []);

  return (
    <div
    
      className={["contentItem", title === '我的团队' ? 'team' : (title === '我的作品集' ? 'portfolio' : '')].join(' ')}
      style={{
        paddingBottom: count ? (count > (rectangle ? 5 : 6) ? 20 : 30) : 30
      }}
    >
      <div className="titleBar">
        <div className="title">
          {/* {rectangle ? 'rectangle ' : ''} */}
          {/* {count} - {data.length} */}

          {/* {props.targetUser ? title.replace('我', 'Ta ') : title}  */}
          {props.targetUser && props.targetUser.id !== userInfo.id
            ? title.replace('我', 'Ta ')
            : title}
          {count > (rectangle ? 5 : 6) &&
          props.targetUser &&
          title === '我的作品' ? (
            null && (
              <Link
                className="more"
                to={`/otherproject/${props.targetUser.username}`}
              >
                查看全部
                <img
                  width="6"
                  style={{ marginBottom: '1px', marginLeft: '5px' }}
                  height="10"
                  src="/image/icon-arrow.svg"
                  alt="add"
                />
              </Link>
            )
          ) : data.length > (rectangle ? 5 : 6) &&
            !props.targetUser &&
            !(title === '我的团队' && !clickable) ? (
            <Link className="more" to={showAllUrl || location.pathname}>
              查看全部
              <img
                width="6"
                style={{ marginBottom: '1px', marginLeft: '5px' }}
                height="10"
                src="/image/icon-arrow.svg"
                alt="add"
              />
            </Link>
          ) : null}
        </div>
        {count > (rectangle ? 5 : 6) && !rectangle ? (
          <div id="simple-pagination">
            <img
              width="8"
              style={{ marginBottom: '1px', marginLeft: '5px' }}
              height="13"
              src={`/image/icon-arrow-left${
                scrollPositon === 0 ? '' : '-black'
              }.png`}
              onClick={() => onChangehandler('left')}
              alt="add"
            />
            <div className="blank"></div>
            <img
              width="8"
              style={{ marginBottom: '1px', marginLeft: '5px' }}
              height="13"
              src={`/image/icon-arrow-right${
                scrollBarRef.current.scrollWidth ===
                scrollPositon + scrollBarRef.current.clientWidth
                  ? ''
                  : '-black'
              }.png`}
              alt="add"
              onClick={() => onChangehandler('right')}
            />
            {/* </div> */}
          </div>
        ) : null}
      </div>
      <div
        className={`list ${data.length > 5 || rectangle ? 'scroll' : ''}`}
        ref={scrollBarRef}
      >
        {data.length ? (
          <>
            {data
              .slice(0, count >= 18 ? 18 : count > 18 ? 17 : 18)
              .map((item, index) => (
                <div
                  className={['item', rectangle ? 'rectangle' : ''].join(' ')}
                  key={index}
                >
                  {clickable ? (
                    title === '我的作品' || title === '我的收藏' ? (
                      <>
                      {/* 缺少platform标注 */}
                        <a
                          href={toDetail(item.platform, item.project_id || item.id)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            className="image_bg"
                            src={
                              item.cover || item.image
                                ? item.cover || item.image
                                : coverMap[item.origin]
                            }
                            alt={item.title}
                          />
                        </a>
                        <a
                          href={toDetail(item.platform, item.project_id || item.id)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="title">{item.title}</div>
                        </a>
                      </>
                    ) : title === '我关注的作品集' ||
                      title === '我的个人作品集' ? (
                      <>
                        <Link
                          to={
                            item.url
                              ? item.url
                              : `/manage/ownportfolio/${
                                  item.code || item.id
                                }/project`
                          }
                        >
                          {title === '最近学习' ? (
                            <div
                              className="img"
                              style={{
                                backgroundImage: `url(${
                                  item.cover || item.image
                                    ? item.cover || item.image
                                    : coverMap[item.origin]
                                })`
                              }}
                              alt={item.name}
                            />
                          ) : (
                            <img
                              className="image_bg"
                              src={
                                item.cover || item.image
                                  ? item.cover || item.image
                                  : coverMap[item.origin]
                              }
                              alt={item.name}
                            />
                          )}
                        </Link>
                        <Link
                          to={
                            item.url
                              ? item.url
                              : `${showAllUrl}/${item.code || item.id}/project`
                          }
                        >
                          <div
                            className={`title ${
                              title === '最近学习' ? 'more' : ''
                            } ${title === '我的个人作品集' ? 'own' : ''}`}
                          >
                            {item.name || item.title || item.studio?.title}
                          </div>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                          to={
                            item.url
                              ? item.url
                              : `${showAllUrl}/${item.code || item.id}${
                                  title === '我的个人作品集' ? '/project' : ''
                                }`
                          }
                        >
                          {title === '最近学习' ? (
                            <div
                              className="img"
                              style={{
                                backgroundImage: `url(${
                                  item.cover || item.image
                                    ? item.cover || item.image
                                    : coverMap[item.origin]
                                })`
                              }}
                              alt={item.name}
                            />
                          ) : (
                            <img
                              className="image_bg"
                              src={
                                item.cover || item.image
                                  ? item.cover || item.image
                                  : coverMap[item.origin]
                              }
                              alt={item.name}
                            />
                          )}
                        </Link>
                        <Link
                          to={
                            item.url
                              ? item.url
                              : `${showAllUrl}/${item.code || item.id}`
                          }
                        >
                          <div
                            className={`title ${
                              title === '最近学习' ? 'more' : ''
                            } ${title === '我的个人作品集' ? 'own' : ''}`}
                          >
                            {item.name || item.title || item.studio?.title}
                          </div>
                        </Link>
                      </>
                    )
                  ) : (
                    <>
                      <img
                        className="disable image_bg"
                        src={item.cover || item.image}
                        alt={item.name}
                      />
                      <div className="title disable">
                        {item.name || item.title || item.studio?.title}
                      </div>
                    </>
                  )}

                  {item.num_student !== undefined ? (
                    <div className="info student">
                      <span className="iconwrapper">
                        <svg
                          t="1585281004201"
                          className="icon"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          p-id="8904"
                          width="18"
                          height="18"
                        >
                          <path
                            d="M512 62.08A449.92 449.92 0 1 0 961.92 512 449.92 449.92 0 0 0 512 62.08z m0 135.04a135.04 135.04 0 1 1-135.04 135.04A134.72 134.72 0 0 1 512 197.12z m0 640a323.84 323.84 0 0 1-269.76-144.96c0-89.6 179.84-138.56 269.76-138.56s268.48 48.96 269.76 138.56A323.84 323.84 0 0 1 512 835.84z"
                            p-id="8905"
                            fill="#9DBEE5"
                          ></path>
                        </svg>
                      </span>
                      {item.num_student} 人
                    </div>
                  ) : null}
                  {item.team_cover ? (
                    <div className="info teamcover">
                      <span
                        className="iconwrapper"
                        style={{
                          backgroundImage: 'url(' + item.team_cover + ')'
                        }}
                      ></span>
                      {item.team_name}
                    </div>
                  ) : null}
                  {title === '我关注的作品集' ? (
                    <Link
                      to={`/account/home/${item.user_name}`}
                      target="_blank"
                    >
                      <div className="info followportfolio">
                        <span
                          className="iconwrapper"
                          style={{
                            backgroundImage: `url(${item.author_image})`
                          }}
                        ></span>
                        {item.author_name || item.user_name}
                      </div>
                    </Link>
                  ) : null}
                  {title === '我的收藏' ? (
                    <div className="info teamcover">
                      <Link
                        to={`/account/home/${item.username}`}
                        target="_blank"
                      >
                        <span
                          className="iconwrapper"
                          style={{
                            backgroundImage: 'url(' + item.avatar + ')'
                          }}
                        ></span>
                      </Link>
                      <Link
                        to={`/account/home/${item.username}`}
                        target="_blank"
                      >
                        {item.name || item.username}
                      </Link>
                    </div>
                  ) : null}
                </div>
              ))}
            {!props.targetUser && count > 18 ? (
              <div className="item more">
                {clickable ? (
                  <Link to={showAllUrl || location.pathname}>
                    <div className="more">查看更多</div>
                  </Link>
                ) : null}
              </div>
            ) : null}
          </>
        ) : props.targetUser && props.targetUser.id !== userInfo.id ? (
          <div className="create">
            <img src="/image/empty-page.png" alt="empty-page" />
            <div className="tips">
              {emptyText.get(title.replace('我', 'Ta '))}
            </div>
          </div>
        ) : (
          <div className="create">
            <img src="/image/empty-page.png" alt="empty-page" />
            {!isTeacher && title === '我的作品集' ? (
              <div className="tips">
                尚未有共享的作品集，请联系您的家长/老师
              </div>
            ) : title === '最近学习' ? (
              <>
                <div className="tips">
                  暂无学习记录，根据喜好去探索更多内容吧！
                </div>
                <div>
                  {/* <Link to="/project">
                    <Button type="primary" className="learn">
                      去玩项目
                    </Button>
                  </Link> */}
                  <Link to="/course">
                    <Button type="primary" className="learn">
                      去学课程
                    </Button>
                  </Link>
                </div>
              </>
            ) : (
              //* before the change
              // <>
              //   <div className="tips">{emptyText.get(title)}</div>

              //   <Button type="primary" onClick={createBtnHandler}>
              //     {emptyBtnText.get(title)}
              //   </Button>
              // </>
              <>
                <div className="tips">{emptyText.get(title)}</div>
                {(() => {
                  if (title === '我的作品集') {
                    let studiosFlag =
                      userInfo?.user_type.indexOf('manager') > -1
                        ? manager_CanCreateStudios
                        : teacher_CanCreateStudios;
                    if (isValidityAbled) {
                      return (
                        <Tooltip
                          placement="top"
                          title={
                            service_validity === 3
                              ? '英荔教学云服务还没开始，请耐心等待'
                              : userInfo?.user_type.indexOf('manager') > -1 ||
                                (userInfo?.user_type?.indexOf('teacher') > -1 &&
                                  JSON.stringify(
                                    userInfo?.organization_data
                                  ) === '{}')
                              ? '英荔教学云服务已到期，如需继续使用请联系英荔销售或致电 400-931-8118'
                              : '英荔教学云服务已到期，如需继续使用，请告知校区管理员'
                          }
                          overlayClassName="teamFloatingTooltip"
                        >
                          <Button type="primary" disabled={isValidityAbled}>
                            创建
                          </Button>
                        </Tooltip>
                      );
                    }
                    if (!(studiosFlag > 0)) {
                      return (
                        <Tooltip
                          placement="top"
                          title={
                            Manager_Or_NonOrgTeacher
                              ? '当前添加作品集的数量已达上限，如需增加作品集数量，请致电 400-931-8118'
                              : '当前添加作品集的数量已达上限，如需增加作品集数量，请联系校区管理员'
                          }
                        >
                          <Button
                            type="primary"
                            // onClick={createPortfolioHandler}
                            disabled={
                              userInfo?.user_type.indexOf('manager') > -1
                                ? !(manager_CanCreateStudios > 0)
                                : !(teacher_CanCreateStudios > 0)
                            }
                          >
                            创建
                          </Button>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Button type="primary" onClick={createBtnHandler}>
                          {emptyBtnText.get(title)}
                        </Button>
                      );
                    }
                  } else if (title === '我的团队') {
                    let teamFlag =
                      userInfo?.user_type.indexOf('manager') > -1
                        ? manager_CanCreateTeam
                        : teacher_CanCreateTeam;
                    if (isValidityAbled) {
                      return (
                        <Tooltip
                          placement="top"
                          title={
                            service_validity === 3
                              ? '英荔教学云服务还没开始，请耐心等待'
                              : userInfo?.user_type.indexOf('manager') > -1 ||
                                (userInfo?.user_type?.indexOf('teacher') > -1 &&
                                  JSON.stringify(
                                    userInfo?.organization_data
                                  ) === '{}')
                              ? '英荔教学云服务已到期，如需继续使用请联系英荔销售或致电 400-931-8118'
                              : '英荔教学云服务已到期，如需继续使用，请告知校区管理员'
                          }
                          overlayClassName="teamFloatingTooltip"
                        >
                          <Button type="primary" disabled={isValidityAbled}>
                            创建
                          </Button>
                        </Tooltip>
                      );
                    }
                    if (!(teamFlag > 0)) {
                      return (
                        <Tooltip
                          placement="top"
                          title={
                            Manager_Or_NonOrgTeacher
                              ? '当前新建团队的数量已达上限，如需增加团队数量，请致电 400-931-8118'
                              : '当前新建团队的数量已达上限，如需增加团队数量，请联系校区管理员'
                          }
                        >
                          <Button
                            type="primary"
                            // onClick={createPortfolioHandler}
                            disabled={
                              userInfo?.user_type.indexOf('manager') > -1
                                ? !(manager_CanCreateTeam > 0)
                                : !(teacher_CanCreateTeam > 0)
                            }
                          >
                            创建
                          </Button>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Button type="primary" onClick={createBtnHandler}>
                          {emptyBtnText.get(title)}
                        </Button>
                      );
                    }
                  } else {
                    return (
                      title === '我的作品' ? <CreateBtn>
                        {emptyBtnText.get(title)}
                      </CreateBtn> : <Button type="primary" onClick={createBtnHandler}>
                      {emptyBtnText.get(title)}
                    </Button>
                    );
                  }
                })()}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ContentItem.propTypes = {
  createUrl: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string
  ]).isRequired
};

export default withRouter(ContentItem);
