import React, { useEffect, useReducer, useRef, useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Button, Pagination, message } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import qs from 'query-string';
import PortfolioModal from '../../../../../components/portfolioModal';
import ConfirmModal from '../../../../../components/confirmModal';
import {
  getOwnProjects,
  getOwnPortfolioProjects,
  addProjectsToStudio,
  deleteOwnPortfolio,
  deleteProjectFromPortfolio,
  patchOwnFolioDetail
} from '../../../../../utils/request';
import {
  getOwnPortfolioProjectsCount,
  getShareProjectsCount
} from '../../../../../utils/api/ownportfolio';
import { scratchURL, simpleToast } from '../../../../../utils';
import './style.scss';

const types = {
  SET_DATA: 'SET_DATA',
  SET_ALLDATA: 'SET_ALL_DATA',
  SET_MODAL_DATA: 'SET_MODAL_DATA',
  SET_PAGE: 'SET_PAGE',
  SET_MODAL_PAGE: 'SET_MODAL_PAGE',
  SET_SELECT_PROJECTS: 'SET_SELECT_PROJECTS',
  TOGGLE_ADD_MODAL: 'TOGGLE_ADD_MODAL',
  TOGGLE_DEL_MODAL: 'TOGGLE_DEL_MODAL',
  DEL_PROJECT: 'DEL_PROJECT',
  DEL_PORTFOLIO: 'DEL_PORTFOLIO'
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_DATA:
      return {
        ...state,
        data: action.data,
        total: action.total !== undefined ? action.total : state.total
      };
    case types.SET_ALLDATA:
      return {
        ...state,
        allData: action.data || state.data,
        allDataTotal: action.total || state.total
      };
    case types.SET_MODAL_DATA:
      return {
        ...state,
        modalAllData: action.data
      };
    case types.TOGGLE_ADD_MODAL:
      return { ...state, toggleAddModal: !state.toggleAddModal };
    case types.TOGGLE_DEL_MODAL:
      return { ...state, toggleDelModal: !state.toggleDelModal };
    case types.SET_SELECT_PROJECTS:
      return { ...state, selectProjects: action.data };
    case types.SET_PAGE:
      return { ...state, page: action.page };
    case types.SET_MODAL_PAGE:
      return { ...state, modalPage: action.page };
    default:
      return state;
  }
};
const Project = props => {
  const location = useLocation();
  const initialState = {
    data: [],
    total: 0,
    allData: [],
    allDataTotal: null,
    modalAllData: [],
    page: 1,
    modalPage: 1,
    toggleAddModal: qs.parse(location.search).create === 'new',
    toggleDelModal: false,
    selectProjects: []
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const userInfo = useSelector(state => state.userInfo);
  const username = userInfo?.username || null;
  const {
    data,
    total,
    allData,
    allDataTotal,
    modalAllData,
    page,
    modalPage,
    toggleAddModal,
    toggleDelModal,
    selectProjects
  } = state;
  const { id, getActivityData, info, exists } = props;
  const onChangeData = useRef(null);

  const formatData = data => {
    return data.map(item => ({
      ...item,
      cancelDelete: false
    }));
  };

  const fetchOwnProjects = (id, params) => {
    return new Promise((resolve, reject) => {
      getOwnPortfolioProjects(id, params).then(res => {
        if (Object.is(res.status, 200)) resolve(res.data);
      });
    });
  };

  const fetchOwnProjectsCount = id => {
    return new Promise((resolve, reject) => {
      getOwnPortfolioProjectsCount(id).then(res => {
        if (Object.is(res.status, 200)) resolve(res.data);
      });
    });
  };

  const fetchData = useCallback(() => {
    const params = {
      offset: (page - 1) * 18,
      limit: 18
    };
    Promise.all([fetchOwnProjects(id, params), fetchOwnProjectsCount(id)]).then(
      resArr => {
        let [listData, countData] = resArr;
        dispatch({
          type: types.SET_DATA,
          data: formatData(listData),
          total: countData.count
        });
      }
    );
  }, [id, page]);

  const fetchProjects = params => {
    return new Promise((resolve, reject) => {
      getOwnProjects(params).then(res => {
        if (Object.is(res.status, 200)) resolve(res.data);
      });
    });
  };

  const fetchProjectsCount = () => {
    return new Promise((resolve, rejct) => {
      getShareProjectsCount().then(res => {
        if (Object.is(res.status, 200)) resolve(res.data);
      });
    });
  };

  const addProjects = useCallback(() => {
    let ids = selectProjects.map(item => item.id);
    addProjectsToStudio(id, ids)
      .then(res => {
        dispatch({ type: types.TOGGLE_ADD_MODAL });
        dispatch({ type: types.SET_SELECT_PROJECTS, data: [] });
        fetchData();
        getActivityData();
        patchOwnFolioDetail(id, { is_touch: true });
        message.success('添加作品成功');
        return fetchOwnProjects(id, { offset: 0, limit: 999 });
      })
      .then(res => {
        dispatch({ type: types.SET_MODAL_DATA, data: formatData(res) });
      });
  }, [fetchData, getActivityData, id, selectProjects]);

  useEffect(() => {
    onChangeData.current = data;
  });

  const cancelDeleteHandler = (data, project, index) => {
    // project.cancelDelete = true;
    const newData = data.slice(0, index).concat(project, data.slice(index));
    dispatch({ type: types.SET_DATA, data: newData });
    message.success('撤销操作成功');
  };

  const delProjectToStudioHandler = (projectId, portfolioId, index) => {
    const getToast = simpleToast();
    // let timer = null
    return () => {
      if (!window.navigator.onLine) {
        getToast({ type: 'error', text: '操作失败，请稍后再尝试' });
        return;
      }
      const project = onChangeData.current[index];
      const newData = onChangeData.current
        .slice(0, index)
        .concat(onChangeData.current.slice(index + 1));
      dispatch({ type: types.SET_DATA, data: newData });
      const cancelText = (
        <span>
          成功移除作品。
          <span
            style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
            onClick={() => {
              cancelDeleteHandler(onChangeData.current, project, index);
              hide();
            }}
          >
            撤销操作?
          </span>
        </span>
      );
      const hide = message.success(cancelText, 3, () => {
        if (!project.cancelDelete) {
          deleteProjectFromPortfolio(projectId, portfolioId)
            .then(res => {
              message.success('移除作品成功');
              fetchData();
              getActivityData();
              return fetchOwnProjects(id, { offset: 0, limit: 999 });
            })
            .then(res => {
              dispatch({ type: types.SET_MODAL_DATA, data: formatData(res) });
            });
        }
        // project.cancelDelete = false;
      });
    };
  };

  const delPortfolio = () => {
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再尝试');
      return;
    }
    deleteOwnPortfolio(id)
      .then(res => {
        message.success('删除作品集成功');
        setTimeout(() => {
          window.location.href = '/manage/ownportfolio';
        }, 1000);
      })
      .catch(err => {
        message.error('操作失败，请稍后再试');
      });
  };

  useEffect(() => {
    const fetchData = () => {
      const params = {
        offset: (modalPage - 1) * 8,
        limit: 8,
        is_published: true
      };

      Promise.all([fetchProjects(params), fetchProjectsCount()]).then(res => {
        const [data, totalData] = res;
        dispatch({
          type: types.SET_ALLDATA,
          data,
          total: totalData.count
        });
      });
    };

    if (userInfo) {
      fetchData();
    }
  }, [modalPage, userInfo]);

  useEffect(() => {
    fetchData();
  }, [fetchData, id, page]);

  useEffect(() => {
    fetchOwnProjects(id, { offset: 0, limit: 999 }).then(res => {
      dispatch({ type: types.SET_MODAL_DATA, data: formatData(res) });
    });
  }, [id]);

  /**
   * 渲染方法
   */
  const getActionBar = () =>
    exists && userInfo && !Object.is(info.role, 'visitor') ? (
      <div className="actionBar">
        <Button
          icon={
            <img
              width="12"
              style={{ marginBottom: '2px', marginRight: '8px' }}
              height="12"
              src="/image/icon-add.png"
              alt="add"
            />
          }
          type="link"
          size="small"
          onClick={() => {
            dispatch({ type: types.TOGGLE_ADD_MODAL });
          }}
        >
          添加作品
        </Button>
      </div>
    ) : null;

  const getList = () => {
    return data.length ? (
      data.map((item, index) => (
        <div className="project" key={index}>
          {userInfo &&
          ((Object.is(item.author.username, username) &&
            !Object.is(info.role, 'visitor')) ||
            Object.is(info.role, 'manager') ||
            Object.is(info.role, 'owner')) ? (
            <CloseCircleFilled
              className="closeBtn"
              onClick={delProjectToStudioHandler(item.id, id, index)}
            />
          ) : null}
          <a
            href={scratchURL + `/projects/${item.id}/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="image">
              <img src={item.image} alt={item.title} />
            </div>
          </a>
          <div className="title">
            <a
              href={scratchURL + `/projects/${item.id}/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.title}
            </a>
          </div>
          <div className="author">
            <Link
              className="author"
              to={`/account/home/${item.author.username}`}
              target="_blank"
            >
              <span
                className="authorImg"
                style={{
                  backgroundImage: 'url(' + item.author.avatar_url + ')'
                }}
              ></span>
              <span className="name">
                {item.author.student_name ||
                  item.author.name ||
                  item.author.username}
              </span>
            </Link>
          </div>
        </div>
      ))
    ) : (
      <div className="empty">
        <img src="/image/empty-page.png" alt="empty" />
        <p className="text">还没有添加任何作品</p>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div id="project-list">
        {getActionBar()}
        <div className="list">
          {getList()}
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>
        {Object.is(info.role, 'owner') && (
          <div className="deleteProjects">
            <p
              className="deleteBtn"
              onClick={() => dispatch({ type: types.TOGGLE_DEL_MODAL })}
            >
              删除作品集
            </p>
          </div>
        )}
        {exists ? (
          <div className="pagination">
            <Pagination
              showQuickJumper
              current={page}
              defaultPageSize={18}
              total={total}
              onChange={val => dispatch({ type: types.SET_PAGE, page: val })}
              showTotal={(total, range) =>
                `共 ${Math.ceil(total / 18)} 页 / ${total} 条数据`
              }
            />
          </div>
        ) : null}
      </div>
      <PortfolioModal
        visible={toggleAddModal}
        selectProjects={selectProjects}
        allData={allData}
        allDataTotal={allDataTotal}
        data={modalAllData}
        page={modalPage}
        onCancel={() => {
          dispatch({ type: types.TOGGLE_ADD_MODAL });
        }}
        onOk={addProjects}
        onChangePage={val => {
          dispatch({ type: types.SET_MODAL_PAGE, page: val });
        }}
        onChangeList={data => {
          dispatch({ type: types.SET_SELECT_PROJECTS, data });
        }}
      />

      <ConfirmModal
        title="是否删除作品集？"
        visible={toggleDelModal}
        iconType="error"
        contentNode={
          <>
            删除作品集后，
            <span style={{ color: 'red' }}>
              作品集的历史动态数据会被删除，且无法恢复。
            </span>
            删除作品集后，作品集的成员、作品集内所有的作品仍会存在。你确定要删除作品集吗？
          </>
        }
        bottomNode={
          <>
            <Button
              className="actionOk"
              onClick={() => dispatch({ type: types.TOGGLE_DEL_MODAL })}
            >
              取消
            </Button>
            <Button danger onClick={delPortfolio}>
              确定
            </Button>
          </>
        }
      />
    </React.Fragment>
  );
};

export default memo(Project);
