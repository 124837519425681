import React, { useCallback, useState, useEffect } from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { secretImageUrl } from './../../utils'
import { getRandomKey } from './../../utils/request'
import { ReloadOutlined } from '@ant-design/icons'

const getRandomFile = async () => {
    // return imageSecret[Math.floor((imageSecret.length * Math.random()))]
    const res = await getRandomKey()
    return res.data.image
}
const styleConfig = {
    portfolio:{
        bgColor:'#C5E3FF',
        borderColor:'#8EC9FF'
    },
    areaPortfolio:{
        bgColor:'#FFE395',
        borderColor:'#FFD86A'
    },
    team:{
        bgColor:'#C695FF',
        borderColor:'#BB83FD'
    },

}

const Icon = ({onChange, onLoad, defaultImg, random, type, showSwitch = false, ...props}) => {
    const [randomFile, setrandomFile] = useState()
    const [imageFile, setImageFile] = useState(null)
    useEffect(() => {
        (async () => {
            if (random) {
                setrandomFile(await getRandomFile())
            }
        })()
    }, [random])

    const uploadImg = useCallback(async () => {
        if (random) {
            let file = await getRandomFile()
            onChange && onChange(file)
            setImageFile(secretImageUrl + file)
            setrandomFile(file)
        } else {
            let input = document.createElement('input')
            input.type = 'file'
            input.click()
            input.onchange = function (e) {
                console.log(this)
                let reader
                if (e.target.files.length) {
                    reader = new FileReader()
                    reader.readAsDataURL(e.target.files[0])
                    setImageFile(e.target.files[0])
                    onChange && onChange(e.target.files[0])
                }
                reader.onload = function(e) {
                //   console.log(e.target.result)
                }
            }
        }
    }, [setImageFile, onChange, random])

    return (
        <div id="iconWrapper" {...props}>
            <div className="imgWrapper" style={{ borderColor: styleConfig[type].borderColor, backgroundColor: styleConfig[type].bgColor }}>
                {
                    random && !randomFile ?
                    null :
                    <img
                        src={imageFile || (defaultImg || secretImageUrl + randomFile)}
                        onLoad={function (e) {
                            if (!imageFile) {
                                onLoad && onLoad(defaultImg || randomFile)
                                setImageFile(e.target.src)
                            }
                        }}
                        alt="icon"
                    />
                }
                {onChange ? <div className="mask" onClick={uploadImg}>更换封面</div> : null}
            </div>
            { showSwitch && <span className="switch" onClick={uploadImg}>换一张 <ReloadOutlined /></span> }
        </div>
    )
}

Icon.propTypes = {
    onChange: PropTypes.func,
    onLoad: PropTypes.func,
    defaultImg: PropTypes.string,
    type:PropTypes.string
}

export default Icon