import React, { memo } from 'react';
import classNames from 'classnames';
import { Modal } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled  } from '@ant-design/icons';
import './style.scss';

const ConfirmModal = memo(
  ({ title, visible, iconType, contentNode, bottomNode = null }) => {
    return (
      <Modal
        visible={visible}
        className="confirmModal"
        footer={null}
        width={535}
        centered={true}
        getContainer={false}
        closable={false}
        data-testid="test-confirm-modal"
      >
        <div className="title">
          {iconType === 'success' ? (
            <CheckCircleFilled className={classNames('icon', iconType)} />
          ) : iconType === 'warning' ? (<ExclamationCircleFilled  className={classNames('icon', iconType)} />) : (
            <ExclamationCircleFilled className={classNames('icon', iconType)} />
          )}
          <span className="detail">{title}</span>
        </div>
        <div
          className={
            title === '确定要从本团队移除以下课程？' ? 'text2' : 'text'
          }
        >
          {contentNode}
        </div>
        <div className="action">{bottomNode}</div>
      </Modal>
    );
  }
);

export default ConfirmModal;
