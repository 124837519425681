import instance from "../request"

/**
 * 获取个人作品集内作品数量
 * @param {*} id 
 */
export const getOwnPortfolioProjectsCount = (id) => {
  return instance.get(`/api/v1/studios/${id}/projects/project_count/`)
}

export const getShareProjectsCount = () => {
  return instance.get('/api/v1/profiles/project_count/?is_published=true')
}

export const getOwnPortfolioNoticeCount = (id) => {
  return instance.get(`/api/v1/studios/${id}/notification_count/`)
}

export const getOwnPortfolioFollowers = (id) => {
  return instance.get(`/api/v1/studios/${id}/follow_user_count/`)
}

export const getManageList = (id, params) => {
  return instance.get(`/api/v1/studios/${id}/studiocurator/`, { params })
}

export const inviteUser = (id, name) => {
  return instance.post(`/api/v1/studios/${id}/studiocurator/`, { user_name: name })
}

export const updateManageRole = (id, userid, data) => {
  return instance.patch(`/api/v1/studios/${id}/studiocurator/${userid}/`, data)
}

export const deleteUser = (id,userid) => {
  return instance.delete(`/api/v1/studios/${id}/studiocurator/${userid}`)
}

export const getInviteStatus = (id) => {
  return instance.get(`/api/v1/studios/${id}/studiocurator/curator/`)
}

export const acceptInvite = (id) => {
  return instance.post(`/api/v1/studios/${id}/studiocurator/curator/`)
}

