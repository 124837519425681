import React from 'react'
import './style.scss'
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux'

const NotFound = ({ history, location, ...props }) => {
    
    const campus = useSelector((state) => state.campus)
    const selectCampus = campus.find(f => f.is_select)
    const toHome = () => {
        if (selectCampus.status === '1') {
            if (selectCampus.org_type === "0") { // 非机构和通用
                history.replace('/course');
            } else {
                if (selectCampus.user_type === 'teacher') {
                    history.replace('/teachplan');
                } else {
                    history.replace('/course');
                }
            }
        } else {
            history.replace('/notification');
        }
    }
    return (
        <div id="not-found">
            <div className="content">
                <img src="/image/404.png" alt="" />
                <div className='tip'>
                    <h1>很抱歉，你所访问的页面不存在</h1>
                    <p>这可能是由于你输入的 URL 不正确、网络连接不稳定、相关功能已暂时下线了等原因导致的，建议检查后再试。</p>
                    <Button type='primary' onClick={toHome}>返回首页</Button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(NotFound)