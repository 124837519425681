import React, { useState, forwardRef, useImperativeHandle } from 'react';
import CommonModal from '../../../../components/commonModal';
import { Select, Button, message } from 'antd';
import { getUser } from './../../../../store/actions';
import { useDispatch } from 'react-redux';
import { changeTeam } from './../../../../utils/request';

const ChangeTeam = (props, ref) => {
  const { record, allTeam, teamData, getAllMember } = props;
  const [changeVisible, setChangeTeam] = useState(false);
  const [changeTeamId, setChangeTeamId] = useState(null);
  const [curTeamCode, setCurTeamCode] = useState(null);
  const _dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    changeTeamHandler: () => setChangeTeam(true)
  }));

  const fetchChangeTeam = () => {
    if (!changeTeamId) {
      message.success('更换团队成功');
      setChangeTeam(false);
      return;
    }
    changeTeam(record?.team || curTeamCode, record?.id, {
      name: record?.name,
      team: changeTeamId
    })
      .then(() => {
        message.success('更换团队成功');
        setChangeTeam(false);
        getAllMember();
        _dispatch(getUser());
      })
      .catch(e => {
        if (typeof e.response.data === 'string') return;
        if (e.response.status === 400) {
          message.warning(e.response.data.message);
        } else {
          message.error('更换团队失败');
        }
      });
  };

  return (
    <CommonModal
      title="更换团队"
      width={374}
      visible={changeVisible}
      onCancel={() => {
        setChangeTeam(false);
        setChangeTeamId(null);
      }}
      contentNode={
        <div className="changeModal">
          <p>团队选择</p>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="请选择"
            optionFilterProp="children"
            onChange={value => {
              setChangeTeamId(value);
              setCurTeamCode(allTeam.find(f => f.id === value).code)
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            defaultValue={teamData.id}
            getPopupContainer={triggerNode =>
              triggerNode.parentElement || document.body
            }
          >
            {allTeam.length > 0 &&
              allTeam.map(list => (
                <Select.Option key={list.id} value={list.id}>
                  {list.name}
                </Select.Option>
              ))}
          </Select>
        </div>
      }
      bottomNode={
        <>
          <Button
            onClick={() => {
              setChangeTeam(false);
              setChangeTeamId(null);
            }}
          >
            取消
          </Button>
          <Button type="primary" onClick={fetchChangeTeam}>
            保存
          </Button>
        </>
      }
    />
  );
};

export default forwardRef(ChangeTeam);
