import React, { useRef, useState, useCallback, useEffect } from 'react';
import './style.scss';
import { Button, Form, Input, message, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { patchUserInfo } from '../../../utils/request';
import { setUserInfo } from './../../../store/actions';
import { UploadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { deBounce, testNickName } from '../../../utils';

const textRegExp = /^[A-Za-z0-9_\u4e00-\u9fa5]+$/;

const User = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [formData, setFormData] = useState({ name: '', description: '' });
  const formRef = useRef();
  const userInfo = useSelector(state => state.userInfo);
  const isTeacher = useSelector(state => state.isTeacher);
  const isStudent = userInfo && userInfo.user_type === 'student';
  const [imageFile, setImageFile] = useState();
  const [validateName, setValidate] = useState(true);
  const [valAstrictName, setAstrict] = useState(true);

  const submitHandler = useCallback(
    data => {
      let formData = new FormData();
      Object.keys(data).forEach(key => {
        if(data[key]){
          formData.append(key, data[key]);
        }
      });
      if (imageFile) {
        formData.append('avatar', imageFile);
      }
      patchUserInfo(formData)
        .then(res => {
          message.success('保存成功');
          dispatch(
            setUserInfo({
              ...res.data,
              // avatar_url: res.data.avatar_url + `?t=${new Date().getTime()}`
              avatar_url: res.data.avatar_url
            })
          );
          setImageFile();
          formRef.current.setFieldsValue({
            description: res.data.description,
            name: res.data.name
          });
        })
        .catch(e => {
          message.error('保存失败，请稍后再试');
          console.log(e);
        });
    },
    [imageFile, dispatch]
  );

  const uploadImg = useCallback(() => {
    let input = document.createElement('input');
    const fileRegExp = /\.(jpg|jpeg|png|bmp|JPG|JPEG|PNG|BMP)$/;
    input.type = 'file';
    input.accept = 'image/jpg, image/jpeg, image/png, image/bmp';
    input.click();
    input.onchange = function (e) {
      let reader;
      if (e.target.files.length) {
        if (!fileRegExp.test(e.target.files[0].name)) {
          message.warning('上传封面失败');
          return;
        }
        console.log(e.target.files[0], e.target.files[0].size / 1024, 'kb');
        if (e.target.files[0].size / 1024 > 1024) {
          message.warn('图片文件过大');
          return;
        }

        reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
      }
      reader.onload = function (e) {
        //   console.log(e.target.result)
      };
    };
  }, []);

  useEffect(() => {
    formRef.current &&
      formRef.current.setFieldsValue({
        description: userInfo.description,
        name: userInfo.name
      });
    formRef.current &&
      setFormData({ description: userInfo.description, name: userInfo.name });
  }, [userInfo]);

  return (
    <div id="user">
      <div className="container">
        <div className="avatar">
          <img
            src={
              imageFile ? URL.createObjectURL(imageFile) : userInfo.avatar_url
            }
            style={{objectFit: "cover"}}
            alt="avatar"
          />
        </div>
        <div className="tips">图片小于 1 MB（支持.jpeg/.png/.bmp）</div>
        <div className="btn">
          <Button icon={<UploadOutlined />} onClick={uploadImg}>
            上传头像
          </Button>
        </div>
        <div>
          <Form
            name="userform"
            layout="vertical"
            ref={formRef}
            initialValues={formData}
            onValuesChange={(value, allValue) => {
              console.log(allValue);
              if (!textRegExp.test(allValue.name) && allValue.name) {
                setValidate(false);
              } else {
                setValidate(true);
              }
              if (!testNickName(allValue.name)) {
                setAstrict(false);
              } else {
                setAstrict(true);
              }
              setFormData(allValue);
            }}
            onFinish={deBounce(submitHandler, 500)}
          >
            <Form.Item label={isStudent ? '登录名：' : '用户名：'}> {userInfo.student_name || userInfo.username} </Form.Item>
            {
              !isStudent ?
                <Form.Item label={
                  <> 姓名：<Tooltip
                    placement="top"
                    title={'姓名由平台管理员或是你的上级主管设置，无法自行修改。如有错误请与对方取得联系。姓名不会被教学云中的其他用户看见'}
                    overlayStyle={{ maxWidth: '296px', width: '296px' }}
                  >
                    <img src="/image/icon-tooltip.png" width="16px" height="16px" alt="" />
                  </Tooltip>
                  </>
                } name="regional_username">
                  {userInfo.regional_username || '-'}
                </Form.Item>
                : null
            }
              {isTeacher ? (
              <>
                <div className="formItem">
                  <Form.Item label={
                  <>
                  昵称：<Tooltip
                    placement="top"
                    title={'若昵称不为空，则教学云中其他用户只可以看到你的昵称，不能看到用户名'}
                    overlayStyle={{ maxWidth: '296px', width: '296px' }}
                  >
                    <img src="/image/icon-tooltip.png" width="16px" height="16px" alt=""/>
                  </Tooltip>
                  </>
                } name="name">
                    <Input placeholder="请设置你的昵称" maxLength="20" />
                  </Form.Item>
                  <span className="textInputSuffix">{`${
                    formData.name.length > 20 ? 20 : formData.name.length
                  }/20`}</span>
                </div>
                {!validateName && (
                  <p className="warningText">
                    昵称支持输入中文、英文大小写、下划线、数字
                  </p>
                )}
                {!valAstrictName && (
                  <p className="warningText">
                    使用了限制词汇，请尝试设置别的昵称
                  </p>
                )}
              </>
            ) : null}
            <Form.Item label="个人介绍：" name="description">
              <Input.TextArea
                rows={4}
                placeholder="Ta 很神秘，还没留下个人介绍"
                style={{ resize: 'none' }}
                maxLength="140"
                onChange={e => {
                  // if (e.target.value.length > 140) {
                  //     formRef.current.setFieldsValue({description: e.target.value.slice(0, 140)})
                  // }
                }}
              />
            </Form.Item>
            <span className="textAreaSuffix">{`${
              formData.description.length > 140
                ? 140
                : formData.description.length
            }/140`}</span>
            <Form.Item>
              <Button
                disabled={
                  (Object.is(formData.description, userInfo.description) &&
                    Object.is(formData.name, userInfo.name) &&
                    !imageFile) ||
                  !validateName ||
                  !valAstrictName
                }
                className="submit"
                type="primary"
                htmlType="submit"
              >
                保存
              </Button>
              <Button
                onClick={() => {
                  setImageFile();
                  formRef.current.setFieldsValue({
                    description: userInfo.description,
                    name: userInfo.name
                  });
                  setFormData({
                    description: userInfo.description,
                    name: userInfo.name
                  });
                  history.push('/account/home');
                }}
              >
                取消
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default User;
