import React, { useReducer, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, Input, message } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  getManageList,
  inviteUser,
  updateManageRole,
  deleteUser,
  getInviteStatus,
  acceptInvite,
} from "../../../../../utils/api/ownportfolio";
import { deBounce } from "../../../../../utils";
import "./style.scss";

const PAFE_SIZE = 12;

const types = {
  SET_DATA: "SET_DATA",
  SET_MORE_DATA: "SET_MORE_DATA",
  SET_INVITE_LOADING: "SET_INVITE_LOADING",
  SET_PAGE: "SET_PAGE",
  SET_INVITE_VAL: "SET_INVITE_VAL",
  SET_INVITE_STATUS: "SET_INVITE_STATUS",
  SET_CONFIRM_INVITE: "SET_CONFIRM_INVITE",
  SET_DELETE_ID: "SET_DELETE_ID",
  SET_MORE_STAUTS: "SET_MORE_STATUS",
  SET_TIP_TEXT: "SET_TIP_TEXT",
  TOGGLE_INVITE_VISIBLE: "TOGGLE_INVITE_VISIBLE",
  TOGGLE_TIP: "TOGGLE_TIP",
};

const initialState = {
  data: [],
  page: 2,
  deleteId: 0,
  inviteLoading: false,
  inviteVal: "",
  inviteStatus: null,
  isConfirm: false,
  visible: false,
  tipText: "",
  toggleTip: false,
  moreStatus: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_DATA:
      return { ...state, data: action.data };
    case types.SET_MORE_DATA:
      return {
        ...state,
        data: state.data.concat(action.data),
        page: action.page,
      };
    case types.SET_PAGE:
      return { ...state, page: action.page };
    case types.SET_INVITE_VAL:
      return { ...state, inviteVal: action.value };
    case types.SET_CONFIRM_INVITE:
      return { ...state, isConfirm: true };
    case types.SET_INVITE_STATUS:
      return { ...state, inviteStatus: action.status };
    case types.SET_INVITE_LOADING:
      return { ...state, inviteLoding: action.value };
    case types.SET_DELETE_ID:
      return { ...state, deleteId: action.id };
    case types.SET_TIP_TEXT:
      return { ...state, tipText: action.text };
    case types.TOGGLE_INVITE_VISIBLE:
      return { ...state, visible: !state.visible };
    case types.TOGGLE_TIP:
      return { ...state, toggleTip: action.value, tipText: action.text };
    case types.SET_MORE_STAUTS:
      return { ...state, moreStatus: false };
    default:
      return state;
  }
};

const Manage = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { id, info, exists } = props;
  const userInfo = useSelector((state) => state.userInfo);
  const { role } = info;
  const {
    data,
    page,
    inviteVal,
    inviteStatus,
    inviteLoading,
    isConfirm,
    visible,
    tipText,
    toggleTip,
    moreStatus,
  } = state;

  const confirmInvite = async () => {
    try {
      let res = await acceptInvite(id);
      if (Object.is(res.status, 200)) {
        dispatch({ type: types.SET_CONFIRM_INVITE });
        let newData = data.slice(0);
        newData.push(res.data);
        dispatch({ type: types.SET_DATA, data: newData });
      }
    } catch (err) {}
  };

  const submitInvite = async () => {
    dispatch({ type: types.SET_INVITE_LOADING, value: true });
    try {
      let res = await inviteUser(id, inviteVal.trim());
      if (Object.is(res.status, 200)) {
        message.success("邀请该用户加入当前作品集成功");
        dispatch({ type: types.TOGGLE_INVITE_VISIBLE });
        dispatch({ type: types.SET_INVITE_VAL, value: "" });
        if (toggleTip) dispatch({ type: types.TOGGLE_TIP, value: false });
      } else {
        dispatch({
          type: types.TOGGLE_TIP,
          value: true,
          text: res.data.message,
        });
      }
      dispatch({ type: types.SET_INVITE_LOADING, value: false });
    } catch (err) {
      dispatch({
        type: types.TOGGLE_TIP,
        value: true,
        text: err.response.data.message,
      });
      dispatch({ type: types.SET_INVITE_LOADING, value: false });
    }
  };

  const updateRoleHandler = (user) => {
    return async () => {
      try {
        let res = await updateManageRole(id, user.id, { role: "manager" });
        if (Object.is(res.status, 200)) {
          let newData = data.slice(0);
          let index = newData.findIndex(
            (item) => item.user_id === user.user_id
          );
          newData.splice(index, 1);
          user.role = "manager";
          newData.push(user);
          dispatch({
            type: types.SET_DATA,
            data: formatManageList(newData),
          });
          message.success("给该成员升职成功");
        }
      } catch (err) {
        message.error("操作失败，请稍后再试");
      }
    };
  };

  const deleteRoleHandler = (user) => {
    return async () => {
      if (!window.navigator.onLine) {
        message.error("操作失败，请稍后再尝试");
        return;
      }
      try {
        let res = await deleteUser(id, user.id);
        if (Object.is(res.status, 204)) {
          let newData = data.slice(0);
          let index = newData.findIndex(
            (item) => item.user_id === user.user_id
          );
          newData.splice(index, 1);
          dispatch({
            type: types.SET_DATA,
            data: formatManageList(newData),
          });
          message.success("将该成员移出当前作品集成功");
        }
      } catch (err) {
        message.error("操作失败，请稍后再尝试");
      }
    };
  };

  const formatManageList = (data) => {
    let result = [];
    const roleListMap = new Map([
      ["owner", []],
      ["manager", []],
      ["curator", []],
    ]);

    data.forEach((item) => {
      roleListMap.set(item.role, roleListMap.get(item.role).concat(item));
    });

    for (let value of roleListMap.values()) {
      result = result.concat(value);
    }
    return result;
  };

  const getMoreList = async () => {
    try {
      let res = await getManageList(id, {
        offset: (page - 1) * PAFE_SIZE + 1,
        limit: PAFE_SIZE,
      });
      res.data.length
        ? dispatch({
            type: types.SET_MORE_DATA,
            data: formatManageList(res.data),
            page: page + 1,
          })
        : dispatch({
            type: types.SET_MORE_STAUTS,
          });
    } catch (err) {}
  };

  const onChangeInput = (e) =>
    dispatch({ type: types.SET_INVITE_VAL, value: e.target.value });

  const filterRoleList = (role) =>
    data.filter((item) => Object.is(item.role, role));

  const updateRole = (item) =>
    Object.is(item.role, "curator") &&
    (Object.is(role, "owner") || Object.is(role, "manager"));
  const renderList = (data) =>
    data.map((item) => (
      <div className="roleItem" key={item.user_id}>
        <div className="coverWrapper">
          <img src={item.avatar_url} alt="avatar" />
          {userInfo && role !== "visitor" ? (
            item.role !== "owner" ? (
              <div className="mask">
                {updateRole(item) ? (
                  <p
                    className="update actions"
                    onClick={deBounce(updateRoleHandler(item), 300)}
                  >
                    升职
                  </p>
                ) : null}
                {Object.is(role, "owner") ||
                Object.is(role, "manager") ||
                (Object.is(userInfo?.id, item.user_id) &&
                  Object.is(role, "curator")) ? (
                  <p
                    className="delete"
                    onClick={deBounce(deleteRoleHandler(item), 300)}
                  >
                    移出
                  </p>
                ) : null}
              </div>
            ) : null
          ) : null}
        </div>
        <Link to={`/account/home/${item.user_name}`}>
          <p className="name">{item.name || item.user_name}</p>
        </Link>
      </div>
    ));
  useEffect(() => {
    // if (userInfo) {
    getManageList(id, { offset: 0, limit: 13 }).then((res) => {
      dispatch({
        type: types.SET_DATA,
        data: formatManageList(res.data),
      });
    });
    if (userInfo) {
      getInviteStatus(id).then((res) => {
        if (Object.is(res.status, 200)) {
          dispatch({
            type: types.SET_INVITE_STATUS,
            status: res.data.is_invited_user,
          });
        }
      });
    }
  }, [id, userInfo]);

  return (
    <div id="manageWrapper">
      {inviteStatus && Object.keys(info).length ? (
        <div className="inviteMsg">
          <>
            {isConfirm ? (
              <>
                <InfoCircleFilled className="tipIcon" />
                <p className="text">
                  太棒了，你现在已经成为这个作品集的「助理」了
                </p>
              </>
            ) : (
              <>
                <InfoCircleFilled className="tipIcon" />
                <p className="text">
                  你被邀请成为当前作品集的「助理」{" "}
                  <span onClick={confirmInvite}>点击接受邀请</span>
                </p>
              </>
            )}
          </>
        </div>
      ) : null}
      {exists && userInfo && !Object.is(role, "visitor") ? (
        <div className="actionBar">
          <Button
            icon={
              <img
                width="12"
                style={{ marginBottom: "2px", marginRight: "8px" }}
                height="12"
                src="/image/icon-add.png"
                alt="add"
              />
            }
            type="link"
            size="small"
            onClick={() => {
              dispatch({ type: types.TOGGLE_INVITE_VISIBLE });
            }}
          >
            添加成员
          </Button>
        </div>
      ) : null}
      <div className="list">
        <div className="listItem">
          <div className="roleTitle">创建者 Owner</div>
          <div className="roleList">{renderList(filterRoleList("owner"))}</div>
        </div>
        <div className="listItem">
          <div className="roleTitle">管理员 Managers</div>
          {filterRoleList("manager").length ? (
            <div className="roleList">
              {renderList(filterRoleList("manager"))}
            </div>
          ) : null}
        </div>
        <div className="listItem">
          <div className="roleTitle">助理 Curators</div>
          {filterRoleList("curator").length ? (
            <div className="roleList">
              {renderList(filterRoleList("curator"))}
            </div>
          ) : null}
        </div>
        {moreStatus && data.length >= 13 ? (
          <div className="loadMore" onClick={getMoreList}>
            加载更多
          </div>
        ) : null}
      </div>
      <Modal
        className="inviteModal"
        visible={visible}
        footer={null}
        width={480}
        centered={true}
        getContainer={false}
        onCancel={() => {
          dispatch({ type: types.TOGGLE_INVITE_VISIBLE });
          dispatch({ type: types.TOGGLE_TIP, value: false, text: "" });
          dispatch({ type: types.SET_INVITE_VAL, value: "" });
        }}
      >
        <div className="title">
          <h3 className="text">邀请他人加入作品集</h3>
          <p className="descr">对方接受邀请后将成为当前作品集的「助理」</p>
        </div>
        <div className="content">
          <div className="searchWrapper">
            <Input
              className="input"
              placeholder="请输入对方的用户名（登录时使用的 ID）"
              value={inviteVal}
              onChange={onChangeInput}
            />
            <Button
              onClick={deBounce(submitInvite, 300)}
              className="submitBtn"
              type="primary"
              loading={inviteLoading}
              disabled={!inviteVal.length}
            >
              搜索并邀请
            </Button>
          </div>
          {toggleTip && <div className="tips">{tipText}</div>}
        </div>
      </Modal>
    </div>
  );
};

export default Manage;
