import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector,useDispatch} from 'react-redux';
import {
  checkInvitationCode,
  bindingInvitationCode,
  getUserInfo,
  handlerWechatUnbind,
  getWXQRCode,
  changeUsername
} from './../../utils/request';
import { QuestionCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { Form, Input, Button, Tooltip, message, Modal } from 'antd';
import './style.scss';
import ConfirmModal from './../../components/confirmModal';
import { urlToJson } from './../../utils';
import { logout } from './../../store/actions';

let childWindow = null;
export default (props) => {
  // const userInfo = useSelector(state => state.userInfo);
  const formRef = useRef();
  const dispatch = useDispatch();
  const initialState = useSelector(state => state.userInfo);
  const allCampus = useSelector(state => state.allCampus);
  const [userInvitation, setUserInvitation] = useState(false);
  const [isUnfold, setIsUnfold] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [formData, setFormData] = useState({ verificationCode: '' });
  const [codeHelpIcon, setCodeHelpIcon] = useState(true);
  const [form] = Form.useForm();
  const [userInfo, setUserInfo] = useState(initialState);
  const [submitErr, setSubmitErr] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    title: null,
    content: null,
    iconType: null,
    modalType: null
  });

  useEffect(()=>{
    if (allCampus.length > 0 && allCampus.find(f => f.is_select).status !== '1') {
      window.location.href = '/403'
    }
    if (!showReset && formRef.current) {
      formRef.current.resetFields(['username'])
    }
    // eslint-disable-next-line
  },[ showReset ])
  const onFinish = values => {
    checkInvitationCode({
      invitation_code: values.verificationCode,
      user_name: userInfo?.username
    })
      .then(() => {
        return bindingInvitationCode({
          invitation_code: values.verificationCode
        });
      })
      .then(() => {
        setCodeHelpIcon(false);
        setUserInvitation(true);
        setIsUnfold(false);
        message.success('已成功激活教师账户使用权限');
        getUserInfo().then(res => {
          setUserInfo(res.data);
        });
        setFormData({ verificationCode: '' });
        form.setFields([
          {
            name: 'verificationCode',
            value: ''
          }
        ]);
      })
      .catch(e => {
        if (typeof e.response.data === 'string') return;
        form.setFields([
          {
            errors: [e.response.data.invitation_code],
            name: 'verificationCode'
          }
        ]);
        setCodeHelpIcon(true);
      });
  };
  const verificationCodeOnBlur = useCallback(() => {
    if (!formData.verificationCode) return;
    checkInvitationCode({
      invitation_code: formData.verificationCode,
      user_name: userInfo?.username
    })
      .then(() => {
        setCodeHelpIcon(false);
      })
      .catch(e => {
        if (typeof e.response.data === 'string') return;
        form.setFields([
          {
            errors: [e.response.data.invitation_code],
            name: 'verificationCode'
          }
        ]);
        setCodeHelpIcon(true);
      });
  }, [formData, userInfo, form]);
  const unfoldClick = () => {
    if (isUnfold) {
      form.resetFields();
      setCodeHelpIcon(true);
      setFormData({});
    }
    setIsUnfold(!isUnfold);
  };
  const onValuesChange = (changedValues, allValues) => {
    setFormData(allValues);
  };

  // 扫描二维码绑定++++++
  const handle = data => {
    const _obj = urlToJson(data);
    if (_obj.message.includes('您选择的微信账号')) {
      handlerSet({
        title: '无法关联账号',
        iconType: 'warning',
        content: _obj.message,
        modalType: 'w1',
        visible: true
      });
    } else {
      handlerSet({
        title: '账号关联成功',
        iconType: 'success',
        content: _obj.message,
        modalType: 's1',
        visible: true
      });
      getUserInfo().then(res => {
        setUserInfo(res.data);
      });
    }

    if (childWindow) {
      childWindow.close();
    }
  };
  const handleMessage = e => {
    if (e.origin !== window.location.origin) return;
    if (e.data) {
      handle(e.data);
    }
  };
  // 获取微信二维码
  const getWechatCode = async () => {
    window.removeEventListener('message', handleMessage, false);
    if (childWindow) {
      childWindow.close();
    }
    window.addEventListener('message', handleMessage, false);
    let iLeft = (window.screen.availWidth - 500) / 2;
    const { data } = await getWXQRCode({ user_id: userInfo?.id });
    if (data.success) {
      childWindow = window.open(
        data.redirect_uri,
        'postMessage',
        `width=500,height=500,left=${iLeft},menubar=yes,location=no,resizable=yes,scrollbars=true,status=true`
      );
    }
    let _timer = window.setInterval(() => {
      if (childWindow && childWindow.closed) {
        clearInterval(_timer);
        window.removeEventListener('message', handleMessage, false);
      }
    }, 1000);

    // 针对qq浏览器返回的信息
    window.addEventListener(
      'storage',
      e => {
        if (e.key === 'openerMessage' && e.newValue) {
          handle(e.newValue);
          localStorage.removeItem('openerMessage');
        }
      },
      false
    );
  };
  // end++++++
  const handlerSet = parmas => {
    const { title, iconType, content, modalType, visible } = parmas;
    setModalMessage({ title, content, iconType, modalType });
    setModalVisible(visible);
  };
  // 微信关联/解除
  const handlerOperate = open_id => {
    if (open_id) {
      handlerSet({
        title: '解除关联',
        iconType: 'warning',
        content: '解除关联后，您将无法使用微信扫码登录，确定解除吗？',
        modalType: 'w2',
        visible: true
      });
    } else {
      getWechatCode();
    }
  };
  const handleCancel = () => {
    setModalVisible(false);
    setModalMessage({ title: null, content: null, iconType: null });
  };
  const handleConfirm = () => {
    if (modalMessage.modalType === 'w2') {
      // 解绑
      handlerWechatUnbind().then(res => {
        setModalVisible(false);
        if (res.data.success) {
          getUserInfo().then(res => {
            setUserInfo(res.data);
          });
          message.success(res.data.message);
        } else {
          message.warn(res.data.message);
        }
      });
    } else {
      setModalVisible(false);
    }
  };
  const showResetUserName = () => {
    setShowReset(true)
    form.setFieldsValue({
      username:''
    })
  }
  const handleSubmitReset = () => {
    formRef.current.submit()
  }

  const submitResetName = (values) =>{
    changeUsername(values).then(res=>{
      setSubmitErr({})
      dispatch(logout());
      props.history.push('/login');
    }).catch(err=>{
      setSubmitErr({
        validateStatus:"error",
        help:err.response.data.message
      })
    })
  }

  return (
    <div id="accountSetting">
      <div className="container">
        <div className="header">账户设置</div>

        <div className="modificationpsw">
          <div>
            <div className="title">用户名</div>
            <div>
              {userInfo?.username}
            </div>
            {!userInfo.changed_username && userInfo.username.startsWith('aim_') && <div className='username_desc'>当前用户名为系统自动生成。您仅有一次重设用户名的机会。</div>}
          </div>
          {
            !userInfo.changed_username && userInfo.username.startsWith('aim_') && <div className="resetbtn">
            <Button type='link' style={{padding:'4px 0px'}} onClick={ showResetUserName }>重设用户名</Button>
            </div>
          }
        </div>
        <div className="modificationpsw">
          <div>
            <div className="title">
              {userInfo?.has_password ? '修改密码' : '设置密码'}
            </div>
            <div>
              为确认是您本人操作，
              {userInfo?.has_password ? '修改密码' : '设置密码'}
              需要验证您之前注册本账户时的手机号。
            </div>
            <div>
              为保证您的账户安全，在
              {userInfo?.has_password ? '修改密码' : '设置密码'}
              成功后，您的账户会暂时退出，需使用
              {userInfo?.has_password ? '新的' : null}密码重新登录。
            </div>
          </div>
          <div className="resetbtn">
            <Link to="/resetpassword/">
              {userInfo?.has_password ? '修改密码' : '设置密码'}
            </Link>
          </div>
        </div>
        <div className="modificationpsw">
          <div>
            <div className="title">手机号</div>
            <div>
              {userInfo?.phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')}
            </div>
          </div>
        </div>
        <div className="modificationpsw">
          <div>
            <div className="title">关联微信账号</div>
            <div>关联后您可以使用微信扫码登录教学云</div>
          </div>
          <div className="resetbtn">
            <span
              className="_relation"
              onClick={() => handlerOperate(userInfo?.open_id)}
            >
              {userInfo?.open_id ? '解除关联' : '关联账号'}
            </span>
          </div>
        </div>
        {/* 
           判断是否校区内用户，是就隐藏，否则显示
        */}

        {JSON.stringify(userInfo?.organization_data) !== '{}' ? null : (
          <div
            className="accUpgrade"
            style={{ paddingBottom: isUnfold ? '32px' : '16px' }}
          >
            <div className="accUpgradeCon">
              <div>
                <div className="title">账户升级</div>
                <div>
                  {userInvitation ? (
                    <div>
                      已通过邀请码激活教师账户使用权限&nbsp;&nbsp;
                      <CheckCircleFilled style={{ color: '#52c41a' }} />
                      <p>
                        教学资源服务有效期：
                        {userInfo?.courses_server_start} ～{' '}
                        {userInfo?.courses_server_end_date}
                      </p>
                    </div>
                  ) : userInfo?.user_is_used_invitation ? (
                    <div>
                      已通过邀请码激活教师账户使用权限&nbsp;&nbsp;
                      <CheckCircleFilled style={{ color: '#52c41a' }} />
                      <p>
                        教学资源服务有效期：
                        {userInfo?.courses_server_start} ～{' '}
                        {userInfo?.courses_server_end_date}
                      </p>
                    </div>
                  ) : (
                    <div>通过邀请码激活教师账户使用权限</div>
                  )}
                </div>
              </div>
              <div
                className="UnfoldText"
                // onClick={() => setIsUnfold(!isUnfold)}
                onClick={unfoldClick}
              >
                {isUnfold ? '收起' : '输入邀请码'}
              </div>
            </div>
            <div style={{ display: isUnfold ? 'block' : 'none' }}>
              <Form
                name="basic"
                ref={formRef}
                form={form}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
              >
                <Form.Item style={{ position: 'relative' }}>
                  <Form.Item
                    name="verificationCode"
                    rules={[{ required: true, message: '请输入邀请码' }]}
                  >
                    <Input
                      placeholder="英荔教学云邀请码"
                      maxLength="20"
                      style={{ width: '198px' }}
                      onBlur={verificationCodeOnBlur}
                      suffix={
                        <div>
                          {codeHelpIcon || !formData.verificationCode ? (
                            <Tooltip title="邀请码仅限英荔教育授权教师使用，如需获取，请致电 400-931-8118">
                              <QuestionCircleFilled
                                style={{ color: '#e0e0e0' }}
                              />
                            </Tooltip>
                          ) : (
                            <CheckCircleFilled style={{ color: '#52c41a' }} />
                          )}
                        </div>
                      }
                    />
                  </Form.Item>
                  <Button
                    disabled={!formData.verificationCode}
                    type="primary"
                    htmlType="submit"
                    style={{ position: 'absolute', left: '206px', top: 0 }}
                  >
                    激活
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}
      </div>
      <Modal width={374} visible={showReset} centered title="重设用户名" className='reset_username' onOk={handleSubmitReset} onCancel={() => {setShowReset(false); setSubmitErr({})}}>
        <Form
          form={form}
          name="resetName"
          layout="inline"
          ref={formRef}
          onFinish={values => {
            submitResetName(values)
          }}>
          <Form.Item
            name="username" 
            style={{ width: '294px', margin: '10px 0  20px 16px' }}
            rules={[{ required: true, message: '请输入用户名' }, {
              validator: (rule, value) => {
                if (value && !isNaN(value)) {
                  return Promise.reject(new Error('请勿使用纯数字的用户名或官方专用词组'))
                }
                return Promise.resolve()
              }
            }]}
            {...submitErr}
          >
            <Input placeholder="用户名" maxLength={20} onChange={() => setSubmitErr({})} />
          </Form.Item>
        </Form>
      </Modal>
      <ConfirmModal
        title={modalMessage.title}
        visible={modalVisible}
        iconType={modalMessage.iconType}
        onCancel={handleCancel}
        contentNode={<div>{modalMessage.content}</div>}
        bottomNode={
          <>
            {modalMessage.modalType === 'w2' && (
              <Button onClick={handleCancel}>取消</Button>
            )}
            <Button type="primary" onClick={handleConfirm}>
              {modalMessage.modalType === 'w1' ? '我知道了' : '确定'}
            </Button>
          </>
        }
      />
    </div>
  );
};
