import { isMobile } from "../../utils"
import { Modal } from "antd"
import React from 'react'
import MobileModal from 'antd-mobile/es/components/modal'
import "./style.scss"
// 公共弹窗
export const MPModal = (props) => {
  if (isMobile()) {
    return <MobileModal bodyClassName="MobileModal" content={props?.children} {...{visible: props.open, ...props}}>
    </MobileModal>
  } else {
    return <Modal {...props}>
      {props?.children}
    </Modal>
  }
}