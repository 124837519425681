/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useReducer,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
// import {useSelector} from 'react-redux'
import { Button, Input, Table, Modal, message, Divider, Form } from 'antd';
import dayjs from 'dayjs';
// import { useSelector } from 'react-redux'
import {
  createOwnPortfolio,
  getOwnPortfolio,
  getOwnPortfolioCount,
} from './../../../utils/request';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { PlusOutlined } from '@ant-design/icons';
import { deBounce } from './../../../utils';
import { coverMap } from './../../../utils/variable';
import './style.scss';

const roleMap = new Map([
  ['owner', '创建者'],
  ['manager', '管理员'],
  ['curator', '助理'],
]);

const types = {
  FETCH_PORTFOLIO_SUCCESS: 'FETCH_PORTFOLIO_SUCCESS',
  FETCH_PORTFOLIO_FAILURE: 'FETCH_PORTFOLIO_FAILURE',
  TOGGLE_CREATE_MODAL: 'TOGGLE_CREATE_MODAL',
  SET_DATA: 'SET_DATA',
  SET_FORM_DATA: 'SET_FORM_DATA',
  SET_PAGE: 'SET_PAGE',
  SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.TOGGLE_CREATE_MODAL:
      return { ...state, toggleCreateModal: !state.toggleCreateModal };
    case types.SET_FORM_DATA:
      return { ...state, formData: action.data };
    case types.SET_DATA:
      return { ...state, data: action.data, total: action.total };
    case types.SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case types.SET_SEARCH_VALUE:
      return {
        ...state,
        searchVal: action.value !== null ? action.value : state.searchVal,
        isSearch: action.status !== null ? action.status : state.status,
      };
    default:
      return state;
  }
};

const OwnPortfolio = (props, ref) => {
  const location = useLocation();
  const initialState = {
    data: [],
    total: 0,
    loaded: false,
    toggleCreateModal: qs.parse(location.search).create === 'new',
    formData: { title: '', description: '' },
    page: 1,
    pageSize: 8,
    searchVal: null,
    isSearch: false,
  };
  const history = useHistory();
  // const userInfo = useSelector(state => state.userInfo)
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    data,
    total,
    toggleCreateModal,
    formData,
    page,
    pageSize,
    searchVal,
    isSearch,
  } = state;

  const formRef = useRef();
  const searchRef = useRef();

  useImperativeHandle(ref, () => ({
    createPortfolioHandler,
  }));
  const columns = [
    {
      title: '名称',
      dataIndex: 'studio',
      key: 'studio',
      width: 500,
      render: (text, record) => (
        <Link to={`/manage/ownportfolio/${record.id}/project`}>
          <span className="name">
            <img
              src={
                record.image ? getCover(record.image) : coverMap[record.origin]
              }
              width="48px"
              alt="cover"
            />
            {record.title}
          </span>
        </Link>
      ),
    },
    {
      title: '身份',
      dataIndex: 'role',
      key: 'role',
      align: 'left',
      width: 200,
      render: (text) => (text ? roleMap.get(text) : '-'),
    },
    {
      title: '作品数',
      dataIndex: 'num_project',
      key: 'num_project',
      align: 'left',
      width: 200,
      render: (value, record) =>
        !record.is_touch && record.stats.num_project === 0 ? (
          <Link to={`/manage/ownportfolio/${record.id}/project?create=new`}>
            <Button>添加作品</Button>
          </Link>
        ) : (
          <Link to={`/manage/ownportfolio/${record.id}/project?create=new`}>
            <span className="projects">{record.stats.num_project}</span>
          </Link>
        ),
    },
    {
      title: '创建时间',
      dataIndex: 'created',
      key: 'created',
      align: 'left',
      width: 200,
      render: (value, record) =>
        dayjs(record.history.created).format('YYYY/MM/DD'),
    },
  ];

  const getCover = (url) => {
    let res = 'https://' + window.location.host;
    if (url.indexOf('/') !== 0) res += '/';
    return res + url;
  };

  const createPortfolioHandler = () => {
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再尝试');
      return;
    }
    createOwnPortfolio({
      title: `个人作品集-${total + 1}`,
      description: '',
    }).then((res) => {
      if (Object.is(res.status, 201)) {
        history.replace('/manage/ownportfolio');
        message.success('新建作品集成功');
        history.push(`/manage/ownportfolio/${res.data.id}/project`);
      }
    });
  };

  const onSearchHandler = (value) => {
    history.push({
      path: history.location.pathname,
      search: qs.stringify({ page: 1 }),
    });
    dispatch({ type: types.SET_PAGE, page: 1 });
    dispatch({
      type: types.SET_SEARCH_VALUE,
      value,
      status: true,
    });
  };

  const fetchPortfolio = (params) => {
    return new Promise((resolve, rejct) => {
      getOwnPortfolio(params).then((res) => {
        if (Object.is(res.status, 200)) resolve(res.data);
      });
    });
  };

  const fetchPortfolioCount = (params) => {
    return new Promise((resolve, reject) => {
      getOwnPortfolioCount(params).then((res) => {
        if (Object.is(res.status, 200)) resolve(res.data);
      });
    });
  };

  const fetchOwnPortfolios = () => {
    const searchParam = {
      ...(searchVal ? { q: searchVal.trim() } : null),
    };

    const params = {
      offset: (page - 1) * pageSize,
      limit: pageSize,
      ...searchParam,
    };

    Promise.all([
      fetchPortfolio(params),
      fetchPortfolioCount(searchParam),
    ]).then((res) => {
      let [data, count] = res;
      dispatch({
        type: types.SET_DATA,
        data,
        total: count.studio_count,
      });
    });
  };

  // const searchAllPortfolio = (e) => {
  //   dispatch({
  //     type: types.SET_PAGE,
  //     page: 1,
  //   });
  //   if (page === 1 && !history.location.search) {
  //     fetchOwnPortfolios();
  //   }
  //   dispatch({
  //     type: types.SET_SEARCH_VALUE,
  //     value: "",
  //     status: false,
  //   });
  //   searchRef.current.input.handleReset(e);
  //   history.push({
  //     path: history.location.pathname,
  //     search: "",
  //   });
  // };

  const formFinishHandler = (value) => {
    getOwnPortfolio({ q: value.title })
      .then((res) => {
        if (
          res.data.length &&
          res.data.find((item) => item.title === value.title)
        ) {
          return Promise.reject({ exist: true });
        }
        return createOwnPortfolio(value);
      })
      .then((res) => {
        if (Object.is(res.status, 201)) {
          formRef.current.setFieldsValue({ title: '', description: '' });
          history.replace('/manage/ownportfolio');
          dispatch({ type: types.TOGGLE_CREATE_MODAL });
          message.success('新建作品集成功');
          history.push(`/manage/ownportfolio/${res.data.id}/project`);
        }
      })
      .catch((err) => {
        if (err.exist) {
          message.warn('您已创建过该作品集，请更换名称');
          return;
        }
        message.error('新建作品集失败，请稍后再试');
      });
  };

  useEffect(() => {
    fetchOwnPortfolios();
  }, [page, searchVal]);

  return (
    <div id="ownportfolio">
      <div className="container">
        {isSearch || data.length ? (
          <React.Fragment>
            <div className="actionBar">
              {/* <Button
                type="primary"
                className="btn-margin"
                onClick={searchAllPortfolio}
              >
                全部
              </Button> */}
              <Button
                icon={<PlusOutlined />}
                type="primary"
                size="large"
                onClick={createPortfolioHandler}
              >
                新建作品集
              </Button>
              <div className="search">
                <Input.Search
                  ref={searchRef}
                  placeholder="搜索作品集名称"
                  onSearch={onSearchHandler}
                  style={{ width: 280, borderRadius: '4px' }}
                />
              </div>
            </div>
            <div className="table">
              <Table
                columns={columns}
                dataSource={data}
                rowKey={'id'}
                pagination={{
                  total,
                  current: page,
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  onChange: (val) => {
                    dispatch({
                      type: types.SET_PAGE,
                      page: val,
                    });
                  },
                  showTotal: (total, range) =>
                    `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`,
                }}
              />
            </div>
          </React.Fragment>
        ) : (
          <div className="nodata">
            <img src="/image/empty-page.png" alt="empty-page" />
            <div className="tips">暂无个人作品集</div>
            <div>
              <Button type="primary" onClick={createPortfolioHandler}>
                立即创建
              </Button>
            </div>
          </div>
        )}
      </div>
      <Modal
        closeIcon={
          <img src="/image/close.png" alt="close" width="14px" height="14px" />
        }
        className="createModal"
        visible={toggleCreateModal}
        onCancel={() => {
          history.replace('/manage/ownportfolio');
          formRef.current.setFieldsValue({ title: '', description: '' });
          dispatch({
            type: types.SET_FORM_DATA,
            data: { title: '', description: '' },
          });
          dispatch({ type: types.TOGGLE_CREATE_MODAL });
        }}
        okButtonProps={{ disabled: !formData.title }}
        okText="保存"
        onOk={deBounce(() => {
          formRef.current.submit();
          console.log(9999999);
        }, 1000)}
        width={500}
        centered={true}
        getContainer={false}
        destroyOnClose={true}
        maskClosable={false}
      >
        <div className="title">新建作品集</div>
        <Divider style={{ margin: '12px 0' }} />
        <div className="content">
          <Form
            ref={formRef}
            initialValues={formData}
            layout="vertical"
            onValuesChange={(value, allValue) =>
              dispatch({ type: types.SET_FORM_DATA, data: allValue })
            }
            onFinish={formFinishHandler}
          >
            <Form.Item
              label="作品集名称"
              name="title"
              rules={[{ required: true, message: '请输入作品集名称' }]}
            >
              <Input
                maxLength="15"
                placeholder="请输入作品集名称"
                suffix={
                  <span className="suffix">{`${Math.min(
                    formData.title.length,
                    15
                  )}/15`}</span>
                }
              ></Input>
            </Form.Item>
            <Form.Item
              label="作品集介绍"
              name="description"
              rules={[{ required: false }]}
            >
              <Input.TextArea
                placeholder="作品集介绍将在作品集主页里展示给每一位来访者。"
                maxLength="200"
                onChange={(e) => {
                  if (e.target.value.length > 200) {
                    formRef.current.setFieldsValue({
                      description: e.target.value.slice(0, 200),
                    });
                  }
                }}
                style={{ height: '120px', resize: 'none' }}
              ></Input.TextArea>
            </Form.Item>
            <span className="textAreaSuffix">{`${
              formData.description.length > 200
                ? 200
                : formData.description.length
            }/200`}</span>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default forwardRef(OwnPortfolio);
