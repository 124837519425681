import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { logout, setOfflineNotification_show } from './../../store/actions';
// import CommonModal from './../../components/commonModal';
import ConfirmModal from '../../components/confirmModal';

const OfflineNotification = props => {
  const dispatch = useDispatch();
  const offlineNotification_show = useSelector(
    state => state.offlineNotification_show
  );
  const userInfo = useSelector(state => state.userInfo);
  const [count, setCount] = useState(3);

  const handleClick = () => {
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再尝试');
      return null;
    }
    dispatch(logout());
    if (
      document.domain.indexOf('aimaker.space') > -1 ||
      document.domain.indexOf('longan.link') > -1
    ) {
      window.websocket.webSocketClose('wss://socket.aimaker.space/ws/login/');
    } else {
      window.websocket.webSocketClose('wss://socket.dev.eliteu.xyz/ws/login/');
    }
    dispatch(setOfflineNotification_show(false));
    // localStorage.removeItem('offshow');

    // props.history.push('/login');
    // setVisibleModal(false);
  };
  useEffect(() => {
    setCount(3);
    return () => {
      setCount(3);
    };
  }, []);
  useEffect(() => {
    // if (
    //   userInfo &&
    //   (localStorage.getItem('offshow') || offlineNotification_show)
    // ) {
    if (userInfo && offlineNotification_show) {
      let a = 3;
      setCount(a);
      let timer = setInterval(hanldeCount, 1000);
      function hanldeCount() {
        --a;
        setCount(a);

        if (a <= 0) {
          clearInterval(timer);
          handleClick();
        }
      }
    }
  }, [userInfo, offlineNotification_show]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ConfirmModal
      title="下线通知"
      visible={userInfo && offlineNotification_show}
      iconType="warning"
      contentNode={
        <div>
          <span>你的账号已在其他地方登录，{count}s 后将自动下线。</span>
        </div>
      }
      bottomNode={
        <>
          <Button
            type="primary"
            onClick={handleClick}
            // style={{ borderColor: '#faad14' }}
          >
            确定
          </Button>
        </>
      }
    />
  );
};
export default OfflineNotification;
