import React, {
  useState,
  useCallback,
  useRef,
  useLayoutEffect,
  useEffect
} from 'react';
import './style.scss';
import { Button, Input, Table, Modal, message } from 'antd';
import qs from 'query-string';
import { useHistory, Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { scratchURL } from '../../utils';
import {
  getFavoritesList,
  clearFavorite,
  clearFavorites
} from '../../utils/request';
import { InfoCircleFilled } from '@ant-design/icons';

const Favoriates = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(8);
  const [total, setTotal] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [current, setCurrent] = useState(
    Number(qs.parse(history.location.search).page) || 1
  );
  const [searchValue, setSearchValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const searchRef = useRef();

  const onSearchHandler = useCallback(
    value => {
      history.push({
        path: history.location.pathname,
        search: qs.stringify({ page: 1 })
      });
      setCurrent(1);
      setSearchValue(value);
      setIsSearch(true);
    },
    [history]
  );

  const getAllFavoritesList = useCallback(() => {
    return getFavoritesList({
      page: current,
      page_size: pageSize,
      ...(searchValue ? { q: searchValue } : null)
    })
      .then(res => {
        console.log(res.data);
        setData(
          res.data.results.map((item, index) => ({ ...item, key: index }))
        );
        setTotal(res.data.count);
      })
      .catch(e => {
        console.error(e);
      });
  }, [current, pageSize, searchValue]);

  useEffect(() => {
    getAllFavoritesList().then(() => setLoaded(true));
  }, [getAllFavoritesList, current]);

  const clearFavoritesHandler = useCallback(() => {
    clearFavorites()
      .then(res => {
        console.log(res);
        message.success('清空收藏夹成功');
        setShowDeleteModal(false);
        getAllFavoritesList();
      })
      .catch(e => {
        message.error('清空收藏夹失败，请稍后再试');
        console.log(e);
      });
  }, [getAllFavoritesList]);

  const clearFavoriteHandler = useCallback(
    id => {
      clearFavorite(id)
        .then(res => {
          getAllFavoritesList();
        })
        .catch(e => {
          console.log(e);
        });
    },
    [getAllFavoritesList]
  );

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <a
          href={scratchURL + `/projects/${record.project_id}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="name">
            <img src={record.image} width="48px" alt="cover" />
            {record.title}
          </span>
        </a>
      )
    },
    {
      title: '作者',
      dataIndex: 'author',
      key: 'autor',
      align: 'left',
      width: '30%',
      render: (text, record) => (
        <Link
          className="author"
          to={`/account/home/${record.username}`}
          target="_blank"
        >
          {record.name || record.username}
        </Link>
      )
    },
    {
      title: '收藏时间',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'left',
      width: '25%',
      render: (value, record) =>
        dayjs(record.created_at).format('YYYY/MM/DD HH:mm')
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'left',
      width: 60,
      render: (value, record) => (
        <div className="action">
          <Button
            size="small"
            className="delete"
            type="link"
            onClick={() => clearFavoriteHandler(record.project_id)}
          >
            删除
          </Button>
        </div>
      )
    }
  ];

  useLayoutEffect(() => {
    document.querySelector(
      '.ant-table-pagination .ant-select-selection-item'
    ) &&
      (document.querySelector(
        '.ant-table-pagination .ant-select-selection-item'
      ).innerText = `${pageSize} 条/页`);
  }, [data.length, loaded]); // eslint-disable-line

  return (
    <div id="favoriates">
      <div className="container">
        <div className="titleBar">
          <div className="title">我的收藏</div>
          {/* {data.length || isSearch  || !loaded ? (
            <a href={scratchURL} target="_blank" rel="noopener noreferrer">
              <Button type="primary">更多作品</Button>
            </a>
          ) : null} */}
        </div>
        {data.length || isSearch || !loaded ? (
          <div className="actionBar">
            <Button onClick={() => setShowDeleteModal(true)}>清空收藏夹</Button>
            <Input.Search
              ref={searchRef}
              placeholder="搜索作品名称/作者"
              onSearch={onSearchHandler}
              style={{ width: 280, borderRadius: '4px' }}
            />
          </div>
        ) : null}
        <div className="table">
          {data.length || isSearch ? (
            <Table
              columns={columns}
              dataSource={data}
              rowKey={'id'}
              pagination={{
                current: current,
                total,
                showQuickJumper: true,
                defaultPageSize: pageSize,
                hideOnSinglePage: !data.length,
                onChange: val => {
                  console.log('onchang', val);
                  history.push({
                    path: history.location.pathname,
                    search: qs.stringify({
                      ...qs.parse(history.location.search),
                      page: val
                    })
                  });
                  setCurrent(val);
                },
                onShowSizeChange: (cur, size) => {
                  history.push({
                    path: history.location.pathname,
                    search: qs.stringify({
                      ...qs.parse(history.location.search),
                      page: 1
                    })
                  });
                  setCurrent(1);
                  setPageSize(size);
                },
                showTotal: (total, range) =>
                  `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`
              }}
            />
          ) : (
            <div className="nodata">
              <img src="/image/empty-page.png" alt="empty-page" />
              <div className="tips">暂无收藏，快去社区浏览更多作品吧</div>
              <div>
                <a
                  href={`${scratchURL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button type="primary">前往社区</Button>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        closable={false}
        className="deleteConfirmModal"
        visible={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        footer={null}
        width={433}
        centered={true}
        getContainer={false}
      >
        <div className="title">
          <InfoCircleFilled className={'errorIcon'} />
          <span className="detail">是否确定清空收藏夹？</span>
        </div>
        <p className="content">
          清空后，历史收藏的记录将无法被复原，请谨慎操作。
        </p>
        <div className="action">
          <Button onClick={() => setShowDeleteModal(false)}>取消</Button>
          <Button danger onClick={clearFavoritesHandler}>
            确定
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Favoriates;
