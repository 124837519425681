import React, { useEffect, useState, useRef, useCallback } from 'react';
import QueueAnim from 'rc-queue-anim';
import './style.scss';
import { useParams, Link } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Menu,
  Select,
  Modal,
  Divider
} from 'antd';
import LoginWithCode from './../../components/longinWithCode';
import {
  CaretDownOutlined,
  InfoCircleFilled,
  CloseOutlined,
  WechatFilled
} from '@ant-design/icons';
import { testPhone, useCutDown, removeToken, urlToJson } from './../../utils';
import { getLoginCaptcha, joinTeam, getWXQRCode } from './../../utils/request';
import { useDispatch } from 'react-redux';
import {
  loginAndSetToken,
  loginAndSetTokenByWechat
} from './../../store/actions';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import '../../utils/browserPrompt';
import { getProgramPlatformHash } from '../../utils/api/programPlatform';

let childWindow = null;

const Login = ({ history, location, ...props }) => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState('account');
  const prevCurrent = useRef('account');
  const [formData, setFormData] = useState({
    agreement: false,
    prefix: '86',
    phone: undefined
  });
  const [forgetModal, setForgetModal] = useState(false);
  const [coldDown, coldDownTime, startCutDown, stopCutDown] = useCutDown();
  const [code, setCode] = useState(useParams().code);
  const [loginWithCode, setLoginWithCode] = useState(!!useParams().code);
  const [showCodeFAQ, setshowCodeFAQ] = useState(false);
  const [submitErr, setSubmitErr] = useState({
    username: null,
    password: null,
    phone: null,
    captcha: null
  }); // eslint-disable-line
  const [scanData, setScanData] = useState(null);

  useEffect(() => {
    if (window.location.href.indexOf('?redirect') > -1) {
      removeToken();
      // history.push('/login');
    }
    // window.location.reload()
  }, [history]);
  const getCaptcha = useCallback(() => {
    getLoginCaptcha(formData.phone)
      .then(res => {
        setSubmitErr({});
        startCutDown();
      })
      .catch(e => {
        if (e.response.data && typeof e.response.data === 'object') {
          Object.keys(e.response.data).forEach(key => {
            e.response.data[key] = {
              help: e.response.data[key],
              validateStatus: 'error'
            };
          });
          setSubmitErr(e.response.data);
        }
      });
  }, [formData, startCutDown]);

  const onValuesChange = (changedValues, allValues) => {
    setFormData(allValues);
  };
  const onFinish = values => {
    if (values.code && current === 'code') {
      joinTeam(values.code)
        .then(res => {
          setCode(values.code);
          setLoginWithCode(true);
          history.push({
            pathname: `/login/${values.code}`,
            search: location.search
          });
        })
        .catch(e => {
          setSubmitErr({
            code: {
              help: '请输入正确的代码',
              validateStatus: 'error'
            }
          });
        });
    } else {
      let login_type;
      switch (current) {
        case 'account':
          login_type = 'password';
          break;
        case 'captcha':
          login_type = 'captcha';
          break;
        default:
          break;
      }
      let loginConfig = {
        login_type,
        ...values
      };
      dispatch(loginAndSetToken(loginConfig))
        .then(async res => {
          stopCutDown();
          console.log('login-res:',JSON.stringify(res,null,'\t'));
          console.log(qs.parse(location.search).redirect, 'qs.parse(location.search).redirect')
          // 跳转
          if (qs.parse(location.search).redirect) {
            let url = decodeURIComponent(qs.parse(location.search).redirect)
            console.log(url, 'url')
            // 是否需要hash
            if (qs.parse(location.search).needHash === 'yes') {
              const { data } = await getProgramPlatformHash()
              console.log(data, 'data')
              const {search} = new URL(url)
              console.log(search, 'search')
              url = search ? `${url}&token=${data.encrypt_text}` : `${url}?token=${data.encrypt_text}`
              console.log(url, 'urlurl')
            }
            window.location.href = url;
            return
          }
          if (window.location.search.indexOf('url') > -1) {
            let url = window.location.search.split('=');
            window.location.href = url[1]
            // window.open(url[1], '_self');
          }
     
          const preEntry = localStorage.getItem('preEntry')
          if (preEntry) {
            window.open(preEntry, '_self')
            return
          }
          const { campus_data: selectCampus, org_type, user_type, campus_count } = res
          if (campus_count === 0) {
            console.log('个人用户：没有校区--');
            window.location.href = '/course'
          } else {
            console.log('非个人用户--');
            if (user_type === 'student') {
              console.log('_isStudent--');
              // 学生
              window.location.href = "/account"
            } else {
              console.log('非学生--');
              if (JSON.stringify(selectCampus) !== '{}') {
                if (selectCampus.is_employee) {
                  console.log('已答应加入');
                  if (org_type === "0") { // 非机构和通用
                    console.log('非机构和通用--');
                    window.location.href = '/course'
                  } else {
                    if (user_type === 'teacher') {
                      console.log('teacher--');
                      window.location.href = '/teachplan'
                    } else {
                      console.log('no-teacher--');
                      window.location.href = '/course'
                    }
                  }
                } else {
                  console.log('未答应加入');
                  window.location.href = "/notification"
                }
              } else {
                console.log('有校区，没有已选择的校区');
                window.location.href = '/course'
              }
            }
          }

        })
        .catch(e => {
          if (e?.response?.data && typeof e.response.data === 'object') {
            Object.keys(e.response.data).forEach(key => {
              e.response.data[key] = {
                help: e.response.data[key],
                validateStatus: 'error'
              };
            });
            setSubmitErr(e.response.data);
          }
        });
    }
  };

  const handlerOperate = data => {
    const _obj = urlToJson(data);
    setScanData(_obj);
    if (_obj?.token) {
      dispatch(loginAndSetTokenByWechat(_obj.token));
    }

    if (childWindow) {
      childWindow.close();
    }
  };
  const handleMessage = e => {
    if (e.origin !== window.location.origin) return;
    if (e.data) {
      handlerOperate(e.data);
    }
  };
  // 获取微信二维码
  const getWechatCode = async () => {
    window.removeEventListener('message', handleMessage, false);
    if (childWindow) {
      childWindow.close();
    }
    window.addEventListener('message', handleMessage, false);
    let iLeft = (window.screen.availWidth - 500) / 2;
    const { data } = await getWXQRCode({ user_id: 0 });
    if (data.success) {
      setTimeout(() => {
        childWindow = window.open(
          data.redirect_uri,
          'postMessage',
          `width=500,height=500,left=${iLeft},menubar=yes,location=no,resizable=yes,scrollbars=true,status=true`
        );
      }, 50);
    }
    let _timer = window.setInterval(() => {
      if (childWindow && childWindow.closed) {
        clearInterval(_timer);
        window.removeEventListener('message', handleMessage, false);
      }
    }, 1000);

    // 针对qq浏览器返回的信息
    window.addEventListener(
      'storage',
      e => {
        if (e.key === 'openerMessage' && e.newValue) {
          handlerOperate(e.newValue);
          localStorage.removeItem('openerMessage');
        }
      },
      false
    );
  };

  const renderWexinLogin = () => {
    return (
      <div className="_loginWexin">
        <p>微信扫码登录（仅限管理员、老师）</p>
        <div onClick={getWechatCode}>
          <WechatFilled />
        </div>
      </div>
    );
  };

  return !loginWithCode ? (
    <>
      {scanData?.message ? (
        <div className="_message">
          <InfoCircleFilled />
          <p
            dangerouslySetInnerHTML={{
              __html: scanData.message
            }}
          ></p>
          <CloseOutlined
            onClick={() => {
              setScanData(null);
            }}
          />
        </div>
      ) : null}

      <div
        id="login"
        className="container"
        style={scanData?.message ? { marginTop: '116px' } : {}}
      >
        <Modal
          closeIcon={
            <img
              src="/image/close.png"
              alt="close"
              width="14px"
              height="14px"
            />
          }
          getContainer={false}
          className="modal"
          centered
          visible={showCodeFAQ}
          footer={null}
          onOk={() => setshowCodeFAQ(false)}
          onCancel={() => setshowCodeFAQ(false)}
          width={'730px'}
        >
          <div className="content">
            <div className="title">如何获得团队代码？</div>
            <div className="titleTag">
              代码是 6 位全大写的英文字母，如：SIJENF、DUVHSD、WFNOWS。
            </div>
            <div className="titleTag">团队代码的作用：</div>
            <p>
              代码是注册用户创建团队后，系统自动赋予给团队的唯一标识，只有
              <span style={{ color: 'red' }}>
                团队中的学生才可以使用团队代码登录英荔教学云
              </span>
              。其他类型的用户请使用注册账号登录。
            </p>
            <div className="titleTag">我是学生：</div>
            <p>询问你的同学或老师以获得正确的团队代码。</p>
            <div className="titleTag">我是老师：</div>
            <p>
              请在「我的管理 -
              团队」的页面下，找到相应的团队，点击「专属链接」或者
              「打印登录卡」
              按钮，来获得该团队的代码。然后告知你的学生，让他们使用代码来登录英荔教学云。
            </p>
            <div className="titleTag">
              <img src="/image/getTeamCode.svg" alt="" />
            </div>
            <div className="buttonDiv">
              <Button type="primary" onClick={() => setshowCodeFAQ(false)}>
                我知道了
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          closeIcon={
            <img
              src="/image/close.png"
              alt="close"
              width="14px"
              height="14px"
            />
          }
          getContainer={false}
          className="modal"
          centered
          visible={forgetModal}
          footer={null}
          onOk={() => setForgetModal(false)}
          onCancel={() => setForgetModal(false)}
          width={'730px'}
        >
          <div className="content">
            <div className="title">忘记密码了？</div>
            <div className="titleTag">我是学生：</div>
            <p>
              根据相关规定，学生需使用学生专用账户。请点击在当前页面「代码登录」，输入你所在班级的团队代码。
            </p>
            <div className="titleTag">我是老师：</div>
            <div>
              如果你现在急需登录，请在当前页面点击「验证码登录」，通过验证你的手机号来登录你的账户。为了你的账户安全和学生的数据安全，我们强烈建议你及时修改你的登录密码。
              <div>修改登录密码时，需要验证你的手机号。</div>
            </div>

            <div className="buttonDiv">
              <Button
                type="primary"
                onClick={() => history.push('/resetpassword')}
              >
                修改密码
              </Button>
            </div>
          </div>
        </Modal>
        <Menu
          onClick={values => {
            prevCurrent.current = current;
            setCurrent(values.key);
            setSubmitErr({
              username: null,
              password: null,
              phone: null,
              captcha: null,
              code: null
            });
          }}
          selectedKeys={[current]}
          mode="horizontal"
          style={{ textAlign: 'center', justifyContent: 'center' }}
        >
          <Menu.Item key="account">账号登录</Menu.Item>
          <Menu.Item key="captcha">验证码登录</Menu.Item>
          <Menu.Item key="code">代码登录</Menu.Item>
        </Menu>
        <div className="form">
          <QueueAnim
            delay={current === 'account' ? 450 : 0}
            type="left"
            appear={false}
          >
            {current === 'account' ? (
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onValuesChange={onValuesChange}
                onFinish={onFinish}
                key="account"
              >
                <Form.Item
                  name="username"
                  {...submitErr.username}
                  rules={[{ required: true, message: '请输入用户名/手机号' }]}
                >
                  <Input size="large" placeholder="用户名/手机号" />
                </Form.Item>
                <Form.Item
                  name="password"
                  {...submitErr.password}
                  rules={[{ required: true, message: '请输入正确的密码' }]}
                >
                  <Input.Password size="large" placeholder="密码" />
                </Form.Item>
                <Form.Item className="_login-form-button">
                  <Button size="large" type="primary" htmlType="submit" block>
                    登录
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox className="color-caption">
                      最近30天自动登录
                    </Checkbox>
                  </Form.Item>
                  {/* <Link to="/resetpassword" className="login-form-forgot">
                  忘记密码
                  </Link> */}
                  <div className="_otherLink">
                    <span
                      className="login-form-forgot"
                      onClick={() => setForgetModal(true)}
                    >
                      忘记密码
                    </span>
                    <Divider type="vertical" />
                    <Link to="/register">立即注册</Link>
                  </div>
                </Form.Item>
                {renderWexinLogin()}
              </Form>
            ) : null}
          </QueueAnim>
          <QueueAnim
            delay={current === 'captcha' ? 450 : 0}
            type={
              current === 'captcha'
                ? prevCurrent.current === 'account'
                  ? 'right'
                  : 'left'
                : current === 'account'
                  ? 'right'
                  : 'left'
            }
          >
            {current === 'captcha' && (
              <Form
                name="normal_login"
                className="login-form"
                key="captcha"
                initialValues={{ remember: true, prefix: '86' }}
                onValuesChange={onValuesChange}
                onFinish={onFinish}
              >
                <Form.Item
                  name="phone"
                  {...submitErr.phone}
                  rules={[
                    { required: true, message: '请输入手机号' },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || (value && testPhone(value))) {
                          return Promise.resolve();
                        }
                        return Promise.reject('请输入正确的手机号');
                      }
                    })
                  ]}
                >
                  <Input
                    size="large"
                    addonBefore={
                      <Form.Item name="prefix" noStyle>
                        <Select
                          defaultValue="86"
                          style={{ width: 74 }}
                          suffixIcon={<CaretDownOutlined />}
                        >
                          <Select.Option value="86">+86</Select.Option>
                        </Select>
                      </Form.Item>
                    }
                    placeholder="手机号"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item className="explain">
                  <div className="captcha">
                    <Form.Item
                      {...submitErr.captcha}
                      name="captcha"
                      style={{ marginBottom: '0' }}
                      rules={[{ required: true, message: '请输入验证码' }]}
                    >
                      <Input size="large" placeholder="验证码" />
                    </Form.Item>
                  </div>
                  <Button
                    size="large"
                    type="primary"
                    disabled={coldDown || !testPhone(formData.phone)}
                    className="captcha-btn"
                    onClick={getCaptcha}
                  >
                    {coldDown ? `${coldDownTime}s 后可重新发送` : '获取验证码 '}
                  </Button>
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    block
                    className="login-form-button"
                  >
                    登录
                  </Button>
                  <div className="register">
                    <Link to="/register">立即注册</Link>
                  </div>
                  {renderWexinLogin()}
                </Form.Item>
              </Form>
            )}
          </QueueAnim>
          <QueueAnim delay={current === 'code' ? 450 : 0}>
            {current === 'code' && (
              <Form
                name="normal_login"
                className="login-form"
                key="code"
                initialValues={{ remember: true }}
                onValuesChange={onValuesChange}
                onFinish={onFinish}
              >
                <Form.Item
                  name="code"
                  {...submitErr.code}
                  rules={[{ required: true, message: '请输入正确的代码' }]}
                  getValueFromEvent={e => {
                    let _value = e.target.value
                      // .replace(/[^\a-\z\A-\Z]/g, '')
                      .replace(/[^a-zA-Z]/g, '')
                      .toUpperCase();
                    return _value;
                  }}
                >
                  <Input size="large" placeholder="代码" />
                </Form.Item>

                <Form.Item style={{ marginBottom: '20px' }}>
                  <Button size="large" type="primary" htmlType="submit" block>
                    进入
                  </Button>
                </Form.Item>

                <div className="faq">
                  <span onClick={() => setshowCodeFAQ(true)}>
                    如何获取代码？
                  </span>
                </div>
                {renderWexinLogin()}
              </Form>
            )}
          </QueueAnim>
        </div>
      </div>
    </>
  ) : (
    <LoginWithCode code={code} />
  );
};

export default withRouter(Login);
