import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserType } from './../../utils/browserPrompt';

const SuccessPage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let _type = BrowserType();
      window.opener.postMessage(location.search, window.location.origin);

      if (_type?.shell === 'qq') {
        localStorage.setItem('openerMessage', location.search);
      }
    }
  }, [location.search]);

  const _style = {
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  };

  return <div style={_style}>授权成功</div>;
};

export default SuccessPage;
