import instance from '../request';

export const deleteComment = id => {
  return instance.delete(`/api/v1/coursecomments/${id}/`);
};

export const getCourseManageList = (team_code, params) => {
  return instance.get(`/api/v1/team/${team_code}/courses/course_list/`, {
    params
  });
};

export const getOrgCourseList = (team_code, params) => {
  return instance.get(`/api/v1/team/${team_code}/courses/org_course_list/`, {
    params
  });
};

export const getOrgCourseBags = (team_code, params) => {
  return instance.get(`/api/v1/team/${team_code}/courses/org_course_bag_list/`, {
    params
  });
};
export const getTeamCourseBags = (team_code, params) => {
  return instance.get(`/api/v1/team/${team_code}/courses/course_bag_list/`, {
    params
  });
};

export const addCourseList = (team_code, params) => {
  return instance.post(`/api/v1/team/${team_code}/courses/`, params);
};

export const closeCourse = (team_code, params) => {
  return instance.post(
    `/api/v1/team/${team_code}/courses/close_visable/`,
    params
  );
};

export const openCourse = (team_code, params) => {
  return instance.post(
    `/api/v1/team/${team_code}/courses/open_visable/`,
    params
  );
};

export const deleteCourse = (team_code, params) => {
  return instance.post(
    `/api/v1/team/${team_code}/courses/delete_team_course/`,
    params
  );
};

export const changeVisible = (team_code, params) => {
  return instance.post(
    `/api/v1/team/${team_code}/courses/change_visable/`,
    params
  );
};
