import React, {
  useState,
  // useCallback,
  forwardRef,
  useImperativeHandle
} from 'react';
import { Form, Input, Button, message } from 'antd';
import CommonModal from '../../../components/commonModal';
import { testPassword, deBounce } from '../../../utils';
import { setStudentSettings } from '../../../utils/request';
import '../style.scss';

const KeyModal = (props, ref) => {
  const { has_password, getSettings } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    handleKeyModal: () => setModalVisible(true)
  }));
  const settingHandle = (type, formData) => {
    let values = Object.assign(formData, type);
    setStudentSettings(values)
      .then(res => {
        if (res.data.success) {
          message.success(res.data.msg);
          setModalVisible(false);
          form.resetFields();
          getSettings();
        } else {
          message.warn(res.data.msg);
        }
      })
      .catch(e => {
        if (typeof e.response.data === 'string') return;
        if (e.response.status === 400) {
          message.warning(e.response.data.msg);
        } else {
          message.error('操作失败，请稍后再尝试');
        }
      });
  };
  return (
    <CommonModal
      visible={modalVisible}
      title={has_password ? '修改密码' : '设置密码'}
      width={422}
      onCancel={() => {
        setModalVisible(false);
        form.resetFields();
      }}
      contentNode={
        <div className="accountForm">
          {has_password ? (
            <Form
              form={form}
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={deBounce(values => {
                settingHandle({ type: 'modify' }, values);
              }, 500)}
              onFinishFailed={() => {}}
              autoComplete="off"
            >
              <Form.Item
                label="原密码"
                name="old_password"
                rules={[{ required: true, message: '请输入旧密码' }]}
              >
                <Input.Password placeholder="请输入旧密码" />
              </Form.Item>
              <Form.Item
                label="新密码"
                name="password1"
                rules={[
                  {
                    required: true,
                    message: '请输入新密码'
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || (value && testPassword(value))) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        '请输入 6-16 位的密码，仅支持数字、英文、下划线'
                      );
                    }
                  })
                ]}
              >
                <Input.Password placeholder="6-16 位密码，区分大小写" />
              </Form.Item>
              <Form.Item
                label="确认新密码"
                name="password2"
                rules={[
                  { required: true, message: '请再次输入新密码' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password1') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('前后密码不一致');
                    }
                  })
                ]}
              >
                <Input.Password placeholder="请再次输入新密码" />
              </Form.Item>
            </Form>
          ) : (
            <Form
              name="basic"
              form={form}
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={deBounce(values => {
                settingHandle({ type: 'add' }, values);
              }, 500)}
              autoComplete="off"
            >
              <Form.Item
                label="密码"
                name="password1"
                rules={[
                  {
                    required: true,
                    message: '请输入密码'
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || (value && testPassword(value))) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        '请输入 6-16 位的密码，仅支持数字、英文、下划线'
                      );
                    }
                  })
                ]}
              >
                <Input.Password placeholder="6-16 位密码，区分大小写" />
              </Form.Item>
              <Form.Item
                label="确认密码"
                name="password2"
                rules={[
                  { required: true, message: '请再次输入密码' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password1') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('前后密码不一致');
                    }
                  })
                ]}
              >
                <Input.Password placeholder="请再次输入密码" />
              </Form.Item>
            </Form>
          )}
        </div>
      }
      bottomNode={
        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          确定
        </Button>
      }
    />
  );
};
export default forwardRef(KeyModal);
