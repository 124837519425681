import React, { memo } from 'react';
import './style.scss';

const ToggleSlier = memo(({ checked, onChange }) => {
  return (
    <label className={`toggle-switch comments-allowed-input`}>
      <input checked={checked} type="checkbox" onChange={onChange} />
      <span className="slider" />
    </label>
  );
});

export default ToggleSlier;
