import React, { useState, memo } from 'react';
import { Modal, Pagination, Empty } from 'antd';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import CusPagination from '../pagination';
import { toDetail } from '../../utils';
import './style.scss';

const PortfolioModal = memo(props => {
  const [selectPage, setSelectPage] = useState(1);
  const {
    visible,
    data,
    page,
    allData,
    allDataTotal,
    selectProjects,
    onCancel,
    onOk,
    onChangePage,
    onChangeList
  } = props;

  const renderList = (allData, data, selectProjects) => {
    return allData.length
      ? allData.map((item, index) => (
          <div className="project" key={index}>
            <div
              className={`image ${
                selectProjects.find(project => project.id === item.id)
                  ? 'selected'
                  : ''
              } ${data.find(projectId => projectId === item.id) ? 'exist' : ''}`}
            >
              <img src={item.image} alt={item.title} />
              {data.find(projectId => projectId === item.id) ? (
                <div className="mask exist">已存在</div>
              ) : selectProjects.find(project => project.id === item.id) ? (
                <div className="mask selected">
                  <CheckCircleFilled
                    className="selectedIcon"
                    onClick={() => {
                      let list = selectProjects.slice(0);
                      let index = list.findIndex(project => item === project);
                      list.splice(index, 1);
                      onChangeList([...list]);
                    }}
                  />
                </div>
              ) : (
                <div
                  className="mask add"
                  onClick={() => {
                    onChangeList([...selectProjects, item]);
                  }}
                >
                  添加
                </div>
              )}
            </div>
            <a
              href={toDetail(item.platform, item.id)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className={`title ${
                  data.find(projectId => projectId === item.id) ? 'exist' : ''
                }`}
              >
                {item.title}
              </div>
            </a>
            <div className="time">
              <div style={{ marginRight: '4px' }}>
                {dayjs(item.updated_at).format('YYYY/MM/DD')}
              </div>
              <div>{dayjs(item.updated_at).format('HH:mm')}</div>
            </div>
          </div>
        ))
      : null;
  };

  const renderSelectList = list => {
    return list.length ? (
      <div className="selected">
        <div className="actionBar">
          <div className="title">
            已选择 <span className="selectedNum">{selectProjects.length}</span>{' '}
            项
          </div>
          <div>
            <CusPagination
              // pages={Math.ceil(selectProjects.length / 6)}
              pages={Math.ceil(selectProjects.length / 4)}
              onChange={value => setSelectPage(value)}
            />
          </div>
        </div>
        <div className="list">
          {selectProjects
            // .slice((selectPage - 1) * 6, selectPage * 6)
            .slice((selectPage - 1) * 4, selectPage * 4)
            .map((item, index) => (
              <div className="project" key={index}>
                <div className="image">
                  <img src={item.image} alt={item.title} />
                  <div
                    className="closeBtn"
                    onClick={() => {
                      let list = selectProjects.slice(0);
                      let index = list.findIndex(project => project === item);
                      list.splice(index, 1);
                      onChangeList(list);
                    }}
                  >
                    <CloseCircleFilled />
                  </div>
                </div>
                <div className="title">{item.title}</div>
              </div>
            ))}
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
    ) : null;
  };

  return (
    <React.Fragment>
      <Modal
        closeIcon={
          <img src="/image/close.png" alt="close" width="14px" height="14px" />
        }
        className="addModal"
        visible={visible}
        onCancel={onCancel}
        onOk={onOk}
        cancelButtonProps={{
          style: { display: 'none' }
        }}
        okText="完成"
        okButtonProps={{ disabled: !selectProjects.length }}
        width={538}
        centered={true}
        getContainer={false}
      >
        <div className="content">
          <div className="title">请选择需要添加到作品集的作品</div>
          <div className="tips">只有公开的作品才可以被添加到作品集</div>
          {allData.length > 0 ?
            <>
              <div className="list">
                {renderList(allData, data, selectProjects)}
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
              </div>
              <div className="pagination">
                <Pagination
                  showQuickJumper
                  total={allDataTotal}
                  defaultPageSize={6}
                  current={page}
                  onChange={val => onChangePage(val)}
                />
              </div>
              {renderSelectList(selectProjects)}
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
              <i></i>
            </>
            : <div className='empty'>
              <Empty
                image="/image/empty-page.png"
                description="你暂时没有已公开的作品"
              ></Empty>
            </div>}
          
        </div>
      </Modal>
    </React.Fragment>
  );
});

export default PortfolioModal;
