import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect
} from 'react';
import CommonModal from '../../../components/commonModal';
import { Transfer, Button, message, Spin } from 'antd';
import { addCourseList } from '../../../utils/api/course';
import { LoadingOutlined, DownOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const AddCourseModal = (props, ref) => {
  const userInfo = useSelector((state) => state.userInfo)
  const {
    team_code,
    list,
    setCurrent,
    fetchList,
    current,
    courseData,
    modalLoading,
    setSelectedRowKeys,
    setSelectedRows,
    courseBagList,
    courseBagData,
    initPageDetail
  } = props;
  const [visible, setVisible] = useState(false);
  const [targetKeys, setTargetKeys] = useState([]);
  const [bagKeys, setBagKeys] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [allowCourseBag, setAllowCourseBag] = useState(false);
  const [organizeAuth, setOrganizeAuth] = useState(false);

  useImperativeHandle(ref, () => ({
    addCourseHandler: () => setVisible(true)
  }));

  useEffect(() => {
    if (courseData && visible) {
      let targetDataId = []
      let courseBagIds = []
      courseData.forEach(el => {
        if (list.find(f => f.id === el)) {
          targetDataId.push(el)
        }
      });
      courseBagData.forEach(el => {
        if (courseBagList.find(f => f.id === el)) {
          courseBagIds.push(el)
        }
      });
      setTargetKeys(targetDataId);
      setBagKeys(courseBagIds);
      setExpanded(courseData.length > 0)
    }
  setAllowCourseBag(userInfo.organization_data.allow_course_bag)
  setOrganizeAuth(userInfo.user_type.includes('manager') || userInfo.user_type.includes('teacher'))
  // eslint-disable-next-line
  }, [courseData, visible, courseBagData]);

  const filterOption = (inputValue, option) => {
    return option.title.indexOf(inputValue) > -1;
  };

  const handleChange = targetKeys => {
    setTargetKeys(targetKeys);
  };
  const handleChangeCourseBag = targetKeys => {
    setBagKeys(targetKeys);
  };

  const submit = () => {
    let param = { course_id_list: targetKeys, course_bag_id_list: bagKeys }
    if (!allowCourseBag) {
      delete param.course_bag_id_list
    }
    addCourseList(team_code, param)
      .then(() => {
        message.success('保存成功');
        setVisible(false);
        setTargetKeys([]);
        setBagKeys([]);
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setCurrent(1);
        if (current === 1 || current === undefined) {
          fetchList(1);
          initPageDetail()
        }
      })
      .catch(() => {
        message.error('课程添加失败，请稍后重试');
      });
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  return (
    <CommonModal
      title="管理本团队课程"
      width={700}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      contentNode={
        <div className="addModal">
          {
            organizeAuth && allowCourseBag && <div>
              <p style={{color:'#333333'}}>给团队关联课程包，并接受上级主管部门直接动态分配课程：</p>
              <Spin spinning={modalLoading} indicator={antIcon}>
                <Transfer
                  titles={['当前你可见课程包', '本团队可用课程包']}
                  dataSource={courseBagList || []}
                  showSearch
                  filterOption={filterOption}
                  targetKeys={bagKeys}
                  onChange={handleChangeCourseBag}
                  render={item => item.title}
                  rowKey={record => record.id}
                  locale={{
                    searchPlaceholder: '搜索课程包名称'
                  }}
                />
              </Spin>
            </div>
          }
          <div>
            {
              organizeAuth && allowCourseBag && <p onClick={() => setExpanded(true)} style={{ color: expanded ? '#333333' : '#0084ff', cursor: expanded ? 'auto' : 'pointer' }}>从当前你可见的课程包中，给团队分配的固定的课程{<span>{expanded ? '：' : <DownOutlined style={{ marginLeft:'5px',fontSize:'14px'}}/>}</span>}</p>
            }
            {
              ((organizeAuth && allowCourseBag && expanded) || !allowCourseBag) && <Spin spinning={modalLoading} indicator={antIcon}>
                <Transfer
                  titles={['当前你可见课程', '本团队课程']}
                  dataSource={list || []}
                  showSearch
                  filterOption={filterOption}
                  targetKeys={targetKeys}
                  onChange={handleChange}
                  render={item => item.title}
                  rowKey={record => record.id}
                  locale={{
                    searchPlaceholder: '搜索课程名称'
                  }}
                  style={{
                    marginBottom: allowCourseBag ? 15 : 0
                  }}
                />
              </Spin>
            }
          </div>
          {
            organizeAuth && allowCourseBag && expanded && <p style={{color:'#999999'}}>给团队分配固定课程一般用于在不方便给当前团队分配一整个课程包的权限时，额外给他分配几节课的权限。</p>
          }
        </div>
      }
      bottomNode={
        <>
          <Button
            onClick={() => {
              setVisible(false);
            }}
          >
            取消
          </Button>
          <Button type="primary" onClick={submit}>
            确定
          </Button>
        </>
      }
    />
  );
};

export default forwardRef(AddCourseModal);
