import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
} from 'react';
import Icon from '../../../../components/icon';
import './style.scss';
import { Menu, Table, Modal } from 'antd';
import dayjs from 'dayjs';
import {
  getOwnPortfolioNotifications,
  getOwnPortfolioDetail,
} from '../../../../utils/request';
import { getOwnPortfolioNoticeCount } from '../../../../utils/api/ownportfolio';
import { useRouteMatch, Link } from 'react-router-dom';
import { coverMap } from '../../../../utils/variable';
// import { formatVerb } from '../portfolioItem/index'

let userdata = {};

const formatVerb = (verb) => {
  switch (verb) {
    case 'studio_project_add':
      return '添加作品';
    case 'studio_project_update':
      return '更新作品';
    case 'studio_bulk_add_project':
      return '添加作品';
    case 'studio_project_delete':
      return '移除作品';
    case 'studioactivity':
      return '更新工作室信息';
    default:
      return verb;
  }
};

const PortfolioActivity = () => {
  const match = useRouteMatch();

  const [portfolioData, setPortfolioData] = useState();
  const [data, setData] = useState();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);

  const getPortfolioNotificationsHandler = useCallback(() => {
    const params = {
      offset: (current - 1) * pageSize,
      limit: pageSize,
    };
    getOwnPortfolioNotifications(match.params.id, params)
      .then((res) => {
        setData(res.data.map((item, index) => ({ ...item, key: index })));
        // setTotal(res.data.count)
      })
      .catch((e) => {
        console.log(e);
      });
  }, [current, match.params.id, pageSize]);

  useEffect(() => {
    getOwnPortfolioDetail(match.params.id)
      .then((res) => {
        setPortfolioData({
          ...res.data,
          cover: 'https://' + window.location.host + res.data.image,
        });
      })
      .catch((e) => {
        console.log(e);
      });
    getOwnPortfolioNoticeCount(match.params.id).then((res) => {
      if (Object.is(res.status, 200)) setTotal(res.data.count);
    });
  }, [match.params.id]);

  useEffect(() => {
    getPortfolioNotificationsHandler();
  }, [current, getPortfolioNotificationsHandler]);

  const columns = [
    {
      title: '用户',
      dataIndex: 'actor_nick',
      key: 'actor_nick',
      align: 'left',
      render: (text, record) => (
        <span className="name">
          <Link
            className="author"
            to={`/account/home/${record.actor_username}`}
            target="_blank"
          >
            <img src={record.actor_avatar} width="48px" alt="cover" />
            {record.actor_nick || record.actor_username}
          </Link>
        </span>
      ),
    },
    {
      title: '操作',
      dataIndex: 'verb',
      key: 'verb',
      align: 'left',
      render: (text) => formatVerb(text),
    },
    {
      title: '作品',
      dataIndex: 'tgt_name',
      key: 'tgt_name',
      align: 'left',
      render: (val, record) => (
        <span
          className={`detail ${record.tgt_content ? '' : 'disable'}`}
          onClick={() => {
            if (!record.tgt_content) return;
            userdata = record;
            setShowDetailModal(true);
          }}
        >
          {record.tgt_name}
          {record.tgt_content
            ? `等 ${record.tgt_content.split(',').length + 1} 个作品`
            : ''}
        </span>
      ),
    },
    {
      title: '时间',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'left',
      render: (val) => dayjs(val).format('YYYY/MM/DD HH:mm'),
    },
  ];

  useLayoutEffect(() => {
    document.querySelector(
      '.ant-table-pagination .ant-select-selection-item'
    ) &&
      (document.querySelector(
        '.ant-table-pagination .ant-select-selection-item'
      ).innerText = `${pageSize} 条/页`);
  }, []); // eslint-disable-line

  return (
    <div id="ownPortfolioActivity">
      <header>
        <div className="container">
          <div className="header">
            <Icon
              defaultImg={
                portfolioData?.image
                  ? portfolioData?.image
                  : coverMap[portfolioData?.origin]
              }
            />
            <div className="detail">
              <div className="title">{portfolioData?.title}</div>
            </div>
          </div>
          <div>
            <Menu selectedKeys={['allActivity']} mode="horizontal">
              <Menu.Item key="allActivity">全部动态</Menu.Item>
            </Menu>
          </div>
        </div>
      </header>
      <div className="content">
        <div className="container">
          <div className="table">
            <Table
              dataSource={data}
              columns={columns}
              pagination={{
                total,
                showQuickJumper: true,
                defaultPageSize: pageSize,
                onChange: (val) => {
                  setCurrent(val);
                },
                onShowSizeChange: (cur, size) => {
                  setCurrent(cur);
                  setPageSize(size);
                },
                showTotal: (total, range) =>
                  `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`,
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        closeIcon={
          <img src="/image/close.png" alt="close" width="14px" height="14px" />
        }
        className="actionDetail"
        visible={showDetailModal}
        onCancel={() => setShowDetailModal(false)}
        footer={null}
        width={300}
        centered={true}
        getContainer={false}
      >
        <div className="content">
          <div className="userWrapper">
            <div className="icon">
              <img
                width="48px"
                height="48px"
                src={userdata.actor_avatar}
                alt="icon"
              />
            </div>
            <div className="userDetail">
              <div className="usermame">{userdata.actor_nick}</div>
              <div className="time">
                {dayjs(userdata.created_at).format('YYYY/MM/DD HH:mm')}
              </div>
            </div>
          </div>
          <div className="title">添加作品名单：</div>
          <div className="details">
            <div className="detail">{userdata.tgt_name}</div>
            {!!userdata.tgt_content &&
              userdata.tgt_content.split(',').map((item, index) => (
                <div className="detail" key={index}>
                  {item}
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PortfolioActivity;
