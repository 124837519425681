import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect
} from 'react';
import './../style.scss';
import { Input, Modal, message, Form } from 'antd';
import { modifyPortfolio, updateAreaPortfolio } from './../../../../utils/request';
import Icon from './../../../../components/icon';

import { deBounce } from './../../../../utils';
const PortfolioModal = (props, ref) => {
  const { patchItem = null, fetchList, isArea } = props;

  // const portfolioFlag = useSelector((state) => state.portfolioFlag);

  const [showCreateModal, setShowCreateModal] = useState(false);
  // qs.parse(location.search).create === 'new'

  const formRef = useRef();
  const [formData, setFormData] = useState({
    cover: null,
    title: '',
    team: undefined,
    description: ''
  });

  useImperativeHandle(ref, () => ({
    modifyHandler: () => setShowCreateModal(true)
  }));
  useEffect(() => {
    if (patchItem && showCreateModal) {
      setFormData({
        cover: patchItem.cover,
        title: patchItem.studio.title,
        description: patchItem.studio.description
      });
      formRef.current.setFieldsValue({
        title: patchItem.studio.title,
        description: patchItem.studio.description
      });
    }
  }, [patchItem, showCreateModal]);
  const formFinishHandler = value => {
    const requestFn = isArea ? updateAreaPortfolio : modifyPortfolio;
    let curImageFile;
    if (value.cover.includes('http')) {
      let filearr = value.cover.split('/');
      curImageFile = filearr[filearr.length - 1];
      value['cover'] = curImageFile; //同 value.cover
    }
    requestFn(patchItem.id, value)
      .then(res => {
        message.success(res.data.message);
        setShowCreateModal(false);
        fetchList();
      })
      .catch(e => {
        if (!window.navigator.onLine) {
          message.error('操作失败，请稍后重试');
          return;
        }
        if (typeof e.response.data === 'string') return;
        if (e.response.status === 400) {
          message.warning(e.response.data.message);
        } else {
          message.error('修改作品集信息失败');
        }
      });
  };

  return (
    <Modal
      closeIcon={
        <img src="/image/close.png" alt="close" width="14px" height="14px" />
      }
      className="createModal"
      visible={showCreateModal}
      onCancel={() => {
        // history.replace('/manage/portfolio');
        // formRef.current &&
        //   formRef.current.setFieldsValue({
        //     cover: formData.cover,
        //     title: '',
        //     description: ''
        //   });
        // setFormData({
        //   cover: formData.cover,
        //   title: '',
        //   description: ''
        // });
        setShowCreateModal(false);
      }}
      okButtonProps={{ disabled: !formData.title }}
      okText="保存"
      onOk={deBounce(() => {
        formRef.current.submit();
      }, 300)}
      width={500}
      centered={true}
      getContainer={false}
      destroyOnClose={true}
      maskClosable={false}
      title="修改作品集"
    >
      <div className="content">
        <Form
          ref={formRef}
          initialValues={formData}
          layout="vertical"
          onValuesChange={(value, allValue) => setFormData(allValue)}
          onFinish={formFinishHandler}
        >
          
          {isArea ? <div className='tips'>
              区域作品集由个人创建，归属于区域组织，和团队、校区不做关联，可用于同组织不同校区的教职工、管理人员间进行各种活动。<br></br>
              只要获取到分享链接，你所在区域组织中的所有人都可以浏览此作品集。你也可以邀请你所在区域组织中的其他职工成为区域作品集的成员，与你一同管理此作品集。
            </div> : null}
          <Form.Item label="作品集封面" name="cover" required>
            <div>
              <Icon
                type={isArea ? 'areaPortfolio' : 'portfolio'}
                defaultImg={patchItem?.cover}
                onLoad={file => {
                  // setFormData({
                  //   cover: file,
                  //   title: '',
                  //   team: undefined,
                  //   description: ''
                  // });
                  formRef.current &&
                    formRef.current.setFieldsValue({
                      cover: file
                    });
                }}
                random={true}
                onChange={file => {
                  formRef.current &&
                    formRef.current.setFieldsValue({
                      cover: file
                    });
                }}
                // onLoad={setImageFile}
                showSwitch
              />
            </div>
          </Form.Item>
          <Form.Item
            label="作品集名称"
            name="title"
            rules={[{ required: true, message: '请输入作品集名称' }]}
          >
            <Input
              maxLength="15"
              placeholder="请输入作品集名称"
              suffix={
                <span className="suffix">{`${Math.min(
                  formData.title.length,
                  15
                )}/15`}</span>
              }
            ></Input>
          </Form.Item>

          <Form.Item
            label="作品集介绍"
            name="description"
            rules={[{ required: false }]}
          >
            <Input.TextArea
              placeholder="作品集介绍将在作品集主页里展示给每一位来访者。"
              maxLength="200"
              style={{ height: '153px', resize: 'none' }}
            ></Input.TextArea>
          </Form.Item>
          <span className="textAreaSuffix">{`${
            formData.description.length > 200
              ? 200
              : formData.description.length
          }/200`}</span>
        </Form>
      </div>
    </Modal>
  );
};
export default forwardRef(PortfolioModal);
