import { useSelector } from "react-redux";
import { scratchURL, snapURL } from "../../utils";
import { Button, Menu, Dropdown } from "antd";
import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import React from 'react'
import './style.scss';

// 新建按钮
const CreateBtn = (props) => {
  const userInfo = useSelector((state) => state.userInfo);
  const isNoRole = userInfo.user_type.includes('null');
  const isSnapOpen = userInfo && userInfo.organization_data && userInfo.organization_data.snap_platform === 2
  const isScratchOpen = !isNoRole // 抄自之前的逻辑
  // 如果有新建scratch和snap的权限
  if (isSnapOpen && isScratchOpen) {
    return <Dropdown
      className='create-btn'
      overlay={<Menu>
        <Menu.Item onClick={() => {window.open(scratchURL + '/projects/editor/');}}>互联创作平台</Menu.Item>
        <Menu.Item onClick={() => {window.open(snapURL + '/snap.html');}}>Snap 互联创作平台</Menu.Item>
      </Menu>}>
        <Button type="primary">{props.children}
          <DownOutlined style={{color: 'white'}}/>
        </Button>
    </Dropdown>
   // 仅有scratch权限
   } else if (isScratchOpen) {
     return <Button
     icon={<PlusOutlined />}
     type="primary"
     onClick={() => {
       window.open(scratchURL + '/projects/editor/');
     }}
     >
      {props.children}
     </Button>
    // 仅有snap权限
   } else if (isSnapOpen) {
    return <Button
      icon={<PlusOutlined />}
      type="primary"
      onClick={() => {
        window.open(snapURL + '/snap.html');
      }}
      >
      {props.children}
    </Button> 
   } else {
      return <></>
    }
  }

export default CreateBtn