import React, { useState, forwardRef, useImperativeHandle } from 'react';
import CommonModal from '../commonModal';
import { Input, Button, message } from 'antd';
import { addStudent } from '../../utils/api/manageCenter';
const isManagerCenter = window.location.pathname.includes('manageCenter');
const AddMember = (props, ref) => {
  const [addVisible, setAddVisible] = useState(false);
  const [username, setUsername] = useState('');

  useImperativeHandle(ref, () => ({
    addMemberHandler: () => setAddVisible(true)
  }));

  const handlerAdd = () => {
    if (!username) {
      message.warning('请输入学员用户名');
      return;
    }
    addStudent(props.team_code, {
      username, from_type: isManagerCenter ? '管理中心' : null
    })
      .then(res => {
        message.success(res.data.messager);
        setAddVisible(false);
        setUsername('');
        props.getAllMember();
      })
      .catch(e => {
        if (typeof e.response.data === 'string') return;
        if (e.response.status === 400) {
          message.warning(e.response.data.message);
        } else {
          message.error('添加本校区学员失败');
        }
      });
  };

  return (
    <CommonModal
      title="添加本校区学员"
      width={374}
      visible={addVisible}
      onCancel={() => {
        setAddVisible(false);
        setUsername('');
      }}
      contentNode={
        <div className="changeModal">
          <p>学员用户名</p>
          <Input
            placeholder="请输入"
            onChange={e => setUsername(e.target.value)}
          />
        </div>
      }
      bottomNode={
        <>
          <Button
            onClick={() => {
              setAddVisible(false);
              setUsername('');
            }}
          >
            取消
          </Button>
          <Button type="primary" onClick={handlerAdd}>
            添加
          </Button>
        </>
      }
    />
  );
};

export default forwardRef(AddMember);
