import React, { memo } from 'react';
import { Modal, Button, Checkbox } from 'antd';
import './index.scss';

function GuideModal({ visible, onCancel, onOk, isTeacher }) {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={null}
      footer={null}
      className={[
        'club-container',
        isTeacher ? 'teachModal' : 'studModal'
      ].join(' ')}
      width={580}
      closeIcon={
        <i className="club-close">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <path
              d="M12.5 10.3c.6.6.6 1.5 0 2.1-.3.3-.7.4-1.1.4-.4 0-.8-.1-1.1-.4L8 10.1l-2.3 2.3c-.3.3-.7.4-1.1.4-.4 0-.8-.1-1.1-.4-.6-.6-.6-1.5 0-2.1L5.9 8 3.5 5.7c-.5-.6-.5-1.6 0-2.2.6-.5 1.6-.5 2.2 0L8 5.9l2.3-2.3c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1L10.1 8l2.4 2.3z"
              fill="#fff"
            />
          </svg>
        </i>
      }
    >
      <div>
        <header>想把作品分享到开放社区吗？</header>
        <main>
          <div>
            <img src="/image/codelab.png" alt="Codelab" className="codelab" />
          </div>
          {isTeacher ? (
            <article>
              为了保证校园网络环境的纯净，响应国家相关政策的倡导，英荔教学云中的作品暂时无法直接分享到开放社区，只能在同学之间流通。
              <br />
              如果您觉得某个作品非常优秀，希望可以和全世界的创作者一起交流，欢迎您注册「CodeLab
              社区」，这是一个与我们深度合作的非营利组织，致力于「传递编程的乐趣，鼓励孩子成为数字时代的创作者」。
            </article>
          ) : (
            <article>
              为了保证校园网络环境的纯净，英荔教学云中的作品暂时无法直接分享到开放社区，只能在同学之间流通。
              <br />
              如果你觉得你的某个作品非常优秀，希望可以和全世界的创作者一起交流，我们欢迎您注册「CodeLab
              社区」。 <br />
              <span className="gray">
                注册「CodeLab 社区」需要验证手机号哦，可以请你的家长协助完成。
              </span>
            </article>
          )}
        </main>
        <footer>
          <Checkbox onClick={onCancel}>
            <span className="gray">不再显示</span>
          </Checkbox>
          <div>
            <Button onClick={onCancel}>暂不需要</Button>
            <Button
              type="primary"
              className="primary"
              onClick={() => {
                onCancel();
                window.open('https://create.codelab.club/join');
              }}
            >
              前往注册
            </Button>
          </div>
        </footer>
      </div>
    </Modal>
  );
}

export default memo(GuideModal);
