import React, { useState, useRef, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Button /*message*/ } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import CommentBox from '../commentBox';
import ConfirmModal from '../confirmModal';
import {
  deleteAccountComment
  // reportAccountComment
} from '../../utils/request';
import './style.scss';

const Comment = memo(
  ({
    hightlight,
    comment,
    userName,
    onAddReply,
    parentId,
    canDelete,
    canReply,
    onDelete,
    type
  }) => {
    const isReport = false;
    const [repplying, setReplying] = useState(false);
    const [reply, setReply] = useState('');
    const userInfo = useSelector(state => state.userInfo);
    const [visible, setVisible] = useState({ delete: false, report: false });
    const [isDelete, setIsDelete] = useState(false);
    // const [isReport, setIsReport] = useState(false);
    const commentRef = useRef(null);

    const handleReply = data => {
      setReplying(false);
      setReply('');
      onAddReply(data);
    };

    const handleDelete = () => {
      deleteAccountComment(comment.id).then(res => {
        if (Object.is(res.status, 200)) {
          setVisible(prev => ({ ...prev, delete: false }));
          setIsDelete(true);
          onDelete(comment.id);
        }
      });
    };

    // const handleReport = () => {
    //   reportAccountComment(comment.id).then(res => {
    //     if (Object.is(res.status, 200)) {
    //       setIsReport(true);
    //       setVisible(prev => ({ ...prev, report: false, delete: false }));
    //       message.success('评论已被举报，并已通知Longan团队');
    //     }
    //   });
    // };

    const renderDeleteBtn = () => {
      return !isReport && canDelete ? (
        <span
          className="delete"
          onClick={() => {
            setVisible(prev => ({ ...prev, delete: true }));
          }}
        >
          <DeleteOutlined />
        </span>
      ) : null;
    };

    const renderReplyBtn = () => {
      return !isReport && canReply ? (
        <span
          className="reply"
          onClick={() => setReplying(prev => !prev)}
        ></span>
      ) : null;
    };

    // const renderReportBtn = () => {
    //   return isReport ? (
    //     <span className="isreport">已举报</span>
    //   ) : (
    //     <span
    //       className="report"
    //       onClick={() => {
    //         setVisible(prev => ({ ...prev, report: true }));
    //       }}
    //     >
    //       举报
    //     </span>
    //   );
    // };

    useEffect(() => {
      if (hightlight) {
        document.documentElement.scrollTop =
          commentRef.current.getBoundingClientRect().top - 200;
      }
    }, [hightlight]);

    // console.log(hightlight, comment.id);
    return (
      <div
        className={`account-comment ${hightlight ? 'highlight' : ''} ${type} ${
          isDelete ? 'delete' : ''
        }`}
        id={`comments-${comment.id}`}
        ref={commentRef}
      >
        <div className="comment-content">
          <div className="avatar">
            <img
              src={comment.author.avatar_url}
              alt={comment.author.username}
            />
          </div>
          <div className="content-container">
            <div className="content">
              <a
                className="name"
                href={`/account/home/${comment.author.username}`}
              >
                <h2 className="name">
                  {comment.author.name || comment.author.username}
                </h2>
              </a>
              <div className="text">
                {Object.is(type, 'reply') ? (
                  <a
                    className="reply-at"
                    href={`/account/home/${comment.commentee_username}`}
                  >
                    @{comment.commentee_name || comment.commentee_username}
                  </a>
                ) : null}
                {comment.content}
              </div>
              <div className="bottom-bar">
                <div className="time">
                  {dayjs(comment.datetime_created).format('YYYY-MM-DD hh:mm')}
                </div>
                <div className="action-bar">
                  {/* {renderReportBtn()} */}
                  {renderDeleteBtn()}
                  {renderReplyBtn()}
                </div>
              </div>
            </div>
            {repplying ? (
              <CommentBox
                comment={reply}
                userInfo={userInfo}
                handleChange={e => e.target && setReply(e.target.value)}
                handleCancel={() => setReply('')}
                userName={userName}
                commenteeId={comment.author.id}
                parentId={parentId}
                onAddComment={handleReply}
                commentee={comment.author.name || comment.author.username}
                type="reply"
              />
            ) : null}
          </div>
        </div>
        <ConfirmModal
          title="删除留言？"
          contentNode={
            '要删除该留言吗？留言删除后自己和其他人都不会在看见它。该操作不可撤销。'
          }
          iconType="error"
          visible={visible.delete}
          onOk={handleDelete}
          onCancel={() => setVisible(prev => ({ ...prev, delete: false }))}
          okText="删除"
          cancelText="关闭"
          bottomNode={
            <>
              <Button
                onClick={() => setVisible(prev => ({ ...prev, delete: false }))}
              >
                  取消  
              </Button>
              {/* <Button onClick={handleReport} style={{ marginRight: 8 }}>
                举报
              </Button> */}
              <Button danger onClick={handleDelete}>
                删除
              </Button>
            </>
          }
        />
        {/* <ConfirmModal
          title="举报评论"
          iconType="error"
          contentNode={'你确定要举报该评论吗？'}
          visible={visible.report}
          bottomNode={
            <>
              <Button
                onClick={() =>
                  setVisible((prev) => ({ ...prev, report: false }))
                }
              >
                  关闭  
              </Button>
              <Button danger onClick={handleReport}>
                 举报
              </Button>
            </>
          }
        /> */}
      </div>
    );
  }
);

export default Comment;
