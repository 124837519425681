import React, { memo } from 'react';
import { Modal } from 'antd';
import './index.scss';

const CommonModal = memo(
  ({
    title,
    width,
    visible,
    showClose = true,
    onCancel,
    contentNode,
    bottomNode = null
  }) => {
    return (
      <Modal
        title={title}
        visible={visible}
        closable={showClose}
        closeIcon={
          <img src="/image/close.png" alt="close" width="14px" height="14px" />
        }
        className="commonModal"
        onCancel={onCancel}
        footer={null}
        width={width}
        centered={true}
        getContainer={false}
        data-testid="team-modal"
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className="text">{contentNode}</div>
        <div className={bottomNode ? 'action' : ''}>{bottomNode}</div>
      </Modal>
    );
  }
);

export default CommonModal;
