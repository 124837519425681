import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback
} from 'react';
import CommonModal from './../../../../components/commonModal';
import { Button, message, Radio, Form, Input, Transfer } from 'antd';
import '../index.scss';
import {
  createStudent,
  fetchSecretkey,
  patchDetail
} from './../../../../utils/api/manageCenter';
import { secretImageUrl } from './../../../../utils';
import { getTeam } from './../../../../utils/request';
import { getUser } from './../../../../store/actions';
import { useDispatch } from 'react-redux';

const Basic = (props, ref) => {
  const { modalType, fetchList, patchItem } = props;
  const [visible, setVisible] = useState(false);
  const formRef = useRef();
  const [targetKeys, setTargetKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [secretType, setSecretType] = useState('image');
  const [secret, setSecret] = useState('');
  const [secretImage, setSecretImage] = useState('');
  const _dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    handlerBasic: () => setVisible(true)
  }));

  const filterOption = (inputValue, option) => {
    return option.name.indexOf(inputValue) > -1;
  };

  const handleChange = targetKeys => {
    setTargetKeys(targetKeys);
  };

  // 获取密钥
  const fetch = useCallback(type => {
    let secret_type = type;
    fetchSecretkey(secret_type).then(res => {
      if (secret_type === 'image') {
        setSecretImage(res?.data?.secret);
      } else {
        setSecret(res?.data?.secret);
      }
    });
  }, []);

  useEffect(() => {
    if (visible && formRef.current) {
      if (modalType === 'new') {
        formRef.current.setFieldsValue({
          secret_type: 'image'
        });

        // ---------
        async function fetchData() {
          await fetch('image');
          await fetch('word');
        }
        fetchData();
        // --------
      } else {
        if (patchItem) {
          const newItem = JSON.parse(JSON.stringify(patchItem));
          let targetDataId = newItem.student_team.map(element => {
            delete element.name;
            return element.id;
          });
          formRef.current.setFieldsValue({
            name: newItem.name,
            secret_type: newItem.secret_type,
            student_team_id: targetDataId
          });
          setSecretType(newItem.secret_type);
          if (newItem.secret_type === 'image') {
            setSecretImage(newItem.secret_image);
            fetch('word');
          } else {
            setSecret(newItem.secret);
            fetch('image');
          }
          setTargetKeys(targetDataId);
        }
      }
      getAllTeam();
    }
  }, [formRef, visible, patchItem, fetch, modalType]);

  const handlerCreate = values => {
    createStudent(values)
      .then(res => {
        if (res.status === 201) {
          message.success('新建学员成功');
          handlerClose();
          setVisible(false);
          fetchList();
          _dispatch(getUser());
        }
      })
      .catch(e => {
        if (typeof e.response.data === 'string') return;
        message.error(e.response.data.message || '新建学员失败');
      });
  };

  const handlerModify = (id, data) => {
    patchDetail(id, data)
      .then(() => {
        message.success('修改学员信息成功');
        handlerClose();
        setVisible(false);
        fetchList();
      })
      .catch(() => {
        message.error('修改学员信息失败');
      });
  };

  const getAllTeam = () => {
    getTeam({ from_type: '管理中心', page: 1, page_size: 9999 }).then(res => {
      setDataSource(res?.data?.results || []);
    });
  };

  // 关闭弹窗时做的处理
  const handlerClose = () => {
    setVisible(false);
    setTargetKeys([]);
    setSecretType('image');
    setSecret('');
    setSecretImage('');
  };

  return (
    <CommonModal
      title={modalType === 'new' ? '新建学员' : '修改学员信息'}
      width={472}
      visible={visible}
      onCancel={() => {
        handlerClose();
      }}
      contentNode={
        <div className="basic secretkey">
          <Form
            name="basic_form"
            ref={formRef}
            onFinish={values => {
              let val =
                values.secret_type === 'word'
                  ? { secret: secret }
                  : { secret_image: secretImage };
              modalType === 'new'
                ? handlerCreate({ ...values, ...val })
                : handlerModify(patchItem.id, { ...values, ...val });
            }}
            autoComplete="off"
            layout="vertical"
            preserve="false"
          >
            <Form.Item
              label="姓名"
              name="name"
              rules={[{ required: true, message: '请输入姓名' }]}
            >
              <Input maxLength="15" placeholder="请输入" />
            </Form.Item>
            <Form.Item
              label="密钥类型"
              name="secret_type"
              rules={[{ required: true, message: '请选择密钥类型' }]}
            >
              <Radio.Group
                buttonStyle="solid"
                onChange={e => setSecretType(e.target.value)}
              >
                <Radio.Button value="image">图片密钥</Radio.Button>
                <Radio.Button value="word">词组密钥</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span className="before">*</span>
                  密钥
                </>
              }
              // name="secret_value"
            >
              <div className="secret">
                <p>
                  {secretType === 'image' ? (
                    <img src={secretImageUrl + secretImage} alt={secretImage} />
                  ) : (
                    secret
                  )}
                </p>
                <span onClick={() => fetch(secretType)}>（更换）</span>
              </div>
            </Form.Item>
            <Form.Item
              label="分配团队"
              name="student_team_id"
              rules={[{ required: true, message: '请选择分配团队' }]}
            >
              <Transfer
                dataSource={dataSource}
                showSearch
                filterOption={filterOption}
                targetKeys={targetKeys}
                onChange={handleChange}
                render={item => item.name}
                rowKey={record => record.id}
              />
            </Form.Item>
          </Form>
        </div>
      }
      bottomNode={
        <Button type="primary" onClick={() => formRef.current.submit()}>
          保存
        </Button>
      }
    />
  );
};

export default forwardRef(Basic);
