import React, { useState, useRef, memo } from 'react'
import { Input, Button, Modal, message } from 'antd'
import ReplyBox from '../replyBox'
import { addAccountComment } from '../../utils/request'
import './style.scss'
import { targetURL } from '../../utils'

const imgFix =
  process.env.NODE_ENV === 'development'
    ? targetURL
    : ''
const CommentBox = memo(
  ({
    userInfo,
    comment,
    handleChange,
    handleCancel,
    userName,
    parentId,
    commenteeId,
    commentee,
    onAddComment,
    type
  }) => {
    const [visible, setVisible] = useState(false)
    const textareaRef = useRef()
    const submitComment = () => {
      if (!comment.trim().length) {
        message.warning('发布评论不能为空')
        return
      }
      addAccountComment(userName, {
        content: comment,
        parent_id: parentId || '',
        commentee_id: commenteeId || '',
      }).then((res) => {
        onAddComment(res.data)
      })
    }

    return (
      <div className={`submit-comment ${type}`}>
        {Object.is(type, 'comment') || Object.is(type, 'smallcomment') ? (
          <>
            <div className="icon-wrapper">
              <img src={imgFix + userInfo.avatar_url} alt={userInfo.username} />
            </div>
            <div className="content">
              <Input.TextArea
                ref={textareaRef}
                rows="5"
                maxLength="500"
                onChange={handleChange}
                onClick={() => {
                  if (!userInfo || userInfo.user_type !== 'teacher') {
                    setVisible(true)
                  }
                }}
                placeholder="发言请自觉遵守互联网相关的政策法规，英荔有权对不合规信息进行删除。"
              />
              <div className="actionBar">
                <Button
                  onClick={(e) => {
                    textareaRef.current.handleReset(e)
                    handleCancel()
                  }}
                >
                  取消
                </Button>
                <Button
                  type="primary"
                  disabled={!comment}
                  onClick={(e) => {
                    textareaRef.current.handleReset(e)
                    submitComment()
                  }}
                >
                  发布
                </Button>
              </div>
              <span className="textAreaSuffix">{`${
                comment.length > 500 ? 500 : comment.length
              }/500`}</span>
            </div>
          </>
        ) : (
          <ReplyBox
            comment={comment}
            commentee={commentee}
            handleChange={handleChange}
            onCancel={handleCancel}
            onOk={submitComment}
          />
        )}
        <Modal
          visible={visible}
          className="modal"
          footer={null}
          width={480}
          centered={true}
          getContainer={false}
          onCancel={() => setVisible(false)}
        >
          <div className="title">
            {/* {renderIcon(iconType)} */}
            <span className="detail">欢迎来到创造乐园</span>
          </div>
          <div className="content">
            <p>
              当前你使用的是学生专用账户或没有登录，无法给其他小伙伴留言或评论哦。如果想在开放的作品社区中与其他小伙伴互动，欢迎到编程魔法师处报名。
            </p>
            <a
              href="http://course.aimaker.space/register"
              target="_blank"
              rel="noopener noreferrer"
            >{`我不是小孩子了，可以自己注册账户 >`}</a>
          </div>
          <div className="action">
            <Button onClick={() => setVisible(false)} type="primary">
              <a
                href="https://wj.qq.com/s2/7173869/e431"
                rel="noopener noreferrer"
                target="_blank"
              >
                报名
              </a>
            </Button>
          </div>
        </Modal>
      </div>
    )
  },
)

export default CommentBox
