import React from 'react'
import moment from 'moment'
import { Tooltip } from 'antd'
import './style.scss'

const hoursRange = [
  '0-8',
  '8-10',
  '10-12',
  '12-14',
  '14-16',
  '16-18',
  '18-20',
  '20-22',
  '22-24',
]
const TimeRecord = (props) => {
  const { data, dates, platform, setPlatForm } = props
  const showTimeRange = (date, range) => {
    if (date === moment().format('YYYYMMDD')) {
      const lastTime = range.split('-')[1]
      const nowTime = new Date()
      return nowTime.getHours() >= lastTime
    }
    return true
  }

  const hasDataDate = (date) => {
    const arr = Array.from(new Set(data.map((item) => item.date)))
    return arr.indexOf(date) > -1
  }

  const getUseTimeColor = (date, range) => {
    const res = getUseTimeVal(date, range)
    const isTeach = platform === 'teach'
    if (res) {
      // console.log(res)
      if (res.val >= 120) {
        return isTeach ? 'rgb(0,132,255)' : 'rgb(11,210,149)'
      } else if (res.val >= 90 && res.val < 120) {
        return isTeach ? 'rgba(0,132,255,0.75)' : 'rgba(11,210,149,.75)'
      } else if (res.val >= 60 && res.val < 90) {
        return isTeach ? 'rgba(0,132,255,0.5)' : 'rgba(11,210,149,.5)'
      } else if (res.val >= 30 && res.val < 60) {
        return isTeach ? 'rgba(0,132,255,0.25)' : 'rgba(11,210,149,.25)'
      } else if (res.val > 0 && res.val < 30) {
        return isTeach ? 'rgba(0,132,255,0.15)' : 'rgba(11,210,149,.15)'
      } else {
        return '#eeeeee'
      }
    }
    return '#fafafa'
  }

  const getUseTimeVal = (date, range) => {
    return data.find((item) => item.date === date && item.hour === range)
  }
  return (
    <>
      <div className="legendList">
        <div class="legend" onClick={() => setPlatForm('teach')}>
          <div
            className="name"
            style={{ color: platform === 'teach' ? '#666666' : '#cccccc' }}
          >
            教学云
          </div>
          <div
            className="round"
            style={{
              backgroundColor:
                platform === 'teach' ? 'rgb(0,132,255)' : '#fff',
              border: platform === 'teach' ? 'none' : '1px solid #dfdfdf'  
            }}
          ></div>
          <div
            className="round"
            style={{
              backgroundColor:
                platform === 'teach'
                  ? 'rgba(0,132,255,.75)'
                  : '#fff',
              border: platform === 'teach' ? 'none' : '1px solid #dfdfdf'
            }}
          ></div>
          <div
            className="round"
            style={{
              backgroundColor:
                platform === 'teach'
                  ? 'rgba(0,132,255,.5)'
                  : '#fff',
              border: platform === 'teach' ? 'none' : '1px solid #dfdfdf'
            }}
          ></div>
          <div
            className="round"
            style={{
              backgroundColor:
                platform === 'teach'
                  ? 'rgba(0,132,255,.25)'
                  : '#fff',
              border: platform === 'teach' ? 'none' : '1px solid #dfdfdf'    
            }}
          ></div>
          <div
            className="round"
            style={{
              backgroundColor:
                platform === 'teach'
                  ? 'rgba(0,132,255,.15)'
                  : '#fff',
              border: platform === 'teach' ? 'none' : '1px solid #dfdfdf'
            }}
          ></div>
        </div>
        <div class="legend" onClick={() => setPlatForm('creative')}>
          <div
            className="name"
            style={{
              color: platform === 'creative' ? '#666666' : '#cccccc',
            }}
          >
            创作平台
          </div>
          <div
            className="round"
            style={{
              backgroundColor:
                platform === 'creative'
                  ? 'rgb(11,210,149)' : 
              '#fff',
              border: platform === 'creative' ? 'none' : '1px solid #dfdfdf'
            }}
          ></div>
          <div
            className="round"
            style={{
              backgroundColor:
                platform === 'creative'
                  ? 'rgba(11,210,149,.75)'
                  : '#fff',
            border: platform === 'creative' ? 'none' : '1px solid #dfdfdf'    
            }}
          ></div>
          <div
            className="round"
            style={{
              backgroundColor:
                platform === 'creative'
                  ? 'rgba(11,210,149,.5)'
                  : '#fff',
              border: platform === 'creative' ? 'none' : '1px solid #dfdfdf'    
            }}
          ></div>
          <div
            className="round"
            style={{
              backgroundColor:
                platform === 'creative'
                  ? 'rgba(11,210,149,.25)'
                  : '#fff',
              border: platform === 'creative' ? 'none' : '1px solid #dfdfdf'
            }}
          ></div>
          <div
            className="round"
            style={{
              backgroundColor:
                platform === 'creative'
                  ? 'rgba(11,210,149,.15)'
                  : '#fff',
              border: platform === 'creative' ? 'none' : '1px solid #dfdfdf'
            }}
          ></div>
        </div>
      </div>
      <div id="useTimeRecord">
        <div className="content">
          <div className="yaxis">
            {hoursRange.map((item) => (
              <span className="hours" key={item}>
                {item}点
              </span>
            ))}
          </div>
          <div className="recordTable">
            {dates.length
              ? dates.map((item) => (
                  <div className="date">
                    {hoursRange.map((hours) => (
                      <>
                        {showTimeRange(item.date, hours) ? (
                          hasDataDate(item.date) ? (
                            <Tooltip
                              placement="top"
                              title={`本时段人均学习时长：${
                                getUseTimeVal(item.date, hours)?.val || 0
                              } 分钟`}
                            >
                              <span
                                className="hours use"
                                style={{
                                  backgroundColor: getUseTimeColor(
                                    item.date,
                                    hours,
                                  ),
                                }}
                                key={`${item.date}-${hours}`}
                              ></span>
                            </Tooltip>
                          ) : (
                            <span
                              className="hours unuse"
                              style={{
                                backgroundColor: getUseTimeColor(
                                  item.date,
                                  hours,
                                ),
                              }}
                              key={`${item.date}-${hours}`}
                            ></span>
                          )
                        ) : null}
                      </>
                    ))}
                    <span
                      className={`xaxis ${
                        hasDataDate(item.date) ? 'use' : 'unuse'
                      }`}
                      key={item.date}
                    >
                      {item.num}
                    </span>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default TimeRecord
