import React, {
  useState,
  // useCallback,
  useRef,
  forwardRef,
  useEffect
  // useImperativeHandle
} from 'react';
import { useSelector } from 'react-redux';
import { getStudentSettings, setStudentSettings } from './../../utils/request';
import { Switch, Button, message, Tooltip } from 'antd';
import KeyModal from './keyModal';
import './style.scss';

const StudentAccountSetting = () => {
  const [setting, setSetting] = useState(null);
  const [keySwitch, setKeySwitch] = useState();
  const userInfo = useSelector(state => state.userInfo);
  const modalRef = useRef();
  const onChangeSwitch = (checked, e) => {
    setStudentSettings({
      type: 'change_key_login',
      is_enable: !checked ? '1' : '0'
    })
      .then(res => {
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.warn(res.data.msg);
        }
        getSettings();
      })
      .catch(err => {});
  };
  useEffect(() => {
    getSettings();
  }, []);
  const getSettings = () => {
    if (!window.navigator.onLine) {
      message.error('获取信息失败，请刷新当前页面');
      return;
    }
    getStudentSettings()
      .then(res => {
        if (res.data.success) {
          setSetting(res.data.data);
          setKeySwitch(!res.data.data.has_key_login);
        } else {
          message.warn(res.data.msg);
        }
      })
      .catch(err => {
        console.log(err);
        // message.warn(err.msg);
      });
  };

  return (
    <div id="studentAccountSetting">
      <div className="container">
        <div className="header">账户设置</div>

        <div className="modificationpsw" style={{ display: 'block' }}>
          <div className="title">登录方式</div>
          <div className="type">
            <div>
              <div className="secondaryTit">登录卡登录</div>
              <div>按照登录卡上「团队代码」和「密钥」方式登录英荔教学云。</div>
            </div>
          </div>
          <div className="type">
            <div>
              <div className="secondaryTit">账号登录</div>

              <div>
                设置密码后在教学云登录页面，通过
                <Tooltip
                  title={'您的用户名: ' + userInfo.username}
                  placement="bottom"
                >
                  「<span className="mainColor">用户名</span>」
                </Tooltip>
                和「密码」的方式登录。
              </div>
            </div>
            <div>
              <Button
                type="link"
                onClick={() => {
                  modalRef.current.handleKeyModal();
                }}
              >
                {setting?.has_password ? '修改密码' : '设置密码'}
              </Button>
            </div>
          </div>
        </div>
        {setting?.has_password ? (
          <div className="modificationpsw" style={{ display: 'block' }}>
            <div className="type" style={{ marginTop: '0' }}>
              <div>
                <div className="threeTit">禁用登录卡登录</div>
                <div style={{ marginTop: '4px' }}>
                  打开禁用开关后，登录卡登录失效，只能通过账号登录，请牢记
                  <Tooltip
                    title={'您的用户名: ' + userInfo.username}
                    placement="bottom"
                  >
                    「<span className="mainColor">用户名</span>」
                  </Tooltip>
                  和「密码」。
                  <br />
                  若遗失需请老师联系管理员重置密码。
                </div>
              </div>

              <div>
                <Switch
                  checked={keySwitch}
                  onChange={checked => onChangeSwitch(checked)}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <KeyModal
        ref={modalRef}
        has_password={setting?.has_password}
        getSettings={getSettings}
      />
    </div>
  );
};
export default forwardRef(StudentAccountSetting);
