import React, { useState, useEffect } from 'react';
import './index.scss';
import { Button, Popover } from 'antd';
import { getSoftware } from '../../utils/request';
import DownloadModal from './downloadModal';

const DownloadSoftware = () => {
  const [teachList, setTeachList] = useState([]);
  const [otherList, setOtherList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');

  useEffect(() => {
    getSoftware()
      .then((res) => {
        // console.log(`getSoftware=`, res);
        if (res?.data) {
          const data = res.data;
          const teachArr = data.filter((list) => list.software_type === 1);
          setTeachList(teachArr);
          const otherArr = data.filter((list) => list.software_type === 2);
          setOtherList(otherArr);
          // console.log(`arr==`, teachArr, '----', otherArr);
        }
      })
      .catch((err) => {
        console.log(`err:`, err);
      });
  }, []);

  const renderList = (arr) => {
    return (
      <div className="softwareList">
        {arr.map((list) => (
          <div className="listBox" key={list.id}>
            <div>
              <img src={list.logo} alt="" />
            </div>
            <div>
              <h2>{list.software_name}</h2>
              <div className="con">
                {list.version ? (
                  <p className="version">{list.version}</p>
                ) : null}
                <p className="description">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: list.remark.replace(/\r\n/g, '<br>'),
                    }}
                  ></span>

                  {/* {list.software_key === 'scratchlink' ? (
                    <span
                      className="scratch_modify"
                      onClick={() => showModal(true, 'scratchlink')}
                    >
                      点击查看修改方式
                    </span>
                  ) : null} */}
                </p>
              </div>
              {/* <p className="buttonBox">
                {list.download_button.map((btn) => (
                  <Button type="primary" key={btn.id}>
                    {btn.button_name}
                  </Button>
                ))}
              </p> */}
              {renderButton(list.download_button, list.software_key)}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderButton = (download_button, software_key) => {
    /**
     * BUTTON_INTERACTION = (
        (1, '直接下载'),
        (2, '显示二维码'),
        (3, '新标签页打开'),
        (4, '弹框提示')
      )
     */
    return (
      <div className="buttonBox">
        {download_button.map((btn) => (
          <div key={btn.id} style={{ display: 'inline-block' }}>
            {(() => {
              switch (btn.button_interaction) {
                case 1:
                  return (
                    <a href={btn.redirect_url}>
                      <Button type="primary">{btn.button_name}</Button>
                    </a>
                  );
                case 2:
                  return (
                    <Popover
                      placement="top"
                      content={<img src={btn.redirect_url} alt="" />}
                      // trigger="click"
                    >
                      <Button type="primary">{btn.button_name}</Button>
                    </Popover>
                  );

                case 3:
                  return (
                    <a
                      href={btn.redirect_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button type="primary">
                        {btn.button_name}
                      </Button>
                    </a>
                  );
                case 4:
                  return (
                    <Button
                      type="primary"
                      onClick={() => showModal(true, software_key)}
                    >
                      {btn.button_name}
                    </Button>
                  );

                default:
                  return '';
              }
            })()}
          </div>
        ))}
      </div>
    );
  };

  const showModal = (value, software_key) => {
    console.log(`software_key===`, software_key);
    setIsModalVisible(value);
    setModalType(software_key);
  };

  return (
    <div>
      <img src="/image/sofeware_banner.png" alt="banner" />
      <div className="mainBox">
        <div className="mainContent">
          {teachList.length && (
            <div className="teachSoftware">
              <h1>
                <i></i>
                <span>教学相关软件</span>
              </h1>
              {renderList(teachList)}
            </div>
          )}

          {otherList.length && (
            <div className="teachSoftware" style={{ marginTop: '48px' }}>
              <h1>
                <i></i>
                <span>其他软件</span>
              </h1>
              {renderList(otherList)}
            </div>
          )}
        </div>
      </div>

      <DownloadModal
        isModalVisible={isModalVisible}
        modalType={modalType}
        showModal={() => showModal()}
      />
    </div>
  );
};

export default DownloadSoftware;
