import React, { useState, useCallback, useRef, useLayoutEffect, useEffect } from 'react'
import './style.scss'
import { Button, Input, Table, Popover } from 'antd'
import qs from 'query-string'
import { useHistory, Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { scratchURL } from './../../utils'
import { getVisitHistory } from '../../utils/request'
// import { useGetProjects } from '../project'
// import { useGetCourse } from '../course'

const Learnhistory = () => {
    const history = useHistory()
    // const [ courses ] = useGetCourse()
    // const [ projects ] = useGetProjects()
    const [ data, setData ] = useState([])
    const [ pageSize, setPageSize ] = useState(8)
    const [ total, setTotal ] = useState(0)
    const [ loaded, setLoaded ] = useState(false)
    const [ current, setCurrent ] = useState(Number(qs.parse(history.location.search).page) || 1)
    const [ searchValue, setSearchValue ] = useState('')

    const searchRef = useRef()

    const onSearchHandler = useCallback((value) => {
        history.push({
            path: history.location.pathname,
            search: qs.stringify({page: 1})
        })
        setCurrent(1)
        setSearchValue(value)
    }, [history])

    useEffect(() => {
        getVisitHistory({page_size: pageSize, page: current, ...(searchValue ? {q: searchValue} : null)})
        .then(res => {
            // res.data.results = res.data.results
            res.data.results = res.data.results.map((item, index) => {
                let url
                if (item.resourcetype === 'CourseHistory') {
                    url = `/course/${item.course}/${item.unit || 0}`
                } else if (item.resourcetype === 'CourseProjectHistory') {
                    url = `/project/${item.project}`
                }

                return {
                    ...item,
                    url,
                    key: index
                }
            })
            setData(res.data.results)
            console.log(res.data.results)
            setTotal(res.data.count)
            setLoaded(true)

            // setVisitHistoryCount(res.data.count)
        })
    }, [ pageSize, current, searchValue ])

    const columns = [
        {
            title: '标题',
            dataIndex: 'name',
            key: 'name',
            width: '65%',
            render: (text, record) => (
                <a href={record.url} target="_blank" rel="noopener noreferrer">
                    <span className="name">
                        <img src={record.cover} width="48px" alt="cover" />
                        {record.title.length > 30 ?
                        <Popover content={record.title} trigger="hover">
                           {record.title.slice(0, 30) + '…'}
                        </Popover> :
                        record.title}
                    </span>
                </a>
            )
        },
        {
            title: '时间',
            dataIndex: 'updated_at',
            key: 'updated_at',
            align: 'left',
            render: (value, record) => dayjs(record.updated_at).format('YYYY/MM/DD HH:mm')
        }
    ]

    useLayoutEffect(() => {
        document.querySelector('.ant-table-pagination .ant-select-selection-item') &&
        (document.querySelector('.ant-table-pagination .ant-select-selection-item').innerText = `${pageSize} 条/页`)
    }, [data.length, loaded]) // eslint-disable-line

    return (
        <div id="learnhistory">
            <div className="container">
                <div className="titleBar">
                    <div className="title">最近学习</div>
                    <div className="btnGroup">
                        <Link to="/project"><Button size="large" type="primary">更多项目</Button></Link>
                        <Link to="/course"><Button size="large" type="primary">更多课程</Button></Link>
                    </div>
                </div>
                <div className="actionBar">
                    <Input.Search
                        ref={searchRef}
                        placeholder="搜索标题"
                        onSearch={onSearchHandler}
                        style={{ width: 280, borderRadius: '4px' }}
                    />
                </div>
                <div className={`table ${!data.length ? 'empty' : ''}`}>
                    <Table columns={columns} dataSource={data} pagination={
                            {
                                current: current,
                                total,
                                showQuickJumper: true,
                                defaultPageSize: pageSize,
                                hideOnSinglePage: !data.length,
                                onChange: (val) => {
                                    console.log('onchang', val)
                                    history.push({
                                        path: history.location.pathname,
                                        search: qs.stringify({...qs.parse(history.location.search), page: val})
                                    })
                                    setCurrent(val)
                                },
                                onShowSizeChange: (cur, size) => {
                                    history.push({
                                        path: history.location.pathname,
                                        search: qs.stringify({...qs.parse(history.location.search), page: 1})
                                    })
                                    setCurrent(1)
                                    setPageSize(size)
                                },
                                showTotal: (total, range) => `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`
                            }}
                    />
                    {
                          loaded && !data.length && !searchValue ?
                          <div className="nodata">
                              <img src="/image/empty-page.png" alt="empty-page" />
                              <div className="tips">暂无收藏，快去社区浏览更多作品吧</div>
                              <div>
                                  <a href={`${scratchURL}/projects/editor/`} target="_blank" rel="noopener noreferrer">
                                      <Button type="primary">前往社区</Button>
                                  </a>
                              </div>
                          </div> : null
                    }
                </div>
            </div>
        </div>
    )
}

export default Learnhistory