import React, { useState, useCallback, useEffect } from 'react';
import './style.scss';
import { Button, Modal, Alert, message } from 'antd';
import { useDispatch } from 'react-redux';
import { loginAndSetToken } from './../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { joinTeam, joinTeamWithName } from './../../utils/request';
import { isMobile, secretImageUrl } from '../../utils';
import qs from 'query-string';
import { CloseCircleFilled } from '@ant-design/icons';
import { getProgramPlatformHash } from '../../utils/api/programPlatform';
import { MPModal } from '../MPModal';

const LoginWithCode = ({ history, location, ...props }) => {
  const [teamName, setTeamName] = useState();
  const [keyType, setKeyType] = useState();
  const [nameArray, setNameArray] = useState([]);
  const [cardsArray, setCardsArray] = useState([]);

  const [selectedName, setSelectedName] = useState();
  const [selectPhrase, setSelectPhrase] = useState();
  const [showCodeFAQ, setshowCodeFAQ] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [errMsg, setErrMsg] = useState('请选择匹配的图片/词组');
  const dispatch = useDispatch();

  useEffect(() => {
    joinTeam(props.code)
      .then(res => {
        setTeamName(res.data.team);
        // setKeyType(res.data.key_type);
        setNameArray(res.data.students);
      })
      .catch(e => {
        // message.error('获取团队信息失败，请检查链接是否正确，或向老师/系统管理员求助', 0)
        Modal.error({
          title: '获取团队信息失败',
          icon: <CloseCircleFilled />,
          content: (
            <div>
              <div className="titleTag">
                请检查团队代码是否正确。团队代码是 6
                位全大写英文字母组成的代码。
              </div>

              <div className="titleTag">我是学生：</div>
              <p>记不清团队代码了，请联系你的授课老师。</p>
              <div className="titleTag">我是老师：</div>
              <p>
                请到「我的管理 -
                团队」界面中，进入相应团队的成员列表页，将「专属链接」发送给学生。
              </p>
              <p style={{ lineHeight: '50px' }}>
                如果仍存在问题，请联系客服人员。
              </p>
            </div>
          ),
          width: 557,
          centered: true,
          okType: 'ghost',
          okText: '我知道了',
          className: 'getTeamMsgErrorModalCancelFooterBtn'
        });
      });
  }, []); // eslint-disable-line

  const handlerLogin = useCallback(() => {
    let loginConfig = {
      login_type: 'code',
      code: props.code,
      name: nameArray[selectedName],
      secret: cardsArray[selectPhrase]
    };
    dispatch(loginAndSetToken(loginConfig))
      .then(async () => {
        if (qs.parse(location.search).redirect) {
          // window.location.href = decodeURIComponent(
          //   qs.parse(location.search).redirect
          // );
          let url = decodeURIComponent(qs.parse(location.search).redirect)
          // 是否需要hash
          if (qs.parse(location.search).needHash === 'yes') {
            const { data } = await getProgramPlatformHash()
            const {search} = new URL(url)
            url = search ? `${url}&token=${data.encrypt_text}` : `${url}?token=${data.encrypt_text}`
          }
          window.location.href = url;
        } else {
          history.push('/account/home');
        }
      })
      .catch(e => {
        setSelectPhrase();
        if (e.response.data.secret?.indexOf('冻结') > -1) {
          return message.warn(e.response.data.secret);
        }
        // 账号被删时做页面刷新 （message为删除）
        if (e.response.data.message) {
          message.error(e.response.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          return;
        }
        setErrMsg(e.response.data.secret);
        setShowErr(e);
      });
  }, [
    dispatch,
    history,
    location.search,
    props.code,
    nameArray,
    cardsArray,
    selectedName,
    selectPhrase
  ]);

  useEffect(() => {
    if (selectedName === undefined) return;
    joinTeamWithName(props.code, { name: nameArray[selectedName] }).then(
      res => {
        console.log(res.data);
        setKeyType(res.data.key_type);

        setShowErr(false);
        setCardsArray(res.data.cards);
        setSelectPhrase();
      }
    );
  }, [selectedName, props.code, nameArray]);
  // 登录路径
  let loginPath = '/login'
  if (isMobile()) {
    loginPath += window.location.search
  }
  return (
    <div id="loginWithCode" className="container">
      <MPModal
        closeIcon={
          <img src="/image/close.png" alt="close" width="14px" height="14px" />
        }
        getContainer={false}
        className="modal"
        centered
        visible={showCodeFAQ}
        footer={null}
        onOk={() => setshowCodeFAQ(false)}
        onCancel={() => setshowCodeFAQ(false)}
        width={'730px'}
      >
        <div className="content">
          <div className="title">为什么找不到自己的姓名？</div>
          <div className="titleTag">
            这里展示了团队中所有成员的名字，若仔细寻找后仍没有找到你，则可能存在以下原因：
          </div>

          <div className="titleTag">1. 进错团队了。</div>
          <p>
            解决办法：检查团队名称，确定自己进入的是正确的团队。如果确定是进错团队了，请询问同学或老师以获得正确的团队代码。
          </p>

          <div className="titleTag">2. 团队创建者没有将你添加到此团队中。</div>
          <p>
            解决办法：联系老师，请他帮你在此团队中创建账号，并告知你正确的登录密钥。
          </p>

          <div className="titleTag">3. 团队创建者将你的名字设置错了。</div>
          <p>
            解决办法：如果你发觉团队中有陌生的成员，很可能是老师不小心将你的名字设置错了，请联系老师修改或给你新建一个账号。
          </p>
          <div className="buttonDiv">
            <Button type="primary" onClick={() => setshowCodeFAQ(false)}>
              我知道了
            </Button>
          </div>
        </div>
      </MPModal>
      <MPModal
        closeIcon={
          <img src="/image/close.png" alt="close" width="14px" height="14px" />
        }
        getContainer={false}
        className="modal"
        centered
        visible={forgotModal}
        footer={null}
        onOk={() => setForgotModal(false)}
        onCancel={() => setForgotModal(false)}
        width={'730px'}
      >
        <div className="content">
          <div className="title">忘记登录密钥了？</div>
          <div className="titleTag">我是学生：</div>
          <p>请联系你的老师，请他协助你找回密钥。</p>
          <div className="titleTag">我是老师：</div>
          <p>
            当学生忘记其密钥时，你可以在「我的管理 -
            团队」中找到当前上课的班级，在成员列表页中找到该学生，即可获得该学生的密钥。如果密钥没有显示，请点击密钥旁的「眼睛」按钮。
          </p>

          <div className="titleTag">
            <img src="/image/forgetTheKey.svg" alt="" />
          </div>
          <div className="buttonDiv">
            <Button type="primary" onClick={() => setForgotModal(false)}>
              我知道了
            </Button>
          </div>
        </div>
      </MPModal>
      <div className="className">团队：{teamName}</div>
      <div className="nameList">请选择您的姓名</div>
      <div className="selectName">
        {nameArray.map((item, index) => (
          <div
            className={`item ${selectedName === index ? 'active' : ''}`}
            key={index}
            onClick={() => setSelectedName(index)}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="faq">
        为什么找不到自己的姓名？请
        <span className="faqBtn" onClick={() => setshowCodeFAQ(true)}>
          点击
        </span>
      </div>
      {keyType === 'word' ? (
        <>
          <div className="phraseList">请选择匹配姓名的词组</div>
          <div className="selectPhrase">
            {cardsArray.map((item, index) => (
              <div
                className={`item ${selectPhrase === index ? 'active' : ''}`}
                key={index}
                onClick={() => {
                  setShowErr(false);
                  setSelectPhrase(index);
                }}
              >
                {item}
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="phraseList">请选择匹配姓名的图片</div>
          <div className="selectPic">
            {cardsArray.map((item, index) => (
              <div
                className={`item ${selectPhrase === index ? 'active' : ''}`}
                key={index}
                onClick={() => {
                  setShowErr(false);
                  setSelectPhrase(index);
                }}
                style={{ backgroundImage: `url(${secretImageUrl}${item})` }}
              />
            ))}
            <i />
            <i />
            <i />
            <i />
          </div>
        </>
      )}
      {showErr && (
        <Alert message={errMsg} type="error" className="errorMsg" showIcon />
      )}
      <div>
        <Button
          style={{ width: 120, height: 40, fontSize: '16px' }}
          disabled={selectedName === undefined || selectPhrase === undefined}
          type="primary"
          onClick={handlerLogin}
        >
          登录
        </Button>
        <Link to={loginPath} style={{ marginLeft: '16px' }}>
          使用其他登录方式
        </Link>
      </div>
      <p className="forgotText" onClick={() => setForgotModal(true)}>
        忘记了自己的密钥？
      </p>
    </div>
  );
};

export default withRouter(LoginWithCode);
