/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Button, Table, Input, Pagination } from 'antd';
import qs from 'query-string';
import dayjs from 'dayjs';
import {
  getFollowPortfolios,
  getFollowPortfolioCount,
  deleteOwnPortfolioFollow
} from '../../../utils/api/account';
import { coverMap } from '../../../utils/variable';
import './styles.scss';

const pageSize = 10;

const AccountFollow = () => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchVal, setSearchVal] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const searchRef = useRef();

  const columns = [
    {
      title: '名称',
      dataIndex: 'title',
      key: 'title',
      width: 500,
      render: (text, record) => (
        <Link to={`/manage/ownportfolio/${record.id}/project`}>
          <span className="name">
            <img
              src={
                record.image ? getCover(record.image) : coverMap[record.origin]
              }
              width="48px"
              alt="cover"
            />
            {record.title}
          </span>
        </Link>
      )
    },
    {
      title: '创建者',
      dataIndex: 'author_name',
      key: 'author_name',
      width: 250,
      render: (text, record) => (
        <Link to={`/account/home/${record.user_name}`}>
          <p className="name">{record.author_name || record.user_name}</p>
        </Link>
      )
    },
    {
      title: '作品数',
      dataIndex: 'num_project',
      key: 'num_project',
      width: 250,
      render: (text, record) => <span>{record.stats.num_project}</span>
    },
    {
      title: '关注时间',
      dataIndex: 'follow_at',
      key: 'follow_at',
      width: 250,
      render: text => dayjs(text).format('YYYY/MM/DD')
    },
    {
      title: '操作',
      key: 'action',
      width: 250,
      render: (text, record) => (
        <div
          style={{ color: '#0084ff', cursor: 'pointer' }}
          onClick={cancelFollow(record.id)}
        >
          取消关注
        </div>
      )
    }
  ];
  const fetchList = (page, val) => {
    const params = {
      offset: (page - 1) * pageSize,
      limit: pageSize,
      ...(val ? { q: val } : null)
    };
    return new Promise((resolve, reject) => {
      getFollowPortfolios(params).then(res => {
        if (Object.is(res.status, 200)) resolve(res.data);
      });
    });
  };

  const fetchTotal = val => {
    const params = {
      ...(val ? { q: val } : null)
    };
    return new Promise((resolve, reject) => {
      getFollowPortfolioCount(params).then(res => {
        if (Object.is(res.status, 200)) resolve(res.data);
      });
    });
  };

  const onSearchHandler = useCallback(
    value => {
      history.push({
        path: history.location.pathname,
        search: qs.stringify({ ...qs.parse(location.search), ...{ page: 1 } })
      });
      setPage(1);
      setSearchVal(value);
      setIsSearch(true);
    },
    [history, location.search]
  );

  const fetchData = () => {
    Promise.all([fetchList(page, searchVal), fetchTotal(searchVal)]).then(
      res => {
        const [data, totalData] = res;
        setData(data);
        setTotal(totalData.count);
      }
    );
  };

  const cancelFollow = id => {
    return () => {
      deleteOwnPortfolioFollow(id).then(res => {
        if (Object.is(res.status, 200)) {
          fetchData();
        }
      });
    };
  };

  const jumpScratch = () => {
    window.location.href =
      'https://' +
      window.location.host.replace('learn', 'make') +
      '/explore/studios/all';
  };

  const getCover = url => {
    let res = 'https://' + window.location.host;
    if (url.indexOf('/') !== 0) res += '/';
    return res + url;
  };

  useEffect(() => {
    fetchData();
  }, [page, searchVal]);

  return (
    <div id="follow">
      {/* <div className="header"> */}
      <div className="container">
        <h1>
          <div>我关注的个人作品集</div>
          {data.length ? (
            <div className="btn">
              <Button type="primary" size="large" onClick={jumpScratch}>
                前往社区
              </Button>
            </div>
          ) : null}
        </h1>
        <div className="content">
          {isSearch || data.length ? (
            <>
              <div className="search">
                <Input.Search
                  ref={searchRef}
                  placeholder="搜索作品集名称"
                  onSearch={onSearchHandler}
                  style={{ width: 280, borderRadius: '4px' }}
                />
              </div>
              <Table
                columns={columns}
                dataSource={data}
                rowKey={'id'}
                pagination={false}
              />
              <div className="pagination">
                <Pagination
                  total={total}
                  current={page}
                  showQuickJumper
                  defaultPageSize={pageSize}
                  onChange={val => setPage(val)}
                  showTotal={(total, range) =>
                    `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`
                  }
                />
              </div>
            </>
          ) : (
            <div className="nodata">
              <img src="/image/empty-page.png" alt="empty-page" />
              <p className="tips">还没有关注的作品集，到社区去找找吧</p>
              <Button className="btn" type="primary" onClick={jumpScratch}>
                前往社区
              </Button>
            </div>
          )}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default AccountFollow;
