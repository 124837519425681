import React, { useEffect, useCallback, useState } from 'react';
import './style.scss';
import { withRouter, useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import printJS from 'print-js';
import { getLoginCard, getTeamDetail } from './../../../../utils/request';
import Pagination from '../../../../components/pagination';
import { secretImageUrl } from './../../../../utils';

function printVertical(team) {
  printJS({
    printable: 'print',
    type: 'html',
    // header: team.name + ' 登录卡片',
    scanStyles: false,
    // headerStyle:
    //   'font-size: 22px;text-align:center;font-weight:normal;padding-bottom:20px;',
    style: `
    #print {
      text-align: center;
      width: 100%;
      height: 100%;
    }
    #print .box{
      display: inline-block;
      margin: 90px 0 0 60px;
    }
    #print .cards{
      display: flex;
      flex-direction: row;
      justify-content: start;
      flex-wrap: wrap;
    }
    #print .card {
      text-align: left;
      width: 170px;
      height: 238px;
      padding: 11px 11px 20px 11px;
      background-color: #ffffff;
      border: solid 1px #dddddd;
    }
    #print .card .word {
      font-size: 28px;
      text-align: center;
      height: 90px;
      line-height: 90px;
      overflow: hidden;
    }
   #print .card .word .secretWord{
      height: 85px;
      line-height: 85px;
      border: 2px solid #dddddd;
      border-radius: 8px;
    }
    #print .card .word img {
      height: 140px;
      width: 140px;
      position: relative;
      top: -25px;
    }
    #print .card .username {
      font-size: 13px;
      margin: 15px 0 13px 0;
    }
    #print .card .link, .code, .name {
      display: flex;
      flex-direction: row;
      padding-bottom: 6px;
      font-size: 7px !important;
      word-break: break-all;
      white-space: normal;
      color: #666666;
    }
    #print .card span.title {
      width: 60px;
      font-size: 7px !important;
      color: #1f1f1f;
    }
    #print .card span.content {
      width: calc(100% - 60px);
    }F
    `
  });
}
function printTransverse(team) {
  printJS({
    printable: 'print',
    type: 'html',
    scanStyles: false,
    style: `
    #print {
      width: 100%;
      height: 100%;
      text-align: center;
    }
    #print .box{
      display: inline-block;
      margin: 60px 0 0 60px;
    }
    #print .cards{
      display: flex;
      flex-direction: row;
      justify-content: start;
      flex-wrap: wrap;
    }
    #print .card {
      width: 280px;
      height: 150px;
      padding: 20px 10px;
      text-align: left;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: solid 1px #dddddd;
    }
    #print .card .word {
      text-align: center;
      margin-right: 10px;
      width: 80px;
      display: flex;
      align-items: center;
      overflow: hidden;
    }
   #print .card .word .secretWord{
      height: 49px;
      font-size: 18px;
      line-height: 49px;
      width: 56px;
      border: 2px solid #dddddd;
      border-radius: 8px;
    }
    #print .card .word img {
      height: 100px;
      width: 100px;
      position: relative;
      left: -15px;
      top: 0px
    }
    #print .card .information{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    #print .card .username {
        font-size: 13px;
        margin-bottom: 15px;
    }
    #print .card .link, .code, .name {
      display: flex;
      flex-direction: row;
      padding-bottom: 6px;
      font-size: 7px !important;
      word-break: break-all;
      white-space: normal;
      color: #666666;
    }
    #print .card span.title {
      width: 60px;
      font-size: 7px !important;
      color: #1f1f1f;
    }
    #print .card span.content {
      width: calc(100% - 60px);
    }
    `
  });
}

const LoginCode = ({ match, ...props }) => {
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [teamData, setTeamData] = useState();
  const [printData, setPrintData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pages, setPage] = useState(0);
  const history = useHistory();
  const [pattern, setPattern] = useState("vertical");
  const getCardsVertical = useCallback(
    (page) => {
      getLoginCard(match.params.id, { page })
        .then(res => {
          setData(res.data.results);
          setPage(Math.ceil(res.data.count / 8));
        })
        .catch(e => {
          message.error('获取登录卡出错');
        });
    },
    [match.params.id]
  );
  const getCardsTransverse = useCallback(
    (page) => {
      getLoginCard(match.params.id, { page_size: 9, page })
        .then(res => {
          setData(res.data.results);
          setPage(Math.ceil(res.data.count / 9));
        })
        .catch(e => {
          message.error('获取登录卡出错');
        });
    },
    [match.params.id]
  );
  useEffect(() => {
    if (current === 1) {
      if (pattern === "vertical") {
        getCardsVertical(current);
      } else {
        getCardsTransverse(current);
      }
    }
  }, [pattern, current, getCardsVertical, getCardsTransverse]);
  useEffect(() => {
    if (pattern === "vertical") {
      getCardsVertical(current);
    } else {
      if (datas.length <= 9 * ((current - 1))) {
        getCardsTransverse(current - 1 || 1);
      } else {
        getCardsTransverse(current);
      }
    }
  }, [current, datas, pattern, getCardsVertical, getCardsTransverse]);
  useEffect(() => {
    getTeamDetail(match.params.id, '管理中心')
      .then(res => {
        setTeamData(res.data);
        return getLoginCard(match.params.id, { page_size: 999 });
      })
      .then(res => {
        let data = [];
        setDatas(res.data.results);
        for (let i = 0; i < res.data.results.length; i += 9) {
          if (i + 9 < res.data.results.length) {
            data.push(res.data.results.slice(i, i + 9));
          } else {
            data.push(res.data.results.slice(i, res.data.results.length));
          }
        }
        setPrintData(data);
      })
      .catch(e => {
        message.error('获取登录卡出错');
      });
  }, []); // eslint-disable-line
  return (
    <div id="loginCode">
      <div className="container">
        <div className="back">
          {/* <Link
            className="backLink"
            to={`/manageCenter/team/${match.params.id}`}
          >
            <LeftOutlined />
            <span className="back">查看团队学员</span>
          </Link> */}
          <span
            className="backLink"
            onClick={() =>
              history.push({
                pathname: `/manageCenter/team/${match.params.id}/detail`,
                state: { code: match.params.id }
              })
            }
          >
            <LeftOutlined />
            <span className="back">查看团队学员</span>
          </span>
          <Pagination
            className="pagination"
            pages={pages}
            onChange={value => setCurrent(value)}
            pattern={pattern}
          ></Pagination>
        </div>
        <div id="cardList">
          <div className={pattern === "vertical" ? "cardList" : "transverse"}>
            {data.map((card, index) => (
              <div className="card" key={index}>
                <div className="word">
                  {/* {card.secret || (
                    <img src={secretImageUrl + card.secret_image} alt="code" />
                  )} */}
                  {card.key_type === 'word' ? (
                    <div className="secretWord">{card.secret}</div>
                  ) : (
                    <img src={secretImageUrl + card.secret_image} alt="code" />
                  )}
                </div>
                <div>
                  <div className="username">
                    {[card.name.slice(0, 13), card.name.slice(13, 15)].map(
                      (item, index) => {
                        if (index === 1 && item) {
                          return (
                            <>
                              <br />
                              <span key={index}>{item}</span>
                            </>
                          );
                        } else {
                          return <span key={index}>{item}</span>;
                        }
                      }
                    )}
                  </div>
                  <div className="link">
                    <span className="title">登录链接：</span>
                    {/* <span>{`${window.location.origin}/login/${card.team}`</span> */}
                    <span className='content'>https://course.aimaker.space</span>
                  </div>
                  <div className="code">
                    <span className="title">团队代码：</span>
                    <span className='content'>{card.team}</span>
                  </div>
                  <div className="name">
                    <span className="title">团队名称：</span>
                    <span className='content'>{card.team_name}</span>
                  </div>
                </div>
              </div>
            ))}
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </div>
        </div>
        <div id="print">
          <div className="cardList">
            {printData.map((cards, indexs) => (
              <div className='box' key={indexs}>
                <div className='cards'>
                  {
                    cards.map((card, index) => (
                      <div className="card" key={index}>
                        <div className="word">
                          {/* {card.secret || (
                    <img src={secretImageUrl + card.secret_image} alt="code" />
                  )} */}
                          {card.key_type === 'word' ? (
                            <div className="secretWord">{card.secret}</div>
                          ) : (
                            <img src={secretImageUrl + card.secret_image} alt="code" />
                          )}
                        </div>
                        <div className='information'>
                          <div className="username">
                            {[card.name.slice(0, 13), card.name.slice(13, 15)].map(
                              (item, index) => {
                                if (index === 1 && item) {
                                  return (
                                    <>
                                      <br />
                                      <span key={index}>{item}</span>
                                    </>
                                  );
                                } else {
                                  return <span key={index}>{item}</span>;
                                }
                              }
                            )}
                          </div>
                          <div className="link">
                            <span className="title">登录链接：</span>
                            {/* <span>{`${window.location.origin}/login/${card.team}`</span> */}
                            <span className='content'>https://course.aimaker.space</span>

                          </div>
                          <div className="code">
                            <span className="title">团队代码：</span>
                            <span className='content'>{card.team}</span>
                          </div>
                          <div className="name">
                            <span className="title">团队名称：</span>
                            <span className='content'>{card.team_name}</span>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
        <div id='FunctionButton'>
          <div className='switch'>
            <div onClick={() => { setPattern("vertical") }} className={pattern === "vertical" ? "Select left" : "left"}>
              <img alt='' src={"/image/CoursePrintCard_VerticalVersion_icon" + (pattern === "vertical" ? "2" : "") + ".png"}></img>
              <span>竖版</span>
            </div>
            <div onClick={() => { setPattern("transverse") }} className={pattern === "transverse" ? "Select right" : "right"}>
              <img alt='' src={"/image/CoursePrintCard_HorizontalVersion_icon" + (pattern === "transverse" ? "2" : "") + ".png"}></img>
              <span>横版</span>
            </div>
          </div>
          <Button type="primary" size="large" onClick={() => pattern === "transverse" ? printTransverse(teamData) : printVertical(teamData)}>
            打印登录卡
          </Button>
        </div>
      </div>
    </div >
  );
};

export default withRouter(LoginCode);
