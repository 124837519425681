import React, { useEffect, useState, useCallback } from 'react';
// import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { Button, Select } from 'antd';
import FileViewer from 'react-file-viewer';
import { getTeachPlanFiles, updateSort } from '../../../utils/request';
import { getFileType } from '../../../utils';
// import { loadTeachPlan } from '../../../store/actions'
import './style.scss';
const static_file =
  'https://cors-anywhere.herokuapp.com/http://africau.edu/images/default/sample.pdf';

const TeachPlan = () => {
  const [fileList, setFileList] = useState([]);
  const [curFile, setCurFile] = useState(null);

  const fetchFileList = useCallback(() => {
    updateSort('get').then(sortRes => {
      getTeachPlanFiles({ page_size: 9999, plan_sort: sortRes.data.plan_sort }).then(res => {
        if (res.status === 200) {
          let list = filterFileList(res.data.results);
          list = filterFileList([...res.data.results]);
          setFileList(list);
          setCurFile(list[0]);
        }
      });
    })

  }, []);

  const filterFileList = fileList => {
    return fileList.filter(file => file.enable);
  };

  useEffect(() => {
    window.resizeTo(500, 500);
    fetchFileList();
  }, [fetchFileList]);

  const handleChange = id => {
    const file = fileList.filter(file => file.id === id)[0];
    sessionStorage.setItem('curFile', JSON.stringify(file));
    setCurFile(file);
  };

  const getFileUrl = file => {
    /** before the change **/
    // Object.is(process.env.NODE_ENV, 'development')
    //   ? static_file
    //   : file.id === 0
    //   ? file.file_url
    //   : 'https://' + window.location.host + file.file_url;

    return Object.is(process.env.NODE_ENV, 'development')
      ? static_file
      : file.id === 0
      ? file.file_url
      : file.file_url.indexOf('http') > -1
      ? file.file_url
      : 'https://' + window.location.host + file.file_url;
  };
  return (
    <div className="teach-plan">
      <header className="header">
        <div>
          <img src="/image/plan_icon.png" alt="" />
          教学计划
        </div>
      </header>
      <section className="select container">
        <div>
          <div>
            {curFile ? (
              <Select
                className="file_select"
                defaultValue={curFile.file_name}
                onChange={handleChange}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={fileList.map(m => ({
                  value: m.id,
                  label: m.file_name,
                }))}
              >
              </Select>
            ) : (
              <img src="/image/disabled_selected.png" alt="" />
            )}
            {fileList.length ? (
              <span className="planlist-detail">
                当前已启用&nbsp;{fileList.length}&nbsp;篇教学计划
              </span>
            ) : null}
          </div>
          <Button
            style={{ minWidth: '109px' }}
            type="primary"
            className="manage-btn"
          >
            <Link to="/teachplan/manage">
              管&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;理
            </Link>
          </Button>
        </div>
      </section>
      {curFile ? (
        <section className="container planlist-wrapper">
          <div className="planlist">
            <div className="plan-item">
              <React.Fragment>
                <FileViewer
                  fileType={getFileType(curFile.file_url)}
                  filePath={getFileUrl(curFile)}
                  key={curFile.id}
                />
              </React.Fragment>
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
};

export default TeachPlan;
