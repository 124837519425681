import instance from '../request';

export const getFollowPortfolios = (params, username) => {
  if (username) {
    return instance.get(`/api/v1/users/${username}/follow_studios/`, {
      params: { page: 1, page_size: 18 }
    });
  }
  return instance.get('/api/v1/studios/follows_v2/', { params });
};

export const getFollowPortfolioCount = params => {
  return instance.get('/api/v1/studios/follows_count/', { params });
};

export const deleteOwnPortfolioFollow = id => {
  return instance.delete(`/api/v1/studios/${id}/follow/`);
};
