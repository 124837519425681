import React, { useCallback, useState, useRef } from 'react'
import './style.scss'
import { Form, Input, Button, Checkbox, Select, Tooltip } from 'antd'
import {
  getCaptcha,
  registry,
  checkPhone,
  checkUsername,
  checkInvitationCode,
} from './../../utils/request';
import { Link, withRouter } from 'react-router-dom';
import { CaretDownOutlined } from '@ant-design/icons';
import {
  testPassword,
  testPhone,
  testUsername,
  useCutDown,
  saveToken,
} from './../../utils';
import { useDispatch } from 'react-redux';
import { setToken } from './../../store/actions';
import qs from 'query-string';
import { QuestionCircleFilled, CheckCircleFilled } from '@ant-design/icons';
const allSettled = require('promise.allsettled');

const Register = (props) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    agreement: false,
    prefix: '86',
    phone: undefined,
    invitation_code:''
  });
  const [codeHelpIcon, setCodeHelpIcon] = useState(true);
  const [coldDown, coldDownTime, startCutDown, stopCutDown] = useCutDown();
  const formRef = useRef();
  const [registered, setregistered] = useState(false);
  const [isredirect, redirectTime, startRedirectCutDown, stopRedirectCutDown] =
    useCutDown(3);
  const [submitErr, setSubmitErr] = useState({
    username: null,
    password: null,
    invitation_code: null,
    phone: null,
    captcha: null,
  });

  const onFinish = (values) => {
    setFormData(values);

    allSettled([
      checkPhone(values.phone),
      checkUsername(values.username),
      checkInvitationCode({
        invitation_code: values.invitation_code,
        user_name: '',
      }),
    ])
      .then((reses) => {
        reses = reses
          .filter((res) => res.status === 'rejected')
          .map((res) => res.reason.response.data);
        if (reses.length) {
          return Promise.reject(reses)
        }
        return registry({ is_consent: true, ...values })
      })
      .then((res) => {
        console.log(res, isredirect);
        stopCutDown();

        if (qs.parse(props.location.search).redirect) {
          saveToken(res.data.token)

          window.location.href = decodeURIComponent(
            qs.parse(props.location.search).redirect,
          )
          return
        }
        setregistered(true)

        startRedirectCutDown()

        setTimeout(() => {
          if (res.data.token) {
            dispatch(setToken(res.data.token))
            saveToken(res.data.token)
          }
          stopRedirectCutDown()
          props.history.push('/')
        }, 3000)
      })
      .catch((e) => {
        if (Array.isArray(e)) {
          let error = e.reduce((acc, item) => ({ ...acc, ...item }), {});
          Object.keys(error).forEach((key) => {
            error[key] = {
              help: error[key],
              validateStatus: 'error',
            };
          });
          setSubmitErr(error);
        } else {
          console.log('Error', e.response.data);
          if (typeof e.response.data === 'string') return;
          Object.keys(e.response.data).forEach((key) => {
            e.response.data[key] = {
              help: e.response.data[key],
              validateStatus: 'error',
            };
          });
          setSubmitErr(e.response.data);
        }
      })
  }

  const checkUsernameHandler = useCallback(() => {
    setSubmitErr((s) => ({ ...s, username: null }));
    if (!formData.username) return;
    checkUsername(formData.username)
      .then(() => {
        setSubmitErr({
          ...submitErr,
          username: null,
        });
      })
      .catch((e) => {
        setSubmitErr({
          ...submitErr,
          username: {
            help: e.response.data.username,
            validateStatus: 'error',
          },
        });
      });
  }, [formData.username, submitErr]);

  const onValuesChange = (changedValues, allValues) => {
    if (allValues.phone !== formData.phone) {
      setSubmitErr({
        ...submitErr,
        phone: null,
      });
    }
    if (allValues.username !== formData.username) {
      setSubmitErr({
        ...submitErr,
        username: null,
      });
    }
    setFormData(allValues)
  }

  const handlerGetCaptcha = useCallback(() => {
    if (!formData.phone) return
    checkPhone(formData.phone)
      .then((res) => {
        console.log(res);
        setSubmitErr({ ...submitErr, phone: null });
        return getCaptcha({ phone: formData.phone });
      })
      .then((res) => {
        console.log(res.data);
        startCutDown();
      })
      .catch((e) => {
        console.log(e.response.data);
        if (typeof e.response.data === 'string') return;
        // Object.keys(e.response.data).forEach(key => {
        //   e.response.data[key] = {
        //     help: e.response.data[key],
        //     validateStatus: 'error'
        //   };
        // });
        // setSubmitErr(e.response.data);
        setSubmitErr({
          ...submitErr,
          phone: {
            help: e.response.data.phone,
            validateStatus: 'error',
          },
        });
      });
  }, [formData.phone, startCutDown, submitErr]);

  const verificationCodeOnBlur = useCallback(() => {
    setSubmitErr({ ...submitErr, invitation_code: null })
    if (!formData.invitation_code) return
    formRef.current.setFieldsValue({
      invitation_code: formData.invitation_code.replace(/\s/gi, '')
    });
    checkInvitationCode({
      invitation_code: formData.invitation_code.replace(/\s/gi, ''),
      user_name: '',
    })
      .then((res) => {
        setCodeHelpIcon(false)
        setSubmitErr({ ...submitErr, invitation_code: null })
      })
      .catch((e) => {
        setSubmitErr({
          ...submitErr,
          invitation_code: {
            help: e.response.data.invitation_code,
            validateStatus: 'error',
          },
        });
        setCodeHelpIcon(true);
      });
  }, [formData.invitation_code, submitErr]);

  return !registered ? (
    <div id="register" className="container">
      <div className="title">注册账号</div>
      <div className="form">
        <Form
          ref={formRef}
          name="normal_login"
          className="login-form"
          initialValues={formData}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            name="username"
            {...submitErr.username}
            rules={[
              { required: true, message: '请输入用户名' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || (value && testUsername(value))) {
                    return Promise.resolve()
                  }
                  return Promise.reject('用户名仅支持数字、英文字母、下划线');
                },
              }),
            ]}
          >
            <Input
              size="large"
              maxLength="20"
              placeholder="用户名"
              onChange={(val) => console.log('change')}
              onBlur={checkUsernameHandler}
            />
          </Form.Item>
          <Form.Item
            name="password"
            {...submitErr.password}
            rules={[
              { required: true, message: '请输入密码' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || (value && testPassword(value))) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    '请输入 6-16 位的密码，仅支持数字、英文、下划线'
                  );
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              placeholder="6-16 位密码，区分大小写"
            />
          </Form.Item>

          <Form.Item
            name="repectpassword"
            rules={[
              {
                required: true,
                message: '请再次输入密码',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('前后密码不一致');
                },
              }),
            ]}
          >
            <Input.Password size="large" placeholder="确认密码" />
          </Form.Item>
          <Form.Item
            name="invitation_code"
            rules={[{ required: true, message: '请输入邀请码' }]}
            {...submitErr.invitation_code}
          >
            <Input
              placeholder="邀请码（必填）"
              maxLength="20"
              size="large"
              onBlur={verificationCodeOnBlur}
              suffix={
                <div>
                  {codeHelpIcon || !formData.invitation_code ? (
                    <Tooltip title="邀请码仅限英荔教育授权教师使用，如需获取，请致电 400-931-8118">
                      <QuestionCircleFilled style={{ color: '#e0e0e0' }} />
                    </Tooltip>
                  ) : (
                    <CheckCircleFilled style={{ color: '#52c41a' }} />
                  )}
                </div>
              }
            />
          </Form.Item>
          <Form.Item
            name="phone"
            {...submitErr.phone}
            rules={[
              { required: true, message: '请输入手机号' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || (value && testPhone(value))) {
                    return Promise.resolve()
                  }
                  return Promise.reject('请输入正确的手机号');
                },
              }),
            ]}
          >
            <Input
              size="large"
              addonBefore={
                <Form.Item name="prefix" noStyle>
                  <Select
                    defaultValue="86"
                    style={{ width: 74 }}
                    suffixIcon={<CaretDownOutlined />}
                  >
                    <Select.Option value="86">+86</Select.Option>
                  </Select>
                </Form.Item>
              }
              placeholder="手机号"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item>
            <div className="captcha">
              <Form.Item
                {...submitErr.captcha}
                name="captcha"
                style={{ marginBottom: '0' }}
                rules={[{ required: true, message: '请输入验证码' }]}
              >
                <Input size="large" placeholder="验证码" />
              </Form.Item>
            </div>
            <Button
              size="large"
              type="primary"
              disabled={coldDown || !testPhone(formData.phone)}
              className="captcha-btn"
              onClick={handlerGetCaptcha}
            >
              {coldDown ? `${coldDownTime}s 后可重新发送` : '获取验证码 '}
            </Button>
          </Form.Item>
          <Form.Item
            name="agreement"
            className="agreement"
            valuePropName="checked"
          >
            <Checkbox>
              勾选即表示同意
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.aimaker.space/%E8%8B%B1%E8%8D%94%E6%95%99%E5%AD%A6%E4%BA%91%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE/"
              >
                《用户协议》
              </a>
              和
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.aimaker.space/%E8%8B%B1%E8%8D%94%E6%95%99%E5%AD%A6%E4%BA%91%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96/"
              >
                《隐私政策》
              </a>
            </Checkbox>
          </Form.Item>

          <Button
            size="large"
            type="primary"
            disabled={!formData.agreement}
            htmlType="submit"
            block
            className="login-form-button"
          >
            注册
          </Button>

          <Form.Item>
            <Link to="/login">
              <Button size="large" className="already" block>
                使用已有账户登录
              </Button>
            </Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  ) : (
    <div id="registered">
      <div className="successImg">
        <img src="/image/success.png" width="60" height="60" alt="success" />
      </div>
      <div className="title">用户：{formData.username} 注册成功</div>
      <div className="desc">
        {redirectTime}s 后自动跳转至首页，或
        <Link to="/" onClick={stopRedirectCutDown}>
          点击此处
        </Link>
        直接跳转
      </div>
    </div>
  );
};

export default withRouter(Register)
