import React, { useState, useEffect } from 'react'
import './style.scss'
import ReactMarkdown from 'react-markdown/with-html'

const Privacy = () => {
    const [ markdown, setMarkdown ] = useState()

    useEffect(() => {
        fetch('/docs/privacypolicy.md')
        .then((response) => response.text())
        .then((text) => {
            setMarkdown(text)
        })
        .catch(e => {
            console.log(e)
        })
    }, [])

    return (
        <div id="privacy" className="container markdown">
            <ReactMarkdown escapeHtml={false} linkTarget="_blank" source={markdown} />
        </div>
    )
}

export default Privacy
