import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
  useRef
} from 'react';
import './style.scss';
import ReactMarkdown from 'react-markdown/with-html';
// import { Modal } from 'antd'
// import { CloseCircleOutlined } from '@ant-design/icons'
import matter from 'gray-matter';
// import Carousel, { Modal, ModalGateway } from 'react-images';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Collapse, BackTop, message, Input } from 'antd';
import { SectionToChinese } from '../../utils';
import {
  // setVisitHistory,
  getCoursePreview
} from '../../utils/request';
// import { useGetHotCourse } from '../course/index';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight as codeStyle } from 'react-syntax-highlighter/dist/cjs/styles/prism';
// import CourseComment from '../../../components/courseComment';
import Viewer from 'viewerjs';
import { DownloadOutlined } from '@ant-design/icons';

const DPlayer = window.DPlayer;

export const CodeBlock = ({ language, value }) =>
  language === 'mermaid' ? (
    <div className="mermaid">{value}</div>
  ) : (
    <SyntaxHighlighter language={language} style={codeStyle}>
      {value}
    </SyntaxHighlighter>
  );

const CoursePreview = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  // const { team_data } = useSelector((state) => state.userInfo);
  const { team_data } = useSelector(state => ({
    is_submit_teaching_plan: false
  }));
  // const [courses] = useGetCourse()
  // const [hotCourses] = useGetHotCourse();
  const [markdown, setMarkdown] = useState();
  // const [showImg, setShowImg] = useState(false)
  // const [imgSrc, setImgSrc] = useState('')
  const [defaultActiveKey, setDefaultActiveKey] = useState(0);
  const [warning, setWarning] = useState(false);
  // const [ topCourse, SetTopCourse ] = useState([])
  const [sectionCourse, setSectionCourse] = useState();
  const [aids, setAids] = useState([]);
  const [aids_2, setAids_2] = useState([]);
  const [studentVal, setStudentVal] = useState('');
  const [groupVal, setGroupVal] = useState('');

  const playerRef = useRef();
  const videoRef = useRef();
  const markdownRef = useRef();
  const warningRef = useRef(false);

  /* 热门课程暂未接入
  useEffect(() => {
      getTopCourse()
      .then(res => {
          console.log('topCourse', res.data.results)
          SetTopCourse(res.data.results)
      })
      .catch(e => {
          console.log(e)
      })
  }, [])
  */
  // 当前页面只能有一个视频在播放
  useEffect(() => {
    var vidios = document.getElementsByTagName('video');
    function pauseAll() {
      var self = this;
      [].forEach.call(vidios, function (i) {
        // 将 vidios 中其他的 vidio 全部暂停
        if (i !== self) {
          i.pause();
        }
      });
    }
    // 给 play 事件绑定暂停函数
    [].forEach.call(vidios, function (i) {
      i.addEventListener('play', pauseAll.bind(i));
    });
  });
  useEffect(() => {
    let currentCourse;
    let sectionCourse;
    localStorage.setItem('urlSearch', location.search);

    getCoursePreview(match.params.id, location.search)
      .then(res => {
        currentCourse = res.data;
        let courseContent;
        if (res.data.title) {
          // if (res.data.is_series && res.data.units.length) {
          //   //  多章节课程
          //   if (res.data.units[0].units.length) {
          //     res.data.units.forEach((section) => {
          //       if (courseContent) return;
          //       let target = section.units.find(
          //         (item) => item.id === Number(match.params.unit_id)
          //       );
          //       sectionCourse = target;
          //       courseContent = target.content;
          //       setSectionCourse(target);
          //     });
          //   } else {
          //     let target = res.data.units.find(
          //       (item) => item.id === Number(match.params.unit_id)
          //     );
          //     sectionCourse = target;
          //     courseContent = target.content;
          //     setSectionCourse(target);
          //   }
          // } else {
          //   courseContent = res.data.content;
          //   sectionCourse = res.data;
          //   setSectionCourse(res.data);
          // }

          if (!res.data.is_series) {
            courseContent = res.data.content;
            sectionCourse = res.data;
            setSectionCourse(res.data);
          } else {
            //  多章节课程
            if (res.data.units.length && res.data.units[0].units.length) {
              res.data.units.forEach(section => {
                if (courseContent) return;
                let target = section.units.find(
                  item => item.id === Number(match.params.unit_id)
                );
                sectionCourse = target;
                courseContent = target.content;
                setSectionCourse(target);
              });
            } else {
              let target = res.data.units.find(
                item => item.id === Number(match.params.unit_id)
              );
              sectionCourse = target;
              courseContent = target ? target.content : '';
              setSectionCourse(target);
            }
          }
          // console.log('sectionCourse====', sectionCourse);

          setSectionCourse(sectionCourse);
          return fetch(courseContent);
        } else {
          setMarkdown(res);

          return Promise.resolve('不请求文档');
        }
      })
      .then(response => {
        if (response !== '不请求文档') {
          return response.text();
        }
      })
      .then(text => {
        if (text) {
          let mattered = matter(text);

          mattered.data = {
            ...mattered.data,
            ...currentCourse,
            sectionid: Number(match.params.unit_id)
          };
          // 合并unit中的预览视频
          sectionCourse &&
            sectionCourse.previewvideo &&
            (mattered.data.previewvideo = sectionCourse.previewvideo);
          // if (!team_data?.is_submit_teaching_plan) {
          // let index = mattered.content.indexOf('教师部分');
          // if (index !== -1) {
          //   // mattered.content = mattered.content.substring(0, index + 5);
          // }
          // }
          setMarkdown(mattered);
          if (mattered.data.aids.length > 0) {
            setStudentVal(mattered.data.student_numbers || 1);
            setGroupVal(mattered.data.group_number || 1);
            setAids(mattered.data.aids);
            setAids_2(mattered.data.aids);
            handlerCalculate(
              mattered.data.student_numbers || 1,
              mattered.data.group_number || 1,
              mattered.data.aids
            );
          }
          if (
            mattered.data.is_series &&
            mattered.data.units.length &&
            mattered.data.units[0].units.length
          ) {
            setDefaultActiveKey(
              mattered.data.units
                .reduce((prev, curr, index) => {
                  return [...prev, ...curr.units];
                }, [])
                .findIndex(section => section.id === mattered.data.sectionid)
            );
          }
          // if (mattered.data.id) {
          // return setVisitHistory({
          //   course: currentCourse.id,
          //   ...(mattered.data.sectionid
          //     ? { unit: mattered.data.sectionid }
          //     : null),
          //   resourcetype: 'CourseHistory',
          // });
          // }
        }

        return Promise.resolve('未发现course id');
      })
      .then(res => {
        console.log(res);
      })
      .catch(e => {
        console.error(e);
      });
  }, [match.params.id, match.params.unit_id, team_data, location]);

  const courseSelecteHandler = useCallback(
    (course, ishotCourse) => {
      if (ishotCourse) {
        history.push(
          `/coursePreview/${course.id}/${course.unit_id || 0
          }/${localStorage.getItem('urlSearch')}`
        );
      } else {
        history.push(
          `/coursePreview/${match.params.id}/${course.id
          }/${localStorage.getItem('urlSearch')}`
        );
      }
    },
    [history, match.params.id]
  );

  useLayoutEffect(() => {
    if (
      sectionCourse?.ppt_furl &&
      sectionCourse?.ppt_furl.includes('http://')
    ) {
      setTimeout(() => {
        if (!playerRef.current) {
          playerRef.current = new DPlayer({
            container: videoRef.current,
            video: {
              url: sectionCourse.ppt_furl
            }
          });
        } else {
          // playerRef.current.switchVideo({
          //   url: sectionCourse.ppt_furl,
          // });

          playerRef.current.destroy();
          playerRef.current = new DPlayer({
            container: videoRef.current,
            video: {
              url: sectionCourse.ppt_furl
            }
          });
        }
      }, 500);
    }

    // if (markdown?.data?.previewvideo) {
    //   if (!playerRef.current) {
    //     playerRef.current = new DPlayer({
    //       container: videoRef.current,
    //       video: {
    //         url: markdown.data.previewvideo,
    //       },
    //     });
    //   } else {
    //     playerRef.current.switchVideo({
    //       url: markdown.data.previewvideo,
    //     });
    //   }
    // }
  }, [sectionCourse]);

  const findTechTitle = () => {
    const arr = document.querySelectorAll('h1');
    for (let h1 of arr) {
      if (Object.is(h1.innerText, '教师部分')) return h1;
    }
    return null;
  };
  /* bug： 部分课程的 markdownRef?.current为 undefined */
  // useEffect(() => {
  //   if (markdownRef.current) {
  //     markdownRef.current.addEventListener('click', e => {
  //       const markdownCon = document.querySelector('.markdown');
  //       // if (e.target.nodeName === 'IMG') {
  //       new Viewer(markdownCon);
  //       // }
  //     });
  //   }
  // }, [markdownRef?.current]);

  useEffect(() => {
    if (markdown && markdown.content) {
      if (markdownRef?.current){
        markdownRef.current.addEventListener(
          'click',
          e => {
            // if (e.target.nodeName === 'IMG') {
            const markdownCon = document.querySelector('.markdown');
            new Viewer(markdownCon, {
              url: 'src'
            });
            // }
          },
          true
        );
      }
    }
  }, [markdown]);

  useEffect(() => {
    let timer = null;
    let onClick = e => {
      // let getToast = simpleToast()
      // console.log('simple')
      // return (e) => {
      //     const techTitle = findTechTitle()
      //     if (Object.is(e.target, techTitle)) getToast({ type: 'warning', text: '当前登录的账户没有查看「教师部分」的权限' })
      //     // getToast = null
      // }
      clearTimeout(timer);
      const techTitle = findTechTitle();
      if (Object.is(e.target, techTitle) && !warningRef.current) {
        message.warning('当前登录的账户没有查看「教师部分」的权限', 0);
        setWarning(true);
      }
      timer = setTimeout(() => {
        message.destroy();
        clearTimeout(timer);
        setWarning(false);
      }, 3000);
    };
    if (!team_data?.is_submit_teaching_plan) {
      window.addEventListener('click', onClick);
    }
    return () => {
      if (!team_data?.is_submit_teaching_plan) {
        window.removeEventListener('click', onClick);
      }
    };
  }, [team_data, history]);

  // useEffect(() => {
  //     return () => {
  //         setWarning(false)
  //     }
  // }, [match.params.id])
  useEffect(() => {
    warningRef.current = warning;
  }, [warning]);

  // 下载
  const downLoad = furl => {
    const a = document.createElement('a');
    a.href = furl;
    a.setAttribute('download', '');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // 改变每组人数时 || 改变学生总人数时
  const handlerChange = (e, input_type, stuNum, groupNum) => {
    const { value } = e.target;
    const reg = /^[1-9]\d*$/;

    if ((!isNaN(value) && reg.test(value)) || value === '') {
      if (input_type === 2) {
        // 改变每组人数时，输入规则：大于等于1并且小于等于学生总人数
        const isTrue = Number(value) <= stuNum;
        const isMax = Number(value) > stuNum;
        if (isTrue) {
          setGroupVal(value);
          handlerCalculate(stuNum, value, aids_2);
        } else if (isMax) {
          setGroupVal(stuNum);
          handlerCalculate(stuNum, stuNum, aids_2);
        }
      } else {
        // 改变学生总人数时
        setStudentVal(value);
        handlerCalculate(value, groupNum, aids_2);
      }
    }
  };

  // 计算
  const handlerCalculate = (stu, group, aids_2_) => {
    /**
     根据教具的计算方式如下：
     * 0：按分组和学生总人数计算：=（学生总人数/每组人数）向上取整 * 数量
     * 1：只按学生总人数计算：= 学生总人数 * 数量
     * 2：不按分组不按学生总人数计算：= 数量
     */
    let real_aids = JSON.parse(JSON.stringify(aids_2_));

    let new_aids = real_aids.map(item => {
      if (item.number > 0) {
        if (item.calculate_way === 0) {
          // 按分组和学生总人数计算：=（学生总人数/每组人数）向上取整 * 数量
          let new_number = Math.ceil(stu / group) * item.number;
          item.number = isFinite(new_number) ? new_number : 0;
        } else if (item.calculate_way === 1) {
          // 只按学生总人数计算：= 学生总人数 * 数量
          let new_number = stu * item.number;
          item.number = new_number;
        }
      }

      return item;
    });

    setAids([...new_aids]);
  };

  return (
    <div id="courseItem2">
      <BackTop />
      <div className="content">
        {markdown?.data?.title ? (
          <>
            <div className="row header_row">
              <div className="container" style={{ padding: '30px 0' }}>
                <div className="project2">
                  <div className="img">
                    <img
                      src={markdown?.data?.cover || '/image/course.png'}
                      alt={markdown.data.title}
                    />
                  </div>
                  <div className="title">{markdown?.data?.title}</div>
                  <div className="description">
                    {markdown?.data?.subtitle || markdown?.data?.description}
                  </div>
                </div>
              </div>
            </div>
            <div className="markdownWrapper">
              <div className="container">
                <div className="row">
                  <div className="left col col--8">
                    {/* new=============== */}
                    {sectionCourse?.ppt_furl &&
                      !sectionCourse?.ppt_furl.includes('http://') ? (
                      <div className="pptBox">
                        <div style={{ position: 'relative' }}>
                          <div className="pptMark">
                            <a
                              href={sectionCourse.ppt_furl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="/image/videoplay.png" alt="" />
                            </a>
                          </div>
                          <div className="row ppt_contain">
                            <i></i>
                            <iframe
                              className="pptIframe"
                              title="pptIframe"
                              // allowfullscreen="true"
                              style={{
                                border: 0,
                                height: '100%',
                                width: '100%',
                                borderRadius: '7px'
                              }}
                              src={sectionCourse.ppt_furl}
                            ></iframe>
                            <i></i>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* ----------------------- */}

                    {/* {markdown?.data?.previewvideo ? (
                      <div className="previewvideo" ref={videoRef}></div>
                    ) : null} */}
                    {sectionCourse?.ppt_furl &&
                      sectionCourse?.ppt_furl.includes('http://') ? (
                      <div className="previewvideo" ref={videoRef}></div>
                    ) : null}

                    {/* ------------ */}
                    {(sectionCourse?.download_url &&
                      !sectionCourse?.download_url.includes('http://')) ||
                      markdown?.data?.subtitle ||
                      markdown?.data?.description ||
                      aids.length > 0 ? (
                      <div className="readycourse">
                        <div className="readytitle">
                          <span className="title">课程准备</span>
                          {sectionCourse?.is_down &&
                            sectionCourse?.download_url &&
                            !sectionCourse?.download_url.includes('http://') ? (
                            <span
                              onClick={() =>
                                downLoad(sectionCourse.download_url)
                              }
                              className="download"
                            >
                              {/* <img src="/image/download.png" alt="" /> */}
                              <DownloadOutlined
                                style={{ marginRight: '8px' }}
                              />
                              下载 PPT
                            </span>
                          ) : null}
                        </div>
                        {/* <p>
                          {markdown?.data?.subtitle ||
                            markdown?.data?.description}
                        </p> */}
                        <p>
                          {markdown?.data?.description ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: markdown.data.description.replace(
                                  /\r\n/g,
                                  '<br>'
                                )
                              }}
                            ></span>
                          ) : (
                            ''
                          )}
                        </p>
                        {(!markdown?.data?.is_series ||
                          (markdown?.data?.is_series &&
                            markdown?.data?.sectionid ===
                            markdown?.data?.unit_id)) &&
                          aids.length ? (
                          <div className="readytable">
                            <div>
                              <p>
                                <span style={{ color: '#333' }}>
                                  学生总人数：
                                </span>
                                <Input
                                  onChange={e =>
                                    handlerChange(e, 1, studentVal, groupVal)
                                  }
                                  value={studentVal}
                                />
                              </p>
                              <p>
                                <span style={{ color: '#333' }}>
                                  每组人数：
                                </span>
                                <Input
                                  onChange={e =>
                                    handlerChange(e, 2, studentVal, groupVal)
                                  }
                                  value={groupVal}
                                />
                              </p>
                            </div>
                            <table border="1">
                              {aids.map(tool => (
                                <tr key={tool.id}>
                                  <td>
                                    <p className="toolname">
                                      {tool.url ? (
                                        <a
                                          href={tool.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {tool.name}
                                        </a>
                                      ) : (
                                        tool.name
                                      )}
                                    </p>
                                    <p>{tool.remark}</p>
                                  </td>
                                  <td>
                                    {tool.number && tool.unit
                                      ? `${tool.number} ${tool.unit}`
                                      : '-'}
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    {/* ------------- */}

                    {markdown?.content &&
                      !markdown.content.toLowerCase().includes('<!doctype') ? (
                      <div ref={markdownRef} className="markdown">
                        <ReactMarkdown
                          escapeHtml={false}
                          linkTarget="_blank"
                          renderers={{ code: CodeBlock }}
                          source={markdown.content}
                        />
                      </div>
                    ) : null}
                    {/* <CourseComment courseid={match.params.id}></CourseComment> */}
                  </div>

                  <div className="col col--4 sidebar">
                    <div className="overview">
                      <div className="title1">概述</div>
                      <div className="overviewmain">
                        {markdown?.data?.['title'] ? (
                          <p>
                            <span className="title">课程</span>
                            <span className="desc">
                              {markdown.data['title'].split('\n').join('、')}
                            </span>
                          </p>
                        ) : null}
                        {markdown?.data?.['new_course_type'].length ? (
                          <p>
                            <span className="title">课程类型</span>
                            <span className="desc">
                              {typeof markdown.data['new_course_type'] === "string" ? markdown.data['new_course_type'] : markdown.data['new_course_type'].join('、')}
                            </span>
                          </p>
                        ) : null}
                        {markdown?.data?.['new_suit_tags'] ? (
                          <p>
                            <span className="title">适合阶段</span>
                            <span className="desc">
                              {typeof markdown.data['new_suit_tags'] === "string" ? markdown.data['new_suit_tags'] : markdown.data['new_suit_tags'].join('、')}
                            </span>
                          </p>
                        ) : null}
                        {/* {markdown?.data?.['course_modules'] &&
                          markdown.data.course_modules !== '无' ? (
                          <p>
                            <span className="title">课程模块</span>
                            <span className="desc">
                              {markdown.data['course_modules']
                                .split('\n')
                                .join('、 ')}
                            </span>
                          </p>
                        ) : null} */}
                        {markdown?.data?.['new_teaching_aid'] ? (
                          <p>
                            <span className="title">课程教具</span>
                            <span className="desc">
                              {typeof markdown.data['new_teaching_aid'] === "string" ? markdown.data['new_teaching_aid'] : markdown.data['new_teaching_aid'].join('、')}
                            </span>
                          </p>
                        ) : null}
                        {markdown?.data?.['new_teaching_aid'] ? (
                          <p>
                            <span className="title">编程平台</span>
                            <span className="desc">
                              {typeof markdown.data['new_program_platform'] === "string" ? markdown.data['new_program_platform'] : markdown.data['new_program_platform'].join('、')}
                            </span>
                          </p>
                        ) : null}
                      </div>
                    </div>

                    {
                      markdown?.data?.is_series &&
                        markdown.data.units.length ? (
                        <div className="nav more">
                          <div className="title">
                            <div className="titleName">课程目录</div>
                            <div className="unit">
                              第{' '}
                              {markdown.data.units[0].units.length === 0
                                ? markdown.data.units.findIndex(
                                  course =>
                                    course.id === markdown.data.sectionid
                                ) + 1
                                : markdown.data.units.findIndex(section =>
                                  section.units.find(
                                    course =>
                                      course.id === markdown.data.sectionid
                                  )
                                ) + 1}{' '}
                              单元 / 共 {markdown.data.units.length} 个单元
                            </div>
                          </div>
                          <p className="solid"></p>
                          {markdown.data.units[0].units.length > 0 ? (
                            <Collapse
                              className="courses sections"
                              accordion
                              bordered={false}
                              expandIconPosition="right"
                              activeKey={[defaultActiveKey]}
                              onChange={val => setDefaultActiveKey(val)}
                            >
                              {markdown.data.units.map((course, index) => (
                                <Collapse.Panel
                                  header={
                                    <>
                                      <span className="section">{`第${SectionToChinese(
                                        index + 1
                                      )}章`}</span>
                                      <span className="sectionName">
                                        {course.title}
                                      </span>
                                    </>
                                  }
                                  key={index}
                                >
                                  {course.units.map((courseItem, index) => (
                                    <p
                                      key={index}
                                      className={`sectionItem ${courseItem.id ===
                                        markdown.data.sectionid
                                        ? 'active'
                                        : ''
                                        }`}
                                      onClick={() =>
                                        courseSelecteHandler(courseItem)
                                      }
                                    >
                                      <span className="section">
                                        {index + 1}
                                      </span>
                                      <span className="sectionName">
                                        {courseItem.title}
                                      </span>
                                    </p>
                                  ))}
                                </Collapse.Panel>
                              ))}
                            </Collapse>
                          ) : (
                            <div className="courses">
                              {markdown.data.units.map((courseItem, index) => (
                                <p
                                  key={index}
                                  className={`sectionItem ${courseItem.id === markdown.data.sectionid
                                    ? 'active'
                                    : ''
                                    }`}
                                  onClick={() =>
                                    courseSelecteHandler(courseItem)
                                  }
                                >
                                  <span className="section">{index + 1}</span>
                                  <span className="sectionName">
                                    {courseItem.title}
                                  </span>
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      ) : null
                      // <>
                      //   {hotCourses.length > 0 ? (
                      //     <div className="nav">
                      //       <div className="title">
                      //         <div className="titleName">推荐课程</div>
                      //       </div>
                      //       <p className="solid"></p>
                      //       <div className="courses hot">
                      //         {hotCourses.slice(0, 6).map((course, index) => (
                      //           <div
                      //             key={index}
                      //             onClick={() => courseSelecteHandler(course, true)}
                      //           >
                      //             <Link
                      //               className="course"
                      //               to={
                      //                 '/course/' +
                      //                 course.id +
                      //                 '/' +
                      //                 (course.unit_id || 0)
                      //               }
                      //               key={course.id}
                      //             >
                      //               <img
                      //                 src={course.cover || '/image/course.png'}
                      //                 alt={course.name}
                      //               />
                      //               <div className="name">{course.title}</div>
                      //             </Link>
                      //           </div>
                      //         ))}
                      //       </div>
                      //     </div>
                      //   ) : null}
                      // </>
                    }

                    {markdown?.data?.resources.length > 0 && (
                      <div className="resources">
                        <div className="title1">
                          课程资源
                          <p></p>
                        </div>
                        <div className="resmain">
                          {markdown.data.resources.map(source => (
                            <div
                              key={source.id}
                              onClick={() =>
                                source.content && downLoad(source.content)
                              }
                            >
                              <p>{source.title}</p>
                              {/* <img src="/image/download.png" alt="" /> */}
                              <DownloadOutlined />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : markdown?.data?.code && markdown?.data?.code === 1000052 ? (
          <div className="container timeoutMsg">{markdown.data.msg}</div>
        ) : null}
      </div>
      {/* <Modal
                className="previewImgModal"
                closable={false}
                visible={showImg}
                width={1200}
                footer={null}
                centered={true}
                getContainer={false}
            >
                <CloseCircleOutlined className="errorIcon" onClick={() => setShowImg(false)} />
                <img style={{ width: '100%' }} src={imgSrc} alt="" />
            </Modal> */}
      {/* <ModalGateway> */}
      {/* {showImg ?
                <img id="showImg" style={{ width: '100%' }} src={imgSrc} alt="" />
                // <Modal onClose={() => setShowImg(false)}>
                //     <Carousel views={[{ source: imgSrc }]} />
                // </Modal>
                : null} */}
      {/* </ModalGateway> */}
    </div>
  );
};

export default CoursePreview;
