import React, { useState, useRef, useCallback, useEffect } from 'react';
import QueueAnim from 'rc-queue-anim';
import './style.scss';
import {
  Link
  /*useParams, useLocation */
} from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Menu,
  Select,
  Modal,
  message
} from 'antd';
import ReactMarkdown from 'react-markdown/with-html';
// import LoginWithCode from '../../components/longinWithCode';
import {
  CaretDownOutlined,
  ExclamationCircleFilled,
  CloseCircleFilled
} from '@ant-design/icons';
import { testPhone, useCutDown } from '../../utils';
import { getLoginCaptcha } from '../../utils/request';
import { useDispatch } from 'react-redux';
import { loginAndSetToken } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import qs from 'query-string';

import { getUserInfo } from './../../utils/request';

// import qs from 'query-string';

const useAuthList = function () {
  // Get authorize type
  // 'binding' | 'default' | ''
  // const { type = '' } = qs.parse(useLocation().search);

  const isBind = window.location.pathname.includes('authorize');
  // [label,disbaled,checked,show]
  const [list, setList] = useState(
    [
      {
        value: '全选',
        disabled: !isBind,
        checked: !isBind,
        show: true
      },
      {
        value: '你的用户名、认证状态',
        // disabled: true,
        disabled: !isBind,
        checked: true,
        show: !isBind
      },
      {
        value: '你的用户名、头像、认证状态',
        disabled: true,
        checked: true,
        show: isBind
      },
      // {
      //   value: '认证状态',
      //   disabled: true,
      //   checked: true,
      //   show: true
      // },
      {
        value: '手机号',
        disabled: !isBind,
        checked: !isBind,
        show: isBind
      }
    ].filter(item => item.show)
  );

  const handleCheck = useCallback(
    (state, index) => {
      // console.log(index, state);
      let newList = [...list];

      if (index === 0) {
        newList = newList.map(item => {
          item.disabled || (item.checked = state);
          return item;
        });
      } else {
        newList[index].checked = state;

        newList[0].checked =
          newList.slice(1).filter(item => item.checked).length ===
          newList.slice(1).length;
      }

      setList(newList);
    },
    [list]
  );

  const getCheckeds = useCallback(() => {
    return list.filter(item => item.checked);
  }, [list]);

  return { list, handleCheck, getCheckeds };
};

const Authorize = ({ history, location, ...props }) => {
  const isDev = window.location.host.indexOf('course.aimaker.space') === -1;
  const codelab = isDev
    ? 'https://create.dev.codelab.eliteu.xyz'
    : 'https://create.codelab.club';

  const codelabOauth = isDev
    ? 'https://api.dev.codelab.eliteu.xyz'
    : 'https://account.codelab.club';
  const isBind = window.location.pathname.includes('authorize');
  const [markdown, setMarkdown] = useState();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState('account');
  const prevCurrent = useRef('account');

  const [formData, setFormData] = useState({
    agreement: false,
    prefix: '86',
    phone: undefined
  });

  const { list, handleCheck, getCheckeds } = useAuthList();

  const [coldDown, coldDownTime, startCutDown, stopCutDown] = useCutDown();
  const [showCodeFAQ, setshowCodeFAQ] = useState(false);
  const [submitErr, setSubmitErr] = useState({
    username: null,
    password: null,
    phone: null,
    captcha: null
  }); // eslint-disable-line

  const handleChangeVisible = useCallback((bol = false) => {
    setVisible(bol);
  }, []);

  const getCaptcha = useCallback(() => {
    getLoginCaptcha(formData.phone)
      .then(res => {
        console.log(res.data);
        setSubmitErr({});
        startCutDown();
      })
      .catch(e => {
        console.log(e);
        if (e.response.data && typeof e.response.data === 'object') {
          Object.keys(e.response.data).forEach(key => {
            e.response.data[key] = {
              help:
                {
                  验证码不正确: '请输入正确的验证码',
                  该手机号未注册: '该账户不存在'
                }[e.response.data[key]] || e.response.data[key],
              validateStatus: 'error'
            };
          });
          setSubmitErr(e.response.data);
        }
      });
  }, [formData, startCutDown]);

  const onValuesChange = (changedValues, allValues) => {
    setFormData(allValues);
  };

  const onFinish = values => {
    let login_type;
    switch (current) {
      case 'account':
        login_type = 'password';
        break;
      case 'captcha':
        login_type = 'captcha';
        break;
      default:
        break;
    }
    let loginConfig = {
      login_type,
      ...values
    };

    console.log(loginConfig, 'loginConfig');
    const agreeAuthorizePhone =
      getCheckeds().findIndex(i => i?.value === '手机号') === -1;

    new Promise(resolve => {
      if (agreeAuthorizePhone && isBind) {
        Modal.confirm({
          className: 'authorize-modal',
          title: '是否授权手机号？',
          icon: <ExclamationCircleFilled />,
          content:
            '根据国家互联网相关法律法规，登录任何在线社区都需要进行实名认证，是否允许 Codelab 使用您英荔账户所绑定的手机号完成认证，以简化注册流程？',
          okText: '授权并登录',
          cancelText: '暂不授权',
          onOk: () => resolve(true),
          onCancel: () => resolve(false)
        });
      } else {
        resolve(true);
      }
    }).then(state => {
      // window.localStorage.setItem('oauth-phone', +state);

      dispatch(loginAndSetToken(loginConfig))
        .then(res => {
          stopCutDown();
          if (isBind) {
            getUserInfo('me').then(({ data }) => {
              const { phone } = data;

              // 保存手机授权状态
              axios
                .post(`${codelabOauth}/api/v1/set_phone_status/`, {
                  status: +state,
                  phone
                })
                .then(() => {
                  axios
                    .get(`${codelabOauth}/accounts/longan/login/?process=login`)
                    .then(() => {
                      // 跳转
                      window.location.replace(
                        `${codelabOauth}/accounts/longan/login/?process=login`
                      );
                    });
                });
              // 授权登录
            });
          } else {
            // 绑定账号
            getUserInfo('me').then(({ data }) => {
              const { id: userid } = qs.parse(window.location.search);
              const { id, username, email, phone, user_type } = data;

              console.log(qs.parse(window.location.search), 'SEARCH');
              const requestData = {
                id,
                username,
                email,
                phone,
                userid: +userid,
                app_metadata: {
                  role: user_type
                },
                platform: 'longan'
              };

              // return console.log(requestData);

              axios
                .post(`${codelabOauth}/api/v1/thridaccount_bind/`, requestData)
                .then(({ data }) => {
                  const { status, codelab_user_name } = data || {};
                  // return;
                  if (status === 1) {
                    console.log('注册跳转');
                    window.location.replace(
                      `${codelab}/accounts/set#oauth_pass`
                    );
                  } else if (status === 0) {
                    // 显示绑定过的弹窗提示
                    Modal.error({
                      className: 'authorize-modal',
                      title: <b>绑定失败</b>,
                      icon: <CloseCircleFilled />,
                      content: (
                        <section>
                          当前「英荔教学云」校园教师账号已经绑定 CodeLab 账户：
                          {codelab_user_name}
                          <br />
                          <span className="binded-tip">
                            每个「英荔教学云」校园教师账号只能绑定一个 CodeLab
                            账户，如果您想继续绑定当前校园教师账号，请先登录上述
                            CodeLab 账户解除绑定。
                          </span>
                        </section>
                      ),
                      okText: '确定'
                    });
                    console.log('绑定过', data);
                  } else if (status === 2) {
                    message.error('绑定失败');
                  }
                });
            });
          }
        })
        .catch(e => {
          if (e?.response?.data && typeof e.response.data === 'object') {
            Object.keys(e.response.data).forEach(key => {
              e.response.data[key] = {
                help:
                  {
                    验证码不正确: '请输入正确的验证码',
                    该手机号未注册: '该账户不存在'
                  }[e.response.data[key]] || e.response.data[key],
                validateStatus: 'error'
              };
            });

            setSubmitErr(e.response.data);
          }
        });
    });
  };

  useEffect(() => {
    fetch('/docs/termsOfService.md')
      .then(response => response.text())
      .then(text => {
        setMarkdown(text);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  return (
    <div id="authorize" className="container">
      <Modal
        visible={visible}
        title={null}
        footer={null}
        width={734}
        onCancel={() => handleChangeVisible(false)}
      >
        <div className="service-term">
          <ReactMarkdown
            escapeHtml={false}
            linkTarget="_blank"
            source={markdown}
          />
        </div>
      </Modal>
      <Modal
        closeIcon={
          <img src="/image/close.png" alt="close" width="14px" height="14px" />
        }
        getContainer={false}
        className="modal"
        centered
        visible={showCodeFAQ}
        footer={null}
        onOk={() => setshowCodeFAQ(false)}
        onCancel={() => setshowCodeFAQ(false)}
        width={'730px'}
      >
        <div className="content">
          <div className="title">如何获得代码？</div>
          <p>代码是 6 位英文字母，如：SIJENF、DUVHSD、WFNOWS；</p>
          <p>如何获得：</p>
          <p>
            1、代码是注册用户创建团队后，系统自动赋予给团队的唯一标识，
            <span style={{ color: 'red' }}>
              只有团队成员才可以使用代码登录 Longan 平台。
            </span>
          </p>
          <p>
            2、请你的老师或系统管理员在「我的管理 - 团队 -
            成员管理」的页面下，点击「专属链接」或者 「打印登录卡」
            按钮，来获得该团队的代码。
          </p>
          <div className="buttonDiv">
            <Button type="primary" onClick={() => setshowCodeFAQ(false)}>
              我知道了
            </Button>
          </div>
        </div>
      </Modal>
      <div className="authorize">
        <div className="login_panel">
          <Menu
            onClick={values => {
              prevCurrent.current = current;
              setCurrent(values.key);
              setSubmitErr({
                username: null,
                password: null,
                phone: null,
                captcha: null,
                code: null
              });
            }}
            selectedKeys={[current]}
            mode="horizontal"
            style={{ textAlign: 'center' }}
          >
            <Menu.Item key="account">账号登录</Menu.Item>
            <Menu.Item key="captcha">验证码登录</Menu.Item>
          </Menu>
          <div className="form">
            <QueueAnim
              delay={current === 'account' ? 450 : 0}
              type="left"
              appear={false}
            >
              {current === 'account' ? (
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onValuesChange={onValuesChange}
                  onFinish={onFinish}
                  key="account"
                >
                  <Form.Item
                    name="username"
                    {...submitErr.username}
                    rules={[{ required: true, message: '请输入用户名/手机号' }]}
                  >
                    <Input size="large" placeholder="用户名/手机号" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    {...submitErr.password}
                    rules={[{ required: true, message: '请输入正确的密码' }]}
                  >
                    <Input.Password size="large" placeholder="密码" />
                  </Form.Item>
                  <Form.Item className="forget-password">
                    {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox className="color-caption">
                        最近30天自动登录
                      </Checkbox>
                    </Form.Item> */}

                    <Link to="/resetpassword" className="login-form-forgot">
                      忘记密码
                    </Link>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      block
                      className="login-form-button"
                    >
                      授权并{isBind ? '登录' : '绑定'}
                    </Button>
                    {/* <div className="register">
                      还没有账号，<a href="https://longan.link">请联系顾问</a>
                    </div> */}
                  </Form.Item>
                </Form>
              ) : null}
            </QueueAnim>
            <QueueAnim
              delay={current === 'captcha' ? 450 : 0}
              type={
                current === 'captcha'
                  ? prevCurrent.current === 'account'
                    ? 'right'
                    : 'left'
                  : current === 'account'
                  ? 'right'
                  : 'left'
              }
            >
              {current === 'captcha' && (
                <Form
                  name="normal_login"
                  className="login-form"
                  key="captcha"
                  initialValues={{ remember: true, prefix: '86' }}
                  onValuesChange={onValuesChange}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="phone"
                    {...submitErr.phone}
                    rules={[
                      { required: true, message: '请输入手机号' },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || (value && testPhone(value))) {
                            return Promise.resolve();
                          }
                          return Promise.reject('请输入正确的手机号');
                        }
                      })
                    ]}
                  >
                    <Input
                      size="large"
                      addonBefore={
                        <Form.Item name="prefix" noStyle>
                          <Select
                            defaultValue="86"
                            style={{ width: 65 }}
                            suffixIcon={<CaretDownOutlined />}
                          >
                            <Select.Option value="86">+86</Select.Option>
                          </Select>
                        </Form.Item>
                      }
                      placeholder="手机号"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <div className="captcha">
                      <Form.Item
                        {...submitErr.captcha}
                        name="captcha"
                        style={{ marginBottom: '0' }}
                        rules={[{ required: true, message: '请输入验证码' }]}
                      >
                        <Input size="large" placeholder="验证码" />
                      </Form.Item>
                    </div>
                    <Button
                      size="large"
                      type="primary"
                      disabled={coldDown || !testPhone(formData.phone)}
                      className="captcha-btn"
                      onClick={getCaptcha}
                    >
                      {coldDown
                        ? `${coldDownTime}s 后可重新发送`
                        : '获取验证码 '}
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      block
                      className="login-form-button"
                    >
                      授权并{isBind ? '登录' : '绑定'}
                    </Button>
                    {/* <div className="register">
                      还没有账号，<a href="https://longan.link">请联系顾问</a>
                    </div> */}
                  </Form.Item>
                </Form>
              )}
            </QueueAnim>
          </div>
        </div>

        <div className="auth_panel ">
          <p className="tip">
            <a href="https://create.codelab.club">CodeLab</a> 将获得以下权限：
          </p>
          <ul className="auth_list">
            {list.map((item, index) => (
              <li key={index}>
                <Checkbox
                  checked={item.checked}
                  disabled={item.disabled}
                  onChange={e => handleCheck(e.target.checked, index)}
                >
                  {item.value}
                </Checkbox>
              </li>
            ))}
            {/* <li>
              <Checkbox className="color-caption">全选</Checkbox>
            </li> */}
          </ul>
          <p>
            授权后表明你已同意{' '}
            <span onClick={() => handleChangeVisible(true)} className="term">
              《登录服务协议》
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Authorize);
